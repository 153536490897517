import { Close } from "@mui/icons-material"
import { Box, Dialog, DialogContent, DialogTitle, IconButton, SxProps, Theme } from "@mui/material"
import { AssetModel, ItemModel, Spinner } from "@recall/common"
import { groupBy, orderBy } from "lodash"
import { FC, useEffect, useState } from "react"
import { Files } from "./Files"

interface Props {
    item: ItemModel
    closeModal: () => void
}

export interface GroupedAssets {
    order: string[]
    groupedAssets: Record<string, AssetModel[]>
}

export const AssetsModal: FC<Props> = ({ item, closeModal }) => {
    const [assets, setAssets] = useState<null | GroupedAssets>(null)

    const getAssets = async () => {
        const assets = await item.assets.fetch()
        const groupedAssets = groupBy(assets, (asset) => new Date(asset.createdAt).toDateString())

        Object.keys(groupedAssets).forEach((date) => {
            groupedAssets[date] = orderBy(
                groupedAssets[date],
                [(asset) => asset.createdAt],
                ["desc"]
            )
        })

        const order = orderBy(
            Object.keys(groupedAssets),
            [(date) => new Date(date).valueOf()],
            ["desc"]
        )

        setAssets({ order, groupedAssets })
    }

    useEffect(() => {
        getAssets()
    }, [])

    return (
        <Dialog open={true} onClose={closeModal} PaperProps={{ sx: styles.paper }}>
            <DialogTitle display="flex" justifyContent="space-between">
                Images{" "}
                <IconButton onClick={closeModal}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {!assets ? (
                    <Box sx={styles.loader}>
                        <Spinner />
                    </Box>
                ) : (
                    <Files assets={assets} item={item} getAssets={getAssets} />
                )}
            </DialogContent>
        </Dialog>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    paper: {
        maxWidth: 700,
        width: "100%",
        background: (theme) => theme.palette.background.default,
        border: (theme) => `1px solid ${theme.palette.action.hover}`,
    },
    loader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}
