var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import CloseIcon from "@mui/icons-material/Close";
import ModeIcon from "@mui/icons-material/Mode";
import { alpha, Box } from "@mui/material";
import { findNodePath, focusEditor, PlateElement } from "@udecode/plate-common";
import { useEffect, useState } from "react";
import { Range, Transforms } from "slate";
import { ActionButton } from "../../components/buttons/ActionButton";
import { ItemPreviewPopper } from "../../components/item/ItemPreviewPopper";
import { useHoverDelay } from "../../hooks/useHoverDelay";
import { useIsMobile } from "../../hooks/useIsMobile";
import { RECALL_APP_URL } from "../../settings";
import { useMyEditorState } from "../types";
export const REFERENCE_DIV_ID = "id-reference";
export const ReferenceElement = (_a) => {
    var { children, element, readOnly, openItemById, getItemByConnectionId, deleteConnectionById } = _a, props = __rest(_a, ["children", "element", "readOnly", "openItemById", "getItemByConnectionId", "deleteConnectionById"]);
    const [item, setItem] = useState(null);
    const isMobile = useIsMobile();
    const editor = useMyEditorState();
    const isCollapsed = Boolean(editor.selection && Range.isCollapsed(editor.selection));
    const canShowPopper = editor.selection === null || isCollapsed;
    const { isOpen, setIsOpen, handleMouseEnter, handleMouseLeave } = useHoverDelay(300);
    useEffect(() => {
        getConnectionItem();
    }, [element.connectionId]);
    const getConnectionItem = async () => {
        const item = await getItemByConnectionId(element.connectionId);
        if (!item)
            return;
        setItem(item);
    };
    const handleDeleteConnection = async () => {
        await deleteConnectionById(element.connectionId);
    };
    const handleClickEdit = () => {
        var _a, _b;
        setIsOpen(false);
        const text = (_b = (_a = element.children) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.text;
        if (text) {
            const path = findNodePath(editor, element);
            if (path) {
                path.push(0);
                focusEditor(editor);
                Transforms.select(editor, { path: path, offset: text.length });
            }
        }
    };
    const handleClick = (event) => {
        if (!item)
            return;
        if (event.metaKey || event.ctrlKey || event.button === 1) {
            window.open(`${RECALL_APP_URL}/item/${item.id}`, "_blank");
        }
    };
    const actionButtonsStart = (_jsx(ActionButton, Object.assign({ onClick: handleClickEdit, tooltip: "Edit text" }, { children: _jsx(ModeIcon, { fontSize: "inherit" }) })));
    const actionButtonsEnd = (_jsx(ActionButton, Object.assign({ onClick: () => setIsOpen(false), tooltip: "Close" }, { children: _jsx(CloseIcon, { fontSize: "inherit" }) })));
    return (_jsx(PlateElement, Object.assign({ id: REFERENCE_DIV_ID, as: "span", onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, element: element }, props, { children: _jsx(Box, Object.assign({ component: "span", id: element.editorBlockId + element.connectionId }, { children: _jsx(ItemPreviewPopper, Object.assign({ deleteConnection: handleDeleteConnection, openItemById: openItemById, item: item, actionButtonsStart: isMobile ? actionButtonsStart : undefined, actionButtonsEnd: isMobile ? actionButtonsEnd : undefined, open: isOpen && canShowPopper && Boolean(item), readOnly: readOnly }, { children: _jsx(Box, Object.assign({ component: "a", href: item ? `/item/${item.id}` : undefined, sx: styles.wrapper, onClick: handleClick }, { children: children })) })) })) })));
};
const styles = {
    wrapper: {
        cursor: "pointer",
        outline: "none",
        textDecoration: "none",
        color: (theme) => (theme.palette.mode === "dark" ? "#f5d471" : "#3366cc") + "!important",
        "&:hover": {
            color: (theme) => alpha(theme.palette.mode === "dark" ? "#f5d471" : "#3366cc", 0.8),
        },
        "& ::selection": {
            color: (theme) => (theme.palette.mode === "dark" ? "primary.dark" : undefined),
        },
    },
};
