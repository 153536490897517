var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Q } from "@nozbe/watermelondb";
import { EditorBlockData } from "../../../services/editorData/EditorBlockData";
import { EDITOR_BLOCKS } from "../schema";
const getEditorBlocksByItemId = (db, itemId) => {
    return db.collections
        .get(EDITOR_BLOCKS)
        .query(Q.where("item_id", itemId))
        .fetch();
};
const get = async (db, id) => {
    try {
        return await db.collections.get(EDITOR_BLOCKS).find(id);
    }
    catch (_a) {
        return null;
    }
};
const getByIds = async (db, ids) => {
    try {
        return await db.collections
            .get(EDITOR_BLOCKS)
            .query(Q.where("id", Q.oneOf(ids)));
    }
    catch (_a) {
        return [];
    }
};
const remove = async (db, id) => {
    return await db.write(async (action) => {
        const editorBlock = await get(db, id);
        if (!editorBlock)
            return;
        await action.callWriter(() => editorBlock.delete());
    });
};
const upsert = async (db, itemId, editorBlock, isSaved = true) => {
    return await db.write(async () => {
        if (!editorBlock.id)
            return null;
        const editorBlockModel = await get(db, editorBlock.id);
        const { id, type, children, options } = editorBlock, rest = __rest(editorBlock, ["id", "type", "children", "options"]);
        const newOptions = Object.assign({}, rest);
        const text = EditorBlockData.getFormattedText([editorBlock]);
        if (editorBlockModel) {
            return editorBlockModel.update((record) => {
                record.type = type;
                record.children = children;
                record.options = newOptions || {};
                record.text = text;
            });
        }
        else {
            return db.collections.get(EDITOR_BLOCKS).create((record) => {
                if (editorBlock.id)
                    record._raw.id = editorBlock.id;
                record.item.id = itemId;
                record.children = children;
                record.isSaved = isSaved;
                record.type = type;
                record.options = newOptions || {};
                record.text = text;
            });
        }
    });
};
export const editorBlockRepository = {
    getEditorBlocksByItemId,
    getByIds,
    upsert,
    remove,
    get,
};
