var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { map, uniq, uniqBy } from "lodash";
import { useCallback } from "react";
import { TagChip } from "../chips/TagChip";
const filter = createFilterOptions();
export const SearchAutocomplete = ({ value = [], handleChange, options, sx, textFieldProps = {}, isSearchOnly = false, optionName = "tag", inputValue, setInputValue, }) => {
    const onChange = (e, values, reason) => {
        if (reason === "removeOption" && ["Backspace", "Enter"].includes(e.code))
            return;
        const newValue = values.map((option) => typeof option === "string" ? option : option.label);
        handleChange(uniqBy(newValue, (tagName) => tagName.toLowerCase().trim()));
    };
    const filterOptions = useCallback((options, params) => {
        let filtered = filter(options, params);
        const groups = map(filtered, "group");
        const groupsCount = uniq(groups).length;
        if (groupsCount === 1) {
            filtered = filtered.map((_a) => {
                var { group } = _a, option = __rest(_a, ["group"]);
                return option.isPermanentGroup ? Object.assign(Object.assign({}, option), { group }) : option;
            });
        }
        if (isSearchOnly)
            return filtered;
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue.toLowerCase().trim() === option.label.toLowerCase().trim());
        if (isExisting || inputValue === "")
            return filtered;
        const inputExistInValues = value.some(({ value }) => value.trim().toLowerCase() === inputValue.trim().toLowerCase());
        if (inputExistInValues) {
            return [
                ...filtered,
                {
                    value: inputValue,
                    label: inputValue,
                    exist: true,
                },
            ];
        }
        return [
            ...filtered,
            {
                value: inputValue,
                label: inputValue,
                create: true,
            },
        ];
    }, 
    // eslint-disable-next-line
    [isSearchOnly]);
    const getOption = (option, input) => {
        if (option.create)
            return `Create: ${option.label}`;
        if (option.exist)
            return `Tag already exist`;
        return _jsx(TagChip, { sx: styles.tagChip, label: option.label, searchText: input });
    };
    return (_jsx(Autocomplete, { multiple: true, noOptionsText: `No ${optionName}s`, inputValue: inputValue, onInputChange: (_, newInputValue) => {
            setInputValue(newInputValue);
        }, options: options || [], isOptionEqualToValue: (option, value) => option.value === value.value, groupBy: (option) => option.group || "", freeSolo: !isSearchOnly, filterSelectedOptions: true, value: value, 
        // @ts-ignore
        onChange: onChange, ListboxProps: {
            // @ts-ignore
            sx: styles.listbox,
        }, sx: sx, openOnFocus: true, disableClearable: true, clearIcon: null, popupIcon: null, filterOptions: filterOptions, renderTags: () => null, renderOption: (props, option, state) => (_createElement(Box, Object.assign({ component: "li" }, props, { key: option.id ? option.id : option.label, sx: {
                p: 1,
                py: "4px !important",
                minHeight: "20px !important",
            }, onClick: option.exist ? undefined : props.onClick }), getOption(option, state.inputValue))), renderInput: (params) => {
            const InputProps = (textFieldProps === null || textFieldProps === void 0 ? void 0 : textFieldProps.InputProps) || {};
            return (_jsx(TextField, Object.assign({ autoFocus: true }, textFieldProps, params, { InputProps: Object.assign(Object.assign({}, params.InputProps), InputProps), "data-testid": "card-tag-input" })));
        } }));
};
const styles = {
    listbox: {
        border: (theme) => theme.borders.paper,
        borderRadius: 1,
        py: 0,
        mt: 0.5,
        "& .MuiAutocomplete-groupLabel": {
            fontSize: "1rem",
            lineHeight: "2rem",
        },
        "& .MuiAutocomplete-option": {
            pl: "8px !important",
        },
    },
    tagChip: {
        cursor: "pointer",
        direction: "rtl",
    },
};
