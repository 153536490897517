import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton, Tooltip } from "@mui/material";
import { usePlateEditorState } from "@udecode/plate-common";
import { ListStyleType, useIndentListToolbarButton, useIndentListToolbarButtonState, } from "@udecode/plate-indent-list";
import { toggleIndentList } from "../../utils/indentList";
export const IndentListToolbarButton = ({ nodeType = ListStyleType.Disc, children }) => {
    const state = useIndentListToolbarButtonState({ nodeType });
    const { props } = useIndentListToolbarButton(state);
    const editor = usePlateEditorState();
    const handleClick = () => {
        toggleIndentList(editor, {
            listStyleType: nodeType === ListStyleType.Disc ? "disc" : "decimal",
        });
    };
    return (_jsx(Tooltip, Object.assign({ title: nodeType === ListStyleType.Disc ? "Bulleted List" : "Numbered List" }, { children: _jsx(IconButton, Object.assign({ onClick: handleClick, size: "small", sx: {
                color: (theme) => props.pressed ? theme.palette.text.primary : theme.palette.action.active,
                backgroundColor: (theme) => props.pressed ? theme.palette.action.selected : undefined,
                ":hover": {
                    backgroundColor: (theme) => props.pressed ? theme.palette.action.selected : undefined,
                },
            } }, { children: children })) })));
};
