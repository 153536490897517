import { Close } from "@mui/icons-material"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from "@mui/material"
import { PRICING_PATH } from "constants/routes"
import { FC } from "react"
import { useHistory } from "react-router"

interface Props {
    closeModal(): void
}

export const ScrapingModal: FC<Props> = ({ closeModal }) => {
    const history = useHistory()

    const handleOnClick = () => {
        closeModal()
        history.push(PRICING_PATH)
    }

    return (
        <Dialog onClose={closeModal} open>
            <DialogTitle>
                You have reached your limit of cards that can be saved using the Recall Browser
                Extension{" "}
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Upgrade to <strong>Recall Plus</strong> to continue adding to your knowledge
                    cards using the Recall Browser Extension.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" variant="contained" onClick={handleOnClick} fullWidth>
                    View Plans
                </Button>
            </DialogActions>
        </Dialog>
    )
}
