import { GridViewOutlined, ViewAgendaOutlined } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { SET_ITEMS_VIEW } from "storage/redux/items/actionTypes"
import { RootState } from "storage/redux/rootReducer"

export const ViewToggle = () => {
    const view = useSelector((state: RootState) => state.items.view)
    const dispatch = useDispatch()

    const toggleView = () => {
        dispatch({ type: SET_ITEMS_VIEW, payload: view === "grid" ? "list" : "grid" })
    }

    return (
        <IconButton onClick={toggleView}>
            {view === "grid" ? <ViewAgendaOutlined /> : <GridViewOutlined />}
        </IconButton>
    )
}
