import { useDatabase } from "@nozbe/watermelondb/react"
import {
    CHECK_USER_PREMIUM_STATE,
    checkExtensionStatus,
    dbUtils,
    firebase,
    premiumUserService,
    sendMessageToExtension,
    sentry,
    SummaryLengthEnum,
    User,
} from "@recall/common"
import { onAuthStateChanged, signInWithCustomToken } from "firebase/auth"
import { useInit } from "hooks/auth/useInit"
import { useBreadcrumbActions } from "hooks/useBreadcrumbActions"
import { seenOptions } from "hooks/useIsSeen"
import { useQueryParameter } from "hooks/useQueryParameter"
import { isEmpty } from "lodash"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { userRepository } from "repositories/userRepository"
import { referralService } from "services/referralService"
import { storageService } from "services/storageService"
import { SET_APP_LOADING, SET_USER_LOADING } from "storage/redux/app/actionTypes"
import { LOGOUT_ACTION, RootState } from "storage/redux/rootReducer"
import { SET_USER } from "storage/redux/user/actionTypes"
import { initializeUser } from "../../utils/config"
import { useClearOnLogout } from "./useClearOnLogout"
import { useExtensionAuth } from "./useExtensionAuth"

export const useAuthenticate = () => {
    const dispatch = useDispatch()
    const uid = useSelector((state: RootState) => state.user.uid)
    const isPremiumUser = useSelector((state: RootState) => state.user.isPremiumUser)
    const { initOnboarding, initTutorials } = useInit()
    const { resetBreadcrumbs } = useBreadcrumbActions()
    const db = useDatabase()
    const extensionAuth = useExtensionAuth()
    const { param: token, remove } = useQueryParameter("authToken")

    const removeAnonymousUserData = async () => {
        await dbUtils.deleteDatabase(db)
    }

    useClearOnLogout()

    useEffect(() => {
        if (uid) loginExtension()
    }, [])

    const loginExtension = async () => {
        await extensionAuth.loginWithCustomToken()
        const { isExtensionInstalled, isExtensionLoggedIn } = await checkExtensionStatus()
        if (isExtensionInstalled && isExtensionLoggedIn)
            sendMessageToExtension({
                type: CHECK_USER_PREMIUM_STATE,
                isPremium: isPremiumUser,
            })
    }

    const loginWithToken = async () => {
        if (!token) return

        try {
            await signInWithCustomToken(firebase.auth, token)
            remove()
        } catch (e) {
            sentry.captureException(e)
        }
    }

    useEffect(() => {
        loginWithToken()
    }, [])

    const migrateUserFields = async (user: User, uid: string) => {
        if (user?.isReviewEmailEnabled === undefined)
            await userRepository.upsertUser(uid, { isReviewEmailEnabled: true })

        if (user?.isOnboardingBannerVisible === undefined) {
            const lastSeen = storageService.getItem(seenOptions.ONBOARDING_BANNER)
            let isOnboardingBannerVisible = !Boolean(lastSeen)
            await userRepository.upsertUser(uid, { isOnboardingBannerVisible })
        }
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebase.auth, async (currentUser) => {
            if (!currentUser) {
                dispatch({ type: LOGOUT_ACTION })
                dispatch({ type: SET_APP_LOADING, payload: true })
                dispatch({ type: SET_USER_LOADING, payload: false })
                userRepository.removeUser()
                extensionAuth.logout()
                resetBreadcrumbs(false)
                return
            }
            dispatch({ type: SET_USER_LOADING, payload: false })

            const user = await userRepository.getUser(currentUser.uid)
            if (currentUser.emailVerified && user?.referralTokenUsed)
                referralService.verifyReferralUsed(currentUser.uid, user.referralTokenUsed)

            await migrateUserFields(user, currentUser.uid)

            await initializeUser(db, currentUser)

            const [, completedTutorials] = await Promise.all([
                initOnboarding(currentUser),
                initTutorials(currentUser),
            ])

            const isPremiumUser = await premiumUserService.checkIsPremiumUser(currentUser.uid)

            const isExistingUserLoggedIn = !uid && !isEmpty(user)

            if (isExistingUserLoggedIn) await removeAnonymousUserData()

            dispatch({
                type: SET_USER,
                payload: {
                    uid: currentUser.uid,
                    email: currentUser.email,
                    emailVerified: currentUser.emailVerified,
                    surveys: user?.surveys || null,
                    modals: user?.modals || [],
                    menu: {
                        expandedTags: user?.menu?.expandedTags,
                    },
                    completedTutorials,
                    dbVersion: user?.dbVersion || null,
                    isPremiumUser,
                    language: user?.summaryOptions?.language || "auto",
                    searchLanguage: user?.summaryOptions?.searchLanguage || "en",
                    defaultLength:
                        user?.summaryOptions?.defaultLength || SummaryLengthEnum.detailed,
                    sharedCards: user?.sharedCards || [],
                    isReviewEmailEnabled:
                        user?.isReviewEmailEnabled === undefined
                            ? true
                            : user?.isReviewEmailEnabled,
                    isOnboardingBannerVisible: user?.isOnboardingBannerVisible,
                    usage: user?.usage?.extension || 0,
                },
            })
        })

        return unsubscribe

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, db, firebase.auth])
}
