import { hasUnsyncedChanges } from "@nozbe/watermelondb/sync";
import { sentry } from "../../utils";
import { COLLECTIONS } from "./schema";
const isStatusSynced = (record) => {
    return record.syncStatus === "synced";
};
const subscribeOnChanges = (db, callback) => {
    const subscription = db.withChangesForTables(COLLECTIONS).subscribe({
        next: async (event) => {
            if (!event)
                return;
            if (event.every(({ record }) => isStatusSynced(record)))
                return;
            callback();
        },
        error: (e) => sentry.captureException(e),
    });
    return subscription;
};
const checkUnsyncedChanges = async (db) => {
    return await hasUnsyncedChanges({ database: db });
};
const deleteDatabase = async (db) => {
    await db.write(async () => {
        await db.unsafeResetDatabase();
    });
};
const escapeId = (id) => {
    const regex = /[.,$#[\]/"]/g;
    return id.replace(regex, "-");
};
const sanitizeSearchTerm = (term) => {
    const specialChars = [
        "\\",
        "^",
        "$",
        "*",
        "+",
        "?",
        ".",
        "(",
        ")",
        "|",
        "{",
        "}",
        "[",
        "]",
        '"',
        "'",
    ];
    return term
        .split("")
        .map((char) => (specialChars.includes(char) ? `\\${char}` : char))
        .join("");
};
export const dbUtils = {
    escapeId,
    checkUnsyncedChanges,
    deleteDatabase,
    sanitizeSearchTerm,
    subscribeOnChanges,
};
