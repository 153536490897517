const post = (type: string, data: any = {}, onlyNative = false) => {
    if (onlyNative && !window?.isNative) return

    if (window?.isNative) {
        //@ts-ignore
        window?.ReactNativeWebView?.postMessage(JSON.stringify({ type, data }))
    } else {
        window.postMessage(JSON.stringify({ type, data }))
    }
}

export const messagesService = { post }
