import { useMemo, useRef, useState } from "react";
import { useIsMobile } from "./useIsMobile";
export const useHoverDelay = (mouseEnterDelay, mouseLeaveDelay = 100) => {
    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useIsMobile();
    const hoverEnterTimeout = useRef(null);
    const hoverLeaveTimeout = useRef(null);
    const handleMouseEnter = () => {
        clearTimeout(hoverLeaveTimeout.current);
        if (isMobile) {
            setIsOpen(true);
            return;
        }
        hoverEnterTimeout.current = setTimeout(() => {
            setIsOpen(true);
        }, mouseEnterDelay);
    };
    const handleMouseLeave = () => {
        clearTimeout(hoverEnterTimeout.current);
        hoverLeaveTimeout.current = setTimeout(() => {
            setIsOpen(false);
        }, mouseLeaveDelay);
    };
    return useMemo(() => ({
        isOpen,
        setIsOpen,
        handleMouseEnter,
        handleMouseLeave,
    }), 
    // eslint-disable-next-line
    [isOpen]);
};
