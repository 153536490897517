import { Box, LinearProgress, SxProps, Theme, Typography } from "@mui/material"
import { FC } from "react"

interface Props {
    referralsCount: number
}

export const ReferralsProgress: FC<Props> = ({ referralsCount }) => {
    return (
        <Box sx={styles.progress}>
            <LinearProgress
                color="secondary"
                variant="determinate"
                value={referralsCount * 10 * 2}
            />
            <Typography sx={styles.progressLabel} fontWeight="bold">
                You have {referralsCount * 10}% off
            </Typography>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    title: {
        textAlign: "center",
        mb: 3,
    },
    progress: {
        mb: 2,
    },
    progressLabel: {
        mt: 1,
        textAlign: "center",
    },
}
