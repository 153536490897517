import { useCallback, useEffect, useState } from "react"
import { httpClient } from "services/httpClient"

export const useIsOnline = () => {
    const [isOnline, setIsOnline] = useState(true)

    const setOffline = useCallback(() => {
        setIsOnline(false)
    }, [])

    const setOnline = useCallback(() => {
        setIsOnline(true)
    }, [])

    const checkIsOnline = async () => {
        try {
            await httpClient.head(`/status-check/`)
        } catch {
            setOffline()
        }
    }

    useEffect(() => {
        checkIsOnline()

        window.addEventListener("offline", setOffline)
        window.addEventListener("online", setOnline)

        return () => {
            window.removeEventListener("offline", setOffline)
            window.removeEventListener("online", setOnline)
        }
        // eslint-disable-next-line
    }, [])

    return isOnline
}
