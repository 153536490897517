import { ItemModel, SourceModel } from "@recall/common"
import { useEffect, useState } from "react"

export const useSources = (item: ItemModel) => {
    const [sources, setSources] = useState<SourceModel[]>([])

    useEffect(() => {
        getSources()
    }, [item.id])

    const getSources = async () => {
        const sources = await item.sources
        setSources(sources)
    }

    return sources
}
