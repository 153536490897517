var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Model } from "@nozbe/watermelondb";
import { field, json, relation, writer } from "@nozbe/watermelondb/decorators";
import { EDITOR_ORDERS } from "../schema";
const sanitizeEditorOrder = (data) => {
    return data;
};
export class EditorOrderModel extends Model {
    constructor() {
        super(...arguments);
        this.prepareSave = () => {
            if (this.isSaved === false) {
                return this.prepareUpdate((record) => {
                    record.isSaved = true;
                });
            }
            return null;
        };
        this.prepareDelete = () => {
            if (this._preparedState === null) {
                if (this.isSaved) {
                    return this.prepareMarkAsDeleted();
                }
                else {
                    return this.prepareDestroyPermanently();
                }
            }
        };
    }
    async delete() {
        const task = this.prepareDelete();
        await this.batch(task);
    }
}
EditorOrderModel.table = EDITOR_ORDERS;
EditorOrderModel.associations = {
    items: { type: "belongs_to", key: "item_id" },
};
__decorate([
    field("is_saved")
], EditorOrderModel.prototype, "isSaved", void 0);
__decorate([
    relation("items", "item_id")
], EditorOrderModel.prototype, "item", void 0);
__decorate([
    json("order", sanitizeEditorOrder)
], EditorOrderModel.prototype, "order", void 0);
__decorate([
    writer
], EditorOrderModel.prototype, "delete", null);
