import ImageIcon from "@mui/icons-material/Image"
import { alpha, Box, SxProps, Theme } from "@mui/material"

interface Props {
    sx?: SxProps<Theme>
    image: string
}

export const PlaceHolderImage = ({ sx }: { sx: Props["sx"] }) => {
    return (
        <Box sx={[styles.image, ...(Array.isArray(sx) ? sx : [sx])]}>
            <ImageIcon sx={styles.icon} fontSize="large" />
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    image: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.05),
        width: 90,
        minHeight: 130,
        height: "100%",
    },
    icon: {
        color: "action.selected",
    },
}
