import { FC } from "react"
import { useFocusItem } from "./hooks/useFocusItem"
import { GroupedTags } from "./hooks/useGroupedTags"

interface Props {
    tags: GroupedTags
    expandedTags: string[]
    makeTagVisibleInTree: (id: string) => void
    handleChangeExpanded: (ids: string[]) => void
}

export const FocusItemProvider: FC<Props> = (props) => {
    useFocusItem(props)

    return null
}
