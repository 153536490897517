import { ArrowForwardIosSharp, Lock, Refresh, TaskAlt } from "@mui/icons-material"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    IconButton,
    Paper,
    SxProps,
    Theme,
    Typography,
    alpha,
} from "@mui/material"
import { useIsMobile } from "hooks/useIsMobile"
import { FC } from "react"

interface Props {
    title: string
    isCompleted: boolean
    isEnabled?: boolean
    isActive?: boolean
    isSkipped?: boolean
    completeLabel?: string
    skippedLabel?: string
    description?: string
    step?: number
    handleComplete?: () => void
    handleSkip?: () => void
}

export const OnboardingStep: FC<Props> = ({
    step,
    title,
    isCompleted,
    isEnabled,
    completeLabel = "Start",
    isSkipped,
    description,
    skippedLabel = "Skipped",
    handleComplete,
}) => {
    const isActionVisible = !isCompleted && isEnabled && !isSkipped
    const isMobile = useIsMobile()

    const handleStart = (e) => {
        e.stopPropagation()
        handleComplete()
    }

    return (
        <Accordion disableGutters>
            <AccordionSummary
                sx={styles.container}
                expandIcon={
                    description ? <ArrowForwardIosSharp sx={styles.arrowIcon} /> : undefined
                }
            >
                {!isEnabled && (
                    <Paper sx={styles.lockOverlay}>
                        <Lock fontSize="large" />
                    </Paper>
                )}
                {isEnabled && isCompleted && (
                    <>
                        <Paper sx={styles.completeOverlay}></Paper>
                        {!isMobile && <TaskAlt color="success" sx={styles.completeIcon} />}
                    </>
                )}
                {isSkipped && <Paper sx={styles.completeOverlay} />}
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flex={1}
                    pl={1}
                >
                    <Typography variant="body1" sx={styles.title} flex={1} py={{ xs: 1, md: 2 }}>
                        {step && `${step}. `}
                        {title}
                        {isMobile && isCompleted && isEnabled && (
                            <TaskAlt color="success" sx={styles.titleIcon} />
                        )}
                        {isSkipped && (
                            <IconButton onClick={handleStart} sx={styles.resetButton}>
                                <Refresh color="secondary" />
                            </IconButton>
                        )}
                    </Typography>
                    <Box px={isActionVisible || isSkipped ? 2 : 0}>
                        {isActionVisible && (
                            <Box height="100%" gap={1.5}>
                                {handleComplete && (
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color={"primary"}
                                        onClick={handleStart}
                                    >
                                        {completeLabel}
                                    </Button>
                                )}
                            </Box>
                        )}
                        {isSkipped && (
                            <Box mr={4} sx={styles.skippedAction}>
                                <Typography color="primary">{skippedLabel}</Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            </AccordionSummary>
            {description && (
                <AccordionDetails sx={{ pt: 0 }}>
                    <Typography variant="body2">{description}</Typography>
                </AccordionDetails>
            )}
        </Accordion>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        mt: 1,
        p: 0,
        position: "relative",
        "& .MuiAccordionSummary-contentGutters": {
            my: 0,
        },
        "& .MuiAccordionSummary-content": {
            m: 0,
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
        },
        flexDirection: "row-reverse",
        ml: 1.8,
    },
    activeStep: {
        border: (theme) => `1px solid ${theme.palette.secondary.main}`,
    },
    titleIcon: {
        ml: 1,
    },
    resetButton: {
        zIndex: 101,
    },
    actions: {
        justifyContent: "flex-end",
        alignItems: "center",
    },
    title: {
        display: "flex",
        alignItems: "center",
    },
    lockOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: 0.3,
    },
    completeOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        pr: 2,
        opacity: 0.4,
    },
    completeIcon: {
        position: "absolute",
        top: "50%",
        right: 16,
        transform: "translateY(-50%)",
        zIndex: 101,
    },
    skippedAction: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    arrowIcon: {
        fontSize: "0.9rem",
        color: (theme) => alpha(theme.palette.text.primary, 0.5),
        "&:hover": {
            color: (theme) => alpha(theme.palette.text.primary, 0.8),
        },
    },
}
