import { User, UserRepository } from "@recall/common"

const LOCALSTORAGE_USER = "local-user"

const userLocalStorage = {
    get: async (): Promise<User | null> => {
        const user = localStorage.getItem(LOCALSTORAGE_USER)
        if (!user) return null
        return JSON.parse(user)
    },
    set: async (user: User) => {
        localStorage.setItem(LOCALSTORAGE_USER, JSON.stringify(user))
    },
    remove: async () => {
        localStorage.removeItem(LOCALSTORAGE_USER)
    },
}

export const userRepository = new UserRepository(userLocalStorage)
