import { getEditorString, getPointBefore, getRange, isCollapsed, } from "@udecode/plate-common";
const isSpaceBreak = (char) => !!char && /\s/.test(char);
const getPreviousChar = (editor, point) => point
    ? getEditorString(editor, getRange(editor, point, getPointBefore(editor, point)))
    : undefined;
const getPreviousPoint = (editor, point) => point ? getPointBefore(editor, point) : undefined;
const isBeginningOfTheLine = (editor, point) => {
    const previousPoint = getPreviousPoint(editor, point);
    let isPointOffsetSmaller = false;
    if ((point === null || point === void 0 ? void 0 : point.offset) && (previousPoint === null || previousPoint === void 0 ? void 0 : previousPoint.offset))
        isPointOffsetSmaller = point.offset < previousPoint.offset;
    return (point === null || point === void 0 ? void 0 : point.path[0]) !== (previousPoint === null || previousPoint === void 0 ? void 0 : previousPoint.path[0]) || isPointOffsetSmaller;
};
export const getFindTriggeringInput = (editor, emojiTriggeringController) => ({ char = "", action = "insert" } = {}) => {
    const { selection } = editor;
    if (!selection || !isCollapsed(selection) || isSpaceBreak(char)) {
        emojiTriggeringController.setIsTriggering(false);
        return;
    }
    const startPoint = selection.anchor;
    let currentPoint = startPoint;
    let previousPoint;
    let foundText = char;
    let previousChar;
    do {
        previousChar = getPreviousChar(editor, currentPoint);
        foundText = previousChar + foundText;
        previousPoint = getPreviousPoint(editor, currentPoint);
        if (isBeginningOfTheLine(editor, currentPoint)) {
            break;
        }
        currentPoint = previousPoint;
    } while (!isSpaceBreak(previousChar));
    if (action === "delete")
        foundText = foundText.slice(0, -1);
    emojiTriggeringController.setText(foundText.trim());
};
