import { Close } from "@mui/icons-material"
import { Box, Fade, Grid, IconButton, Typography } from "@mui/material"
import { alpha, SxProps, Theme } from "@mui/material/styles"
import { ONBOARDING_BANNER_CLICKED } from "constants/events"

import { FC, memo, MouseEvent } from "react"
import { useDispatch, useSelector } from "react-redux"
import { captureEventService } from "services/captureEventService"
import { RootState } from "storage/redux/rootReducer"
import { updateUser } from "storage/redux/user/actions"
import { useIsOnboardingBannerClosed } from "./hooks/useIsOnboardingBannerClosed"

interface Props {
    onClick: () => void
}

const OnboardingBannerComponent: FC<Props> = ({ onClick }) => {
    const uid = useSelector((state: RootState) => state.user.uid)
    const isClosed = useIsOnboardingBannerClosed()

    const dispatch = useDispatch()

    const handleClick = () => {
        captureEventService.capture(ONBOARDING_BANNER_CLICKED)
        onClick()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const handleClose = async (e: MouseEvent) => {
        e.stopPropagation()
        dispatch(updateUser({ isOnboardingBannerVisible: false }, uid))
    }

    if (isClosed) return null

    return (
        <Fade in timeout={500}>
            <Grid container sx={styles.banner} onClick={handleClick}>
                <Grid item flexGrow={1} sx={styles.bannerContent}>
                    <Typography variant="body2" sx={styles.text}>
                        Continue onboarding
                    </Typography>
                </Grid>
                <Grid item>
                    <Box sx={styles.closeIcon}>
                        <IconButton color="inherit" size="small" onClick={handleClose}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
        </Fade>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    banner: {
        position: "relative",
        width: "100%",
        cursor: "pointer",
        transition: "all 0.2s",
        color: (theme: Theme) => theme.palette.primary.contrastText,
        zIndex: (theme: Theme) => theme.zIndex.appBar,
        backgroundColor: (theme: Theme) => theme.palette.primary.main,
        "&:hover": {
            backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.8),
        },
    },
    bannerContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    closeIcon: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        mr: 2,
        height: "100%",
    },
    text: {
        fontWeight: 500,
        py: 0.4,
    },
}

export const OnboardingBanner = memo(OnboardingBannerComponent)
