import { Fade, SxProps, Theme } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import { TransitionProps } from "@mui/material/transitions"
import { SimpleLayout } from "components/layouts/SimpleLayout"
import { FC, PropsWithChildren, forwardRef } from "react"

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement
    },
    ref: React.Ref<unknown>
) {
    return <Fade in ref={ref} {...props} />
})

interface Props {
    handleClose: () => void
}

export const QuestionsWrapper: FC<PropsWithChildren<Props>> = ({ children, handleClose }) => {
    return (
        <Dialog
            fullScreen
            open
            onClose={handleClose}
            TransitionComponent={Transition}
            sx={styles.dialog}
        >
            <SimpleLayout onClose={handleClose}>{children}</SimpleLayout>
        </Dialog>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    dialog: {
        pr: 0,
        "& .MuiDialog-paper": {
            backgroundColor: "background.default",
        },
    },
}
