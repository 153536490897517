import { Box, Fade, useScrollTrigger } from "@mui/material"
import { OrderItemsDropdown } from "components/ItemsPage/components/OrderItemsDropdown"
import { useIsMobile } from "hooks/useIsMobile"
import { FC, memo } from "react"
import { DrawerButton } from "../Drawer/DrawerButton"
import { AddButton } from "./AddButton/AddButton"
import { BookmarksImportButton } from "./BookmarksImportButton"
import { OfflineStatus } from "./OfflineStatus"
import { ViewToggle } from "./ViewToggle"

interface Props {
    showItemsActions?: boolean
}

const HeaderComp: FC<Props> = ({ showItemsActions = false }) => {
    const trigger = useScrollTrigger({ disableHysteresis: true })
    const isMobile = useIsMobile()

    return (
        <>
            <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                gap={0.5}
                mb={2}
                zIndex={1300}
                minHeight={showItemsActions && 60}
            >
                <DrawerButton />
                <OfflineStatus />
                <BookmarksImportButton />
                <Box flexGrow={1} />

                {showItemsActions && (
                    <>
                        <ViewToggle />
                        <Box mr={0.5}>
                            <OrderItemsDropdown />
                        </Box>
                        <AddButton />
                    </>
                )}
            </Box>
            {showItemsActions && (
                <Fade in={trigger} unmountOnExit>
                    <Box
                        zIndex={1300}
                        position="fixed"
                        top={isMobile ? 55 : 50}
                        right={isMobile ? 10 : 15}
                    >
                        <AddButton />
                    </Box>
                </Fade>
            )}
        </>
    )
}

export const Header = memo(HeaderComp)
