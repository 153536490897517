import { Check } from "@mui/icons-material"
import { Button } from "@mui/material"
import { keyframes, SxProps, Theme } from "@mui/material/styles"
import { Spinner } from "@recall/common"
import { memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SET_IMPORT_STATE } from "storage/redux/app/actionTypes"
import { isBookmarksImportingFinished, isImportingBookmarksInBg } from "storage/redux/app/selectors"
import { importEnum } from "storage/redux/app/types"

const BookmarksImportButtonComponent = () => {
    const dispatch = useDispatch()

    const isImportingBookmarksFinished = useSelector(isBookmarksImportingFinished)
    const isImportingBookmarks = useSelector(isImportingBookmarksInBg)

    const handleOpenBookmarkModal = () => {
        dispatch({ type: SET_IMPORT_STATE, payload: importEnum.OPENED })
    }

    if (!isImportingBookmarks) return null

    return (
        <Button
            onClick={handleOpenBookmarkModal}
            variant="outlined"
            sx={styles.importBookmarksButton}
            endIcon={
                isImportingBookmarksFinished ? (
                    <Check color="secondary" />
                ) : (
                    <Spinner size={25} thickness={2} />
                )
            }
        >
            {isImportingBookmarksFinished ? "Bookmarks imported" : "Importing bookmarks"}
        </Button>
    )
}

const bump = keyframes`
    50% {
        transform: scale(1.2)
    }
    100% {
        transform: scale(1)
    }
`

const styles: Record<string, SxProps<Theme>> = {
    importBookmarksButton: {
        animation: `1s ${bump} ease`,
    },
}

export const BookmarksImportButton = memo(BookmarksImportButtonComponent)
