export const HOTKEYS = {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
    "mod+h": "highlight",
    // 'mod+`': 'code',
};
export const SLATE_EDITOR_DIV_ID = "id-slate-editor";
export const SLATE_EDITOR_INPUT_ID = "id-slate-editor-input";
export const REFERENCE_TYPE = "reference";
export const SEARCH_REFERENCE_TYPE = "search-reference";
export const LINK_TYPE = "link";
export const IMAGE_TYPE = "image";
export const PARAGRAPH_TYPE = "paragraph";
export const EDITOR_BLOCK_TYPE = "editor-block";
export const HEADING_ONE_TYPE = "heading-one";
export const HEADING_TWO_TYPE = "heading-two";
export const HEADING_THREE_TYPE = "heading-three";
export const HEADING_FOUR_TYPE = "heading-four";
export const HEADING_FIVE_TYPE = "heading-five";
export const HEADING_SIX_TYPE = "heading-six";
export const BLOCKQUOTE_TYPE = "block-quote";
