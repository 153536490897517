import { sentry } from "../../utils";
export const handleScroll = () => {
    var _a, _b;
    try {
        const selection = window.getSelection();
        if (!selection)
            return;
        const range = selection.getRangeAt(0);
        const screenHeight = window.innerHeight;
        const yHeight = (_b = (_a = range.getClientRects()) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.y;
        if (!yHeight)
            return;
        let scrollAmount = false;
        const heightFromBottom = screenHeight - yHeight;
        if (heightFromBottom / screenHeight < 0.55) {
            scrollAmount = screenHeight * 0.6 - heightFromBottom;
        }
        if (scrollAmount !== false) {
            window.scrollBy({
                top: scrollAmount,
                behavior: "smooth",
            });
        }
    }
    catch (e) {
        sentry.captureException(e);
        return;
    }
};
