import { deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { FIRESTORE_COLLECTIONS } from "../constants";
import { firebase } from "../services";
const update = async (params) => {
    await updateDoc(doc(firebase.firestore, FIRESTORE_COLLECTIONS.REVIEWS_SCHEDULE, params.id), Object.assign({}, params));
};
const upsert = async (uid, params) => {
    await setDoc(doc(firebase.firestore, FIRESTORE_COLLECTIONS.REVIEWS_SCHEDULE, params.id), Object.assign({ uid }, params));
};
const remove = async (id) => {
    const docRef = doc(firebase.firestore, FIRESTORE_COLLECTIONS.REVIEWS_SCHEDULE, id);
    const document = await getDoc(docRef);
    if (document.exists())
        await deleteDoc(docRef);
};
export const reviewsScheduleRepository = {
    upsert,
    update,
    remove,
};
