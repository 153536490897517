import {
    Alert,
    AlertColor,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material"
import { FC } from "react"

interface Props {
    isOpen: boolean
    onClose: (e?: React.MouseEvent) => void
    closeText: string
    onConfirm: (e?: React.MouseEvent) => Promise<void>
    confirmText: string
    title: string
    description: string
    alertLevel?: AlertColor
}

export const ConfirmModal: FC<Props> = ({
    isOpen,
    onClose,
    onConfirm,
    title,
    closeText,
    confirmText,
    description,
    alertLevel,
}) => {
    if (!alertLevel) alertLevel = "error"

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>
                <Alert severity={alertLevel}>{title}</Alert>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose} size="small">
                    {closeText}
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    color={alertLevel}
                    onClick={onConfirm}
                    data-testid="modal-confirm-button"
                >
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
