import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useFocused } from "slate-react";
import { SearchResults } from "../../components/SearchResults/SearchResults";
import { useIsMobile } from "../../hooks/useIsMobile";
import useSearchItems from "../hooks/useSearchItems";
import { useMyEditorState } from "../types";
import { handleScroll } from "../utils";
import CustomEditor from "../utils/CustomEditor";
export const LINKS_SEARCH_INPUT_ID = "id-link-search-input";
export const LINK_SELECTED_EVENT = "link-selected-event";
export const SearchReferenceElement = ({ attributes, children, nodeProps, getSummaryPreview, searchWikipedia, createConnection }) => {
    const editor = useMyEditorState();
    const [ref, setRef] = useState(null);
    const isMobile = useIsMobile();
    const focused = useFocused();
    useEffect(() => {
        if (!ref)
            return;
        handleScroll();
    }, [ref, isMobile]);
    useEffect(() => {
        if (!focused)
            handleClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focused]);
    const handleClose = () => {
        CustomEditor.removeSearchReference(editor);
    };
    const handleSelectResult = async (result) => {
        result = Object.assign(Object.assign({}, result), { isSaved: true });
        const { name, connectionId, itemId } = await createConnection(result);
        handleClose();
        CustomEditor.insertReference(editor, connectionId, name);
        CustomEditor.moveCursorAfterReference(editor);
        const event = new CustomEvent(LINK_SELECTED_EVENT, { detail: { itemId } });
        document.dispatchEvent(event);
    };
    const textNode = CustomEditor.getTextNode(editor);
    const searchTerm = (textNode === null || textNode === void 0 ? void 0 : textNode.text) || "";
    const [loading, results] = useSearchItems(searchTerm, {}, searchWikipedia);
    return (_jsxs("span", Object.assign({}, nodeProps, { ref: (r) => setRef(r), id: LINKS_SEARCH_INPUT_ID }, { children: [_jsx(Box, Object.assign({ component: "span", sx: styles.chip }, attributes, { children: children })), ref && (_jsx(SearchResults, { value: searchTerm, results: results, loading: loading, open: true, anchorEl: ref, onClose: handleClose, onSelectResult: handleSelectResult, closeOnEmptyBackspace: true, subheaderText: "Keep typing to search...", getSummaryPreview: getSummaryPreview }))] })));
};
const styles = {
    chip: {
        whiteSpace: "nowrap",
        backgroundColor: (theme) => theme.palette.secondary.main,
        color: (theme) => theme.palette.secondary.contrastText,
        paddingTop: (theme) => theme.spacing(0.4),
        paddingBottom: (theme) => theme.spacing(0.4),
        paddingRight: (theme) => theme.spacing(1),
        paddingLeft: (theme) => theme.spacing(1),
        borderRadius: (theme) => theme.spacing(1.5),
        outline: "none",
    },
};
