import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { RootState } from "storage/redux/rootReducer"
import { SET_DID_UPDATE } from "storage/redux/user/actionTypes"
import * as serviceWorker from "../serviceWorker"

export default function useServiceWorker() {
    const dispatch = useDispatch()
    const didUpdate = useSelector((state: RootState) => state.user.didUpdate)

    // After page reloads, we show the toast.
    useEffect(() => {
        if (didUpdate) {
            toast("A new version of Recall has been loaded.", { type: "success" })
            dispatch({ type: SET_DID_UPDATE, payload: false })
        }
    }, [didUpdate, dispatch])

    const handleOnUpdate = useCallback(
        (registration: ServiceWorkerRegistration) => {
            if (registration.waiting.postMessage) {
                registration.waiting.postMessage({ type: "SKIP_WAITING" })
                dispatch({ type: SET_DID_UPDATE, payload: true })
                window.location.reload()
            }
        },
        [dispatch]
    )

    useEffect(() => {
        serviceWorker.register({ onUpdate: handleOnUpdate })
    }, [handleOnUpdate])
}
