import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FormatAlignCenter, FormatAlignJustify, FormatAlignLeft, FormatAlignRight, } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { useAlignDropdownMenu, useAlignDropdownMenuState } from "@udecode/plate-alignment";
import { useState } from "react";
const items = [
    {
        value: "left",
        icon: _jsx(FormatAlignLeft, {}),
    },
    {
        value: "center",
        icon: _jsx(FormatAlignCenter, {}),
    },
    {
        value: "right",
        icon: _jsx(FormatAlignRight, {}),
    },
    {
        value: "justify",
        icon: _jsx(FormatAlignJustify, {}),
    },
];
export const AlignToolbarButton = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const state = useAlignDropdownMenuState();
    const { radioGroupProps } = useAlignDropdownMenu(state);
    const currentAlignment = items.find((item) => item.value === radioGroupProps.value);
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ title: "Align" }, { children: _jsx(IconButton, Object.assign({ size: "small", onClick: (e) => {
                        setAnchorEl(e.currentTarget);
                    }, sx: {
                        color: (theme) => Boolean(anchorEl)
                            ? theme.palette.text.primary
                            : theme.palette.action.active,
                        backgroundColor: (theme) => Boolean(anchorEl) ? theme.palette.action.selected : undefined,
                        ":hover": {
                            backgroundColor: (theme) => Boolean(anchorEl) ? theme.palette.action.selected : undefined,
                        },
                    } }, { children: currentAlignment === null || currentAlignment === void 0 ? void 0 : currentAlignment.icon })) })), _jsx(Menu, Object.assign({ anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                }, transformOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                }, anchorEl: anchorEl, open: Boolean(anchorEl), onClose: () => setAnchorEl(null) }, { children: items.map((item) => (_jsx(MenuItem, Object.assign({ onClick: () => radioGroupProps.onValueChange(item.value) }, { children: item.icon }), item.value))) }))] }));
};
