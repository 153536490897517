import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Edit, Link, LinkOff, Notes, OpenInNew } from "@mui/icons-material";
import { Box, Divider, IconButton, InputBase, Tooltip, } from "@mui/material";
import { FloatingLinkUrlInput, LinkOpenButton, useFloatingLinkEdit, useFloatingLinkEditState, useFloatingLinkInsert, useFloatingLinkSelectors, } from "@udecode/plate-link";
import { useFloatingLinkInsertState } from "../../hooks/useFloatingLinkInsertState";
const FullWidthInput = (props) => {
    return _jsx(InputBase, Object.assign({ sx: styles.input, fullWidth: true }, props));
};
const FullWidthInputWithLink = (props) => {
    return _jsx(FullWidthInput, Object.assign({ startAdornment: _jsx(Link, { sx: { mr: 1 } }) }, props));
};
export function LinkFloatingToolbar(isExtension) {
    const isEditing = useFloatingLinkSelectors().isEditing();
    const state = useFloatingLinkInsertState({ isExtension });
    const { props: insertProps, ref: insertRef, textInputProps } = useFloatingLinkInsert(state);
    const editState = useFloatingLinkEditState();
    const { props: editProps, ref: editRef, editButtonProps, unlinkButtonProps, } = useFloatingLinkEdit(editState);
    if (!state.isOpen)
        return null;
    const input = (_jsxs(Box, Object.assign({ sx: styles.wrapper }, { children: [_jsx(Box, Object.assign({ sx: styles.inputGroup }, { children: _jsx(FloatingLinkUrlInput, { as: FullWidthInputWithLink, placeholder: "Paste link" }) })), _jsx(Box, Object.assign({ sx: styles.inputGroup }, { children: _jsx(FullWidthInput, Object.assign({ startAdornment: _jsx(Notes, { sx: { mr: 1 } }), placeholder: "Text to display" }, textInputProps)) }), textInputProps.defaultValue)] })));
    const editContent = isEditing ? (input) : (_jsxs(Box, Object.assign({ sx: styles.linkOptions }, { children: [_jsx(Tooltip, Object.assign({ title: "Edit Link" }, { children: _jsx(IconButton, Object.assign({}, editButtonProps, { size: "small" }, { children: _jsx(Edit, {}) })) })), _jsx(Divider, { orientation: "vertical" }), _jsx(Tooltip, Object.assign({ title: "Open Link" }, { children: _jsx(LinkOpenButton, { children: _jsx(IconButton, Object.assign({ size: "small" }, { children: _jsx(OpenInNew, {}) })) }) })), _jsx(Divider, { orientation: "vertical" }), _jsx(Tooltip, Object.assign({ title: "Remove Link" }, { children: _jsx(IconButton, Object.assign({}, unlinkButtonProps, { size: "small" }, { children: _jsx(LinkOff, {}) })) }))] })));
    return (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ ref: insertRef }, insertProps, { style: Object.assign(Object.assign({}, insertProps.style), { zIndex: 1201 }) }, { children: input })), _jsx(Box, Object.assign({ ref: editRef }, editProps, { style: Object.assign(Object.assign({}, editProps.style), { zIndex: 1201 }) }, { children: editContent }))] }));
}
const styles = {
    wrapper: {
        minWidth: 350,
        borderRadius: 1,
        p: 1,
        backgroundColor: (theme) => theme.palette.background.paper,
        boxShadow: 4,
        gap: 1,
        display: "flex",
        flexDirection: "column",
    },
    inputGroup: {
        display: "flex",
        alignItems: "center",
        gap: 1,
    },
    linkOptions: {
        display: "flex",
        borderRadius: 1.5,
        padding: 1,
        backgroundColor: (theme) => theme.palette.background.paper,
        boxShadow: 4,
        gap: 0.5,
        alignItems: "center",
    },
    input: {
        backgroundColor: (theme) => theme.palette.action.selected,
        borderRadius: 0.5,
        px: 1,
    },
};
