import { HOME_PATH } from "constants/routes"
import { useOpenItem } from "hooks/items/useOpenItem"
import { useQueryParameter } from "hooks/useQueryParameter"
import { Redirect } from "react-router"

export const ItemPageRedirect = () => {
    const { isPresent, param: itemId } = useQueryParameter("id")
    const { getItemPath } = useOpenItem()
    const redirectPath = isPresent ? getItemPath(itemId) : HOME_PATH
    return <Redirect to={redirectPath} exact />
}
