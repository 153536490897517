import { Add, Share } from "@mui/icons-material"
import { Box, Button, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import { keyframes, SxProps, Theme } from "@mui/material/styles"
import { useDatabase } from "@nozbe/watermelondb/react"
import { assetRepository, createAssets, ItemModel, Spinner, tagRepository } from "@recall/common"
import { useCurrentBreadcrumbs } from "components/ItemPage/hooks/useCurrentBreadcrumbs"
import { useRerender } from "components/ItemPage/hooks/useRerender"
import { RERENDER_ITEMS_LIST } from "components/ItemsPage/hooks/useGroupedItems"
import { Editor } from "components/shared/editor/Editor"
import { RERENDER_TAGS_EVENT } from "components/shared/tags/hooks/useGroupedTags"
import { SAVE_CARD_EVENT } from "constants/events"
import { useOpenState } from "hooks/useOpenState"
import { FC, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { captureEventService } from "services/captureEventService"
import { RootState } from "storage/redux/rootReducer"
import { SET_ACTIVE_TUTORIAL } from "storage/redux/user/actionTypes"
import { ExpandButton } from "./components/Expand/Expand"
import { ItemMenu } from "./components/ItemMenu"
import { Links } from "./components/Links/Links"
import { Mentions } from "./components/Links/Mentions"
import { PushPinToggle } from "./components/PushPinToggle"
import { Sources } from "./components/Sources"
import { SubscribeModal } from "./components/SubscribeModal"
import { Tags } from "./components/Tags/Tags"

export const ADD_BUTTON_DIV_ID = "id-add-button"

interface Props {
    item: ItemModel
    focussedConnection?: string
}

export const Item: FC<Props> = ({ item, focussedConnection }) => {
    const db = useDatabase()
    const dispatch = useDispatch()

    const rerender = useRerender()
    const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false)
    const itemRef = useRef<HTMLDivElement | null>(null)
    const [isScrolled, setIsScrolled] = useState(false)
    const [isTranslating, setIsTranslating] = useState(false)
    const {
        isOpen: isShareModalOpen,
        handleClose: handleCloseShareModal,
        handleOpen: handleOpenShareModal,
    } = useOpenState()
    const uid = useSelector((state: RootState) => state.user.uid)

    useEffect(() => {
        document.title = `Recall - ${item.name}`
    }, [item.name])

    // @ts-ignore
    useEffect(() => {
        return async () => {
            if (!item) return
            dispatch({ type: SET_ACTIVE_TUTORIAL, payload: "none" })
            const isEmpty = await item.isEmpty()

            if (
                isEmpty ||
                (!item.isSaved && !item.isReference && !window.location.href.includes("/item/"))
            ) {
                await item.delete()
                await assetRepository.deleteByItemId(db, item.id)
                await tagRepository.deleteStale(db)
                document.dispatchEvent(new CustomEvent(RERENDER_ITEMS_LIST))
            }

            if (!item.isSaved) document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    useEffect(() => {
        const itemEl = itemRef.current

        if (!itemEl || (isScrolled && !focussedConnection)) return

        setTimeout(() => {
            if (focussedConnection) {
                const connection = focussedConnection && document.getElementById(focussedConnection)

                if (connection) {
                    connection.classList.add("focus-animation")
                    connection.scrollIntoView({ block: "center" })
                    setTimeout(() => {
                        connection.classList.remove("focus-animation")
                    }, 4000)
                } else {
                    setTimeout(() => {
                        const connection =
                            focussedConnection && document.getElementById(focussedConnection)
                        if (!connection) return

                        connection.classList.add("focus-animation")
                        connection.scrollIntoView({ block: "center" })
                        setTimeout(() => {
                            connection.classList.remove("focus-animation")
                        }, 4000)
                    }, 50)
                }
            }
        })
        setIsScrolled(true)
    }, [focussedConnection])

    useCurrentBreadcrumbs(item)

    const handleSetIsReference = async (e) => {
        e.preventDefault()
        await item.setIsReference(!item.isReference)
        const tags = await item.tags.fetch()
        await tagRepository.updateTagsReferenceState(db, tags, item)
        document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
    }

    const handleSave = async () => {
        captureEventService.capture(SAVE_CARD_EVENT, { cardName: item.name })
        await item.saveDeep()
        createAssets(db, item, uid)
        rerender()
    }

    if (isTranslating)
        return (
            <Box display="flex" alignItems="center" justifyContent="center" position="relative">
                <Spinner />
            </Box>
        )

    return (
        <Box position="relative" key={item.id + item.isExpanded}>
            <Box sx={styles.root} ref={itemRef}>
                {isSubscribeModalOpen && (
                    <SubscribeModal
                        name={item.name}
                        isModalOpen={isSubscribeModalOpen}
                        setIsModelOpen={setIsSubscribeModalOpen}
                    />
                )}
                <Grid container direction="column" spacing={1}>
                    <Grid container item spacing={1} wrap="nowrap">
                        <Grid item container display="flex">
                            <Sources item={item} language={item.language} />
                        </Grid>
                        <Grid
                            item
                            container
                            xs
                            display="flex"
                            wrap="nowrap"
                            alignItems="flex-start"
                            justifyContent="flex-end"
                        >
                            <Box display="flex" alignItems="center" gap={0.5}>
                                {!item.isSaved && (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        onClick={handleSave}
                                        sx={styles.saveButton}
                                        data-testid="card-add-button"
                                        id={ADD_BUTTON_DIV_ID}
                                    >
                                        <Add />
                                        <Typography ml={0.5} fontWeight={500}>
                                            Save
                                        </Typography>
                                    </Button>
                                )}
                                {!item.isExpanded && item.isSaved && (
                                    <ExpandButton
                                        rerender={rerender}
                                        item={item}
                                        name={item.name}
                                    />
                                )}
                                {item.isSaved && (
                                    <Tooltip title="Share this card">
                                        <IconButton onClick={handleOpenShareModal}>
                                            <Share />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <PushPinToggle
                                    isPinned={!item.isReference}
                                    onClick={handleSetIsReference}
                                />
                                {item.isSaved && (
                                    <ItemMenu
                                        item={item}
                                        setIsTranslating={setIsTranslating}
                                        handleCloseShareModal={handleCloseShareModal}
                                        handleOpenShareModal={handleOpenShareModal}
                                        isShareModalOpen={isShareModalOpen}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Tags item={item} />
                    </Grid>
                    <Grid item xs>
                        <Editor item={item} />
                    </Grid>
                    <Grid container item>
                        <Mentions itemId={item.id} connections={item.mentions} />
                    </Grid>
                    <Grid container item>
                        <Links id={item.id} connections={item.links} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

const highlighted = keyframes`
    0% {
        background: #F2B80720;
        padding: 2px;
    }

    70% {
        background: #F2B80760;
        padding: 2px;
    }

    100% {
        background: ##F2B80700;
        padding: 0;
    }
`

const styles: Record<string, SxProps<Theme>> = {
    root: {
        padding: 1,
        fontFamily: (theme) => theme.typography.secondaryFontFamily,
        backgroundColor: (theme) => theme.palette.action.hover,
        borderRadius: 1,
        " .focus-animation": {
            animation: `${highlighted} 2s ease`,
            borderRadius: 1,
        },
    },
    saveButton: { mr: 1, pr: 2 },
}
