import { FC } from "react"
import { Helmet } from "react-helmet"

interface Props {
    title: string
    description: string
}

export const PageMetadata: FC<Props> = ({ title, description }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="https://getrecall.ai/images/recall-preview.webp" />
        </Helmet>
    )
}
