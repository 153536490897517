import { synchronize, } from "@nozbe/watermelondb/sync";
import { get, orderByChild, query, ref, startAt } from "firebase/database";
import { httpsCallable } from "firebase/functions";
import { v4 as uuidv4 } from "uuid";
import { firebase, posthogService } from "../../services";
import { sentry } from "../../utils";
import { COLLECTIONS } from "./schema";
export async function syncRealTimeDb(database, user_id, handlePushCallback, handlePullCallback) {
    const sessionId = uuidv4();
    await synchronize({
        database,
        sendCreatedAsUpdated: true,
        pullChanges: async (data) => await pullChanges(sessionId, user_id, data, handlePullCallback),
        pushChanges: async (data) => await pushChanges(sessionId, data, handlePushCallback),
    });
}
export const pushDatabaseChanges = httpsCallable(firebase.functions, "pushDatabaseChanges");
const pullChanges = async (sessionId, user_id, { lastPulledAt = 0 }, handlePullCallback) => {
    const syncTimestamp = new Date();
    let changes = {};
    await Promise.all(COLLECTIONS.map(async (collectionName) => {
        let updated = [];
        let created = [];
        let deleted = [];
        const queryRef = ref(firebase.database, `${user_id}/${collectionName}/`);
        const values = (await get(query(queryRef, orderByChild("last_synced_at"), startAt(lastPulledAt - 1, "last_synced_at")))).val();
        if (values) {
            updated = Object.values(values)
                // @ts-ignore
                .filter((data) => data.session_id !== sessionId)
                // @ts-ignore
                .filter((data) => data.is_deleted === false);
            deleted = Object.values(values)
                // @ts-ignore
                .filter((data) => data.session_id !== sessionId)
                // @ts-ignore
                .filter((data) => data.is_deleted === true)
                // @ts-ignore
                .map((data) => data.id);
        }
        changes = Object.assign(Object.assign({}, changes), { [collectionName]: { created, deleted, updated } });
    }));
    await handlePullCallback();
    return { changes, timestamp: +syncTimestamp };
};
const LARGE_CHUNK_SIZE = "Chunk size bigger then 7MB";
const pushChanges = async (sessionId, { changes, lastPulledAt }, handlePushCallback) => {
    const filteredChanges = filterUnsavedChanges(changes);
    const isEmpty = areChangesEmpty(filteredChanges);
    if (isEmpty)
        return;
    let changesArray = [changes];
    if (getSize(changes) > maxSize) {
        try {
            posthogService.captureEvent(LARGE_CHUNK_SIZE);
            changesArray = chunkChanges(changes);
        }
        catch (error) {
            sentry.captureException(error);
            changesArray = [changes];
        }
    }
    for (const changes of changesArray)
        await pushDatabaseChanges({ changes, lastPulledAt, sessionId });
    await handlePushCallback();
};
const filterUnsavedChanges = (changes) => {
    const filteredChanges = {};
    for (const [collectionName, collectionChanges] of Object.entries(changes)) {
        filteredChanges[collectionName] = {
            created: [],
            updated: [],
            deleted: [],
        };
        for (const actionType in collectionChanges) {
            const isDelete = actionType === "deleted";
            if (isDelete) {
                filteredChanges[collectionName][actionType] = collectionChanges[actionType];
                continue;
            }
            // @ts-ignore
            filteredChanges[collectionName][actionType] = collectionChanges[actionType].filter((action) => action.is_saved === true);
        }
    }
    return filteredChanges;
};
const areChangesEmpty = (changes) => {
    for (const collection of Object.values(changes)) {
        for (const actions of Object.values(collection)) {
            if (actions.length > 0) {
                return false;
            }
        }
    }
    return true;
};
const maxSize = 7 * 1024 * 1024; // 7 MB
const getSize = (item) => {
    const itemSize = new TextEncoder().encode(JSON.stringify(item)).length;
    return itemSize;
};
const addItemsToChunk = (key, items, chunkKey, maxSize, currentChunk, currentSize, chunks) => {
    for (const item of items) {
        const itemSize = getSize({ [chunkKey]: [item] });
        if (currentSize + itemSize > maxSize) {
            chunks.push(currentChunk);
            currentChunk = { [key]: { created: [], updated: [], deleted: [] } };
            currentSize = 0;
        }
        currentChunk[key][chunkKey].push(item);
        currentSize += itemSize;
    }
    return { currentSize, currentChunk };
};
const chunkChanges = (obj) => {
    const chunks = [];
    let currentChunk = {};
    let currentSize = 0;
    for (const key in obj) {
        const collection = obj[key];
        const created = collection["created"] || [];
        const updated = collection["updated"] || [];
        const deleted = collection["deleted"] || [];
        currentChunk[key] = { created: [], updated: [], deleted: [] };
        ({ currentSize, currentChunk } = addItemsToChunk(key, created, "created", maxSize, currentChunk, currentSize, chunks));
        ({ currentSize, currentChunk } = addItemsToChunk(key, updated, "updated", maxSize, currentChunk, currentSize, chunks));
        ({ currentSize, currentChunk } = addItemsToChunk(key, deleted, "deleted", maxSize, currentChunk, currentSize, chunks));
    }
    if (Object.keys(currentChunk).length > 0 && currentSize > 0) {
        chunks.push(currentChunk);
    }
    return chunks;
};
export const DOCUMENT_WAS_MODIFIED_ERROR = "DOCUMENT WAS MODIFIED DURING PULL AND PUSH OPERATIONS";
export const DOCUMENT_WAS_DELETED_ERROR = "DOCUMENT WAS DELETED DURING PULL AND PUSH OPERATIONS";
