import { Box } from "@mui/material"
import { FC, useState } from "react"

interface Props {
    image: string
}

export const ListImage: FC<Props> = ({ image }) => {
    const [hasFailedToLoad, setHasFailedToLoad] = useState(false)

    if (hasFailedToLoad) return null

    return (
        <Box
            component="img"
            src={image}
            onError={() => setHasFailedToLoad(true)}
            sx={{
                display: "block",
                width: "25%",
                height: "100%",
                background: (theme) => theme.palette.action.hover,
                objectFit: "cover",
            }}
        />
    )
}
