import { useEffect } from "react"

export const useNewDomain = () => {
    useEffect(() => {
        const oldDomain = "recall.wiki"
        const currentHostname = window.location.hostname
        const currentPath = window.location.pathname
        const queryParams = window.location.search
        if (currentHostname.includes(oldDomain)) {
            const newURL = `https://app.getrecall.ai${currentPath}${queryParams}`
            window.location.href = newURL
        }
    }, [])
}
