import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { UsageSurvey } from "./UsageSurvey"
import { USAGE_SURVEY } from "./constants/surveys"

export const Survey = () => {
    const surveys = useSelector((state: RootState) => state.user.surveys)
    const surveysLoading = surveys === undefined
    const isAppLoading = useSelector((state: RootState) => state.app.appLoading)

    if (isAppLoading || surveysLoading) return null

    if (!surveys?.includes(USAGE_SURVEY)) return <UsageSurvey />

    return null
}
