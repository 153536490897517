import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton, Tooltip } from "@mui/material";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { useMarkToolbarButton, useMarkToolbarButtonState, } from "../../hooks/useMarkToolbarButtonState";
export const MarkButton = ({ children, tooltip, nodeType, clear, }) => {
    const state = useMarkToolbarButtonState({ clear, nodeType });
    const { props: buttonProps } = useMarkToolbarButton(state);
    const isMobile = useIsMobile();
    return (_jsx(Tooltip, Object.assign({ title: tooltip, disableTouchListener: true }, { children: _jsx(IconButton, Object.assign({ onClick: buttonProps.onClick, size: "small", sx: {
                color: (theme) => buttonProps.pressed
                    ? theme.palette.text.primary
                    : theme.palette.action.active,
                backgroundColor: (theme) => buttonProps.pressed ? theme.palette.action.selected : undefined,
                ":hover": {
                    backgroundColor: (theme) => isMobile && buttonProps.pressed
                        ? theme.palette.action.selected
                        : undefined,
                },
            } }, { children: children })) })));
};
