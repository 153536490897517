import { Check } from "@mui/icons-material"
import { Box, SxProps, Theme, Tooltip, Typography } from "@mui/material"
import { Coupon, sentry } from "@recall/common"
import { LoadingButton } from "components/shared/buttons/LoadingButton"
import copyToClipboard from "copy-to-clipboard"
import { FC, useEffect, useState } from "react"
import { toast } from "react-toastify"

interface CouponProps {
    coupon: Coupon
    applyCoupon: (couponId: string) => Promise<void>
}

export const ReferralCoupon: FC<CouponProps> = ({ coupon, applyCoupon }) => {
    const [isCopied, setIsCopied] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleCopyCoupon = () => {
        copyToClipboard(coupon.code)
        setIsCopied(true)
    }

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => {
                setIsCopied(false)
            }, 3000)
        }
    }, [isCopied])

    const handleApplyCoupon = async () => {
        setIsLoading(true)

        try {
            await applyCoupon(coupon.id)
        } catch (error) {
            sentry.captureException(error)
            toast.error("Something went wrong and we couldn't apply the coupon. Please try again.")
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Box sx={styles.coupon}>
            <Tooltip title={coupon.isValid ? "Click to copy coupon" : undefined}>
                <Box display="flex" alignItems="center">
                    <Box sx={styles.promoCode} mr={1}>
                        <Typography onClick={coupon.isValid ? handleCopyCoupon : undefined}>
                            <code>{coupon.code}</code>
                        </Typography>
                        {isCopied && (
                            <Box sx={styles.couponOverlay}>
                                <Check fontSize="small" />
                                <Typography>Copied</Typography>
                            </Box>
                        )}
                        {!coupon.isValid && (
                            <Box sx={styles.couponOverlay}>
                                <Typography>Used</Typography>
                            </Box>
                        )}
                    </Box>
                    <Typography sx={{ textDecoration: coupon.isValid ? "none" : "line-through" }}>
                        - {coupon.discount}% off
                    </Typography>
                </Box>
            </Tooltip>
            {coupon.isValid && (
                <LoadingButton
                    sx={styles.button}
                    loading={isLoading}
                    variant="contained"
                    color="secondary"
                    onClick={handleApplyCoupon}
                >
                    Apply
                </LoadingButton>
            )}
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    coupon: {
        display: "flex",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
        minWidth: 300,
    },
    promoCode: {
        cursor: "pointer",
        position: "relative",
        border: (theme) => theme.borders.paper,
        borderRadius: 1,
        p: 1,
        boxSizing: "border-box",
        overflow: "hidden",
    },
    couponOverlay: {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        background: (theme) => theme.palette.background.layout,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    button: {
        minWidth: 100,
    },
}
