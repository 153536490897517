import { orderBy } from "lodash";
import { v4 as uuid } from "uuid";
import { toItem } from "../../../api/types/convertDTO";
import { ROOT_TYPE_NAME, WEBSITE, WIKIPEDIA } from "../../../constants";
import { deserializeMd } from "../../../editor/parsers/markdownToSlate";
import { createPlatePlugins } from "../../../editor/plugins/createPlatePlugins";
import { EditorBlockData, ImageBlockData } from "../../../services";
import { getMatchedReferences, getTextReferences } from "../../../utils";
import { connectionRepository } from "../repository/connectionRepository";
import { itemRepository } from "../repository/itemRepository";
import { ROOT_TAG_ID, tagRepository } from "../repository/tagRepository";
const addItemTagsByType = async (db, type, item) => {
    return await db.write(async (action) => {
        if (!type || type.name === ROOT_TYPE_NAME)
            return;
        const genealogy = (type === null || type === void 0 ? void 0 : type.genealogy) || [];
        let parentId = ROOT_TAG_ID;
        for (const { display } of genealogy.reverse()) {
            if (display === ROOT_TYPE_NAME)
                continue;
            const existingTag = await tagRepository.getTagByNameAndParentId(db, display, parentId);
            if (existingTag) {
                parentId = existingTag.id;
            }
            else {
                // eslint-disable-next-line no-loop-func
                const tag = await action.callWriter(() => tagRepository.create({ db, name: display, parentId, isSaved: item.isSaved }));
                if (!tag)
                    continue;
                parentId = tag.id;
            }
        }
        const tag = await action.callWriter(() => tagRepository.create({ db, name: type.display, item, parentId }));
        return tag;
    });
};
const addItemTags = async (db, tags, item) => {
    return await db.write(async (action) => {
        if (!tags || !tags.length)
            return;
        for (const tag of tags) {
            const existingTag = await tagRepository.getTagByName(db, tag.name);
            if (existingTag) {
                await action.callWriter(() => tagRepository.attach(db, existingTag, item));
                continue;
            }
            await action.callWriter(() => tagRepository.create({ db, name: tag.name, item }));
        }
    });
};
const getEditorBlocks = async (item, linksConnections) => {
    if (!item.markdown)
        return item.editorBlocks;
    const title = `# ${item.name}\n`;
    const imageUrl = item.image;
    let image = imageUrl ? `![Image](${imageUrl})\n` : "";
    const markdown = insertMarkdownLinks(item.markdown, linksConnections);
    const editorBlocks = await deserializeMd(title + image + markdown, createPlatePlugins({}));
    return editorBlocks.map((editorBlock) => editorBlock.id ? editorBlock : Object.assign(Object.assign({}, editorBlock), { id: uuid() }));
};
const replaceSpecialCharacters = (markdown) => {
    return markdown.replaceAll(`\\`, "\\\\");
};
const insertMarkdownLinks = (markdown, links) => {
    const placeholders = {};
    const orderedLinks = orderBy(links, ({ link }) => link.item.name.length, "desc");
    let markdownLines = markdown.split("\n");
    for (const { link, connection } of orderedLinks) {
        const mention_texts = link.mention_texts || [];
        const textReferences = getTextReferences(mention_texts, link.item.name);
        const matchedReferences = getMatchedReferences(markdownLines, textReferences);
        for (const matchedReference of matchedReferences) {
            placeholders[`__${matchedReference}__`] = `[${matchedReference}](${connection.id})`;
        }
    }
    let updatedMarkdown = markdownLines.join("\n");
    for (const [placeholder, replacement] of Object.entries(placeholders)) {
        updatedMarkdown = updatedMarkdown.split(placeholder).join(replacement);
    }
    return replaceSpecialCharacters(updatedMarkdown);
};
const saveLinks = async ({ db, itemApi, isSaved, editorBlocks, itemModel, }) => {
    return await db.write(async (writer) => {
        var _a, _b;
        const linksConnections = [];
        const links = itemApi.links || [];
        for (let link of links) {
            let linkedItem = await itemRepository.getBySources(db, link.item.sources);
            if (!linkedItem) {
                linkedItem = await writer.callWriter(() => saveItemApi(db, link.item, isSaved));
            }
            const connection = await writer.callWriter(() => connectionRepository.create(db, {
                fromId: itemModel.id,
                // @ts-ignore
                toId: linkedItem.id,
                property: link.property,
            }, isSaved));
            linksConnections.push({ link, connection });
            if (itemApi.markdown)
                continue;
            for (let editorBlock of editorBlocks) {
                for (let child of editorBlock.children) {
                    if (child.children) {
                        for (let grandChild of child.children) {
                            if (((_a = grandChild.connectionId) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === ((_b = link.slug) === null || _b === void 0 ? void 0 : _b.toLowerCase())) {
                                grandChild.connectionId = connection.id;
                            }
                        }
                    }
                }
            }
        }
        return linksConnections;
    });
};
const saveItemApi = async (db, itemApi, isSaved, options) => {
    return await db.write(async (writer) => {
        var _a;
        const item = toItem(Object.assign({}, itemApi));
        if (options === null || options === void 0 ? void 0 : options.id) {
            item.id = options.id;
        }
        item.image = "";
        if ((_a = item === null || item === void 0 ? void 0 : item.images) === null || _a === void 0 ? void 0 : _a[0]) {
            const imageUrl = ImageBlockData.getUrl320(item.images[0]);
            item.image = imageUrl;
        }
        item.isExpanded = (options === null || options === void 0 ? void 0 : options.isExpanded) || item.isExpanded;
        const itemModel = await writer.callWriter(() => itemRepository.create(db, item, isSaved));
        await writer.callWriter(() => addItemTagsByType(db, itemApi.type, itemModel));
        await writer.callWriter(() => addItemTags(db, itemApi.tags, itemModel));
        let editorBlocks = item.markdown ? [] : item.editorBlocks;
        let linksConnections = [];
        if ("links" in itemApi)
            linksConnections = await writer.callWriter(() => saveLinks({
                db,
                itemModel,
                editorBlocks,
                isSaved,
                itemApi,
            }));
        editorBlocks = item.markdown ? await getEditorBlocks(item, linksConnections) : editorBlocks;
        await writer.callWriter(() => itemModel.addEditorBlocks(editorBlocks, isSaved));
        await writer.callWriter(() => itemModel.addSources(item.sources, isSaved));
        await itemModel.update((record) => {
            record.description = EditorBlockData.getTextWithoutHeadings(editorBlocks);
        });
        return itemModel;
    });
};
const updateItemLanguage = async (db, itemModel, itemApi, language) => {
    return await db.write(async (writer) => {
        var _a, _b;
        const item = toItem(itemApi);
        let editorBlocks = item.markdown ? [] : item.editorBlocks;
        if ((_a = item === null || item === void 0 ? void 0 : item.images) === null || _a === void 0 ? void 0 : _a[0]) {
            const imageUrl = ImageBlockData.getUrl320(item.images[0]);
            try {
                await fetch(imageUrl, { mode: "no-cors" });
                item.image = imageUrl;
            }
            catch (_c) { }
        }
        if ((_b = item === null || item === void 0 ? void 0 : item.images) === null || _b === void 0 ? void 0 : _b[0])
            item.image = ImageBlockData.getUrl320(item.images[0]);
        const text = EditorBlockData.getText(item.editorBlocks);
        item.description = text.length > 60 ? text.slice(0, 60).trim() + "..." : text;
        const existingLinks = await itemModel.links;
        for (const link of existingLinks) {
            const item = await link.to.fetch();
            await writer.callWriter(() => link.delete());
            const backlinksCount = await item.mentions.count;
            if (backlinksCount === 0)
                await writer.callWriter(() => item.delete());
        }
        let linksConnections = [];
        if ("links" in itemApi)
            linksConnections = await writer.callWriter(() => saveLinks({
                db,
                itemModel,
                editorBlocks,
                isSaved: true,
                itemApi,
            }));
        editorBlocks = item.markdown ? await getEditorBlocks(item, linksConnections) : editorBlocks;
        await writer.callWriter(() => itemModel.updateName(item.name));
        await writer.callWriter(() => itemModel.updateDescription(item.description));
        if (item.image)
            await writer.callWriter(() => itemModel.updateImage(item.image || ""));
        const blocks = await itemModel.editorBlocks.fetch();
        for (const block of blocks)
            await writer.callWriter(() => block.delete());
        const orders = await itemModel.editorOrders.fetch();
        for (const order of orders)
            await writer.callWriter(() => order.delete());
        await writer.callWriter(() => itemModel.addEditorBlocks(editorBlocks, true));
        const wikipediaSource = await itemModel.getSource(WIKIPEDIA);
        const websiteSource = await itemModel.getSource(WEBSITE);
        if (wikipediaSource)
            await writer.callWriter(() => wikipediaSource.delete());
        if (websiteSource)
            await writer.callWriter(() => websiteSource.delete());
        const existingSources = await itemModel.sources.fetch();
        const missingSources = item.sources.filter((source) => existingSources.every((existingSource) => existingSource.name !== source.name &&
            existingSource.identifier !== source.identifier));
        await writer.callWriter(() => itemModel.addSources(missingSources, true));
        await writer.callWriter(() => itemModel.setIsExpanded(true));
        await writer.callWriter(() => itemModel.updateLanguage(language));
        return itemModel;
    });
};
export const itemService = {
    saveItemApi,
    updateItemLanguage,
};
