import { useDatabase } from "@nozbe/watermelondb/react"
import { dbUtils, sentry } from "@recall/common"
import * as Sentry from "@sentry/react"
import { ONE_DAY_IN_MS, seenOptions, useIsSeen } from "hooks/useIsSeen"
import { FC, PropsWithChildren } from "react"

export const ErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
    const db = useDatabase()

    const { isSeen, handleSetSeen } = useIsSeen(seenOptions.NOT_FOUND_ERROR, ONE_DAY_IN_MS)

    const handleError = async (error: Error) => {
        const isNotFoundError =
            error.message.includes("Record") && error.message.includes("not found")

        if (isNotFoundError && isSeen) {
            sentry.captureEvent({ message: "not found error on page reload" })
            return
        }

        if (isNotFoundError && !isSeen) {
            await dbUtils.deleteDatabase(db)
            handleSetSeen()
            window.location.reload()
        }
    }

    return (
        <Sentry.ErrorBoundary showDialog onError={handleError}>
            {children}
        </Sentry.ErrorBoundary>
    )
}
