import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppBar, Box, Dialog, IconButton, ListItemIcon, Menu, MenuItem, Slide, Toolbar, Typography, } from "@mui/material";
import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote";
import { collapseSelection, focusEditor, toggleNodeType, usePlateEditorState, } from "@udecode/plate-common";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import { Close } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import { IconH1, IconH2, IconH3, IconH4, IconH5, IconH6, IconList, IconListNumbers, IconPilcrow, IconQuote, } from "@tabler/icons-react";
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3, ELEMENT_H4, ELEMENT_H5, ELEMENT_H6, } from "@udecode/plate-heading";
import { ELEMENT_OL, ELEMENT_UL, unwrapList } from "@udecode/plate-list";
import React, { useState } from "react";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { toggleIndentList } from "../../utils/indentList";
const items = [
    {
        value: ELEMENT_PARAGRAPH,
        label: "Paragraph",
        icon: _jsx(IconPilcrow, {}),
    },
    {
        value: ELEMENT_H1,
        label: "Heading 1",
        icon: _jsx(IconH1, {}),
    },
    {
        value: ELEMENT_H2,
        label: "Heading 2",
        icon: _jsx(IconH2, {}),
    },
    {
        value: ELEMENT_H3,
        label: "Heading 3",
        icon: _jsx(IconH3, {}),
    },
    {
        value: ELEMENT_H4,
        label: "Heading 4",
        icon: _jsx(IconH4, {}),
    },
    {
        value: ELEMENT_H5,
        label: "Heading 5",
        icon: _jsx(IconH5, {}),
    },
    {
        value: ELEMENT_H6,
        label: "Heading 6",
        icon: _jsx(IconH6, {}),
    },
    {
        value: ELEMENT_UL,
        label: "Bulleted list",
        icon: _jsx(IconList, {}),
    },
    {
        value: ELEMENT_OL,
        label: "Numbered list",
        icon: _jsx(IconListNumbers, {}),
    },
    {
        value: ELEMENT_BLOCKQUOTE,
        label: "Quote",
        icon: _jsx(IconQuote, {}),
    },
];
const Transition = React.forwardRef(function Transition(props, ref) {
    return _jsx(Slide, Object.assign({ direction: "up", ref: ref }, props));
});
export const TextTypeMenu = ({ isOpen, setIsOpen }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const editor = usePlateEditorState();
    const isMobile = useIsMobile();
    const handleClick = (event) => {
        if (isMobile) {
            collapseSelection(editor);
            setIsOpen(true);
        }
        else {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        if (isMobile)
            setIsOpen(false);
        else
            setAnchorEl(null);
    };
    const handleChange = (type) => {
        if (type === ELEMENT_UL || type === ELEMENT_OL) {
            toggleIndentList(editor, { listStyleType: type === ELEMENT_UL ? "disc" : "decimal" });
        }
        else {
            unwrapList(editor);
            toggleNodeType(editor, { activeType: type });
        }
        collapseSelection(editor);
        focusEditor(editor);
        if (isMobile) {
            setIsOpen(false);
        }
        else {
            setAnchorEl(null);
        }
    };
    const Wrapper = isMobile ? MobileWrapper : DesktopWrapper;
    return (_jsxs(Box, Object.assign({ sx: {
            color: (theme) => theme.palette.action.active,
            ":hover": {
                color: (theme) => theme.palette.text.primary,
            },
        } }, { children: [_jsxs(IconButton, Object.assign({ onClick: handleClick, color: "inherit", sx: { position: "relative", pr: 1.5 } }, { children: [_jsx(TextFieldsIcon, { fontSize: "small" }), _jsx(Box, Object.assign({ position: "absolute", top: "50%", right: -3, sx: { transform: "translateY(-40%)" } }, { children: isOpen ? (_jsx(ArrowDropUpIcon, { fontSize: "small" })) : (_jsx(ArrowDropDownIcon, { fontSize: "small" })) }))] })), _jsx(Wrapper, Object.assign({ anchorEl: anchorEl, handleClose: handleClose, isOpen: isMobile ? isOpen : Boolean(anchorEl) }, { children: items.map((item) => (_jsxs(MenuItem, Object.assign({ sx: styles.menuItem, value: item.value, onClick: () => {
                        handleChange(item.value);
                    } }, { children: [_jsx(ListItemIcon, Object.assign({ sx: styles.icon }, { children: item.icon })), item.label] }), item.value))) }))] })));
};
const styles = {
    icon: {
        color: (theme) => theme.palette.text.secondary,
    },
    menuItem: {
        borderRadius: 0.5,
    },
};
const DesktopWrapper = ({ children, anchorEl, handleClose, isOpen }) => {
    return (_jsx(Menu, Object.assign({ anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
        }, transformOrigin: {
            vertical: "top",
            horizontal: "center",
        }, anchorEl: anchorEl, open: isOpen, onClose: handleClose }, { children: children })));
};
const MobileWrapper = ({ children, handleClose, isOpen }) => {
    return (_jsxs(Dialog, Object.assign({ fullScreen: true, open: isOpen, onClose: handleClose, PaperProps: { sx: { background: (theme) => theme.palette.background.layout } }, TransitionComponent: Transition }, { children: [_jsx(AppBar, Object.assign({ position: "relative" }, { children: _jsxs(Toolbar, { children: [_jsx(IconButton, Object.assign({ edge: "start", color: "inherit", onClick: handleClose, "aria-label": "close" }, { children: _jsx(Close, {}) })), _jsx(Typography, Object.assign({ sx: { ml: 2, flex: 1 }, variant: "h6", component: "div" }, { children: "Change text" }))] }) })), children] })));
};
