import { Box, Button, Fade, SxProps, Theme, Typography } from "@mui/material"
import { Coupon, sentry } from "@recall/common"
import { Loader } from "components/layouts/components/Loader/AppLoader"
import { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { functions } from "services/firebase/firebaseFunction"
import { MONTHLY_PRICING, paymentService } from "services/paymentService"
import { referralService } from "services/referralService"
import { RootState } from "storage/redux/rootReducer"
import { ReferralCoupon } from "./ReferralCoupon"

interface Props {
    handleClose: () => void
}

export const CouponsSection: FC<Props> = ({ handleClose }) => {
    const [coupons, setCoupons] = useState<null | Coupon[]>(null)
    const [isLoading, setIsLoading] = useState(true)
    const uid = useSelector((state: RootState) => state.user.uid)
    const isPremium = useSelector((state: RootState) => state.user.isPremiumUser)

    useEffect(() => {
        getCoupons()
    }, [])

    const getCoupons = async () => {
        setIsLoading(true)
        try {
            const coupons = await referralService.getCoupons()
            setCoupons(coupons)
        } catch (error) {
            sentry.captureException(error)
            toast.error("Something went wrong and we couldn't get your coupons. Please try again.")
        } finally {
            setIsLoading(false)
        }
    }

    const applyCoupon = async (couponId: string) => {
        if (isPremium) {
            await functions.applyPromoCode({ promoCodeId: couponId })
            toast.success("Discount applied successfully!")
        } else await paymentService.createCheckout(uid, MONTHLY_PRICING, { promoCodeId: couponId })
        await getCoupons()
    }

    if (isLoading)
        return (
            <Fade in>
                <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1}>
                    <Loader />
                </Box>
            </Fade>
        )

    return (
        <Fade in>
            <Box display="flex" flexDirection="column" justifyContent="space-between" flexGrow={1}>
                {coupons && coupons.length > 0 ? (
                    <Box sx={styles.couponContainer}>
                        {coupons.map((coupon) => (
                            <ReferralCoupon
                                key={coupon.code}
                                coupon={coupon}
                                applyCoupon={applyCoupon}
                            />
                        ))}
                    </Box>
                ) : (
                    <Box>
                        <Typography mb={2}>You don't have any coupons yet.</Typography>
                    </Box>
                )}
                <Button sx={styles.button} variant="contained" onClick={handleClose}>
                    Back
                </Button>
            </Box>
        </Fade>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    couponContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 1,
    },
    button: {
        mt: 2,
        alignSelf: "flex-start",
    },
}
