import { buildUrl } from "../utils";
const EMAIL_VALIDATOR_PATH = "/email/validate/";
export class EmailValidatorApi {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    async validate(email) {
        const url = buildUrl(EMAIL_VALIDATOR_PATH, { email });
        try {
            const response = await this.httpClient.get(url);
            return await response.json();
        }
        catch (_a) {
            return null;
        }
    }
}
