import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Slide, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Spinner } from "./Spinner";
export const SummaryLoading = () => {
    const [loadingText, setLoadingText] = useState("Loading");
    const loadingTexts = [
        "Parsing data",
        "Summarizing content",
        "Condensing ideas",
        "Extracting key points",
        "Discovering connections",
        "Finding main insights",
        "Generating summary",
    ];
    useEffect(() => {
        const interval = setInterval(() => {
            const nextIndex = Math.floor(Math.random() * loadingTexts.length);
            setLoadingText(loadingTexts[nextIndex]);
        }, 2000);
        return () => clearInterval(interval);
    }, []);
    return (_jsxs(Grid, Object.assign({ container: true, spacing: 2, direction: "column", justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Spinner, {}) })), _jsx(Grid, Object.assign({ item: true, overflow: "hidden" }, { children: _jsx(Slide, Object.assign({ direction: "up", in: true, timeout: 200 }, { children: _jsx(Typography, Object.assign({ variant: "h6", color: "text.secondary" }, { children: loadingText })) }), loadingText) }))] })));
};
