import { Apple } from "@mui/icons-material"
import GoogleIcon from "@mui/icons-material/Google"
import { APPLE_AUTH_CLICKED, GOOGLE_AUTH_CLICKED } from "@recall/common"
import { BoldButton } from "components/shared/buttons/BoldButton"
import { useAuth } from "hooks/auth/useAuth"
import { useIsNative } from "hooks/useIsNative"
import { usePlatform } from "hooks/usePlatform"
import { useQueryParameter } from "hooks/useQueryParameter"
import { FC, useState } from "react"
import { messagesService } from "services/messagesService"
import { useRedirectToCheckout } from "../hooks/useRedirectToCheckout"

interface Props {
    reauthenticateWithGoogle?: () => void
    reauthenticateWithApple?: () => void
}

const SignUpProvider: FC<Props> = ({ reauthenticateWithGoogle, reauthenticateWithApple }) => {
    const auth = useAuth()
    const { isPresent: isAndroid } = useQueryParameter("android")
    const [loading, setLoading] = useState<"apple" | "google" | null>(null)
    const handleRedirect = useRedirectToCheckout()
    const isNative = useIsNative()
    const platform = usePlatform()

    const handleClick = async () => {
        setLoading("google")
        if (isNative) return messagesService.post(GOOGLE_AUTH_CLICKED, "", true)

        const user = await auth.signInWithGooglePopup()
        setLoading(null)

        if (!user) return

        await handleRedirect(user.uid)
    }

    const handleSignInWithApple = async () => {
        setLoading("apple")
        auth.signInWithApple()
    }

    const handleClickApple = async () => {
        setLoading("apple")
        return messagesService.post(APPLE_AUTH_CLICKED, "", true)
    }

    return (
        <>
            <BoldButton
                fullWidth
                onClick={reauthenticateWithGoogle || handleClick}
                loading={loading === "google"}
                disabled={!!loading}
                startIcon={<GoogleIcon />}
            >
                {reauthenticateWithGoogle ? "Reauthenticate" : "Continue"} with Google
            </BoldButton>
            {!isAndroid && ((isNative && platform === "ios") || !isNative) && (
                <BoldButton
                    fullWidth
                    onClick={
                        reauthenticateWithApple ||
                        (isNative ? handleClickApple : handleSignInWithApple)
                    }
                    loading={loading === "apple"}
                    disabled={!!loading}
                    startIcon={<Apple />}
                    sx={{ mt: 1 }}
                >
                    {reauthenticateWithApple ? "Reauthenticate" : "Continue"} with Apple
                </BoldButton>
            )}
        </>
    )
}

export default SignUpProvider
