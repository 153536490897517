import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar } from "@mui/material";
const CountBadge = (props) => {
    return (_jsx(Avatar, Object.assign({ sizes: "small", sx: {
            mr: "3px",
            width: (theme) => theme.spacing(3),
            height: (theme) => theme.spacing(3),
            fontSize: "1em",
            color: (theme) => theme.palette.text.secondary,
            backgroundColor: (theme) => theme.palette.action.disabledBackground,
        } }, { children: props.count })));
};
export default CountBadge;
