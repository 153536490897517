var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Model } from "@nozbe/watermelondb";
import { date, field, readonly, relation, writer } from "@nozbe/watermelondb/decorators";
import { ITEM_REVIEWS, ITEMS } from "../schema";
export class ItemReviewModel extends Model {
    constructor() {
        super(...arguments);
        this.isItemReviewed = () => {
            return ((this.nextReviewTime && this.nextReviewTime > new Date()) || this.interest === "never");
        };
        this.prepareSave = () => {
            if (this.isSaved === false) {
                return this.prepareUpdate((record) => {
                    record.isSaved = true;
                });
            }
            return null;
        };
        this.prepareDelete = () => {
            if (this._preparedState === null) {
                if (this.isSaved) {
                    return this.prepareMarkAsDeleted();
                }
                else {
                    return this.prepareDestroyPermanently();
                }
            }
        };
    }
    async delete() {
        const task = this.prepareDelete();
        await this.batch(task);
    }
}
ItemReviewModel.table = ITEM_REVIEWS;
ItemReviewModel.associations = {
    items: { type: "belongs_to", key: "item_id" },
};
__decorate([
    field("is_saved")
], ItemReviewModel.prototype, "isSaved", void 0);
__decorate([
    field("reviews_count")
], ItemReviewModel.prototype, "reviewsCount", void 0);
__decorate([
    field("interest")
], ItemReviewModel.prototype, "interest", void 0);
__decorate([
    date("last_time_reviewed")
], ItemReviewModel.prototype, "lastTimeReviewed", void 0);
__decorate([
    date("next_review_time")
], ItemReviewModel.prototype, "nextReviewTime", void 0);
__decorate([
    relation(ITEMS, "item_id")
], ItemReviewModel.prototype, "item", void 0);
__decorate([
    readonly,
    date("created_at")
], ItemReviewModel.prototype, "createdAt", void 0);
__decorate([
    readonly,
    date("updated_at")
], ItemReviewModel.prototype, "updatedAt", void 0);
__decorate([
    writer
], ItemReviewModel.prototype, "delete", null);
