import { useDatabase } from "@nozbe/watermelondb/react";
import { map } from "lodash";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import { EditorBlockData } from "../../services";
import { initItemEditorData } from "../../services/editorData/initEditorData";
import { ASSET_TYPES, assetRepository, editorBlockRepository, editorOrderRepository, } from "../../storage/watermelon";
import { MAX_IMAGE_SIZE_IN_BYTES } from "../constants/assets";
import { uploadArrayBufferImage } from "../plugins";
export const usePlateHelpers = (item, uid, onItemNameUpdate) => {
    const db = useDatabase();
    return {
        initEditorBlocks: () => initEditorBlocks(db, item),
        updateEditorBlocks: (newValue) => updateEditorBlocks(db, item, newValue, onItemNameUpdate),
        uploadImage: (arrayBuffer, contentType) => uploadImage(db, item, uid, arrayBuffer, contentType),
    };
};
export const initEditorBlocks = (db, item) => {
    return initItemEditorData(db, item);
};
const upsertEditorBlocks = async (db, item, editorBlocks) => {
    for (let editorBlock of editorBlocks) {
        await editorBlockRepository.upsert(db, item.id, editorBlock);
    }
};
const updateItemDetails = async (item, newValue, onItemNameUpdate) => {
    for (const editorBlock of newValue) {
        const title = EditorBlockData.getText([editorBlock]);
        const name = title.trim();
        if (name !== item.name) {
            await item.updateName(name);
            onItemNameUpdate && onItemNameUpdate(name);
        }
        if (name)
            break;
    }
    const description = EditorBlockData.getTextWithoutHeadings(newValue);
    if (item.description !== description)
        await item.updateDescription(description);
};
export const updateEditorBlocks = async (db, item, newValue, onItemNameUpdate) => {
    const existingIds = [];
    for (const editorBlock of newValue) {
        // Plate editor creates custom elements with same ID when you hit enter, so it can cause duplicates without this fix
        if (!(editorBlock === null || editorBlock === void 0 ? void 0 : editorBlock.id) || existingIds.includes(editorBlock.id)) {
            editorBlock.id = uuid();
        }
        existingIds.push(editorBlock.id);
    }
    const editorOrder = map(newValue, "id");
    await upsertEditorBlocks(db, item, newValue);
    await editorOrderRepository.upsert(db, item.id, editorOrder);
    await updateItemDetails(item, newValue, onItemNameUpdate);
    await item.updateUpdatedAt();
};
export const uploadImage = async (db, item, uid, arrayBuffer, contentType) => {
    const size = arrayBuffer.byteLength;
    if (size > MAX_IMAGE_SIZE_IN_BYTES) {
        toast.error(`Max image size is ${MAX_IMAGE_SIZE_IN_BYTES / (1024 * 1024)}MB`);
        return null;
    }
    const url = await uploadArrayBufferImage(arrayBuffer, contentType, uid);
    if (!url)
        return null;
    await assetRepository.createAsset(db, {
        item,
        url,
        size,
        type: ASSET_TYPES.IMAGE,
    });
    if (!item.image) {
        await item.updateImage(url);
    }
    return url;
};
