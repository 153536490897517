import { Close } from "@mui/icons-material"
import { Box, IconButton, SxProps, Theme } from "@mui/material"
import { FC } from "react"

interface Props {
    url: string
    handleClose: () => void
}

export const ZoomedImage: FC<Props> = ({ url, handleClose }) => {
    return (
        <Box
            sx={styles.wrapper}
            onClick={(e) => {
                e.stopPropagation()
                handleClose()
            }}
        >
            <Box sx={styles.button}>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </Box>
            <Box sx={styles.image} component="img" src={url} onClick={(e) => e.stopPropagation()} />
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    wrapper: {
        position: "fixed",
        top: 0,
        left: 0,
        backdropFilter: "blur(3px)",
        width: "100vw",
        height: "100vh",
        zIndex: 1000,
    },
    button: {
        position: "fixed",
        top: "10px",
        right: "10px",
    },
    image: {
        borderRadius: 1,
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "80vh",
    },
}
