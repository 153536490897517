import { DeleteOutline, Image, MoreHoriz } from "@mui/icons-material"
import { Box, Fade, List, ListItemButton, SxProps, Theme, Typography } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import {
    AssetModel,
    deleteFromStorage,
    EditorBlockData,
    editorBlockRepository,
    editorOrderRepository,
    ItemModel,
    itemRepository,
    removeEditorBlockWithUrl,
    RERENDER_EDITOR_BLOCKS_EVENT,
} from "@recall/common"
import { ELEMENT_IMAGE } from "@udecode/plate-media"
import MyPopover from "components/shared/popovers/MyPopover"
import { FC, useState } from "react"

interface Props {
    asset: AssetModel
    item: ItemModel
    getAssets: () => void
}

export const FileMenu: FC<Props> = ({ asset, item, getAssets }) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const open = Boolean(anchorEl)
    const db = useDatabase()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleMakePrimary = async () => {
        const item = await asset.item.fetch()
        await item.updateImage(asset.url)
        setAnchorEl(null)
    }

    const handleDelete = async () => {
        const [editorBlocks, editorOrder, item] = await Promise.all([
            editorBlockRepository.getEditorBlocksByItemId(db, asset.item.id),
            editorOrderRepository.get(db, asset.item.id),
            itemRepository.get(db, asset.item.id),
        ])
        const editorBlock = editorBlocks.find(
            (editorBlock) =>
                editorBlock.type === ELEMENT_IMAGE &&
                // @ts-ignore
                (editorBlock.options?.url === asset.url || editorBlock.url === asset.url)
        )

        if (editorBlock) {
            await db.write(async () => {
                await editorOrder.update((record) => {
                    record.order = editorOrder.order.filter((id) => id !== editorBlock.id)
                })
            })
            await editorBlock.delete()
        } else {
            for (const editorBlock of editorBlocks) {
                const children = removeEditorBlockWithUrl(editorBlock.children, asset.url)
                await db.write(async () => {
                    await editorBlock.update((record) => {
                        record.children = children
                    })
                })
            }
        }

        if (item.image === asset.url) {
            const editorBlocks = await item.editorBlocks.fetch()
            const image = EditorBlockData.getImage(editorBlocks)
            await item.updateImage(image || "")
        }
        await deleteFromStorage(asset.url)
        await asset.delete()
        getAssets()

        document.dispatchEvent(new CustomEvent(RERENDER_EDITOR_BLOCKS_EVENT))
    }

    return (
        <Box className="menu" sx={{ display: open ? "block !important" : "auto", ...styles.menu }}>
            <Box onClick={handleClick} sx={styles.icon}>
                <MoreHoriz fontSize="small" />
            </Box>
            <MyPopover
                TransitionComponent={Fade}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                slotProps={{ paper: { sx: { py: 0 } } }}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <List sx={{ py: 0 }}>
                    <ListItemButton
                        disabled={item.image === asset.url}
                        sx={{ px: 1, gap: 0.5 }}
                        onClick={handleMakePrimary}
                    >
                        <Image fontSize="small" />
                        <Typography variant="body2">
                            {item.image === asset.url ? "Main image" : "Use as main image"}
                        </Typography>
                    </ListItemButton>
                    <ListItemButton sx={{ px: 1, gap: 0.5 }} onClick={handleDelete}>
                        <DeleteOutline color="error" fontSize="small" />
                        <Typography variant="body2">Delete</Typography>
                    </ListItemButton>
                </List>
            </MyPopover>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    menu: {
        position: "absolute",
        top: 5,
        right: 5,
    },
    icon: {
        backgroundColor: "background.default",
        display: "flex",
        alignItems: "center",
        borderRadius: 0.3,
        cursor: "pointer",
    },
}
