import { jsx as _jsx } from "react/jsx-runtime";
import AddIcon from "@mui/icons-material/Add";
import { Chip } from "@mui/material";
export const TAG_INPUT_DIV_ID = "id-tag-input";
export const TagInputChip = ({ setIsHidden }) => {
    const handleClick = () => {
        setIsHidden(true);
    };
    return (_jsx(Chip, { size: "medium", icon: _jsx(AddIcon, { sx: { fontSize: 16 } }), sx: styles, variant: "outlined", label: "Add tag", onClick: handleClick, "data-testid": "card-tag-add", id: TAG_INPUT_DIV_ID }));
};
const styles = {
    borderStyle: "dashed",
    opacity: 0.8,
    cursor: "pointer",
    py: 0.4,
    fontSize: "12px",
    ":hover": {
        opacity: 1,
    },
};
