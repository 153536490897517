import { escapeRegExp, uniq } from "lodash";
const supportsLookbehind = () => {
    try {
        new RegExp("(?<=test)");
        return true;
    }
    catch (e) {
        return false;
    }
};
export const getMatchedReferences = (markdownLines, textReferences) => {
    const matchedReferences = [];
    const sortedReferences = textReferences.sort((a, b) => b.length - a.length);
    let linesBetweenCount = 0;
    let isReferenceAdded = false;
    for (const i in markdownLines) {
        let line = markdownLines[i];
        isReferenceAdded && linesBetweenCount++;
        if (line.startsWith("#"))
            continue;
        let newLine = "";
        let lastIndex = 0;
        const linkRegex = /\[.*?\]\(.*?\)/g;
        let match;
        while ((match = linkRegex.exec(line)) !== null) {
            const beforeLink = line.slice(lastIndex, match.index);
            newLine += processText(beforeLink, sortedReferences);
            newLine += match[0];
            lastIndex = linkRegex.lastIndex;
        }
        newLine += processText(line.slice(lastIndex), sortedReferences);
        markdownLines[i] = newLine;
    }
    return matchedReferences;
    function processText(text, references) {
        for (const reference of references) {
            text = text.replace(!supportsLookbehind()
                ? new RegExp(`\\b${reference}\\b`, "giu")
                : new RegExp(`(?<![\\p{L}\\p{N}])${reference}(?![\\p{L}\\p{N}\\p{Pd}'\`])`, `giu`), (matched, offset, string) => {
                const beforeMatch = string.slice(0, offset);
                const afterMatch = string.slice(offset + matched.length);
                const isUrl = /(\(https?:\/\/|www\.)$/.test(beforeMatch);
                const isInfrequent = linesBetweenCount >= 6 || !isReferenceAdded;
                const isAlreadyEmphasized = beforeMatch.endsWith("__") || afterMatch.startsWith("__");
                if (isInfrequent && !isUrl && !isAlreadyEmphasized) {
                    matchedReferences.push(matched);
                    linesBetweenCount = 0;
                    isReferenceAdded = true;
                    return `__${matched}__`;
                }
                return matched;
            });
        }
        return text;
    }
};
export const getTextReferences = (mention_texts, reference) => {
    const textReferences = uniq([...mention_texts, `\`${reference}\``, reference].map((ref) => escapeRegExp(ref))).sort((a, b) => b.length - a.length);
    return textReferences;
};
