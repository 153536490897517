import { Download, OpenInNew, Payment } from "@mui/icons-material"
import {
    Box,
    Button,
    Container,
    Fade,
    FormControlLabel,
    Switch,
    SxProps,
    Theme,
    Typography,
} from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { posthogService, PremiumIcon, RECALL_WEBSITE_URL, SummaryLengthEnum } from "@recall/common"
import { SurfaceBox } from "components/shared/surfaces/SurfaceBox"
import { APP_UPGRADE_CLICK } from "constants/events"
import { PRICING_PATH } from "constants/routes"
import { useIsNative } from "hooks/useIsNative"
import { useOpenInNewTab } from "hooks/useOpenInNewTab"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { exportService } from "services/export/exportService"
import { functions } from "services/firebase/firebaseFunction"
import { paymentService } from "services/paymentService"
import { summaryOptionsService } from "services/summaryOptionsService"
import { RootState } from "storage/redux/rootReducer"
import { updateUser } from "storage/redux/user/actions"
import { DeleteAccountButton } from "./components/DeleteAccountButton"
import { FormValues, LanguagesFrom } from "./components/SummaryOptionsFrom"

const FAQ_URL = RECALL_WEBSITE_URL + "/faq"

export const SettingsPage = () => {
    const { email, isPremiumUser, uid, isReviewEmailEnabled } = useSelector(
        (state: RootState) => state.user
    )
    const history = useHistory()
    const db = useDatabase()
    const isNative = useIsNative()
    const { openInNewTab } = useOpenInNewTab()
    const [summaryOptions, setSummaryOptions] = useState<FormValues | null>(null)
    const dispatch = useDispatch()

    const handleExportDatabase = async () => {
        await exportService.exportAllToMarkdown(db)
    }

    const handleOpenPricingPage = () => {
        history.push(PRICING_PATH)
        posthogService.captureEvent(APP_UPGRADE_CLICK)
    }

    const handleReportBug = () => {
        openInNewTab("https://forms.gle/2ZMZa4HSjpCRagxC8")
    }

    const handleOpenFAQ = () => {
        openInNewTab(FAQ_URL)
    }

    const getOptions = async () => {
        if (!uid) return

        const summaryOptions = await summaryOptionsService.getSummaryOptions(uid)

        setSummaryOptions({
            language: summaryOptions.language || "auto",
            searchLanguage: summaryOptions.searchLanguage || "en",
            summaryLength: summaryOptions.defaultLength || SummaryLengthEnum.detailed,
        })
    }

    const handleRecallReviewChange = async (_, isEnabled) => {
        dispatch(updateUser({ isReviewEmailEnabled: isEnabled }, uid))
    }

    const handleOpenFeatureRequest = async () => {
        const ssoToken = await functions.generateCannyJwt()
        const redirectUrl = `https://canny.io/api/redirects/sso?companyID=659538f0893793303f920809&ssoToken=${ssoToken.data}&redirect=https://feedback.getrecall.ai/feature-requests`
        openInNewTab(redirectUrl)
    }

    useEffect(() => {
        getOptions()
    }, [])

    return (
        <Fade in>
            <Container sx={styles.container} maxWidth="sm">
                <Typography mb={2} fontWeight={600} variant="h4" textAlign="left">
                    My Settings
                </Typography>
                <SurfaceBox sx={styles.card}>
                    <Box>
                        <Typography fontWeight={500} variant="h5" component="h5">
                            Data
                        </Typography>
                        <Typography>Export your knowledge base to markdown</Typography>
                        <Box mt={1}>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={isNative}
                                startIcon={<Download />}
                                onClick={handleExportDatabase}
                                sx={{ minWidth: 130 }}
                            >
                                Export {isNative ? "(Desktop only)" : ""}
                            </Button>
                        </Box>
                    </Box>

                    <Box mt={4}>
                        <Typography fontWeight={500} variant="h5" component="h5">
                            Subscription
                        </Typography>
                        {isPremiumUser ? (
                            <>
                                <Typography>Manage your subscription</Typography>
                                <Box mt={1}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        disabled={isNative}
                                        startIcon={<Payment />}
                                        onClick={paymentService.openBillingPortal}
                                        sx={{ minWidth: 130 }}
                                    >
                                        Manage {isNative ? "(Desktop only)" : ""}
                                    </Button>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Typography>Upgrade to Recall Plus</Typography>
                                <Box mt={1}>
                                    <Button
                                        startIcon={<PremiumIcon fontSize="small" />}
                                        onClick={handleOpenPricingPage}
                                        variant="contained"
                                        color="primary"
                                        sx={{ minWidth: 130 }}
                                    >
                                        Upgrade
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Box>

                    <Box mt={4}>
                        <Typography fontWeight={500} variant="h5" component="h5">
                            Recall Review Email
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                            The review email is sent only if you have questions to review scheduled
                            for that day.
                        </Typography>
                        <Box>
                            <FormControlLabel
                                onChange={handleRecallReviewChange}
                                control={<Switch color="primary" checked={isReviewEmailEnabled} />}
                                label="Receive Email"
                            />
                        </Box>
                    </Box>

                    {summaryOptions && (
                        <Box mt={4}>
                            <LanguagesFrom defaultValues={summaryOptions} />
                        </Box>
                    )}

                    <Box mt={4}>
                        <Typography fontWeight={500} variant="h5" component="h5">
                            Account
                        </Typography>
                        <Typography>{email}</Typography>
                        <DeleteAccountButton />
                    </Box>
                </SurfaceBox>
                <Box sx={styles.buttonContainer}>
                    <Button
                        color="inherit"
                        onClick={handleOpenFeatureRequest}
                        endIcon={<OpenInNew />}
                        sx={styles.button}
                    >
                        Feature request
                    </Button>
                    <Button
                        color="inherit"
                        endIcon={<OpenInNew />}
                        onClick={handleReportBug}
                        sx={styles.button}
                    >
                        Report a bug
                    </Button>
                    <Button
                        color="inherit"
                        endIcon={<OpenInNew />}
                        sx={styles.button}
                        onClick={handleOpenFAQ}
                    >
                        FAQ
                    </Button>
                </Box>
            </Container>
        </Fade>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        pt: 7,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    card: {
        borderRadius: 1,
        p: 3,
        width: "100%",
    },
    logo: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: (theme) => theme.spacing(1.5),
        height: "64px",
    },
    button: {
        px: 2,
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: { xs: "column", md: "row" },
        gap: 2,
        my: 3,
        width: "100%",
    },
}
