import { useDatabase } from "@nozbe/watermelondb/react";
import { debounce, uniqBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { WIKIPEDIA } from "../../constants";
import { itemRepository } from "../../storage/watermelon/repository";
import { createEmptyItem } from "../../types";
import { checkIsUrl } from "../../utils";
const SEARCH_DEBOUNCE_MS = 200;
const SAVED_ITEMS_SEARCH_NUMBER = 10;
export default function useSearchItems(value, options = {}, searchWikipedia) {
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const db = useDatabase();
    const getSavedItems = async (searchValue) => {
        const searchedSavedItems = await itemRepository.search(db, searchValue, true);
        const savedItems = await Promise.all(searchedSavedItems.map(async (item) => await item.toItemPartial()));
        return savedItems;
    };
    const getWikiSourceIdentifiers = (items) => {
        const wikiSourcesSet = new Set();
        for (const item of items) {
            const wikiSource = item.sources.find((source) => source.name === WIKIPEDIA);
            if (!wikiSource)
                continue;
            wikiSourcesSet.add(wikiSource.identifier);
        }
        return wikiSourcesSet;
    };
    const getCreateItemOption = (searchValue) => {
        const emptyItem = createEmptyItem();
        const emptyItemPartial = Object.assign(Object.assign({}, emptyItem), { name: searchValue, isCreateOption: true });
        return emptyItemPartial;
    };
    const getGenerateSummaryOption = (url) => {
        const fullUrl = !url.startsWith("http") ? "https://" + url : url;
        const emptyItem = createEmptyItem();
        const emptyItemPartial = Object.assign(Object.assign({}, emptyItem), { name: fullUrl, isGenerateSummaryOption: true });
        return emptyItemPartial;
    };
    const getUniqueItemsBySource = (savedItems, wikiItems, sourceName) => {
        const savedItemsSourceIdentifiers = getWikiSourceIdentifiers(savedItems);
        const unsavedWikiItems = wikiItems.filter((item) => {
            const wikiSource = item.sources.find((source) => source.name === sourceName);
            return wikiSource && !savedItemsSourceIdentifiers.has(wikiSource.identifier);
        });
        const slicedSavedItems = savedItems.slice(0, SAVED_ITEMS_SEARCH_NUMBER);
        return [...slicedSavedItems, ...unsavedWikiItems];
    };
    const getAllSavedItems = async (savedItems, wikiItems) => {
        const wikiSources = Array.from(getWikiSourceIdentifiers(wikiItems));
        const wikiSavedItems = await itemRepository.getItemsBySourceIdentifiers(db, wikiSources);
        const wikiSavedItemPartials = await Promise.all(wikiSavedItems.map((item) => item.toItemPartial()));
        const allSavedItems = uniqBy([...wikiSavedItemPartials, ...savedItems], "id");
        return allSavedItems;
    };
    // eslint-disable-next-line
    const debouncedSearch = useCallback(debounce(async (searchValue) => {
        if (!searchValue) {
            setResults([]);
            setLoading(false);
            return;
        }
        const [savedItems, wikiItems] = await Promise.all([
            getSavedItems(searchValue),
            searchWikipedia(searchValue),
        ]);
        const allSavedItems = await getAllSavedItems(savedItems, wikiItems);
        const searchedItems = getUniqueItemsBySource(allSavedItems, wikiItems, WIKIPEDIA);
        const createItemOption = getCreateItemOption(searchValue);
        setResults([createItemOption, ...searchedItems]);
        setLoading(false);
    }, SEARCH_DEBOUNCE_MS), [db]);
    useEffect(() => {
        const searchValue = value.trim();
        if (checkIsUrl(searchValue) && (options === null || options === void 0 ? void 0 : options.isSummarizationEnabled)) {
            setResults([getGenerateSummaryOption(searchValue)]);
            debouncedSearch.cancel();
            setLoading(false);
            return;
        }
        setLoading(true);
        debouncedSearch(searchValue);
    }, [value, debouncedSearch, options === null || options === void 0 ? void 0 : options.isSummarizationEnabled]);
    return [loading, results];
}
