import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SvgIcon from "@mui/material/SvgIcon";
export const LinkIcon = ({ count, iconSVG }) => {
    const theme = useTheme();
    let rightPosition = "15px";
    if (count && count > 9) {
        rightPosition = "12px";
    }
    return (_jsxs(Box, Object.assign({ position: "relative", display: "inline-flex" }, { children: [_jsx(SvgIcon, Object.assign({ sx: { fontSize: "30px" } }, { children: iconSVG })), count && (_jsx(Typography, Object.assign({ variant: "caption", component: "span", style: {
                    position: "absolute",
                    top: "9px",
                    right: rightPosition,
                    fontSize: "0.75em",
                    color: theme.palette.text.primary,
                    fontWeight: 500,
                    cursor: "default",
                } }, { children: count })))] })));
};
