import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from "@mui/material";
import { ResizeHandle as ResizeHandlePrimitive } from "@udecode/plate-resizable";
export const ResizeHandle = ({ options }) => {
    const theme = useTheme();
    const styles = {
        resizeHandle: {
            width: 4,
            height: 70,
            backgroundColor: theme.palette.text.primary,
            cursor: "col-resize",
            margin: 6,
            borderRadius: 20,
            opacity: 0.9,
        },
    };
    return (_jsx(ResizeHandlePrimitive, { className: "resize-handler", style: styles.resizeHandle, options: options }));
};
