import { useDatabase } from "@nozbe/watermelondb/react"
import { itemRepository } from "@recall/common"
import { useEffect, useState } from "react"

export const useItemsCount = (includeReferences = false) => {
    const [itemsCount, setItemsCount] = useState<number | null>(null)
    const db = useDatabase()

    const getItemsCount = async (count: number) => {
        setItemsCount(count)
    }

    useEffect(() => {
        const subscription = itemRepository
            .observeCount(db, true, includeReferences)
            .subscribe(getItemsCount)

        return () => subscription.unsubscribe()
        // eslint-disable-next-line
    }, [includeReferences])

    return itemsCount
}
