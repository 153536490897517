const trackPurchaseConversion = () => {
    trackConversion("vDJACJTXq_gYELWkrsQq")
    return false
}

const trackSignupConversion = () => {
    trackConversion("RMjBCKOL7IIZELWkrsQq")
    return false
}

const trackConversion = (eventId: string) => {
    // @ts-ignore
    window.gtag("event", "conversion", {
        send_to: `AW-11417653813/${eventId}`,
    })

    return false
}

export const gtmService = { trackSignupConversion, trackPurchaseConversion }
