var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Model } from "@nozbe/watermelondb";
import { field, relation, writer } from "@nozbe/watermelondb/decorators";
import { dispatchConnectionDeleted } from "../../../types";
import { CONNECTION_PROPERTIES, CONNECTIONS } from "../schema";
export class ConnectionModel extends Model {
    constructor() {
        super(...arguments);
        this.prepareSave = () => {
            if (this.isSaved === false)
                return this.prepareUpdate((record) => {
                    record.isSaved = true;
                });
            return null;
        };
        this.prepareDeleteWithStaleItem = async (dispatchEvent = true) => {
            let tasks = [];
            const task = this.prepareDelete(dispatchEvent);
            try {
                const toItem = await this.to.fetch();
                const count = await toItem.mentions.count;
                const isStale = await toItem.isStale();
                if (isStale && count <= 1)
                    tasks = await toItem.prepareDelete();
            }
            catch (_a) { }
            tasks.push(task);
            return tasks;
        };
        this.prepareDelete = (dispatchEvent = true) => {
            if (this._preparedState === null) {
                if (dispatchEvent === true) {
                    dispatchConnectionDeleted(this.id);
                }
                if (this.isSaved) {
                    return this.prepareMarkAsDeleted();
                }
                else {
                    return this.prepareDestroyPermanently();
                }
            }
        };
    }
    async deleteWithStaleItem(dispatchEvent = true) {
        const tasks = await this.prepareDeleteWithStaleItem(dispatchEvent);
        await this.batch(...tasks);
    }
    async delete(dispatchEvent = true) {
        const task = this.prepareDelete(dispatchEvent);
        if (!task)
            return;
        await this.batch(task);
    }
}
ConnectionModel.table = CONNECTIONS;
ConnectionModel.associations = {
    property: { type: "belongs_to", key: "property_id" },
};
__decorate([
    field("is_saved")
], ConnectionModel.prototype, "isSaved", void 0);
__decorate([
    relation("items", "from_id")
], ConnectionModel.prototype, "from", void 0);
__decorate([
    relation("items", "to_id")
], ConnectionModel.prototype, "to", void 0);
__decorate([
    relation(CONNECTION_PROPERTIES, "property_id")
], ConnectionModel.prototype, "property", void 0);
__decorate([
    writer
], ConnectionModel.prototype, "deleteWithStaleItem", null);
__decorate([
    writer
], ConnectionModel.prototype, "delete", null);
