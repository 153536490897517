import { Q } from "@nozbe/watermelondb";
import { subDays, subHours } from "date-fns";
import { keyBy, map, uniqBy } from "lodash";
import { v4 as uuid } from "uuid";
import { sentry } from "../../../utils/sentry";
import { ITEM_TAG, TAGS } from "../schema";
import { itemRepository } from "./itemRepository";
export const ROOT_TAG_ID = "all-tags";
export const ROOT_TAG_NAME = "All tags";
const getNestedTags = async (db, tagIds) => {
    let i = 0;
    let tags = await getByParentTagIds(db, tagIds);
    while (i < tags.length) {
        const nestedTags = await getByParentTagIds(db, [tags[i].id]);
        tags = [...tags, ...nestedTags];
        i++;
    }
    return uniqBy(tags, "id");
};
const get = async (db, id) => {
    try {
        const tags = await db.collections.get(TAGS).query(Q.where("id", id));
        return (tags === null || tags === void 0 ? void 0 : tags[0]) || null;
    }
    catch (_a) {
        return null;
    }
};
const observe = (db) => {
    return db.collections.get(TAGS).query().observe();
};
const observeCount = (db) => {
    return db.collections.get(TAGS).query().observeCount();
};
const getTagByName = async (db, name) => {
    const tag = await db.collections.get(TAGS).query(Q.where("name", name)).fetch();
    return (tag === null || tag === void 0 ? void 0 : tag[0]) || null;
};
const getTagByNameAndParentId = async (db, name, parentId) => {
    const tag = await db.collections
        .get(TAGS)
        .query(Q.and(Q.where("name", name), Q.where("parent_id", parentId)))
        .fetch();
    return (tag === null || tag === void 0 ? void 0 : tag[0]) || null;
};
const getTagByNameLike = async (db, name) => {
    const tags = await getAll(db);
    const tag = tags.find((tag) => tag.name.toLowerCase().trim() === name.toLowerCase().trim());
    return tag || null;
};
const create = async ({ db, name, item, isSaved = false, id = uuid(), parentId = ROOT_TAG_ID, }) => {
    return await db.write(async (writer) => {
        const trimmedName = name.trim();
        if (!trimmedName)
            return null;
        const existingTag = await getTagByNameAndParentId(db, trimmedName, parentId);
        if (existingTag) {
            if (item)
                await writer.callWriter(() => attach(db, existingTag, item));
            return existingTag;
        }
        const parent = await get(db, parentId);
        const tag = await db.collections.get(TAGS).create((record) => {
            record._raw.id = id;
            record.name = trimmedName;
            record.isSaved = item ? item.isSaved : isSaved;
            record.isReference = (item === null || item === void 0 ? void 0 : item.isReference) || false;
            if (parent)
                record.parent.set(parent);
        });
        if (item)
            await writer.callWriter(() => attach(db, tag, item));
        else {
            const isReference = await isTagReference(db, tag.id, item);
            return await tag.update((record) => {
                record.isReference = isReference;
            });
        }
        return tag;
    });
};
const isTagReference = async (db, tagId, item) => {
    if (tagId === ROOT_TAG_ID)
        return false;
    const nestedTags = await getNestedTags(db, [tagId]);
    const itemTags = await getItemTagsByTagIds(db, [tagId]);
    const itemIds = map(itemTags, "item.id");
    const savedCount = await itemRepository
        .getQueryAll(db, false)
        .extend(Q.where("id", Q.oneOf(itemIds)))
        .fetchCount();
    const referenceCount = await itemRepository
        .getQueryAll(db, true)
        .extend(Q.where("id", Q.oneOf(itemIds)))
        .fetchCount();
    const isEveryNestedTagReference = nestedTags
        .filter((tag) => tag.id !== tagId)
        .every((tag) => tag.isReference);
    const isTagInTheMiddle = savedCount === 0 && referenceCount === 0 && isEveryNestedTagReference;
    if (item && item.isReference && isTagInTheMiddle)
        return true;
    if (!item && isTagInTheMiddle)
        return true;
    return savedCount === 0 && referenceCount > 0 && isEveryNestedTagReference;
};
const attach = async (db, tag, item) => {
    return await db.write(async (writer) => {
        const existingTag = await get(db, tag.id);
        if (!existingTag)
            return;
        const existingItemTag = await getItemTagsByTagIds(db, [tag.id]);
        if (existingItemTag.length &&
            existingItemTag.some((itemTag) => itemTag.item.id === item.id))
            return existingItemTag;
        await db.collections.get(ITEM_TAG).create((record) => {
            record._raw.id = uuid();
            record.tag.set(tag);
            record.item.set(item);
            record.isSaved = item.isSaved;
        });
        const isReference = await isTagReference(db, tag.id, item);
        if (tag.isReference !== isReference)
            await tag.update((record) => {
                record.isReference = isReference;
            });
        await writer.callWriter(() => updateIsParentTagReference(db, tag.id));
    });
};
const detach = async (db, tagId, itemId) => {
    if (!tagId || !itemId)
        return;
    return await db.write(async (action) => {
        const itemTag = await db.collections
            .get(ITEM_TAG)
            .query(Q.where("item_id", Q.eq(itemId)), Q.where("tag_id", Q.eq(tagId)))
            .fetch();
        if (!itemTag)
            return;
        await action.callWriter(() => itemTag[0].delete());
    });
};
const getAll = (db) => db.collections.get(TAGS).query().fetch();
const getAllWithoutReferences = (db) => db.collections.get(TAGS).query(Q.where("is_reference", false)).fetch();
const getTagsByItemIds = (db, itemIds) => db.collections.get(TAGS).query(Q.on(ITEM_TAG, Q.where("item_id", Q.oneOf(itemIds))));
const getItemTagsByItemIds = (db, itemIds) => db.collections.get(ITEM_TAG).query(Q.where("item_id", Q.oneOf(itemIds)));
const getItemTags = (db) => db.collections.get(ITEM_TAG).query(Q.sortBy("created_at", "desc"));
const getByTagIds = (db, tagIds) => db.collections
    .get(TAGS)
    .query(Q.where("id", Q.oneOf(tagIds)))
    .fetch();
const getByParentTagIds = (db, tagIds) => db.collections
    .get(TAGS)
    .query(Q.where("parent_id", Q.oneOf(tagIds)))
    .fetch();
const getItemTagsByTagIds = async (db, tagIds) => {
    const itemTags = await db.collections
        .get(ITEM_TAG)
        .query(Q.where("tag_id", Q.oneOf(tagIds)))
        .fetch();
    return itemTags;
};
const getItemTagByTagAndItemId = async (db, tagId, itemId) => {
    const itemTags = await db.collections
        .get(ITEM_TAG)
        .query(Q.and(Q.where("tag_id", tagId), Q.where("item_id", itemId)))
        .fetch();
    return (itemTags === null || itemTags === void 0 ? void 0 : itemTags[0]) || null;
};
const getAllItemTags = async (db) => {
    const itemTags = await db.collections
        .get(ITEM_TAG)
        .query(Q.where("is_saved", true))
        .fetch();
    return itemTags;
};
const getItemsByTagIds = async (db, tagIds) => {
    const itemTags = await db.collections
        .get(ITEM_TAG)
        .query(Q.where("tag_id", Q.oneOf(tagIds)))
        .fetch();
    const items = [];
    for (const itemTag of itemTags) {
        try {
            const item = await itemTag.item.fetch();
            items.push(item);
        }
        catch (_a) {
            await db.write(async (writer) => await writer.callWriter(() => itemTag.delete()));
        }
    }
    return items.filter(Boolean);
};
const updateParent = async (db, parentId, tagId) => {
    return await db.write(async (writer) => {
        const parent = await get(db, parentId);
        const tag = await get(db, tagId);
        if (!parent || !tag)
            return;
        await tag.update((record) => {
            record.parent.set(parent);
        });
        const isReference = await isTagReference(db, parentId);
        await writer.callWriter(() => updateIsParentTagReference(db, parentId));
        return await tag.update((record) => {
            record.isReference = isReference;
        });
    });
};
const updateIsParentTagReference = async (db, tagId) => {
    return await db.write(async (writer) => {
        const tag = await get(db, tagId);
        if (!tag)
            return;
        const ancestors = [tag];
        let i = 0;
        while (i < ancestors.length) {
            const tag = ancestors[i];
            const parent = await tag.parent.fetch();
            if (parent)
                ancestors.push(parent);
            i++;
        }
        for (const ancestor of ancestors) {
            const isReference = await isTagReference(db, ancestor.id);
            if (isReference === ancestor.isReference)
                continue;
            await ancestor.update((record) => {
                record.isReference = isReference;
            });
        }
    });
};
const getTagsByItemId = async (db, itemId) => {
    const itemTags = await db.collections
        .get(ITEM_TAG)
        .query(Q.where("item_id", itemId))
        .fetch();
    const tags = [];
    for (const itemTag of itemTags) {
        try {
            const tag = await itemTag.tag.fetch();
            tags.push(tag);
        }
        catch (_a) {
            await db.write(async (writer) => await writer.callWriter(() => itemTag.delete()));
        }
    }
    return tags;
};
const assignChildrenToTag = async (db, targetTag, sourceTag) => {
    return await db.write(async (writer) => {
        const sourceChildren = await getByParentTagIds(db, [sourceTag.id]);
        const childrenByNames = keyBy(sourceChildren, (child) => child.name.toLowerCase());
        const targetChildren = await getByParentTagIds(db, [targetTag.id]);
        for (const child of sourceChildren)
            await child.update((record) => {
                record.parent.set(targetTag);
            });
        for (const targetChild of targetChildren) {
            const duplicateChild = childrenByNames[targetChild.name.toLowerCase()];
            if (duplicateChild)
                await writer.callWriter(() => mergeTags(db, targetChild, duplicateChild));
        }
    });
};
const assignItemTags = async (db, targetTag, sourceTag) => {
    return await db.write(async (writer) => {
        const itemTags = await getItemTagsByTagIds(db, [sourceTag.id]);
        let tasks = [];
        for (const itemTag of itemTags) {
            const existingItemTag = await getItemTagByTagAndItemId(db, targetTag.id, itemTag.item.id);
            if (targetTag.id === ROOT_TAG_ID || existingItemTag) {
                const task = itemTag.prepareDelete();
                tasks.push(task);
            }
            else {
                const task = itemTag.prepareUpdate((record) => {
                    record.tag.set(targetTag);
                });
                tasks.push(task);
            }
        }
        await writer.batch(...tasks);
    });
};
const mergeTags = async (db, targetTag, sourceTag) => {
    return await db.write(async (writer) => {
        await writer.callWriter(() => assignChildrenToTag(db, targetTag, sourceTag));
        await writer.callWriter(() => assignItemTags(db, targetTag, sourceTag));
        await writer.callWriter(() => sourceTag.delete());
    });
};
const deleteTag = async (db, tagId) => {
    return await db.write(async (writer) => {
        const tag = await get(db, tagId);
        if (!tag)
            return;
        let parent = await tag.parent.fetch();
        if (!parent) {
            sentry.captureMessage("Parent not defined while deleting a tag");
            parent = await get(db, ROOT_TAG_ID);
        }
        // @ts-ignore
        if (parent)
            await writer.callWriter(() => mergeTags(db, parent, tag));
    });
};
const deleteStale = async (db) => {
    return await db.write(async (writer) => {
        const staleTags = await db.collections
            .get(TAGS)
            .query(Q.where("is_saved", false))
            .fetch();
        for (const staleTag of staleTags) {
            await writer.callWriter(() => deleteTag(db, staleTag.id));
        }
    });
};
const deleteStaleByDate = async (db) => {
    const oneHourAgo = subHours(new Date(), 1);
    return await db.write(async (writer) => {
        const staleTags = await db.collections
            .get(TAGS)
            .query(Q.and(Q.where("is_saved", false), Q.or(Q.where("created_at", Q.lt(oneHourAgo.getTime())), Q.where("created_at", null))))
            .fetch();
        for (const staleTag of staleTags) {
            await writer.callWriter(() => deleteTag(db, staleTag.id));
        }
    });
};
const deleteAllStale = async (db) => {
    const fiveDaysAgo = subDays(new Date(), 5);
    const tags = await db.collections
        .get(TAGS)
        .query(Q.and(Q.where("is_saved", false), Q.where("created_at", Q.lt(fiveDaysAgo.getTime()))))
        .fetch();
    for (const tag of tags) {
        const isStale = await tag.isStale();
        if (!isStale)
            continue;
        await tag.delete();
    }
};
const rename = async (db, tagId, name) => {
    return await db.write(async () => {
        const tag = await get(db, tagId);
        if (!tag)
            return;
        return await tag.update((record) => {
            record.name = name;
        });
    });
};
const updateTagsReferenceState = async (db, tags, item) => {
    await db.write(async (writer) => {
        for (const tag of tags) {
            const isReference = await isTagReference(db, tag.id, item);
            await tag.update((record) => {
                record.isReference = isReference;
            });
            await writer.callWriter(() => updateIsParentTagReference(db, tag.id));
        }
    });
};
export const tagRepository = {
    get,
    getItemsByTagIds,
    getTagsByItemId,
    getTagByNameAndParentId,
    updateParent,
    observe,
    observeCount,
    create,
    attach,
    detach,
    getAll,
    getAllWithoutReferences,
    getTagsByItemIds,
    getItemTagsByItemIds,
    getItemTags,
    getTagByName,
    getTagByNameLike,
    getNestedTags,
    getByTagIds,
    getItemTagsByTagIds,
    deleteTag,
    deleteStale,
    deleteStaleByDate,
    rename,
    updateTagsReferenceState,
    getAllItemTags,
    getByParentTagIds,
    mergeTags,
    deleteAllStale,
};
