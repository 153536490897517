import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Menu, Tooltip } from "@mui/material";
import { IconMoodSmile } from "@tabler/icons-react";
import { useEmojiDropdownMenuState } from "@udecode/plate-emoji";
import { useRef } from "react";
import { emojiCategoryIcons, emojiSearchIcons } from "./EmojiIcons";
import { EmojiPicker } from "./EmojiPicker";
export const EmojiDropDownMenu = () => {
    const { isOpen, setIsOpen, emojiPickerState } = useEmojiDropdownMenuState();
    const anchorEl = useRef(null);
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ title: "Emoji" }, { children: _jsx(IconButton, Object.assign({ onClick: () => {
                        setIsOpen(true);
                    }, ref: anchorEl, size: "small", sx: {
                        color: (theme) => isOpen ? theme.palette.text.primary : theme.palette.action.active,
                        backgroundColor: (theme) => isOpen ? theme.palette.action.selected : undefined,
                        ":hover": {
                            backgroundColor: (theme) => isOpen ? theme.palette.action.selected : undefined,
                        },
                    } }, { children: _jsx(IconMoodSmile, {}) })) })), _jsx(Menu, Object.assign({ anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                }, transformOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                }, MenuListProps: {
                    sx: { p: 0.5, px: 1 },
                }, anchorEl: anchorEl.current, open: isOpen, onClose: () => setIsOpen(false) }, { children: _jsx(EmojiPicker, Object.assign({}, emojiPickerState, { isOpen: isOpen, setIsOpen: setIsOpen, icons: {
                        categories: emojiCategoryIcons,
                        search: emojiSearchIcons,
                    } })) }))] }));
};
