import ExpandIcon from "@mui/icons-material/Expand"
import { Tooltip } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { ItemModel, SourceModel, WIKIPEDIA, createAssets, sentry } from "@recall/common"
import { useSources } from "components/ItemPage/hooks/useSources"
import { LoadingButton } from "components/shared/buttons/LoadingButton"
import { RERENDER_TAGS_EVENT } from "components/shared/tags/hooks/useGroupedTags"
import { FC, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { itemAPI } from "storage/api/items/Item"
import { RootState } from "storage/redux/rootReducer"

export const EXPAND_DIV_ID = "id-expand-button"

interface Props {
    item: ItemModel
    name: string
    rerender: () => void
}

export const ExpandButton: FC<Props> = ({ item, name, rerender }) => {
    const [loading, setLoading] = useState(false)
    const db = useDatabase()
    const uid = useSelector((state: RootState) => state.user.uid)
    const sources = useSources(item)

    const wikipediaSource = sources.find((source: SourceModel) => source.name === WIKIPEDIA)

    if (!wikipediaSource) {
        return null
    }

    const handleOnClick = async () => {
        setLoading(true)

        try {
            const newItem = await itemAPI.getAndSaveWikipedia(
                db,
                wikipediaSource.identifier,
                item.language || "en"
            )
            if (!newItem)
                throw new Error(`Wikipedia source ${wikipediaSource.identifier} not found`)

            await item.merge(newItem)
            await createAssets(db, item, uid)
            document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
        } catch (e) {
            sentry.captureException(e)
            toast.error("Could not expand card.")
        } finally {
            setLoading(false)
            rerender()
        }
    }

    return (
        <Tooltip title={`Expand "${name}" with info from the web.`}>
            <div>
                <LoadingButton
                    id={EXPAND_DIV_ID}
                    onClick={handleOnClick}
                    variant="contained"
                    color="secondary"
                    size="small"
                    loading={loading}
                    startIcon={<ExpandIcon />}
                    sx={{
                        boxShadow: "none",
                        mr: 0.5,
                        minWidth: "94px",
                    }}
                >
                    Expand
                </LoadingButton>
            </div>
        </Tooltip>
    )
}
