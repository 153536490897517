import { sentry } from "@recall/common"

export const executeInOneTabOnly = async (callback: () => Promise<void>, lock: string) => {
    if ("locks" in navigator) {
        await navigator.locks.request(
            lock,
            { mode: "exclusive", ifAvailable: true },
            async (lock: Lock | null) => {
                try {
                    if (lock) await callback()
                } catch (error) {
                    sentry.captureException(error, { message: "Execute in one tab error" })
                }
            }
        )
    } else {
        await obtainLockWithLocalStorage(callback, lock)
    }
}

const obtainLockWithLocalStorage = async (callback: () => Promise<void>, lock: string) => {
    const lockItem = localStorage.getItem(lock)

    if (lockItem && Number(lockItem) && !isLockStale(Number(lockItem))) {
        return
    }

    localStorage.setItem(lock, String(Date.now()))

    try {
        await callback()
    } catch (error) {
        sentry.captureException(error, { message: "Execute in one tab error" })
    }

    localStorage.removeItem(lock)
}

const isLockStale = (lockTimestamp: number) => {
    const now = Date.now()
    const lockAge = now - lockTimestamp
    const threshold = 5 * 60 * 1000
    return lockAge > threshold
}
