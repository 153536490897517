import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium"
import { Button, ButtonProps } from "@mui/material"
import { SxProps, Theme, alpha } from "@mui/material/styles"
import { PRICING_PATH } from "constants/routes"
import { FC } from "react"
import { useHistory } from "react-router"

interface Props extends ButtonProps {
    onclick?: () => void
}

export const UpgradeButton: FC<Props> = ({ onclick, ...props }) => {
    const history = useHistory()

    const handleOnClick = () => {
        if (onclick) onclick()
        history.push(PRICING_PATH)
    }

    return (
        <Button
            color="secondary"
            variant="contained"
            onClick={handleOnClick}
            startIcon={<WorkspacePremiumIcon sx={styles.buttonIcon} />}
            {...props}
        >
            Upgrade
        </Button>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    buttonIcon: {
        color: alpha("#000", 0.6),
        "&:hover:": {
            color: alpha("#000", 0.8),
        },
    },
}
