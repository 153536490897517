import { useDatabase } from "@nozbe/watermelondb/react"
import { ItemModel, itemRepository, RECALL_APP_URL } from "@recall/common"
import { ITEM_PATH } from "constants/routes"
import { useBreadcrumbActions } from "hooks/useBreadcrumbActions"
import { useIsNative } from "hooks/useIsNative"
import { useCallback, useMemo } from "react"
import { useHistory } from "react-router"

interface Options {
    state?: object
    reset?: boolean
    target?: string
}

export const useOpenItem = () => {
    const db = useDatabase()
    const { addBreadcrumb, resetAndAddBreadcrumb } = useBreadcrumbActions()
    const history = useHistory()
    const isNative = useIsNative()

    const openItem = useCallback((item: ItemModel, options?: Options) => {
        const path = getItemPath(item.id)

        if (options?.reset) {
            resetAndAddBreadcrumb({ label: item.name, path })
            history.push(path, options?.state)
        } else if (options?.target === "_blank" && !isNative) {
            window.open(RECALL_APP_URL + path, options.target)
        } else {
            addBreadcrumb({ label: item.name, path }, window.scrollY)
            history.push(path, options?.state)
        }
    }, [])

    const openItemInNewTab = useCallback((item: ItemModel, options?: Options) => {
        const path = getItemPath(item.id)
        if (options?.target && !isNative) {
            window.open(RECALL_APP_URL + path, "_blank")
        } else {
            history.push(path, options?.state)
        }
    }, [])

    const openItemById = useCallback(
        async (id: string, options?: Options) => {
            const item = await itemRepository.get(db, id)
            openItem(item, options)
        },
        [db, openItem]
    )

    const getItemPath = useCallback((id: string) => {
        return ITEM_PATH.replace(":id", id)
    }, [])

    return useMemo(
        () => ({ openItemById, openItem, getItemPath, openItemInNewTab }),
        [openItemById, openItem]
    )
}
