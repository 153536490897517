import { THANK_YOU_FOR_INSTALLING_EXTENSION } from "@recall/common"
import InstallExtension from "components/ExtensionPages/InstallExtensionPage/InstallExtensionPage"
import ItemPage from "components/ItemPage/ItemPage"
import { ItemPageRedirect } from "components/ItemPage/ItemPageRedirect"
import ItemsPage from "components/ItemsPage/ItemsPage"
import { SettingsPage } from "components/SettingsPage/SettingsPage"
import { LoggingInPage } from "components/UserPages/LoggingInPage"
import { VerifyEmailPage } from "components/UserPages/VerifyEmailPage"
import PageLayout from "components/layouts/PageLayout"
import { Navigation } from "components/layouts/components/Navigation/Navigation"
import {
    DEPRECATED_ITEM_PATH,
    HOME_PATH,
    INSTALL_EXTENSION_PATH,
    ITEM_PATH,
    KNOWLEDGE_GRAPH_PATH,
    LOGGING_IN_PATH,
    LOGIN_PATH,
    SAVE_LIB_SUMMARY_PATH,
    SETTINGS_PATH,
    SHARE_TARGET_PATH,
    SPACED_REPETITION,
} from "constants/routes"
import { FC, lazy } from "react"
import { useSelector } from "react-redux"
import { Redirect, Route, RouteProps } from "react-router-dom"
import { RootState } from "storage/redux/rootReducer"
import { isLoggedInSelector } from "storage/redux/user/selector"
import ShareTargetPage from "../ShareTarget/ShareTargetPage"

const KnowledgeGraphPage = lazy(() => import("components/KnowledgeGraphPage/KnowledgeGraphPage"))

const SaveLibrarySummaryPage = lazy(
    () => import("components/LibrarySummary/SaveLibrarySummaryPage")
)
const ThankYouExtensionPage = lazy(
    () => import("../ExtensionPages/ThankYouExtensionPage/ThankYouExtensionPage")
)
const SpacedRepetitionPage = lazy(() => import("components/SpacedRepetition/SpacedRepetition"))

export const PrivateRoutes: FC<RouteProps> = ({ ...rest }) => {
    const isLoggedIn = useSelector(isLoggedInSelector)

    const emailVerified = useSelector((state: RootState) => state.user.emailVerified)
    const isPremiumUser = useSelector((state: RootState) => state.user.isPremiumUser)

    const showVerifyEmailPage = !emailVerified && !isPremiumUser

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isLoggedIn ? (
                    showVerifyEmailPage ? (
                        <VerifyEmailPage />
                    ) : (
                        <>
                            <Route
                                path={[
                                    HOME_PATH,
                                    DEPRECATED_ITEM_PATH,
                                    ITEM_PATH,
                                    SHARE_TARGET_PATH,
                                    SAVE_LIB_SUMMARY_PATH,
                                    SPACED_REPETITION,
                                    KNOWLEDGE_GRAPH_PATH,
                                    SETTINGS_PATH,
                                ]}
                            >
                                <Navigation />
                            </Route>
                            <Route path={INSTALL_EXTENSION_PATH} exact>
                                <InstallExtension />
                            </Route>
                            <Route path={THANK_YOU_FOR_INSTALLING_EXTENSION} exact>
                                <ThankYouExtensionPage />
                            </Route>
                            <Route
                                path={[
                                    HOME_PATH,
                                    ITEM_PATH,
                                    SHARE_TARGET_PATH,
                                    SAVE_LIB_SUMMARY_PATH,
                                    DEPRECATED_ITEM_PATH,
                                ]}
                                exact
                            >
                                <PageLayout>
                                    <Route path={HOME_PATH} exact>
                                        <ItemsPage />
                                    </Route>
                                    <Route path={DEPRECATED_ITEM_PATH} exact>
                                        <ItemPageRedirect />
                                    </Route>
                                    <Route path={ITEM_PATH} exact>
                                        <ItemPage />
                                    </Route>
                                    <Route path={SHARE_TARGET_PATH} exact>
                                        <ShareTargetPage />
                                    </Route>
                                    <Route path={SAVE_LIB_SUMMARY_PATH} exact>
                                        <SaveLibrarySummaryPage />
                                    </Route>
                                </PageLayout>
                            </Route>
                            <Route path={SPACED_REPETITION} exact>
                                <SpacedRepetitionPage />
                            </Route>
                            <Route path={[KNOWLEDGE_GRAPH_PATH, SETTINGS_PATH]} exact>
                                <Route path={KNOWLEDGE_GRAPH_PATH} exact>
                                    <KnowledgeGraphPage />
                                </Route>
                                <Route path={SETTINGS_PATH} exact>
                                    <SettingsPage />
                                </Route>
                            </Route>
                            <Route path={LOGGING_IN_PATH} exact>
                                <LoggingInPage />
                            </Route>
                        </>
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: LOGIN_PATH,
                            state: { from: location },
                            search: location.search,
                        }}
                    />
                )
            }
        />
    )
}
