import { ItemModel } from "@recall/common"
import { useOpenItem } from "hooks/items/useOpenItem"
import { useBreadcrumbActions } from "hooks/useBreadcrumbActions"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"

export const useCurrentBreadcrumbs = (item: ItemModel) => {
    const breadcrumbs = useSelector((state: RootState) => state.app.breadcrumbs)

    const { getItemPath } = useOpenItem()
    const { addBreadcrumbWithoutRedirect } = useBreadcrumbActions()

    useEffect(() => {
        const existingBreadcrumb = breadcrumbs.find(({ label }) => label === item.name)

        if (existingBreadcrumb) {
            if (existingBreadcrumb.position)
                setTimeout(() => window.scroll({ top: existingBreadcrumb.position }))
            else window.scroll({ top: 0 })
            return
        } else {
            window.scroll({ top: 0 })
        }

        addBreadcrumbWithoutRedirect({ label: item.name, path: getItemPath(item.id) })
    }, [item])
}
