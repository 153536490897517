import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { MenuItem, Select } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
export const defaultLanguageOption = {
    label: "English",
    value: "en",
};
export const LanguageSelect = ({ options, name = "language" }) => {
    const { control } = useFormContext();
    return (_jsx(Controller, { control: control, name: name, rules: { required: "Language is required" }, render: ({ field: { onChange, value } }) => {
            return (_jsx(Select, Object.assign({ sx: { minWidth: 300, mt: 1 }, placeholder: "Language", onChange: onChange, value: value }, { children: options.map(({ value, label, flag }) => (_jsxs(MenuItem, Object.assign({ value: value }, { children: [flag, " \u00A0 ", label] }), value))) })));
        } }));
};
