import { createAlignPlugin } from "@udecode/plate-alignment";
import { createAutoformatPlugin } from "@udecode/plate-autoformat";
import { createBasicElementsPlugin } from "@udecode/plate-basic-elements";
import { createBasicMarksPlugin } from "@udecode/plate-basic-marks";
import { createExitBreakPlugin } from "@udecode/plate-break";
import { createComboboxPlugin } from "@udecode/plate-combobox";
import { createInsertDataPlugin } from "@udecode/plate-common";
import { createEmojiPlugin } from "@udecode/plate-emoji";
import { createFontBackgroundColorPlugin, createFontColorPlugin } from "@udecode/plate-font";
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3, ELEMENT_H4, ELEMENT_H5, ELEMENT_H6, KEYS_HEADING, } from "@udecode/plate-heading";
import { createHighlightPlugin } from "@udecode/plate-highlight";
import { createHorizontalRulePlugin } from "@udecode/plate-horizontal-rule";
import { createIndentPlugin } from "@udecode/plate-indent";
import { createIndentListPlugin } from "@udecode/plate-indent-list";
import { createLinkPlugin } from "@udecode/plate-link";
import { createImagePlugin, ELEMENT_IMAGE, ELEMENT_MEDIA_EMBED } from "@udecode/plate-media";
import { createMentionPlugin } from "@udecode/plate-mention";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import { createSelectOnBackspacePlugin } from "@udecode/plate-select";
import { EmojiCombobox } from "../components/EmojiPicker/EmojiCombobox";
import { LinkFloatingToolbar } from "../components/Toolbar/LinkFloatingToolbar";
import { createMyPlugins } from "../types";
import { autoformatRules } from "./autoformat";
import { createUIComponents } from "./createUIComponents";
import { createCustomEditorBlockPlugin, ELEMENT_CUSTOM_EDITOR_BLOCK } from "./editor-block";
import { createCustomImagePlugin } from "./image";
import { createCustomParagraphPlugin, ELEMENT_CUSTOM_PARAGRAPH } from "./paragraph";
import { createReferencePlugin } from "./reference";
import { EmojiTriggeringController } from "./withEmoji/EmojiTriggeringController";
import { withEmoji } from "./withEmoji/withEmoji";
import { withImage } from "./withImage/withImage";
import { withPaste } from "./withPaste/withPaste";
import { createYouTubeTimestampPlugin } from "./youtube-timestamp";
const textTypes = [
    ELEMENT_CUSTOM_EDITOR_BLOCK,
    ELEMENT_CUSTOM_PARAGRAPH,
    ELEMENT_PARAGRAPH,
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
];
export const createPlatePlugins = ({ readOnly = false, isExtension = false, isMainImage, updateImage, openItemById, uploadImage, getItemByConnectionId, deleteConnectionById, createConnection, searchWikipedia, getSummaryPreview, }) => {
    return createMyPlugins([
        createInsertDataPlugin({ withOverrides: withPaste }),
        createBasicElementsPlugin(),
        createHorizontalRulePlugin(),
        createBasicMarksPlugin(),
        createCustomEditorBlockPlugin(),
        createCustomParagraphPlugin(),
        createAutoformatPlugin({
            options: {
                rules: autoformatRules,
                enableUndoOnDelete: true,
            },
        }),
        createIndentPlugin({
            inject: {
                props: {
                    validTypes: textTypes,
                },
            },
        }),
        createIndentListPlugin({
            inject: {
                props: {
                    validTypes: textTypes,
                },
            },
        }),
        createComboboxPlugin(),
        createEmojiPlugin({
            withOverrides: withEmoji,
            options: {
                emojiTriggeringController: new EmojiTriggeringController(),
            },
            renderAfterEditable: EmojiCombobox,
        }),
        createAlignPlugin({
            inject: {
                props: {
                    validTypes: textTypes,
                },
            },
        }),
        createExitBreakPlugin({
            options: {
                rules: [
                    {
                        hotkey: "mod+enter",
                    },
                    {
                        hotkey: "mod+shift+enter",
                        before: true,
                    },
                    {
                        hotkey: "enter",
                        query: {
                            start: true,
                            end: true,
                            allow: KEYS_HEADING,
                        },
                        relative: true,
                        level: 1,
                    },
                ],
            },
        }),
        createHighlightPlugin(),
        createReferencePlugin(),
        createImagePlugin({
            options: {
                disableEmbedInsert: true,
                uploadImage,
            },
            withOverrides: withImage,
        }),
        createCustomImagePlugin(),
        // createCaptionPlugin({ options: { pluginKeys: [ELEMENT_IMAGE, ELEMENT_MEDIA_EMBED] } }),
        // createMediaEmbedPlugin(),
        createFontColorPlugin(),
        createFontBackgroundColorPlugin(),
        createSelectOnBackspacePlugin({
            options: {
                query: {
                    allow: [ELEMENT_IMAGE, ELEMENT_MEDIA_EMBED],
                },
            },
        }),
        createMentionPlugin({
            options: {
                insertSpaceAfterMention: true,
                trigger: "/",
            },
            enabled: !isExtension,
        }),
        createYouTubeTimestampPlugin(),
        createLinkPlugin({
            renderAfterEditable: () => LinkFloatingToolbar(isExtension),
        }),
    ], {
        components: createUIComponents({
            readOnly,
            isExtension,
            isMainImage,
            updateImage,
            openItemById,
            getItemByConnectionId,
            deleteConnectionById,
            createConnection,
            searchWikipedia,
            getSummaryPreview,
        }),
    });
};
