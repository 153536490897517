export class UsageService {
    constructor(userRepository) {
        this.defaultUsage = { extension: 0, scraping: 0, cost: { value: 0, resetDate: Date.now() } };
        this.getUserUsage = (user, usage) => {
            return Object.assign(Object.assign({}, ((user === null || user === void 0 ? void 0 : user.usage) || this.defaultUsage)), usage);
        };
        this.isUsageExceededByCount = (usage) => {
            return usage >= this.limit;
        };
        this.userRepository = userRepository;
    }
}
