import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Chip, Tooltip, Typography } from "@mui/material";
import { TotalCountBadge } from "../badges/TotalCountBadge";
export const CardChip = ({ name, description, image, mentionCount, onClick, hideTooltip, onlyOtherMentionCount = true, disabled = false, }) => {
    var _a;
    let label = _jsx(_Fragment, { children: name });
    if (!hideTooltip) {
        label = (_jsx(Tooltip, Object.assign({ title: _jsxs(_Fragment, { children: [_jsx(Typography, Object.assign({ variant: "body2" }, { children: name })), description || ""] }), arrow: true }, { children: _jsx("span", { children: name }) })));
    }
    return (_jsx(Chip, { disabled: disabled, onClick: onClick ? onClick : undefined, sx: {
            mr: 1,
            mb: 1,
            bgcolor: (theme) => theme.palette.background.layout,
            maxWidth: "250px",
        }, label: label, avatar: image ? (_jsx(Avatar, { sx: { bgcolor: (theme) => theme.palette.text.secondary }, alt: (image === null || image === void 0 ? void 0 : image.caption) && Array.isArray(image.caption)
                ? ((_a = image.caption[0]) === null || _a === void 0 ? void 0 : _a.text) || image.caption[0]
                : (image === null || image === void 0 ? void 0 : image.caption) || "", src: (image === null || image === void 0 ? void 0 : image.urlThumbnail) || (image === null || image === void 0 ? void 0 : image.url) })) : undefined, variant: "outlined", deleteIcon: mentionCount ? (_jsx(TotalCountBadge, { name: name, count: mentionCount, onlyOtherMentionCount: onlyOtherMentionCount })) : undefined, onDelete: mentionCount ? () => { } : undefined }));
};
