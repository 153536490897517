import { ItemModel, TotalCountBadge } from "@recall/common"
import { FC, useEffect, useState } from "react"

interface Props {
    item: ItemModel
}

export const MentionCountBadge: FC<Props> = ({ item }) => {
    const [mentions, setMentions] = useState<number>()

    useEffect(() => {
        getCount()
    }, [])

    const getCount = async () => {
        const mentionsCount = await item.mentions.count
        setMentions(mentionsCount)
    }

    if (!mentions) return null

    return <TotalCountBadge name={item.name} count={mentions} />
}
