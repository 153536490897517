import { useRef } from "react"

export const useLongPressSelection = (select: () => void) => {
    const pressTimer = useRef(null)
    const longPressDelay = 500

    const handleTouchStart = (e) => {
        e.preventDefault()
        pressTimer.current = setTimeout(() => {
            select()
        }, longPressDelay)
    }

    const handleTouchEnd = () => {
        if (pressTimer.current) {
            clearTimeout(pressTimer.current)
        }
    }

    return { handleTouchStart, handleTouchEnd }
}
