import {
    alpha,
    Box,
    Fade,
    SxProps,
    Theme,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material"
import { assets, getTheme } from "@recall/common"
import { DarkLayout } from "components/layouts/DarkLayout"
import { PageMetadata } from "components/shared/PageMetadata"
import { useIsNative } from "hooks/useIsNative"
import { FC, useState } from "react"
import { useSelector } from "react-redux"
import { BillingPeriod } from "services/paymentService"
import { RootState } from "storage/redux/rootReducer"
import rectangleSVG from "../../assets/svg/rectangle.svg"
import { PricingCard } from "./PricingCard"
import { PricingFAQ } from "./PricingFAQ"
import { PricingNative } from "./PricingNative"

export type BillingPeriodWithoutLifetime = Exclude<BillingPeriod, "lifetime">

export const PricingPage: FC = () => {
    const [billingPeriod, setBillingPeriod] = useState<BillingPeriodWithoutLifetime>("yearly")

    const isPremiumUser = useSelector((state: RootState) => state.user.isPremiumUser)
    const isNative = useIsNative()

    const userId = useSelector((state: RootState) => state.user.uid)
    const isMonthly = billingPeriod === "monthly"

    const handleSelectBillingPeriod = (
        _: React.MouseEvent<HTMLElement>,
        billingPeriod: BillingPeriodWithoutLifetime
    ) => {
        if (billingPeriod) setBillingPeriod(billingPeriod)
    }

    if (isNative) return <PricingNative />

    return (
        <DarkLayout showCloseButton>
            <PageMetadata
                title="Recall Pricing"
                description="On this page you will find all the pricing plans for Recall including information about the free plan. This page also includes information about the Recall lifetime license and some FAQs regarding our pricing."
            />
            <Box sx={styles.container}>
                <Typography variant="h2" component={"h1"} sx={styles.title}>
                    Pricing
                </Typography>
                <ToggleButtonGroup
                    value={billingPeriod}
                    exclusive
                    onChange={handleSelectBillingPeriod}
                >
                    <ToggleButton sx={styles.toggleButton} value="yearly">
                        Yearly{" "}
                        <Box sx={styles.toggleBadge} component="span">
                            {" "}
                            Save 35%
                        </Box>
                    </ToggleButton>
                    <ToggleButton sx={styles.toggleButton} value="monthly">
                        Monthly
                    </ToggleButton>
                </ToggleButtonGroup>
                <Fade timeout={1000} in key={isMonthly.toString()}>
                    <Box sx={styles.cardsContainer}>
                        <Box sx={styles.cards}>
                            <PricingCard
                                billingPeriod={billingPeriod}
                                features={[
                                    "Unlimited in-app knowledge cards",
                                    "10 AI generated summaries",
                                ]}
                                image={assets.RECALL_LITE_LOGO}
                                priceComponent={
                                    <Box component="span" sx={styles.price}>
                                        Free forever
                                    </Box>
                                }
                                showAction={!userId}
                                isFree
                            />
                            <PricingCard
                                features={[
                                    "Unlimited in-app knowledge cards",
                                    "Unlimited AI generated summaries",
                                    "Priority support",
                                ]}
                                image={assets.RECALL_PLUS_LOGO}
                                billingPeriod={billingPeriod}
                                highlighted
                                priceComponent={
                                    <Box>
                                        <Box component="span" sx={styles.price}>
                                            {`$ ${isMonthly ? 10 : 7}`}
                                        </Box>
                                        <Box component="span" sx={styles.billingPeriod}>
                                            {` / per month  ${!isMonthly ? "billed yearly" : ""}`}
                                        </Box>
                                    </Box>
                                }
                                showAction={!isPremiumUser}
                            />
                            <PricingCard
                                image={assets.RECALL_BUSINESS_LOGO}
                                features={["Custom features for business use cases", "Custom SLA"]}
                                billingPeriod={billingPeriod}
                                priceComponent={
                                    <Box
                                        component="span"
                                        sx={{
                                            ...styles.price,
                                            fontSize: 32,
                                            lineHeight: "60px",
                                        }}
                                    >
                                        Custom pricing
                                    </Box>
                                }
                                showAction
                                isBusiness
                            />
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                        >
                            <PricingFAQ />
                        </Box>
                    </Box>
                </Fade>
            </Box>
        </DarkLayout>
    )
}

const lightTheme = getTheme("light")

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 4,
        pt: 8,
        px: { xs: 0, md: 4 },
    },
    cardsContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
        borderRadius: 2,
    },
    titleContainer: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        justifyContent: "space-between",
        width: "100%",
        gap: 2,
        mb: 4,
    },
    title: {
        color: alpha(lightTheme.palette.primary.contrastText, 0.9),
        fontWeight: 600,
        textAlign: "center",
    },
    cards: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        alignItems: "stretch",
        borderRadius: 2,
        color: lightTheme.palette.text.primary,
        backgroundBlendMode: "overlay, normal",
        backdropFilter: "blur(2px)",
        background: {
            sm: `url(${rectangleSVG}) no-repeat right, radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(165, 239, 255, 0.2) 0%, rgba(110, 191, 244, 0.0447917) 77.08%, rgba(70, 144, 213, 0) 100%)`,
        },
        backgroundSize: { xs: "cover", lg: "auto 100%" },
        p: { xs: 0, md: 2 },
    },
    select: {
        minWidth: 160,
    },
    toggleButton: {
        minWidth: 160,
    },
    toggleBadge: {
        backgroundColor: lightTheme.palette.secondary.main,
        color: lightTheme.palette.secondary.contrastText,
        borderRadius: 2,
        fontSize: 12,
        fontWeight: 600,
        px: 1,
        ml: 2,
    },
    price: {
        fontSize: 36,
        fontWeight: 700,
        color: (theme) => theme.palette.primary.dark,
    },
    billingPeriod: {
        color: (theme) => alpha(theme.palette.primary.dark, 0.8),
    },
}
