import { ItemModel, TagsWithAncestors, useTagAncestors } from "@recall/common"
import { useState } from "react"

export const useItemTags = (item: ItemModel) => {
    const [itemTags, setItemTags] = useState<TagsWithAncestors[]>([])
    const { getTagAncestors, getTagNesting } = useTagAncestors()

    const getItemTags = async () => {
        const itemTags = await item.getTags()
        const tagsWithAncestors: TagsWithAncestors[] = []
        for (const tag of itemTags) {
            const ancestors = await getTagAncestors(tag)
            tagsWithAncestors.push({
                tag: {
                    id: tag.id,
                    name: tag.name,
                    parentId: tag.parent.id,
                    updatedAt: tag.updatedAt,
                },
                ancestors: ancestors.map((tag) => ({
                    id: tag.id,
                    name: tag.name,
                    parentId: tag.parent.id,
                    updatedAt: tag.updatedAt,
                })),
                nesting: getTagNesting(tag, ancestors),
            })
        }

        setItemTags(tagsWithAncestors)
    }

    return { itemTags, getItemTags }
}
