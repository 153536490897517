import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { EmojiSettings } from "@udecode/plate-emoji";
import { EmojiPcikerNavigation } from "./EmojiPcikerNavigation";
import { EmojiPickerContent } from "./EmojiPickerContent";
import { EmojiPickerPreview } from "./EmojiPickerPreview";
import { EmojiPickerSearch } from "./EmojiPickerSearch";
export const EmojiPicker = ({ i18n, searchValue, setSearch, clearSearch, isSearching, hasFound, searchResult, emoji, onSelectEmoji, onMouseOver, emojiLibrary, icons, handleCategoryClick, focusedCategory, visibleCategories, refs, settings = EmojiSettings, }) => {
    return (_jsxs(Box, Object.assign({ sx: {
            height: 350,
            width: 316,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
        } }, { children: [_jsx(EmojiPcikerNavigation, { i18n: i18n, emojiLibrary: emojiLibrary, icons: icons, focusedCategory: focusedCategory, onClick: handleCategoryClick }), _jsx(EmojiPickerSearch, { i18n: i18n, clearSearch: clearSearch, searchValue: searchValue, setSearch: setSearch }), _jsx(EmojiPickerContent, { i18n: i18n, emojiLibrary: emojiLibrary, isSearching: isSearching, searchResult: searchResult, visibleCategories: visibleCategories, settings: settings, onSelectEmoji: onSelectEmoji, onMouseOver: onMouseOver, refs: refs }), _jsx(EmojiPickerPreview, { i18n: i18n, emoji: emoji, hasFound: hasFound, isSearching: isSearching })] })));
};
