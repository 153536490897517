import { useDispatch, useSelector } from "react-redux"
import { userRepository } from "repositories/userRepository"
import { RootState } from "storage/redux/rootReducer"
import { SET_DB_VERSION } from "storage/redux/user/actionTypes"
import { v4 as uuid } from "uuid"

export const useDbVersion = () => {
    const dispatch = useDispatch()
    const uid = useSelector((state: RootState) => state.user.uid)
    const dbVersion = useSelector((state: RootState) => state.user.dbVersion)

    const updateDbVersion = async () => {
        const dbVersion = uuid()
        dispatch({ type: SET_DB_VERSION, payload: dbVersion })
        await userRepository.upsertUser(uid, { dbVersion })
    }

    const updateLocalDbVersion = async () => {
        const user = await userRepository.getUser(uid)

        if (!user) return

        if (user.dbVersion) {
            dispatch({ type: SET_DB_VERSION, payload: user?.dbVersion })
        } else {
            updateDbVersion()
        }
    }

    const isDbVersionSame = (userDbVersion: string) => {
        return uid && dbVersion && userDbVersion === dbVersion
    }

    return { updateDbVersion, updateLocalDbVersion, isDbVersionSame }
}
