import { keyBy } from "lodash";
export const getOrderedModels = (editorBlocks, editorOrder) => {
    if (!editorOrder)
        return [];
    const editorBlockById = keyBy(editorBlocks, "id");
    let value = [];
    for (const editorBlockId of editorOrder.order)
        if (editorBlockById[editorBlockId])
            value.push(editorBlockById[editorBlockId]);
    return value;
};
