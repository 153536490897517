"use client";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, MenuItem, Popper } from "@mui/material";
import { comboboxActions, useActiveComboboxStore, useComboboxContent, useComboboxContentState, useComboboxControls, useComboboxItem, useComboboxSelectors, } from "@udecode/plate-combobox";
import { useEventEditorSelectors, usePlateEditorState } from "@udecode/plate-common";
import { getBoundingClientRect } from "@udecode/plate-floating";
import { useEffect } from "react";
import { useFocused } from "slate-react";
export function ComboboxItem({ combobox, index, item, onRenderItem, }) {
    const { props } = useComboboxItem({ item, index, combobox, onRenderItem });
    return _jsx(MenuItem, Object.assign({ selected: props["data-highlighted"] }, props));
}
export function ComboboxContent(props) {
    const { component: Component, items, combobox, onRenderItem } = props;
    const editor = usePlateEditorState();
    const filteredItems = useComboboxSelectors.filteredItems();
    const activeComboboxStore = useActiveComboboxStore();
    const state = useComboboxContentState({ items, combobox });
    const { menuProps, targetRange } = useComboboxContent(state);
    const rect = getBoundingClientRect(editor, targetRange !== null && targetRange !== void 0 ? targetRange : undefined);
    if (!rect)
        return null;
    return (_jsx(Popper, Object.assign({ open: true, anchorEl: {
            getBoundingClientRect: () => {
                return rect;
            },
            nodeType: 1,
        }, sx: { zIndex: 1201 } }, { children: _jsxs(Box, Object.assign({}, menuProps, { sx: {
                background: (theme) => theme.palette.background.layout,
                borderRadius: 1,
                border: (theme) => `1px solid ${theme.palette.background.paper}`,
                maxHeight: 288,
                minWidth: 300,
                overflowY: "auto",
            } }, { children: [Component ? Component({ store: activeComboboxStore }) : null, filteredItems.map((item, index) => (_jsx(ComboboxItem, { item: item, combobox: combobox, index: index, onRenderItem: onRenderItem }, item.key)))] })) })));
}
export function Combobox(_a) {
    var _b, _c;
    var { id, trigger, searchPattern, onSelectItem, controlled, maxSuggestions, filter, sort, disabled: _disabled } = _a, props = __rest(_a, ["id", "trigger", "searchPattern", "onSelectItem", "controlled", "maxSuggestions", "filter", "sort", "disabled"]);
    const storeItems = useComboboxSelectors.items();
    const disabled = _disabled !== null && _disabled !== void 0 ? _disabled : (storeItems.length === 0 && !((_b = props.items) === null || _b === void 0 ? void 0 : _b.length));
    const focusedEditorId = (_c = useEventEditorSelectors.focus) === null || _c === void 0 ? void 0 : _c.call(useEventEditorSelectors);
    const combobox = useComboboxControls();
    const activeId = useComboboxSelectors.activeId();
    const focused = useFocused();
    const editor = usePlateEditorState();
    useEffect(() => {
        comboboxActions.setComboboxById({
            id,
            trigger,
            searchPattern,
            controlled,
            onSelectItem,
            maxSuggestions,
            filter,
            sort,
        });
    }, [
        id,
        trigger,
        searchPattern,
        controlled,
        onSelectItem,
        maxSuggestions,
        filter,
        sort,
        editor.selection,
    ]);
    useEffect(() => {
        if (!focused) {
            comboboxActions.reset();
        }
    }, [focused]);
    if (!combobox ||
        !editor.selection ||
        focusedEditorId !== editor.id ||
        activeId !== id ||
        disabled) {
        return null;
    }
    return _jsx(ComboboxContent, Object.assign({ combobox: combobox }, props));
}
