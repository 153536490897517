import { CloudUpload } from "@mui/icons-material"
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    FormHelperText,
    Paper,
    SxProps,
    Theme,
    Typography,
} from "@mui/material"
import { posthogService, SummaryLengthToggle } from "@recall/common"
import { IN_APP_PDF_TOO_BIG } from "constants/events"
import { SHARE_TARGET_PATH } from "constants/routes"
import { useSummaryLength } from "hooks/useSummaryLength"
import { FC, useRef, useState } from "react"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
interface Props {
    closeModal: () => void
}

export const PdfUploadModal: FC<Props> = ({ closeModal }) => {
    const [file, setFile] = useState<File | null>(null)
    const [dragActive, setDragActive] = useState(false)
    const [message, setMessage] = useState("")
    const inputRef = useRef(null)
    const history = useHistory()

    const { summaryLength, updateSummaryLength } = useSummaryLength()

    const handleFile = (file) => {
        const fileSizeInMB = file.size / (1024 * 1024)

        if (fileSizeInMB > 32) {
            toast.error(`Max file size is 32MB`)
            posthogService.captureEvent(IN_APP_PDF_TOO_BIG)
            return
        }

        if (file && file.type === "application/pdf") {
            setFile(file)
            setMessage("")
        } else {
            setMessage("Please upload pdf file")
        }
    }

    const handleChange = (e) => {
        e.preventDefault()
        if (e.target.files && e.target.files[0]) {
            handleFile(e.target.files[0])
        }
    }

    const handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true)
        } else if (e.type === "dragleave") {
            setDragActive(false)
        }
    }

    const handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragActive(false)
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFile(e.dataTransfer.files[0])
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (file) {
            history.push({
                pathname: SHARE_TARGET_PATH,
                state: { pdfFile: file },
            })
        }
    }

    return (
        <Dialog onClose={closeModal} open PaperProps={{ sx: { maxWidth: "350px" } }}>
            <Box sx={styles.title}>
                <Typography variant="h6">Summarize PDF</Typography>
                <SummaryLengthToggle value={summaryLength} onChange={updateSummaryLength} />
            </Box>
            <DialogContent>
                <Paper
                    sx={{
                        ...styles.root,
                        borderColor: (theme) => dragActive && theme.palette.action.disabled,
                        backgroundColor: (theme) => dragActive && theme.palette.action.hover,
                    }}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                >
                    <Box
                        component="input"
                        ref={inputRef}
                        sx={styles.input}
                        type="file"
                        accept=".pdf"
                        onChange={handleChange}
                        id="contained-button-file"
                    />
                    <Box component="label" htmlFor="contained-button-file">
                        <Typography variant="body1" gutterBottom>
                            Drag and drop your PDF here or
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            startIcon={<CloudUpload />}
                            sx={styles.button}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()

                                inputRef.current.click()
                            }}
                        >
                            Click to upload
                        </Button>
                    </Box>
                    {file && (
                        <Box sx={styles.fileInfo}>
                            <Typography variant="body2" gutterBottom>
                                File selected: <strong>{file.name}</strong>
                            </Typography>
                        </Box>
                    )}
                </Paper>
                {message && <FormHelperText error>{message}</FormHelperText>}
                {file && (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleSubmit}
                        sx={styles.button}
                        fullWidth
                    >
                        Summarize
                    </Button>
                )}
            </DialogContent>
        </Dialog>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
        textAlign: "center",
        border: (theme) => `2px dashed ${theme.palette.action.hover}`,
        minHeight: 200,
    },
    title: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 2,
        pb: 0,
        gap: 2,
        flexDirection: "column",
    },
    input: {
        display: "none",
    },
    button: {
        marginTop: (theme) => theme.spacing(2),
    },
    fileInfo: {
        marginTop: (theme) => theme.spacing(2),
    },
}
