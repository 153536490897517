import { Button, ButtonProps, CircularProgress, alpha } from "@mui/material"
import { FC, PropsWithChildren } from "react"

interface BoldButtonProps extends ButtonProps {
    loading?: boolean
    size?: "small"
}

export const BoldButton: FC<PropsWithChildren<BoldButtonProps>> = ({
    sx = {},
    size,
    loading,
    startIcon,
    children,
    ...props
}) => {
    const isSmall = size === "small"

    return (
        <Button
            translate="no"
            variant="contained"
            sx={{
                ...sx,
                fontSize: !isSmall ? "1rem" : undefined,
                px: 4,
                minHeight: !isSmall ? "40px" : undefined,
                color: (theme) => theme.palette.text.secondary,
                backgroundColor: (theme) =>
                    !isSmall ? alpha(theme.palette.text.primary, 0.15) : undefined,
                "&:hover": {
                    backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.1),
                },
            }}
            {...props}
            disabled={loading}
            startIcon={!loading && startIcon}
        >
            {loading ? <CircularProgress size={27} color="inherit" /> : children}
        </Button>
    )
}
