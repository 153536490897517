const setItem = (name: string, value: any) => {
    localStorage.setItem(name, JSON.stringify(value))
}

const getItem = (name: string) => {
    const value = localStorage.getItem(name)
    return JSON.parse(value)
}

const removeItem = (name: string) => {
    localStorage.removeItem(name)
}

const clear = () => {
    localStorage.clear()
}

export const storageService = { setItem, getItem, removeItem, clear }
