import { addMonths } from "date-fns";
import { posthogService } from "../postHog";
import { UsageService } from "./usageService";
const MONTHLY_COST_USAGE = "Monthly cost usage";
export class CostUsageService extends UsageService {
    constructor() {
        super(...arguments);
        this.limit = 5;
        this.increaseUsage = async (userId, cost) => {
            var _a;
            const user = await this.userRepository.getUser(userId);
            const costUsage = cost || 0;
            const shouldResetCostUsage = !user || !((_a = user === null || user === void 0 ? void 0 : user.usage) === null || _a === void 0 ? void 0 : _a.cost) || user.usage.cost.resetDate <= Date.now();
            if (shouldResetCostUsage) {
                await this.resetCostUsage(userId, costUsage);
                return costUsage;
            }
            const newCost = user.usage.cost.value + costUsage;
            await this.userRepository.upsertUser(userId, {
                usage: this.getUserUsage(user, { cost: Object.assign(Object.assign({}, user.usage.cost), { value: newCost }) }),
            });
            return newCost;
        };
        this.resetCostUsage = async (userId, cost) => {
            const user = await this.userRepository.getUser(userId);
            const resetDate = addMonths(Date.now(), 1).getTime();
            await this.userRepository.upsertUser(userId, {
                usage: this.getUserUsage(user, { cost: { value: cost, resetDate } }),
            });
        };
        this.isUsageExceeded = async (userId) => {
            var _a, _b;
            const user = await this.userRepository.getUser(userId);
            const shouldResetCostUsage = !user || !((_a = user === null || user === void 0 ? void 0 : user.usage) === null || _a === void 0 ? void 0 : _a.cost) || user.usage.cost.resetDate <= Date.now();
            if (shouldResetCostUsage) {
                const currentUsage = (_b = user === null || user === void 0 ? void 0 : user.usage) === null || _b === void 0 ? void 0 : _b.cost;
                if (currentUsage)
                    posthogService.captureEvent(MONTHLY_COST_USAGE, { usage: currentUsage });
                await this.resetCostUsage(userId, 0);
                return false;
            }
            return user.usage.cost.value > this.limit;
        };
        this.getUsage = async (userId) => {
            var _a, _b;
            const user = await this.userRepository.getUser(userId);
            const usage = ((_b = (_a = user === null || user === void 0 ? void 0 : user.usage) === null || _a === void 0 ? void 0 : _a.cost) === null || _b === void 0 ? void 0 : _b.value) || 0;
            return usage;
        };
    }
}
