import { Grid, Paper, SxProps, Theme, Typography } from "@mui/material"

export const ItemsListEmpty = () => {
    return (
        <Grid mt={4} container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12} md={8} lg={6} xl={4}>
                <Paper elevation={0} sx={styles.paper}>
                    <Typography variant="h6" textAlign="center">
                        You have no cards that match your filters
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    paper: {
        border: "1px solid",
        borderColor: (theme) => theme.palette.action.active,
        mx: 2,
        px: 2,
        py: 1,
    },
}
