import {
    Bookmark,
    BookmarksResponse,
    ExtensionMessage,
    GenericExtensionResponse,
    GET_BOOKMARKS_TREE,
    sendMessageToExtension,
} from "@recall/common"

const sendMessage = async (message: ExtensionMessage): Promise<boolean> => {
    try {
        const response = await sendMessageToExtension<GenericExtensionResponse>(message)
        if (!response.success) throw new Error()
        return true
    } catch (error) {
        return false
    }
}

const extractBookmarkIds = (bookmarks: Bookmark[]): string[] => {
    const ids = bookmarks.map(({ id, children }) => {
        if (!children) return [id]

        return [id, ...extractBookmarkIds(children)]
    })

    return ids.flat()
}

const getChildrenBookmarks = (bookmarks: Bookmark[], parents: Bookmark[] = []): Bookmark[] => {
    const childrenBookmarks = bookmarks.map((bookmark) => {
        if (!bookmark.children) return [{ ...bookmark, parents }]

        return [bookmark, ...getChildrenBookmarks(bookmark.children, [...parents, bookmark])]
    })

    return childrenBookmarks.flat()
}

const getPageBookmarks = async () => {
    const { bookmarks } = await sendMessageToExtension<BookmarksResponse>({
        type: GET_BOOKMARKS_TREE,
    })

    return { bookmarks, bookmarksIds: extractBookmarkIds(bookmarks) }
}

const getParentBookmarks = (bookmarks: Bookmark[], id: string) => {
    const bookmarksWithParent = getChildrenBookmarks(bookmarks)

    const bookmark = bookmarksWithParent.find((bookmark) => bookmark.id === id)

    if (!bookmark?.parents) return []

    return bookmark.parents.filter(({ name }) => !!name)
}

export const extensionService = {
    sendMessage,
    getPageBookmarks,
    extractBookmarkIds,
    getChildrenBookmarks,
    getParentBookmarks,
}
