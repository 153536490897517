import { applyMiddleware, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import { persistStore } from "redux-persist"
import thunkMiddleware from "redux-thunk"
import rootReducer from "./rootReducer"

const enhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))
let store = createStore(rootReducer, enhancer)
export let persistor = persistStore(store)

export default store
