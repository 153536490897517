import { EditorBlockData } from "../services/editorData/EditorBlockData";
export const extractSearchedText = (editorBlocks, searchText) => {
    for (const editorBlock of editorBlocks) {
        const text = extractSearchTextFromEditorBlocks(editorBlock, searchText);
        if (text)
            return text;
    }
    return null;
};
export const extractSearchTextFromEditorBlocks = (editorBlock, searchText) => {
    const text = EditorBlockData.getFormattedText([editorBlock]);
    return extractSearchText(text, searchText);
};
export const extractSearchText = (text, searchText) => {
    const keywordIndex = text.toLowerCase().indexOf(searchText.toLowerCase());
    if (keywordIndex === -1)
        return null;
    const textBeforeKeyword = text.substring(0, keywordIndex);
    const textAfterKeyword = text.substring(keywordIndex + searchText.length);
    const beforeTextWords = textBeforeKeyword.split(/\s+/);
    const afterTextWords = textAfterKeyword.split(/\s+/);
    const beforeWords = beforeTextWords.slice(-6);
    const afterWords = afterTextWords.slice(0, 6);
    const prefix = beforeTextWords.length > 6 && !textBeforeKeyword.trim().endsWith(".") ? "..." : "";
    const suffix = afterTextWords.length > 6 ? "..." : "";
    return {
        beforeSearch: prefix + beforeWords.join(" "),
        searchedText: text.substring(keywordIndex, keywordIndex + searchText.length),
        afterSearch: afterWords.join(" ") + suffix,
    };
};
