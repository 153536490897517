var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Model } from "@nozbe/watermelondb";
import { date, field, json, readonly, relation, writer } from "@nozbe/watermelondb/decorators";
import { ITEMS, QUESTIONS } from "../schema";
const sanitizeOptions = (data) => data;
export class QuestionModel extends Model {
    constructor() {
        super(...arguments);
        this.prepareSave = () => {
            if (this.isSaved === false) {
                return this.prepareUpdate((record) => {
                    record.isSaved = true;
                });
            }
            return null;
        };
        this.prepareDelete = () => {
            if (this._preparedState === null) {
                if (this.isSaved) {
                    return this.prepareMarkAsDeleted();
                }
                else {
                    return this.prepareDestroyPermanently();
                }
            }
        };
        this.isCorrectAnswer = () => {
            return this.answer === this.correctAnswer;
        };
        this.isCorrectOption = (option) => {
            return option === this.correctAnswer;
        };
        this.isQuestionReviewed = () => {
            return this.nextReviewTime && this.nextReviewTime > new Date();
        };
    }
    async delete() {
        const task = this.prepareDelete();
        await this.batch(task);
    }
}
QuestionModel.table = QUESTIONS;
QuestionModel.associations = {
    items: { type: "belongs_to", key: "item_id" },
};
__decorate([
    field("is_saved")
], QuestionModel.prototype, "isSaved", void 0);
__decorate([
    field("question")
], QuestionModel.prototype, "question", void 0);
__decorate([
    field("answer")
], QuestionModel.prototype, "answer", void 0);
__decorate([
    json("options", sanitizeOptions)
], QuestionModel.prototype, "options", void 0);
__decorate([
    field("correct_answer")
], QuestionModel.prototype, "correctAnswer", void 0);
__decorate([
    date("next_review_time")
], QuestionModel.prototype, "nextReviewTime", void 0);
__decorate([
    relation(ITEMS, "item_id")
], QuestionModel.prototype, "item", void 0);
__decorate([
    readonly,
    date("created_at")
], QuestionModel.prototype, "createdAt", void 0);
__decorate([
    readonly,
    date("updated_at")
], QuestionModel.prototype, "updatedAt", void 0);
__decorate([
    writer
], QuestionModel.prototype, "delete", null);
