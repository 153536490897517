import { Popover, PopoverProps, SxProps, Theme } from "@mui/material"
import { getTheme } from "@recall/common"
import React from "react"

// TODO - this might work better using popper instead of popover
const MyPopover: React.FC<React.PropsWithChildren<PopoverProps>> = (props) => {
    return <Popover slotProps={{ paper: { sx: styles.paper } }} {...props} />
}

export default MyPopover

const theme = getTheme("dark")

const styles: Record<string, SxProps<Theme>> = {
    paper: {
        backgroundColor: "background.default",
        border: (theme) => theme.borders.paper,
        [theme.breakpoints.up("sm")]: {
            minWidth: "300px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}
