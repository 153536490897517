import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { Box, Tooltip } from "@mui/material";
import { ActionButton } from "../buttons/ActionButton";
import { ItemPreview } from "./ItemPreview/ItemPreview";
export const ItemPreviewPopper = ({ item, open, actionButtonsEnd, actionButtonsStart, children, readOnly, openItemById, deleteConnection, }) => {
    const handleOpenItem = () => {
        if (item)
            openItemById(item.id);
    };
    const handleDelete = () => {
        if (item) {
            deleteConnection(item.id);
        }
    };
    return (_jsx(Tooltip, Object.assign({ open: open, PopperProps: {
            sx: styles.popper,
            modifiers: [
                {
                    name: "offset",
                    options: {
                        offset: [0, -2],
                    },
                },
            ],
        }, title: open && (_jsxs(Box, Object.assign({ sx: styles.title }, { children: [!readOnly && (_jsxs(Box, Object.assign({ sx: styles.header }, { children: [_jsxs(Box, { children: [_jsx(ActionButton, Object.assign({ onClick: handleDelete, tooltip: "Delete link to this card" }, { children: _jsx(DeleteIcon, { fontSize: "inherit" }) })), actionButtonsStart && actionButtonsStart] }), _jsxs(Box, { children: [_jsx(ActionButton, Object.assign({ onClick: handleOpenItem, tooltip: "Open Card" }, { children: _jsx(OpenInFullIcon, { fontSize: "inherit" }) })), actionButtonsEnd && actionButtonsEnd] })] }))), item && (_jsx(ItemPreview, { openItemById: (id, state) => openItemById(id, { state }), item: item, onClick: readOnly ? undefined : handleOpenItem }))] }))), arrow: true }, { children: _jsx("span", { children: children }) })));
};
const styles = {
    popper: {
        "& .MuiTooltip-arrow": {
            color: (theme) => theme.palette.background.layout,
        },
        "& .MuiTooltip-arrow::before": {
            border: (theme) => theme.borders.paper,
        },
        "& .MuiTooltip-tooltip": {
            minWidth: 250,
            maxWidth: 350,
            padding: 0,
            borderRadius: 1,
            border: (theme) => theme.borders.paper,
            backgroundColor: (theme) => theme.palette.background.layout,
        },
    },
    title: {
        boxShadow: 3,
        maxWidth: 350,
        minWidth: 200,
        overflow: "hidden",
        borderRadius: 1,
        borderTopLeftRadius: 0,
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
    },
};
