export const HOME_PATH = "/items"
export const ITEM_PATH = "/item/:id"
/**
 * @deprecated
 */
export const DEPRECATED_ITEM_PATH = "/item"
export const LOGIN_PATH = "/login/"
export const VERIFY_EMAIL_PATH = "/verify-email/"
export const SHARE_TARGET_PATH = "/share-target/"
export const SIGNUP_PATH = "/signup/"
export const RESET_PATH = "/reset/"
export const LOGGING_IN_PATH = "/logging-in/"
export const PRICING_PATH = "/pricing/"
export const INSTALL_EXTENSION_PATH = "/install-extension/"
export const SAVE_LIB_SUMMARY_PATH = "/library/save-summary"
export const KNOWLEDGE_GRAPH_PATH = "/knowledge-graph"
export const SETTINGS_PATH = "/settings"
export const SHARE_ROUTE = "/share/:id"
export const SPACED_REPETITION = "/spaced-repetition"
export const REVIEW_UNSUBSCRIBE = "/review/unsubscribe"
