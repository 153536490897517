import { BASE_API_URL, HttpClient, firebase } from "@recall/common"

class AuthenticatedHttpClient extends HttpClient {
    protected async initHeaders(headers: HeadersInit = {}): Promise<HeadersInit> {
        const authToken = await firebase.getAuthToken()
        return { ...this.defaultHeaders, ...headers, Authorization: `Bearer ${authToken}` }
    }
}

export const httpClient = new AuthenticatedHttpClient(BASE_API_URL)
