import { Q } from "@nozbe/watermelondb";
import { v4 as uuidv4 } from "uuid";
import { CONNECTION_PROPERTIES } from "../schema";
const getOrCreate = async (db, property) => {
    const collection = db.collections.get(CONNECTION_PROPERTIES);
    const connectionPropertyModels = await collection.query(Q.where("name", property.name)).fetch();
    if (connectionPropertyModels.length)
        return connectionPropertyModels[0];
    return await collection.create((record) => {
        record._raw.id = uuidv4();
        record.name = property.name;
        record.display = property.display;
        record.description = property.description || record.description;
        record.wikidataPid = property.wikidataPid || record.wikidataPid;
        record.isSaved = true;
    });
};
const getByIds = (db, propertyIds) => {
    return db.collections
        .get(CONNECTION_PROPERTIES)
        .query(Q.where("id", Q.oneOf(propertyIds)));
};
export const connectionPropertyRepository = { getOrCreate, getByIds };
