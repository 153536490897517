import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    SxProps,
    Theme,
} from "@mui/material"
import subscribeGIF from "assets/images/subscribe.gif"
import {
    DONT_LIKE_SUBSCRIBE_FEATURE_EVENT,
    LIKE_SUBSCRIBE_FEATURE_EVENT,
    NEED_SUBSCRIBE_FEATURE_EVENT,
} from "constants/events"
import React from "react"
import { captureEventService } from "services/captureEventService"

interface Props {
    name: string
    isModalOpen: boolean
    setIsModelOpen: Function
}

export const SubscribeModal: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
    const handleClose = () => {
        props.setIsModelOpen(false)
    }

    const handleLoveIt = () => {
        captureEventService.capture(NEED_SUBSCRIBE_FEATURE_EVENT)
        handleClose()
    }
    const handleLikeIt = () => {
        captureEventService.capture(LIKE_SUBSCRIBE_FEATURE_EVENT)
        handleClose()
    }

    const handleDontLikeIt = () => {
        captureEventService.capture(DONT_LIKE_SUBSCRIBE_FEATURE_EVENT)
        handleClose()
    }

    return (
        <Dialog onClose={handleClose} open={props.isModalOpen}>
            <DialogTitle>
                We are still working on this feature and would love to get your feedback
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    The feature will allow you to subscribe to news on specific cards helping you
                    stay informed on the things you care about.
                </DialogContentText>
                <Box
                    component="img"
                    sx={{
                        border: (theme) => `4px solid ${theme.palette.secondary.main}`,
                        borderRadius: "10px",
                        margin: "20px 0px",
                    }}
                    alt="Subscribe GIF"
                    src={subscribeGIF}
                    width="100%"
                />

                <DialogContentText>
                    Please use the buttons below to express your opinion on this feature.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Box
                    display="flex"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="space-between"
                >
                    <Button fullWidth variant="contained" color="success" onClick={handleLoveIt}>
                        I need this
                    </Button>
                    <Button
                        fullWidth
                        sx={styles.button}
                        color="secondary"
                        variant="contained"
                        onClick={handleLikeIt}
                        autoFocus
                    >
                        Seems useful
                    </Button>
                    <Button
                        fullWidth
                        sx={styles.button}
                        variant="contained"
                        color="error"
                        onClick={handleDontLikeIt}
                    >
                        Not useful
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    button: {
        ml: 1,
    },
}
