import { Android, Apple } from "@mui/icons-material"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { Box, Button, Typography } from "@mui/material"
import { assets } from "@recall/common"
import { useIsNative } from "hooks/useIsNative"
import { FC, useMemo } from "react"

interface Props {
    handleContinue: () => void
}

export const MobileStep: FC<Props> = ({ handleContinue }) => {
    const isNative = useIsNative()

    const platform = useMemo(() => {
        const userAgent = navigator.userAgent

        if (/iPad|iPhone|iPod/.test(userAgent)) {
            return "ios"
        } else if (/android/i.test(userAgent)) {
            return "android"
        }
        return "other"
    }, [])

    const handleOpenStore = () => {
        if (platform === "ios")
            window.open("https://apps.apple.com/app/recall-summarize-save/id6445893722", "_blank")
        else window.open("https://play.google.com/store/apps/details?id=com.recall.wiki", "_blank")
    }

    if (isNative) {
        return (
            <>
                <Typography variant="h4" fontWeight={600} textAlign="center">
                    Welcome to Recall
                </Typography>
                <Typography textAlign="center">
                    The video below shows how to create summaries with our mobile app.
                </Typography>
                <Typography variant="caption" textAlign="center">
                    <b>Please note:</b> Our mobile app is still in beta. For the best experience, we
                    recommend logging in on your desktop via our website.
                </Typography>
                <Box
                    sx={{ borderRadius: 1, boxShadow: 1, width: "100%" }}
                    component="video"
                    controls
                    autoPlay
                    loop
                    muted
                >
                    <source src={assets.CREATE_SUMMARY_MOBILE.url} type="video/mp4" />
                </Box>
                <Button
                    onClick={handleContinue}
                    variant="contained"
                    color="secondary"
                    fullWidth
                    endIcon={<NavigateNextIcon />}
                >
                    Continue
                </Button>
            </>
        )
    }

    return (
        <>
            <Typography variant="h4" fontWeight={600} textAlign="center">
                We Noticed You're on Mobile
            </Typography>
            <Typography textAlign="center">
                For the best Recall experience, we recommend using Recall on Desktop with our
                browser extension. You can also use our mobile app which is currently in beta.
            </Typography>
            <Typography textAlign="center" flexGrow={1}>
                On mobile, you can still view your saved content and save new content using the
                in-app search. However, the in-app search is limited to Wikipedia pages.
            </Typography>
            {platform !== "other" && (
                <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={handleOpenStore}
                    startIcon={platform === "ios" ? <Apple /> : <Android />}
                >
                    Install Mobile App
                </Button>
            )}
            <Button
                onClick={handleContinue}
                variant={platform !== "other" ? "outlined" : "contained"}
                color="secondary"
                fullWidth
                endIcon={<NavigateNextIcon />}
            >
                Continue on Mobile
            </Button>
        </>
    )
}
