import { ItemModel, QuestionModel } from "@recall/common"
import { Dispatch, FC, SetStateAction } from "react"
import { Questions } from "./Questions"
import { QuestionsWrapper } from "./QuestionsWrapper"

interface Props {
    item: ItemModel
    questions: QuestionModel[]
    setQuestions: Dispatch<SetStateAction<QuestionModel[]>>
    handleClose: () => void
}

export const QuestionsContainer: FC<Props> = ({ handleClose, setQuestions, questions, item }) => {
    return (
        <QuestionsWrapper handleClose={handleClose}>
            <Questions item={item} questions={questions} setQuestions={setQuestions} />
        </QuestionsWrapper>
    )
}
