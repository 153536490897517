import { SyncDatabaseChangeSet } from "@nozbe/watermelondb/sync"
import { Coupon, ItemApi, firebase } from "@recall/common"
import { httpsCallable } from "firebase/functions"

export const createCustomAuthToken = httpsCallable<string, string>(
    firebase.functions,
    "createCustomAuthToken"
)

interface PushDatabaseChanges {
    changes: SyncDatabaseChangeSet
    sessionId: string
    lastPulledAt: number
}

export const pushDatabaseChanges = httpsCallable<PushDatabaseChanges, void>(
    firebase.functions,
    "pushDatabaseChanges"
)

export const claimPromoCode = httpsCallable<null, string>(firebase.functions, "claimPromoCode")
export const getCoupons = httpsCallable<null, Coupon[]>(firebase.functions, "getPromoCodes")
export const applyPromoCode = httpsCallable<{ promoCodeId: string }, boolean>(
    firebase.functions,
    "applyPromoCode"
)

export const createReferralItem = httpsCallable<{ token: string; verified?: boolean }, Coupon[]>(
    firebase.functions,
    "createReferralItem"
)
export const verifyReferralItem = httpsCallable<{ token: string }, Coupon[]>(
    firebase.functions,
    "verifyReferralItem"
)

export const generateCannyJwt = httpsCallable<null, string>(firebase.functions, "generateCannyJwt")
export const getSharedCard = httpsCallable<{ id: string }, ItemApi>(
    firebase.functions,
    "getSharedCard"
)

export const reviewUnsubscribe = httpsCallable<{ token: string }, string>(
    firebase.functions,
    "reviewUnsubscribe"
)

export const isEmailDeletedRecently = httpsCallable<{ email: string }, boolean>(
    firebase.functions,
    "isEmailDeletedRecently"
)

export const verifyEmail = httpsCallable<
    { verificationCode: string },
    { success: boolean; message?: string }
>(firebase.functions, "verifyEmail")

export const sendEmailVerification = httpsCallable<null, void>(
    firebase.functions,
    "sendEmailVerification"
)

export const functions = {
    createCustomAuthToken,
    pushDatabaseChanges,
    claimPromoCode,
    getCoupons,
    applyPromoCode,
    createReferralItem,
    verifyReferralItem,
    generateCannyJwt,
    reviewUnsubscribe,
    isEmailDeletedRecently,
    verifyEmail,
    sendEmailVerification,
}
