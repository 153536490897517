var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { KEY_EMOJI, useEmojiComboboxState, } from "@udecode/plate-emoji";
import { Box } from "@mui/material";
import { Combobox } from "../Combobox";
export function EmojiComboboxItem(_a) {
    var { item } = _a, props = __rest(_a, ["item"]);
    const { data: { id, emoji }, } = item;
    return (_jsxs(Box, Object.assign({ sx: { px: 0.5, display: "flex", alignItems: "center" } }, { children: [emoji, " :", id, ":"] })));
}
export const EmojiCombobox = (_a) => {
    var { pluginKey = KEY_EMOJI, id = pluginKey } = _a, props = __rest(_a, ["pluginKey", "id"]);
    const { trigger, onSelectItem } = useEmojiComboboxState({ pluginKey });
    return (_jsx(Combobox, Object.assign({ id: id, trigger: trigger, controlled: true, onSelectItem: onSelectItem, onRenderItem: EmojiComboboxItem }, props)));
};
