import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Clear, Search } from "@mui/icons-material";
import { Box, InputBase, alpha } from "@mui/material";
export function EmojiPickerSearch({ i18n, searchValue, setSearch, clearSearch, }) {
    return (_jsxs(Box, Object.assign({ sx: styles.wrapper }, { children: [_jsx(Box, Object.assign({ sx: styles.icon }, { children: _jsx(Search, {}) })), _jsx(InputBase, { autoFocus: true, value: searchValue, onChange: (e) => setSearch(e.target.value), sx: styles.input, placeholder: i18n.search, inputProps: { "aria-label": "search" }, endAdornment: searchValue && _jsx(Clear, { onClick: clearSearch, sx: styles.close }) })] })));
}
const styles = {
    wrapper: {
        position: "relative",
        borderRadius: (theme) => theme.shape.borderRadius,
        border: (theme) => theme.palette.mode === "light"
            ? `1px solid ${theme.palette.grey[400]}`
            : `1px solid ${alpha(theme.palette.grey[500], 0.3)}`,
        backgroundColor: (theme) => alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: (theme) => alpha(theme.palette.common.white, 0.25),
        },
        mt: 0.8,
        mb: 1,
        flex: 1,
    },
    icon: {
        paddingLeft: 0.9,
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: (theme) => theme.palette.grey[400],
    },
    input: {
        color: "inherit",
        paddingLeft: (theme) => `calc(1em + ${theme.spacing(2.3)})`,
        width: "100%",
    },
    close: {
        mr: 1,
        cursor: "pointer",
    },
};
