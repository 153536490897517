import { Database } from "@nozbe/watermelondb"
import {
    HttpClientError,
    ItemPartial,
    itemService,
    sentry,
    SummaryLengthEnum,
    toItemPartial,
} from "@recall/common"

import { summariesApi } from "services/api"

const searchWikipedia = async (query: string, language = "en"): Promise<ItemPartial[]> => {
    try {
        const itemApis = await summariesApi.findWikipediaSummary(query, language)
        if (!itemApis) return []
        return itemApis.map((item) => toItemPartial(item)).filter(Boolean)
    } catch (e) {
        sentry.captureException(e)
        return []
    }
}

const getAndSaveWikipedia = async (db: Database, slug: string, language = "en") => {
    try {
        const itemApi = await summariesApi.summarizeWikipediaPage(slug, language)
        if (!itemApi) return null
        const itemModel = await itemService.saveItemApi(db, itemApi, false)
        return itemModel
    } catch (e) {
        sentry.captureException(e)
        return null
    }
}

const getAndSaveScraped = async (
    db: Database,
    url: string,
    language = "en",
    summaryLength: SummaryLengthEnum
) => {
    try {
        const { data, cost } = await summariesApi.summarizePage(url, summaryLength, { language })
        if (!data) return { item: null, cost: 0 }
        const item = await itemService.saveItemApi(db, data, true)
        return { item, cost }
    } catch (err) {
        const error = err as HttpClientError
        return { item: null, cost: 0, error: error.details }
    }
}

const getAndSavePdf = async (
    db: Database,
    name: string,
    summaryLength: SummaryLengthEnum,
    formData: FormData,
    language = "en"
) => {
    try {
        const { data, cost } = await summariesApi.summarizePdfFile(
            null,
            name,
            summaryLength,
            formData,
            {
                language,
                isSaveInBackgroundAllowed: false,
            }
        )
        if (!data) return { item: null, cost: 0 }
        const item = await itemService.saveItemApi(db, data, true)
        return { item, cost }
    } catch (err) {
        const error = err as HttpClientError
        return { item: null, cost: 0, error: error.details }
    }
}

export const itemAPI = {
    searchWikipedia,
    getAndSaveWikipedia,
    getAndSaveScraped,
    getAndSavePdf,
}
