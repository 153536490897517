import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, ToggleButton, ToggleButtonGroup, alpha, Tooltip } from "@mui/material";
export const SummaryLengthToggle = ({ value, onChange }) => {
    return (_jsx(ToggleButtonGroup, Object.assign({ value: value, exclusive: true, onChange: (_, newValue) => newValue && onChange(newValue), "aria-label": "summary length" }, { children: _jsxs(Box, Object.assign({ sx: styles.container }, { children: [_jsx(Tooltip, Object.assign({ title: "Set summary length to create brief summaries with key points.", arrow: true }, { children: _jsx(ToggleButton, Object.assign({ value: "concise", "aria-label": "concise", sx: styles.toggleButton }, { children: "Concise" })) })), _jsx(Tooltip, Object.assign({ title: "Set summary length to create comprehensive summaries with more details.", arrow: true }, { children: _jsx(ToggleButton, Object.assign({ value: "detailed", "aria-label": "detailed", sx: styles.toggleButton }, { children: "Detailed" })) }))] })) })));
};
const styles = {
    container: {
        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.1),
        borderRadius: 1,
    },
    toggleButton: {
        minWidth: "100px",
        border: 0,
        borderRadius: 1,
        py: 0.6,
        px: 1,
        minHeight: 0,
        fontWeight: 500,
        "&.Mui-selected": {
            backgroundColor: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.primary.contrastText,
            borderRadius: 1,
            "&:hover": {
                backgroundColor: (theme) => theme.palette.primary.main,
            },
        },
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
};
