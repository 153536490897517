import { languages } from "../constants";
import { buildUrl, HttpClient } from "../utils";
const WIKIPEDIA_BASE_URL = "https://en.wikipedia.org";
const WIKIDATA_BASE_URL = "https://www.wikidata.org/w/api.php";
const WIKIPEDIA_SUMMARY_URL = "/api/rest_v1/page/summary";
const httpClient = new HttpClient(WIKIPEDIA_BASE_URL);
const wikidataHttpClient = new HttpClient(WIKIDATA_BASE_URL);
const getSummary = async (sourceIdentifier) => {
    const url = `${WIKIPEDIA_SUMMARY_URL}/${sourceIdentifier}`;
    try {
        return await httpClient.get(url);
    }
    catch (_a) {
        return null;
    }
};
const getImageUrlForSource = async (sourceIdentifier) => {
    var _a, _b;
    const response = await getSummary(sourceIdentifier);
    return ((_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.thumbnail) === null || _b === void 0 ? void 0 : _b.source) || null;
};
const getGroupedSlugsByLanguage = async (identifier) => {
    var _a, _b;
    try {
        const response = await wikidataHttpClient.get(buildUrl("", {
            action: "wbgetentities",
            ids: identifier,
            props: "sitelinks/urls",
            format: "json",
            origin: "*",
        }));
        const data = await response.json();
        const availableLanguages = Object.keys(languages);
        const sitelinks = ((_b = (_a = data === null || data === void 0 ? void 0 : data.entities) === null || _a === void 0 ? void 0 : _a[identifier]) === null || _b === void 0 ? void 0 : _b.sitelinks) || {};
        const slugsByLanguage = {};
        availableLanguages.forEach((lang) => {
            const wikiCode = `${lang}wiki`;
            if (sitelinks[wikiCode]) {
                slugsByLanguage[lang] = sitelinks[wikiCode].title;
            }
        });
        return slugsByLanguage;
    }
    catch (_c) {
        return {};
    }
};
export const wikiService = { getImageUrlForSource, getGroupedSlugsByLanguage };
