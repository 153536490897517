import { MoreHoriz, MoreVert, Settings } from "@mui/icons-material"
import {
    Box,
    Fade,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    SxProps,
    Theme,
} from "@mui/material"
import IconButton from "@mui/material/IconButton"
import { DateTimeComp } from "components/shared/datetime/DateTime"
import MyPopover from "components/shared/popovers/MyPopover"
import React, { FC, MouseEvent, useState } from "react"

interface Option {
    name: string
    icon?: Function
    callback: Function
    options?: Option[]
    disabled?: boolean
    badge?: React.ReactNode
    secondaryButton?: {
        icon: React.ReactNode
        callback: Function
    }
}

interface Props {
    createdAt: string
    updatedAt: string
    options: Option[]
    vertical?: boolean
}

export const Menu: FC<Props> = ({ options, createdAt, updatedAt, vertical = true }) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleOptionClick = (option: Option) => {
        handleClose()
        option.callback()
    }

    const handleOptionSecondaryClick = (option: Option) => {
        handleClose()
        option?.secondaryButton?.callback()
    }

    const createdAtDate = new Date(createdAt)
    const updatedAtDate = new Date(updatedAt)

    return (
        <div>
            <IconButton
                sx={{ backgroundColor: open ? "action.selected" : undefined }}
                onClick={handleClick}
            >
                {vertical ? <MoreVert /> : <MoreHoriz />}
            </IconButton>
            {open && (
                <MyPopover
                    TransitionComponent={Fade}
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <List
                        sx={{
                            minWidth: "300px",
                            pt: 0,
                            pb: 0,
                        }}
                        component="nav"
                    >
                        <ListItem
                            sx={{
                                backgroundColor: "action.disabledBackground",
                            }}
                        >
                            <ListItemText
                                primary={
                                    <Box fontSize={14}>
                                        <Box sx={styles.dateTime}>
                                            Last updated
                                            <DateTimeComp dateTime={updatedAtDate} />
                                        </Box>
                                        <Box sx={styles.dateTime}>
                                            Created
                                            <DateTimeComp dateTime={createdAtDate} />
                                        </Box>
                                    </Box>
                                }
                            />
                        </ListItem>
                        {options.map((option: Option) => (
                            <Box display="flex" key={option.name}>
                                <ListItemButton
                                    onClick={() => handleOptionClick(option)}
                                    disabled={option.disabled}
                                    sx={{ py: 0.6, position: "relative" }}
                                >
                                    {option.icon && (
                                        <ListItemIcon sx={{ minWidth: 32 }}>
                                            {option.icon()}
                                        </ListItemIcon>
                                    )}
                                    <ListItemText
                                        primary={
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                {option.name}
                                                {option.badge}
                                            </Box>
                                        }
                                    />
                                </ListItemButton>
                                {option.secondaryButton && (
                                    <Box
                                        sx={styles.secondaryOptionsButton}
                                        onClick={() => handleOptionSecondaryClick(option)}
                                    >
                                        {option.secondaryButton.icon}
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </List>
                </MyPopover>
            )}
        </div>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    dateTime: {
        display: "flex",
        justifyContent: "space-between",
    },
    secondaryOptionsButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 42,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "action.hover",
        },
    },
}
