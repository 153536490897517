import { MoreHoriz } from "@mui/icons-material"
import { IconButton, Menu, SxProps, Theme, Typography, alpha } from "@mui/material"
import { useBreadcrumbActions } from "hooks/useBreadcrumbActions"
import { FC, MouseEvent, useState } from "react"
import { BreadcrumbItem } from "storage/redux/app/types"

export const BreadcrumbMenu: FC<{ breadcrumbs: BreadcrumbItem[] }> = ({ breadcrumbs }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const { removeBreadcrumbsAfter } = useBreadcrumbActions()

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreHoriz fontSize="small" />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                    sx: { p: 0 },
                }}
            >
                {breadcrumbs.map(({ label, path }) => (
                    <Typography
                        variant="body2"
                        sx={styles}
                        onClick={() => removeBreadcrumbsAfter(path)}
                        key={path}
                    >
                        {label}
                    </Typography>
                ))}
            </Menu>
        </>
    )
}

const styles: SxProps<Theme> = {
    px: 2,
    py: 1,
    opacity: 0.8,
    cursor: "pointer",
    ":hover": {
        opacity: 1,
        background: (theme) => alpha(theme.palette.background.paper, 0.4),
    },
}
