import { Box, CircularProgress } from "@mui/material"
import { useRefObserver } from "hooks/useRefObserver"
import { FC, memo } from "react"

interface Props {
    handleLoadMore: () => void
}

export const UntaggedItemsLoader: FC<Props> = memo(({ handleLoadMore }) => {
    const loader = useRefObserver(handleLoadMore)

    return (
        <Box display="flex" alignItems="center" justifyContent="center" mt={1} pb={2}>
            <CircularProgress size={20} ref={loader} sx={{ color: "text.primary" }} />
        </Box>
    )
})
