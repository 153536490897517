import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { alpha, Box, CircularProgress, circularProgressClasses, } from "@mui/material";
export const Spinner = (props) => {
    return (_jsxs(Box, Object.assign({ sx: styles.container }, { children: [_jsx(CircularProgress, Object.assign({ variant: "determinate", sx: styles.backCircle, size: 50, thickness: 6 }, props, { value: 100 })), _jsx(CircularProgress, Object.assign({ variant: "indeterminate", disableShrink: true, sx: Object.assign(Object.assign({}, styles.frontCircle), { color: props.color || "secondary.light" }), size: 50, thickness: 6 }, props))] })));
};
const styles = {
    container: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    backCircle: {
        color: (theme) => alpha(theme.palette.text.primary, 0.2),
    },
    frontCircle: {
        animationDuration: "800ms",
        position: "absolute",
        left: 0,
        [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
        },
    },
};
