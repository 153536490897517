import { RootState } from "../rootReducer"

export const isLoggedInSelector = (state: RootState) => {
    if (state.user.uid && state.user.email) {
        return true
    }

    return false
}

export const isTutorialActive = (state: RootState) => {
    return state.user.tutorial.active !== "none"
}

export const isCardShared = (state: RootState, id: string) => {
    return Boolean(state?.user?.sharedCards?.includes(id))
}
