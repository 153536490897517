import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Avatar, Box, Button, Card, Typography } from "@mui/material";
import { useState } from "react";
import { MentionText } from "./MentionText";
export const Mentions = ({ mentions, openItemById }) => {
    return (_jsx(Box, { children: mentions.map((mention) => (_jsx(Box, Object.assign({ px: 1, py: 0.5 }, { children: _jsx(MentionItem, { mention: mention, openItemById: openItemById }) }), mention.id))) }));
};
const MentionItem = ({ mention, openItemById }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const blocks = isExpanded ? mention.blocks : mention.blocks.slice(0, 2);
    const isShowMoreVisible = mention.blocks.length > 2 && !isExpanded;
    return (_jsxs(Card, Object.assign({ sx: styles.card, elevation: 2, onClick: () => {
            openItemById(mention.id);
        } }, { children: [_jsxs(Box, Object.assign({ display: "flex", justifyContent: "space-between" }, { children: [_jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h6", sx: styles.name }, { children: mention.name })), _jsx(Typography, Object.assign({ variant: "caption", component: "p", sx: styles.description }, { children: mention.description }))] }), mention.image && _jsx(Avatar, { variant: "square", src: mention.image, sx: styles.image })] })), Boolean(mention.blocks.length) && (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ mt: 1 }, { children: blocks.map(({ textBlocks, elementId }) => (_jsx(MentionText, { textBlocks: textBlocks, handleClick: (e) => {
                                e.stopPropagation();
                                openItemById(mention.id, { elementId });
                            } }, elementId))) })), isShowMoreVisible && (_jsx(Box, Object.assign({ display: "flex", justifyContent: "center" }, { children: _jsx(Button, Object.assign({ color: "inherit", onClick: (e) => {
                                e.stopPropagation();
                                setIsExpanded(true);
                            }, size: "small" }, { children: "Show more" })) })))] }))] })));
};
const styles = {
    card: {
        p: 1,
        cursor: "pointer",
        ":hover": {
            background: (theme) => theme.palette.action.hover,
        },
    },
    description: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
    },
    name: {
        fontFamily: (theme) => theme.typography.secondaryFontFamily,
        wordBreak: "break-word",
        fontSize: "1em",
        fontWeight: 500,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
    },
    image: {
        ml: 0.5,
        height: 48,
        width: 48,
        borderRadius: 1,
        border: "1px solid rgba(255, 255, 255, 0.16)",
        bgcolor: (theme) => theme.palette.text.secondary,
    },
};
