import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { languages } from "../../../constants";
import { LanguageSelect } from "./LanguageSelect";
const availableLanguages = Object.entries(languages);
export const defaultOptions = availableLanguages.map(([value, { label, flag }]) => ({
    value,
    label,
    flag,
}));
export const SummaryOptionsForm = () => {
    const options = [{ value: "auto", label: "Don't Translate", flag: "" }, ...defaultOptions];
    return (_jsxs(Box, Object.assign({ display: "flex", flexDirection: "column" }, { children: [_jsx(Typography, Object.assign({ fontWeight: 500, variant: "h5" }, { children: "Translate Summaries" })), _jsxs(Typography, Object.assign({ variant: "caption", color: "text.secondary" }, { children: ["Choose a language for your summaries to be translated to. ", _jsx("br", {}), "If you select ", _jsx("b", { children: "Don't Translate" }), ", the language of the original content will be used."] })), _jsx(LanguageSelect, { options: options })] })));
};
