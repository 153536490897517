import { UsageService } from "./usageService";
export class BookmarksImportUsageService extends UsageService {
    constructor() {
        super(...arguments);
        this.limit = 10;
        this.increaseUsage = async (userId) => {
            var _a;
            const user = await this.userRepository.getUser(userId);
            const scrapingUsage = ((_a = user === null || user === void 0 ? void 0 : user.usage) === null || _a === void 0 ? void 0 : _a.scraping) || 0;
            const scraping = scrapingUsage + 1;
            await this.userRepository.upsertUser(userId, {
                usage: this.getUserUsage(user, { scraping }),
            });
            return scraping;
        };
        this.isUsageExceeded = async (userId) => {
            const usage = await this.getUsage(userId);
            return usage > this.limit;
        };
        this.getUsage = async (userId) => {
            var _a;
            const user = await this.userRepository.getUser(userId);
            const usage = ((_a = user === null || user === void 0 ? void 0 : user.usage) === null || _a === void 0 ? void 0 : _a.scraping) || 0;
            return usage;
        };
    }
}
