import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material"
import { posthogService, CONTACT_US_EMAIL } from "@recall/common"
import { USER_PURCHASED_EVENT } from "constants/events"
import { FC, useEffect } from "react"
import { gtmService } from "services/gtmService"

interface Props {
    closeModal: () => void
}

export const ThankYouModal: FC<Props> = ({ closeModal }) => {
    useEffect(() => {
        posthogService.captureEvent(USER_PURCHASED_EVENT)
        gtmService.trackPurchaseConversion()
    }, [])

    return (
        <Dialog onClose={closeModal} open>
            <DialogTitle>Thank you for subscribing to Recall Plus</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Thank you for your continued support and trust in our platform. If you ever have
                    any questions or feedback, please don't hesitate to reach out to our team.
                </DialogContentText>
                <DialogContentText mt={2}>Contact: {CONTACT_US_EMAIL}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={closeModal} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}
