import DarkModeIcon from "@mui/icons-material/DarkMode"
import LightModeIcon from "@mui/icons-material/LightMode"
import { IconButton } from "@mui/material"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { SET_BRIGHTNESS_MODE } from "storage/redux/app/actionTypes"
import { themeModeEnum } from "storage/redux/app/types"
import { RootState } from "storage/redux/rootReducer"

const ModeSelection: React.FC<React.PropsWithChildren<unknown>> = () => {
    const themeMode = useSelector((state: RootState) => state.app.themeMode)

    const dispatch = useDispatch()

    const handleChange = (newthemeMode: themeModeEnum) => {
        // setAlignment(newAlignment);
        dispatch({ type: SET_BRIGHTNESS_MODE, payload: newthemeMode })
    }

    return themeMode === themeModeEnum.LIGHT ? (
        <IconButton onClick={() => handleChange(themeModeEnum.DARK)}>
            <DarkModeIcon />
        </IconButton>
    ) : (
        <IconButton onClick={() => handleChange(themeModeEnum.LIGHT)}>
            <LightModeIcon />
        </IconButton>
    )
}

export default ModeSelection
