import { useDatabase } from "@nozbe/watermelondb/react";
import { mentionsService } from "../../services/links/mentionsService";
import { connectionRepository } from "../../storage/watermelon/repository/connectionRepository";
import { itemRepository } from "../../storage/watermelon/repository/itemRepository";
import { sentry } from "../../utils";
export const usePluginHelpers = (item) => {
    const db = useDatabase();
    return {
        getItemByConnectionId: (id) => getItemByConnectionId(db, id),
        deleteConnectionById: (id) => deleteConnectionById(db, id),
        createConnection: (itemResult) => createConnection(db, itemResult, item.id),
        updateImage: (url) => updateImage(item, url),
        isMainImage: (url) => isMainImage(item, url),
    };
};
export const getItemByConnectionId = async (db, id) => {
    try {
        const connection = await connectionRepository.get(db, id);
        if (!connection)
            return null;
        const item = await connection.to;
        if (!item)
            return null;
        const mentions = await item.mentions;
        const otherMentions = mentions.filter(({ id, isSaved }) => isSaved && id !== connection.id);
        return {
            id: item.id,
            name: item.name,
            description: item.description,
            image: item.image,
            mentionsCount: mentions.length,
            mentions: await mentionsService.getByConnections(otherMentions),
        };
    }
    catch (error) {
        sentry.captureException(error);
        return null;
    }
};
export const deleteConnectionById = async (db, id) => {
    await connectionRepository.delete(db, [id]);
};
export const createConnection = async (db, item, id) => {
    const itemModel = await itemRepository.getOrCreate(db, item);
    const connectionModel = await connectionRepository.create(db, {
        fromId: id,
        toId: itemModel.id,
    });
    return { name: itemModel.name, connectionId: connectionModel.id, itemId: itemModel.id };
};
export const updateImage = async (item, url) => {
    await item.updateImage(url);
};
export const isMainImage = (item, url) => item.image === url;
