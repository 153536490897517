import { Delete } from "@mui/icons-material"
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material"
import { QuestionModel, reviewsScheduleRepository } from "@recall/common"
import { FC, memo, useState } from "react"

interface Props {
    question: QuestionModel
    deleteQuestion: (question: QuestionModel) => Promise<void>
}

const DeleteQuestionComponent: FC<Props> = ({ question, deleteQuestion }) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleClose = () => {
        setIsOpen(false)
    }

    const handleOpen = () => {
        setIsOpen(true)
    }

    const handleConfirm = async () => {
        setIsOpen(false)
        await deleteQuestion(question)
        await reviewsScheduleRepository.remove(question.id)
        await question.delete()
    }

    return (
        <>
            <Box onMouseLeave={handleClose}>
                <Tooltip
                    open={isOpen}
                    arrow={false}
                    PopperProps={{
                        disablePortal: true,
                    }}
                    title={
                        <Box>
                            <Typography textAlign="center">Delete question</Typography>
                            <Box display="flex" gap={1}>
                                <Button
                                    onClick={handleClose}
                                    sx={{ color: (theme) => theme.palette.background.paper }}
                                >
                                    Cancel
                                </Button>
                                <Button color="error" onClick={handleConfirm}>
                                    Confirm
                                </Button>
                            </Box>
                        </Box>
                    }
                >
                    <IconButton onClick={handleOpen}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </Box>
        </>
    )
}

export const DeleteQuestion = memo(
    DeleteQuestionComponent,
    (prev, next) => prev.question.id === next.question.id
)
