import { Close, Folder, School, Settings, Share, Translate } from "@mui/icons-material"
import DeleteIcon from "@mui/icons-material/Delete"
import DownloadIcon from "@mui/icons-material/Download"
import InfoIcon from "@mui/icons-material/Info"
import {
    Box,
    Button,
    Divider,
    IconButton,
    Snackbar,
    SxProps,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import {
    ItemApi,
    ItemModel,
    QuestionModel,
    SourceModel,
    Spinner,
    WIKIDATA,
    assetRepository,
    createAssets,
    itemService,
    questionRepository,
    reviewsScheduleRepository,
    sentry,
    sharingRepository,
} from "@recall/common"
import { RECALL_REVIEW_DOCS_URL } from "components/SpacedRepetition/constants"
import { SharedBadge } from "components/shared/badges/SharedBadge"
import { QuestionsContainer } from "components/shared/questions/QuestionsContainer"
import { SPACED_REPETITION } from "constants/routes"
import { useBreadcrumbActions } from "hooks/useBreadcrumbActions"
import { useGenerateQuestions } from "hooks/useGenerateQuestions"
import { useIsNative } from "hooks/useIsNative"
import { useOpenInNewTab } from "hooks/useOpenInNewTab"
import { useOpenState } from "hooks/useOpenState"
import { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import { exportService } from "services/export/exportService"
import { isLoadingQuestionsSelector } from "storage/redux/app/selectors"
import { RootState } from "storage/redux/rootReducer"
import { isCardShared } from "storage/redux/user/selector"
import { AssetsModal } from "./Assets/AssetsModal"
import { LanguageModal } from "./LanguageModal/LanguageModal"
import { Menu } from "./Menu"
import { ShareModal } from "./ShareModal/ShareModal"

interface Props {
    item: ItemModel
    isShareModalOpen: boolean
    handleOpenShareModal: () => void
    handleCloseShareModal: () => void
    setIsTranslating: (_: boolean) => void
}

export const ItemMenu: FC<Props> = ({
    item,
    isShareModalOpen,
    handleOpenShareModal,
    handleCloseShareModal,
    setIsTranslating,
}) => {
    const db = useDatabase()
    const history = useHistory()
    const [questions, setQuestions] = useState<QuestionModel[]>([])
    const { popBreadcrumb } = useBreadcrumbActions()
    const isNative = useIsNative()
    const { openInNewTab } = useOpenInNewTab()
    const isShared = useSelector((state: RootState) => isCardShared(state, item.id))
    const isLoadingQuestions = useSelector((state: RootState) =>
        isLoadingQuestionsSelector(state, item.id)
    )
    const uid = useSelector((state: RootState) => state.user.uid)
    const [wikidataSource, setWikidataSource] = useState<SourceModel>(null)

    const [isSnackbarVisible, setIsSnackbarVisible] = useState(false)

    const { handleGenerateQuestions } = useGenerateQuestions({ item, questions, setQuestions })
    const {
        isOpen: isAssetsModalOpen,
        handleClose: handleCloseAssetsModal,
        handleOpen: handleOpenAssetsModal,
    } = useOpenState()

    const {
        isOpen: isLanguageModalOpen,
        handleClose: handleCloseLanguageModal,
        handleOpen: handleOpenLanguageModal,
    } = useOpenState()

    const {
        isOpen: isQuestionsDialogOpen,
        handleClose: handleCloseQuestions,
        handleOpen: handleOpenQuestions,
    } = useOpenState()

    useEffect(() => {
        getQuestions()
        getWikidataSource()
    }, [])

    const getQuestions = async () => {
        const questions = await questionRepository.getQuestionsByItemId(db, item.id)
        setQuestions(questions)
    }

    const getWikidataSource = async () => {
        const sources = await item.sources
        const wikidataSource = sources.find((source) => source.name === WIKIDATA)
        setWikidataSource(wikidataSource)
    }

    const handleDelete = async () => {
        try {
            await sharingRepository.unshareCard(item.id)
            const questions = await item.questions.fetch()
            await Promise.all(
                questions.map(async (question) => reviewsScheduleRepository.remove(question.id))
            )
            await item.delete()

            popBreadcrumb()
            await assetRepository.deleteByItemId(db, item.id)
        } catch (e) {
            sentry.captureException(e as Error, { item: item?.name, id: item?.id })
        }
    }
    const handleExportMarkdown = async () => {
        exportService.exportCardToMarkdown(db, item, { useHyperlinks: true })
    }

    const handleUpdateItemLanguage = async (itemApi: ItemApi, language: string) => {
        setIsTranslating(true)
        await itemService.updateItemLanguage(db, item, itemApi, language)
        await createAssets(db, item, uid)
        handleCloseLanguageModal()
        setIsTranslating(false)
    }

    const handleReviewCard = () => {
        history.push(`${SPACED_REPETITION}?itemId=${item.id}`)
    }

    const generateQuestions = () => {
        if (!isLoadingQuestions && !questions.length) {
            handleGenerateQuestions()
            setIsSnackbarVisible(true)
        } else handleOpenQuestions()
    }

    const handleOpenReviewDocs = () => {
        openInNewTab(RECALL_REVIEW_DOCS_URL)
    }

    const hasQuestions = questions.length > 0

    const itemOptions = [
        hasQuestions
            ? {
                  name: "Review Questions",
                  callback: handleReviewCard,
                  icon: () => <School fontSize="small" />,
                  secondaryButton: {
                      icon: (
                          <Tooltip title="Manage Questions">
                              <Settings />
                          </Tooltip>
                      ),
                      callback: handleOpenQuestions,
                  },
              }
            : {
                  name: isLoadingQuestions ? "Generating Questions" : "Generate Questions",
                  callback: generateQuestions,
                  secondaryButton: isLoadingQuestions
                      ? undefined
                      : {
                            icon: (
                                <Tooltip
                                    title={
                                        <>
                                            <Typography variant="h6">
                                                How does this work?
                                            </Typography>
                                            This will generate questions based on the information in
                                            this knowledge card. You can then answer these questions
                                            to test your knowledge on the content.
                                            <Typography variant="h6">The Recall Review</Typography>
                                            Additionally the questions will be added to your Recall
                                            Review. The Recall Review will schedule these questions
                                            to be shown to you at the optimal time for you to
                                            remember the information.
                                        </>
                                    }
                                >
                                    <InfoIcon />
                                </Tooltip>
                            ),
                            callback: handleOpenReviewDocs,
                        },
                  icon: () =>
                      isLoadingQuestions ? <Spinner size={20} /> : <School fontSize="small" />,
              },
        wikidataSource && {
            name: "Change Language",
            callback: handleOpenLanguageModal,
            icon: () => <Translate fontSize="small" />,
        },
        item.isSaved && {
            name: "Share",
            callback: handleOpenShareModal,
            icon: () => <Share fontSize="small" />,
            badge: isShared ? <SharedBadge /> : null,
        },
        {
            name: `Export to Markdown ${isNative ? "(Desktop Only)" : ""}`,
            callback: handleExportMarkdown,
            icon: () => <DownloadIcon fontSize="small" />,
            disabled: isNative,
        },
        {
            name: "Images",
            callback: handleOpenAssetsModal,
            icon: () => <Folder fontSize="small" />,
        },
        {
            name: "Delete",
            callback: handleDelete,
            icon: () => <DeleteIcon fontSize="small" />,
        },
    ].filter(Boolean)

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={isSnackbarVisible}
                onClose={() => {
                    setIsSnackbarVisible(true)
                }}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => setIsSnackbarVisible(false)}
                    >
                        <Close fontSize="small" />
                    </IconButton>
                }
                ContentProps={{
                    sx: {
                        background: (theme) => theme.palette.background.paper,
                        color: (theme) => theme.palette.text.primary,
                        border: (theme) => `1px solid ${theme.palette.divider}`,
                        alignItems: "start",
                        "& .MuiSnackbarContent-message": {
                            py: 0,
                        },
                    },
                }}
                message={
                    isLoadingQuestions ? (
                        <Box display="flex" gap={2} alignItems="center">
                            <Spinner size={30} />
                            <Typography>Generating Questions</Typography>
                        </Box>
                    ) : (
                        <Box display="flex" flexDirection="column" gap={1}>
                            <Typography variant="caption">
                                {questions.length} questions added to your <i>Recall Review</i>
                            </Typography>
                            <Box display="flex" alignItems="center">
                                <Button
                                    size="small"
                                    color="inherit"
                                    sx={styles.snackButtons}
                                    onClick={() => {
                                        setIsSnackbarVisible(false)
                                        history.push(`${SPACED_REPETITION}?itemId=${item.id}`)
                                    }}
                                >
                                    Review Now
                                </Button>
                                <Divider orientation="vertical" sx={{ height: 20, mx: 0.5 }} />
                                <Button
                                    size="small"
                                    color="inherit"
                                    sx={styles.snackButtons}
                                    onClick={() => {
                                        setIsSnackbarVisible(false)
                                        handleOpenQuestions()
                                    }}
                                    endIcon={<Settings />}
                                >
                                    Manage
                                </Button>
                            </Box>
                        </Box>
                    )
                }
            />
            <Menu
                updatedAt={item.updatedAt}
                createdAt={item.createdAt}
                options={itemOptions}
                vertical={false}
            />
            {isQuestionsDialogOpen && (
                <QuestionsContainer
                    setQuestions={setQuestions}
                    questions={questions}
                    handleClose={handleCloseQuestions}
                    item={item}
                />
            )}
            {isLanguageModalOpen && wikidataSource && (
                <LanguageModal
                    item={item}
                    source={wikidataSource}
                    closeModal={handleCloseLanguageModal}
                    handleUpdateItem={handleUpdateItemLanguage}
                />
            )}
            {isAssetsModalOpen && <AssetsModal item={item} closeModal={handleCloseAssetsModal} />}
            {isShareModalOpen && <ShareModal item={item} closeModal={handleCloseShareModal} />}
        </>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    snackButtons: {
        px: 2,
        backgroundColor: (theme) => theme.palette.action.selected,
    },
}
