import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Image } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { focusEditor } from "@udecode/plate-common";
import { insertImage } from "@udecode/plate-media";
import { useRef } from "react";
import { useMyPlateEditorRef } from "../../types";
export const ImageToolbarButton = ({ uploadImage }) => {
    const editor = useMyPlateEditorRef();
    const inputRef = useRef(null);
    const handleImageChange = (e) => {
        var _a;
        const file = (_a = e.target.files) === null || _a === void 0 ? void 0 : _a[0];
        if (file) {
            const contentType = file.type;
            const reader = new FileReader();
            reader.onloadend = async () => {
                if (reader.result instanceof ArrayBuffer) {
                    const arrayBuffer = reader.result;
                    const url = await uploadImage(arrayBuffer, contentType);
                    if (!url)
                        return null;
                    insertImage(editor, url);
                    focusEditor(editor);
                }
            };
            reader.readAsArrayBuffer(file);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("input", { type: "file", accept: "image/*", style: { display: "none" }, ref: inputRef, onChange: handleImageChange }), _jsx(Tooltip, Object.assign({ title: "Upload image" }, { children: _jsx(IconButton, Object.assign({ size: "small", onClick: () => {
                        var _a;
                        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click();
                    } }, { children: _jsx(Image, {}) })) }))] }));
};
