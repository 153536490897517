export const checkIsUrl = (url) => {
    var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return res !== null;
};
export const checkIsBookmarkUrl = (url) => {
    var res = url.match(/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return res !== null;
};
export const YoutubeLiveUrlRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/live\/([a-zA-Z0-9_-]{11})/;
export const YoutubeUrlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=)?([a-zA-Z0-9_-]{11})/;
export const ShortYoutubeUrlRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be)\/([a-zA-Z0-9_-]{11})/;
export const MobileYoutubeUrlRegex = /https?:\/\/m\.youtube\.com\/(?:watch\?v=|v\/)([a-zA-Z0-9_-]{11})/;
export const AlternativeYoutubeWatchRegex = /https?:\/\/(?:www\.)?youtube\.com\/watch\/([a-zA-Z0-9_-]{11})/;
export const isYoutubeVideo = (url) => {
    return Boolean(url.match(YoutubeUrlRegex));
};
export const isYouTubeLiveVideo = (url) => {
    return Boolean(url.match(YoutubeLiveUrlRegex));
};
export const isShortenYouTubeVideo = (url) => {
    return Boolean(url.match(ShortYoutubeUrlRegex));
};
export const isMobileYoutubeVideo = (url) => {
    return Boolean(url.match(MobileYoutubeUrlRegex));
};
export const isAlternativeYouTubeVideo = (url) => {
    return Boolean(url.match(AlternativeYoutubeWatchRegex));
};
export const isWikipediaUrl = (url) => {
    const regex = /^https:\/\/(?:www\.)?([a-z]{2,})\.wikipedia\.org\/wiki\/(.+)$/;
    return regex.test(url);
};
export const expandShortenYouTubeUrl = (url) => {
    const urlParts = url.split("/");
    const lastSegment = urlParts[urlParts.length - 1];
    const videoId = lastSegment.split("?")[0];
    return `https://www.youtube.com/watch?v=${videoId}`;
};
export const isFirefox = () => {
    return (navigator.userAgent.toLowerCase().indexOf("firefox") > -1 ||
        ("netscape" in window && / rv:/.test(navigator.userAgent)));
};
export const isGoogleSearchUrl = (url) => {
    try {
        const parsedUrl = new URL(url);
        const isGoogleDomain = /^www\.google\.[a-z]{2,}(?:\.[a-z]{2})?$/.test(parsedUrl.hostname);
        const isSearchPath = parsedUrl.pathname.startsWith("/search");
        return isGoogleDomain && isSearchPath;
    }
    catch (_a) {
        return false;
    }
};
export const formatYoutubeTimestamp = (timestamp) => {
    const timestampRegex = /^\(\d{2}:\d{2}:\d{2}\)$/;
    if (!timestampRegex.test(timestamp))
        return timestamp;
    const [hours, minutes, seconds] = timestamp.slice(1, -1).split(":").map(Number);
    const parts = [];
    if (hours > 0)
        parts.push(hours.toString().padStart(2, "0"));
    parts.push(minutes.toString().padStart(2, "0"));
    parts.push(seconds.toString().padStart(2, "0"));
    return parts.join(":");
};
const YOUTUBE_URL_FORMAT = "https://www.youtube.com/watch?v=$1";
export const normalizeUrl = (url) => {
    const parsedUrl = new URL(url);
    const isYouTubeUrl = parsedUrl.hostname.includes("youtube.com") || parsedUrl.hostname.includes("youtu.be");
    if (!isYouTubeUrl) {
        return url;
    }
    const videoId = parsedUrl.searchParams.get("v");
    if (videoId)
        return `https://www.youtube.com/watch?v=${videoId}`;
    if (isShortenYouTubeVideo(url))
        return url.replace(ShortYoutubeUrlRegex, YOUTUBE_URL_FORMAT);
    if (isYouTubeLiveVideo(url))
        return url.replace(YoutubeLiveUrlRegex, YOUTUBE_URL_FORMAT);
    if (isMobileYoutubeVideo(url))
        return url.replace(MobileYoutubeUrlRegex, YOUTUBE_URL_FORMAT);
    if (isAlternativeYouTubeVideo(url))
        return url.replace(AlternativeYoutubeWatchRegex, YOUTUBE_URL_FORMAT);
    return url;
};
export const parseUrl = (url) => {
    return stripParameters(normalizeUrl(url));
};
export const stripParameters = (url) => {
    if (isWikipediaUrl(url)) {
        const urlObj = new URL(url);
        const strippedUrl = urlObj.origin + urlObj.pathname + urlObj.search;
        return strippedUrl;
    }
    return url;
};
