import { createPlateEditor, createPluginFactory, createPlugins, createTEditor, getTEditor, useEditorRef, useEditorState, usePlateActions, usePlateEditorRef, usePlateEditorState, usePlateSelectors, usePlateStates, } from "@udecode/plate-common";
/**
 * Plate store, Slate context
 */
export const getMyEditor = (editor) => getTEditor(editor);
export const useMyEditorRef = () => useEditorRef();
export const useMyEditorState = () => useEditorState();
export const useMyPlateEditorRef = (id) => usePlateEditorRef(id);
export const useMyPlateEditorState = (id) => usePlateEditorState(id);
export const useMyPlateSelectors = (id) => usePlateSelectors(id);
export const useMyPlateActions = (id) => usePlateActions(id);
export const useMyPlateStates = (id) => usePlateStates(id);
/**
 * Utils
 */
export const createMyEditor = () => createTEditor();
export const createMyPlateEditor = (options = {}) => createPlateEditor(options);
export const createMyPluginFactory = (defaultPlugin) => createPluginFactory(defaultPlugin);
export const createMyPlugins = (plugins, options) => createPlugins(plugins, options);
