import { comboboxActions } from "@udecode/plate-combobox";
import { isCollapsed } from "@udecode/plate-common";
import { EmojiInlineIndexSearch, getEmojiOnSelectItem } from "@udecode/plate-emoji";
import { getFindTriggeringInput } from "./getFindTriggeringInput";
export const withEmoji = (editor, { options: { id, emojiTriggeringController } }) => {
    const emojiInlineIndexSearch = EmojiInlineIndexSearch.getInstance();
    const findTheTriggeringInput = getFindTriggeringInput(editor, emojiTriggeringController);
    const { apply, insertText, deleteBackward, deleteForward } = editor;
    editor.insertText = (char) => {
        const { selection } = editor;
        if (!isCollapsed(selection)) {
            return insertText(char);
        }
        findTheTriggeringInput({ char });
        return insertText(char);
    };
    editor.deleteBackward = (unit) => {
        findTheTriggeringInput({ action: "delete" });
        return deleteBackward(unit);
    };
    editor.deleteForward = (unit) => {
        findTheTriggeringInput();
        return deleteForward(unit);
    };
    editor.apply = (operation) => {
        apply(operation);
        if (!(emojiTriggeringController === null || emojiTriggeringController === void 0 ? void 0 : emojiTriggeringController.hasTriggeringMark)) {
            return;
        }
        const searchText = emojiTriggeringController.getText();
        switch (operation.type) {
            case "set_selection": {
                emojiTriggeringController.reset();
                comboboxActions.reset();
                break;
            }
            case "insert_text": {
                if (emojiTriggeringController.hasEnclosingTriggeringMark() &&
                    emojiInlineIndexSearch.search(searchText).hasFound(true)) {
                    const item = emojiInlineIndexSearch.getEmoji();
                    item && getEmojiOnSelectItem()(editor, item);
                    break;
                }
                if (!emojiTriggeringController.hasEnclosingTriggeringMark() &&
                    emojiTriggeringController.isTriggering &&
                    emojiInlineIndexSearch.search(searchText).hasFound()) {
                    comboboxActions.items(emojiInlineIndexSearch.search(searchText).get());
                    comboboxActions.open({
                        activeId: id,
                        text: "",
                        targetRange: editor.selection,
                    });
                    break;
                }
                emojiTriggeringController.reset();
                comboboxActions.reset();
                break;
            }
            case "remove_text": {
                findTheTriggeringInput();
                if (emojiTriggeringController.isTriggering &&
                    emojiInlineIndexSearch.search(searchText).hasFound()) {
                    comboboxActions.items(emojiInlineIndexSearch.search(searchText).get());
                    comboboxActions.open({
                        activeId: id,
                        text: "",
                        targetRange: editor.selection,
                    });
                    break;
                }
                emojiTriggeringController.reset();
                comboboxActions.reset();
                break;
            }
        }
    };
    return editor;
};
