import { WifiOff } from "@mui/icons-material"
import { Tooltip } from "@mui/material"
import { useIsOnline } from "hooks/useIsOnline"

export const OfflineStatus = () => {
    const isOnline = useIsOnline()

    if (isOnline) return null

    return (
        <Tooltip enterTouchDelay={0} title="No internet connection, data syncing is on hold.">
            <WifiOff sx={{ color: (theme) => theme.palette.text.primary }} />
        </Tooltip>
    )
}
