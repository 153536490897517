import { Box, SxProps, Theme, Typography } from "@mui/material"
import {
    FIREFOX_RECALL_EXTENSION_STORE_URL,
    RECALL_EXTENSION_STORE_URL,
    isFirefox,
} from "@recall/common"
import { InstallExtensionButton } from "components/shared/buttons/InstallExtensionButton"
import { FC } from "react"

interface Props {
    onClose: () => void
}

export const InstallExtension: FC<Props> = ({ onClose }) => {
    return (
        <Box sx={styles.container}>
            <Typography variant="h4">Install extension</Typography>
            <Typography variant="body1" pt={2}>
                In order to import your bookmarks, you must first install the Recall Browser
                Extension.
            </Typography>
            <Box sx={styles.cta}>
                <InstallExtensionButton
                    href={
                        !isFirefox()
                            ? RECALL_EXTENSION_STORE_URL
                            : FIREFOX_RECALL_EXTENSION_STORE_URL
                    }
                    target="_blank"
                />
            </Box>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        p: 2,
        height: "100%",
    },
    cta: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
    },
}
