import { v4 as uuidv4 } from "uuid";
const addId = (entity) => {
    return Object.assign(Object.assign({}, entity), { id: uuidv4() });
};
export const toItem = (item) => {
    return Object.assign(Object.assign({}, toItemPartial(item)), { links: item.links ? item.links.map(toLink) : [], mentions: [], markdown: item.markdown });
};
export const toItemPartial = (item) => {
    const sources = item.sources.map(addId);
    const editorBlocks = item.editorBlocks.map(addId);
    const images = item.images.map(addId);
    return Object.assign(Object.assign({}, item), { id: uuidv4(), type: item.type && toType(item.type), sources,
        images,
        editorBlocks, language: item.language, isExpanded: item.isExpanded, length: item.summaryLength });
};
export const toType = (type) => {
    return Object.assign({ id: uuidv4() }, type);
};
export const toLink = (link) => {
    const item = toItemPartial(link.item);
    return {
        id: uuidv4(),
        item,
        property: link.property,
    };
};
