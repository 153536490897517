var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
function EmojiPreview({ emoji }) {
    return (_jsxs(Box, Object.assign({ sx: styles.preview }, { children: [_jsx(Box, Object.assign({ fontSize: 24 }, { children: emoji === null || emoji === void 0 ? void 0 : emoji.skins[0].native })), _jsxs(Box, Object.assign({ display: "flex", flexDirection: "column" }, { children: [_jsx(Box, { children: emoji === null || emoji === void 0 ? void 0 : emoji.name }), _jsx(Typography, Object.assign({ variant: "caption", sx: styles.previewId }, { children: `:${emoji === null || emoji === void 0 ? void 0 : emoji.id}:` }))] }))] })));
}
function NoEmoji({ i18n }) {
    return (_jsxs(Box, Object.assign({ sx: styles.preview }, { children: [_jsx(Box, Object.assign({ fontSize: 24 }, { children: "\uD83D\uDE22" })), _jsxs(Box, Object.assign({ display: "flex", flexDirection: "column" }, { children: [_jsx(Box, { children: i18n.searchNoResultsTitle }), _jsx(Typography, Object.assign({ sx: styles.previewId, variant: "caption" }, { children: i18n.searchNoResultsSubtitle }))] }))] })));
}
function PickAnEmoji({ i18n }) {
    return (_jsxs(Box, Object.assign({ sx: styles.preview }, { children: [_jsx(Box, Object.assign({ fontSize: 24 }, { children: "\u261D\uFE0F" })), _jsx(Box, { children: _jsx(Box, { children: i18n.pick }) })] })));
}
export function EmojiPickerPreview(_a) {
    var { emoji, hasFound = true, isSearching = false, i18n } = _a, props = __rest(_a, ["emoji", "hasFound", "isSearching", "i18n"]);
    const showPickEmoji = !emoji && !(isSearching && !hasFound);
    const showNoEmoji = isSearching && !hasFound;
    const showPreview = emoji;
    return (_jsxs(_Fragment, { children: [!showNoEmoji && showPreview && _jsx(EmojiPreview, Object.assign({ emoji: emoji }, props)), !showNoEmoji && showPickEmoji && _jsx(PickAnEmoji, Object.assign({ i18n: i18n }, props)), showNoEmoji && _jsx(NoEmoji, Object.assign({ i18n: i18n }, props))] }));
}
const styles = {
    preview: {
        display: "flex",
        alignItems: "center",
        p: 1,
        gap: 1,
    },
    previewId: {
        lineHeight: 1,
        mt: 0,
        p: 0,
        color: (theme) => theme.palette.text.disabled,
    },
};
