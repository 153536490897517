import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Box, Chip } from "@mui/material";
import React, { useMemo } from "react";
import { extractSearchText } from "../../utils/extractSearchText";
import { TotalCountBadge } from "../badges/TotalCountBadge";
function stringToColor(string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
}
function stringAvatar(name) {
    const nameParts = name.split(" ");
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: nameParts.length > 1 ? `${nameParts[0][0]}${nameParts[1][0]}` : name[0],
    };
}
const ItemChipComponent = ({ id, name, image, selected, searchText, size = "small", mentionCount, handleClick, }) => {
    const label = useMemo(() => {
        if (!searchText) {
            return _jsx(_Fragment, { children: name });
        }
        const extractedSearch = extractSearchText(name, searchText);
        if (!extractedSearch) {
            return _jsx(_Fragment, { children: name });
        }
        const { beforeSearch, afterSearch, searchedText } = extractedSearch;
        const maxDisplayLength = Math.max(searchText.length, 25);
        const beforeSearchLengthLimit = Math.max(maxDisplayLength - searchText.length, 0);
        return (_jsxs(_Fragment, { children: [(beforeSearch.length > beforeSearchLengthLimit ? "..." : "") +
                    (beforeSearchLengthLimit === 0
                        ? ""
                        : beforeSearch.slice(-beforeSearchLengthLimit)), _jsx(Box, Object.assign({ component: "b", sx: { color: "secondary.main" } }, { children: searchedText })), afterSearch] }));
    }, [searchText]);
    return (_jsx(Chip, { id: id, size: size, onClick: () => handleClick(id), sx: {
            bgcolor: (theme) => theme.palette.background.layout,
            maxWidth: "210px",
            border: (theme) => selected ? `1px solid ${theme.palette.secondary.main}` : undefined,
            borderRadius: 1.5,
        }, label: label, avatar: image ? (_jsx(Avatar, { sx: { bgcolor: (theme) => theme.palette.text.secondary, borderRadius: 1.2 }, alt: name, src: image })) : (_jsx(Avatar, Object.assign({}, stringAvatar(name), { sx: { borderRadius: 1.2 } }))), variant: "outlined", deleteIcon: mentionCount ? (_jsx(TotalCountBadge, { name: name, count: mentionCount, onlyOtherMentionCount: true })) : undefined, onDelete: mentionCount ? () => { } : undefined }));
};
export const ItemChip = React.memo(ItemChipComponent);
