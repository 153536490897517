import { OnboardingStepsEnum, checkExtensionStatus, tutorials, useTabFocus } from "@recall/common"
import { FINISHED_ONBOARDING_STEP } from "constants/events"
import { isEqual, uniq } from "lodash"
import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { captureEventService } from "services/captureEventService"
import { isBookmarksImportingFinished } from "storage/redux/app/selectors"
import { RootState } from "storage/redux/rootReducer"
import { setOnboarding } from "storage/redux/user/actions"

export const useCompleteOnboarding = () => {
    const completedTutorials = useSelector((state: RootState) => state.user.tutorial.completed)
    const onboarding = useSelector((state: RootState) => state.user.onboarding)
    const isImportingBookmarksFinished = useSelector(isBookmarksImportingFinished)

    const dispatch = useDispatch()

    const completeInstallExtensionOnboarding = useCallback(async () => {
        const { isExtensionInstalled } = await checkExtensionStatus()

        if (!isExtensionInstalled) return

        completeOnboardingStep([OnboardingStepsEnum.INSTALL_EXTENSION])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onboarding])

    useTabFocus(completeInstallExtensionOnboarding)

    const completeOnboardingStep = async (steps: OnboardingStepsEnum[]) => {
        const { finished, skipped } = onboarding

        const skippedSteps = skipped.filter((skippedStep) =>
            steps.every((step) => skippedStep !== step)
        )
        const finishedSteps = uniq([...finished, ...steps])

        if (
            isEqual(finishedSteps, onboarding.finished) &&
            isEqual(skippedSteps, onboarding.skipped)
        )
            return

        const onboardingSteps = {
            finished: finishedSteps,
            skipped: skippedSteps,
        }

        for (const step of steps)
            captureEventService.capture(FINISHED_ONBOARDING_STEP, {
                step,
                isSkipped: skipped.includes(step),
            })

        dispatch(setOnboarding(onboardingSteps))
    }

    const completeTutorialOnboardings = async (finishedTutorials: string[]) => {
        const finishedOnboardingSteps = []
        if (finishedTutorials.includes(tutorials.CREATE_LINK))
            finishedOnboardingSteps.push(OnboardingStepsEnum.CREATE_LINK)
        if (finishedTutorials.includes(tutorials.ITEMS_INTRO))
            finishedOnboardingSteps.push(OnboardingStepsEnum.CREATE_CARD)
        if (finishedTutorials.includes(tutorials.EXTENSION))
            finishedOnboardingSteps.push(OnboardingStepsEnum.CREATE_CARD_WITH_EXTENSION)
        await completeOnboardingStep(finishedOnboardingSteps)
    }

    useEffect(() => {
        const tutorials = Object.keys(completedTutorials)
        const finishedTutorials = tutorials.filter((tutorial) => completedTutorials[tutorial])

        completeTutorialOnboardings(finishedTutorials)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completedTutorials])

    useEffect(() => {
        if (isImportingBookmarksFinished)
            completeOnboardingStep([OnboardingStepsEnum.IMPORT_BOOKMARKS])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isImportingBookmarksFinished])

    useEffect(() => {
        completeInstallExtensionOnboarding()
    }, [completeInstallExtensionOnboarding])
}
