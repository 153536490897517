import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { useHoverDelay } from "../../hooks/useHoverDelay";
import { useIsMobile } from "../../hooks/useIsMobile";
import { ItemChip } from "../chips";
import { ItemPreviewPopper } from "./ItemPreviewPopper";
const HOVER_DELAY = 250;
export const Link = ({ item, connectionId, readOnly = false, openItemById, handleDelete, }) => {
    const isMobile = useIsMobile();
    const { isOpen, handleMouseEnter, handleMouseLeave } = useHoverDelay(HOVER_DELAY);
    const handleOnClick = () => {
        openItemById(item.id);
    };
    const deleteConnection = async () => {
        handleDelete(connectionId);
    };
    return (_jsx(Box, Object.assign({ sx: { p: 0.2, display: "inline-block" }, id: item.id, onMouseEnter: isMobile ? undefined : handleMouseEnter, onMouseLeave: handleMouseLeave }, { children: _jsx(ItemPreviewPopper, Object.assign({ readOnly: readOnly, item: item, open: isOpen, openItemById: openItemById, deleteConnection: deleteConnection }, { children: _jsx(ItemChip, { size: "medium", id: item.id, name: item.name, image: item.image, mentionCount: item.mentionsCount, handleClick: isMobile ? handleMouseEnter : handleOnClick }) })) })));
};
