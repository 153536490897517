import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { isTutorialActive } from "storage/redux/user/selector"
import { useOnboardingProgress } from "./useOnboardingProgress"

export const useIsOnboardingBannerClosed = () => {
    const { isCompleted } = useOnboardingProgress()
    const isTutorialInProgress = useSelector(isTutorialActive)
    const isOnboardingBannerVisible = useSelector(
        (state: RootState) => state.user.isOnboardingBannerVisible
    )
    const isClosed = !isOnboardingBannerVisible || isCompleted || isTutorialInProgress

    return isClosed
}
