import { Database } from "@nozbe/watermelondb"
import {
    assetRepository,
    connectionRepository,
    firebase,
    isDevelopment,
    itemRepository,
    sentry,
    sourceRepository,
    syncRealTimeDb,
    tagRepository,
} from "@recall/common"
import { initializeRootTag } from "utils/config"
import { executeInOneTabOnly } from "utils/locks"
import { migrate } from "../dataMigrations"

import { fixMigration34Issue } from "./migrations"

export const SYNC_TIME_MS = 2000

interface SyncingParams {
    db: Database
    uid: string
    handleFinish: Function
    handlePushCallback?: Function
    handlePullCallback?: Function
    isInitial?: boolean
}

const deleteStaleModels = async (db: Database) => {
    await sourceRepository.deleteStale(db)
    await connectionRepository.deleteStale(db)
    await itemRepository.deleteUnsaved(db)
    await itemRepository.deleteStale(db)
    await assetRepository.deleteStale(db)
    await tagRepository.deleteStaleByDate(db)
}

export const syncDatabase = async ({
    db,
    uid,
    handleFinish = () => {},
    handlePullCallback,
    handlePushCallback,
    isInitial = false,
}: SyncingParams) => {
    try {
        if (!firebase.auth.currentUser) return

        if (!isDevelopment()) {
            await syncRealTimeDb(db, uid, handlePushCallback, handlePullCallback)
        }

        if (isInitial) {
            await initializeRootTag(db)
            await deleteStaleModels(db)
            await executeInOneTabOnly(async () => await migrate(db), "migrations")
            await fixMigration34Issue(db, uid, handlePushCallback, handlePullCallback)
        }
    } catch (err) {
        sentry.captureException(err as Error)
    } finally {
        handleFinish()
    }
}
