import { logEvent } from "@firebase/analytics";
import { firebase } from "./firebaseServices";
const log = async (eventName, properties) => {
    const analytics = firebase.getAnalytics();
    if (!analytics)
        return;
    logEvent(analytics, eventName.replace(" ", "_"), properties);
};
export const googleAnalyticsService = {
    log,
};
