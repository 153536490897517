import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { Fragment } from "react";
export const MentionText = ({ textBlocks, handleClick }) => {
    return (_jsxs(Box, Object.assign({ sx: styles.mention, className: "mention", onClick: handleClick }, { children: [_jsx(Box, Object.assign({ mr: 1, ml: 0.5, mt: 1 }, { children: _jsx(Box, { sx: styles.dot }) })), _jsx(Typography, Object.assign({ variant: "caption", component: "p", sx: styles.text }, { children: textBlocks.map(({ text, isHighlighted }) => (_jsx(Fragment, { children: isHighlighted ? (_jsx(Box, Object.assign({ component: "b", sx: styles.mentionText }, { children: text }))) : (text) }, text + isHighlighted))) }))] })));
};
const styles = {
    mention: {
        borderRadius: 1,
        display: "flex",
        mb: 0.5,
        ":hover": {
            background: (theme) => theme.palette.action.hover,
        },
    },
    text: {
        opacity: 0.8,
        fontSize: "0.75em",
    },
    mentionText: {
        opacity: 1,
    },
    dot: {
        p: 0.2,
        bgcolor: (theme) => theme.palette.text.primary,
        borderRadius: "50%",
    },
};
