import { KEYS_HEADING } from "@udecode/plate-heading";
import { ELEMENT_LI } from "@udecode/plate-list";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import { v4 as uuidv4 } from "uuid";
import { MAX_DESCRIPTION_LENGTH } from "../../constants/editor";
import { YOUTUBE_TIMESTAMP } from "../../editor/plugins/youtube-timestamp";
import { toEditorBlock } from "../../storage/watermelon/helpers/editorBlocks";
import { getOrderedModels } from "../../storage/watermelon/services/editorBlocks";
import { ImageBlockData } from "./ImageBlockData";
export const EditorBlockData = {
    unwrapReference(referenceBlock) {
        referenceBlock.text = referenceBlock.children[0].text;
        delete referenceBlock.type;
        delete referenceBlock.children;
        delete referenceBlock.connectionId;
    },
    removeStaleReferences(editorBlocks, connectionIds) {
        for (let child of editorBlocks) {
            if (!(child === null || child === void 0 ? void 0 : child.id))
                continue;
            this.removeStaleReference(child.children, connectionIds, child.id);
        }
    },
    removeStaleReference(editorBlocks, connectionIds, editorBlockId) {
        for (let child of editorBlocks) {
            if (!child)
                continue;
            if ("connectionId" in child) {
                const connectionId = child.connectionId;
                child.editorBlockId = editorBlockId;
                if (!connectionIds.includes(connectionId)) {
                    this.unwrapReference(child);
                }
            }
            else if ("children" in child) {
                this.removeStaleReference(child.children, connectionIds, editorBlockId);
            }
        }
    },
    hasReference(editorBlock, connectionId) {
        if (editorBlock.connectionId === connectionId) {
            return true;
        }
        else if (editorBlock.children) {
            for (let child of editorBlock.children) {
                if (!child)
                    continue;
                const hasReference = this.hasReference(child, connectionId);
                if (hasReference === true) {
                    return hasReference;
                }
            }
        }
        return false;
    },
    getText(editorBlocks, maxLength = MAX_DESCRIPTION_LENGTH) {
        let text = "";
        for (let child of editorBlocks) {
            if (!child || (child === null || child === void 0 ? void 0 : child.type) === YOUTUBE_TIMESTAMP)
                continue;
            if (child.children) {
                text += " " + this.getText(child.children, maxLength);
            }
            else {
                text += " " + child.text;
            }
            if (maxLength && text.length > maxLength) {
                return text;
            }
        }
        return text.trim();
    },
    getTextWithoutHeadings(editorBlocks, maxLength = MAX_DESCRIPTION_LENGTH) {
        let text = "";
        for (let child of editorBlocks) {
            if (!child || KEYS_HEADING.includes(child === null || child === void 0 ? void 0 : child.type) || (child === null || child === void 0 ? void 0 : child.type) === YOUTUBE_TIMESTAMP)
                continue;
            if ([ELEMENT_PARAGRAPH, ELEMENT_LI].includes(child === null || child === void 0 ? void 0 : child.type)) {
                text += " ";
            }
            if (child.children) {
                text += this.getTextWithoutHeadings(child.children, maxLength);
            }
            else {
                text += child.text;
            }
            if (maxLength && text.length > maxLength) {
                return text.trim();
            }
        }
        return text.trim();
    },
    getFormattedText(editorBlocks) {
        const elements = this.getTextElements(editorBlocks);
        return elements.map(({ text }) => text).join("");
    },
    getTextElements(editorBlocks, endsWithDot = false) {
        let texts = [];
        editorBlocks.forEach((child, index) => {
            var _a, _b, _c;
            if ((child === null || child === void 0 ? void 0 : child.type) === YOUTUBE_TIMESTAMP) {
                return;
            }
            if (child.connectionId) {
                let text = ((_a = child === null || child === void 0 ? void 0 : child.children) === null || _a === void 0 ? void 0 : _a.length) ? this.getText(child.children, null) : "";
                text = !Boolean(text) && Boolean(child.text) ? child.text : text;
                texts = [
                    ...texts,
                    {
                        text: endsWithDot && index === 0 ? " " + text : text,
                        connectionId: child.connectionId,
                    },
                ];
            }
            else if (child.children) {
                const isLastEndOfSentence = texts.length > 0 ? (_c = (_b = texts[texts.length - 1]) === null || _b === void 0 ? void 0 : _b.text) === null || _c === void 0 ? void 0 : _c.endsWith(".") : false;
                texts = [...texts, ...this.getTextElements(child.children, isLastEndOfSentence)];
            }
            else {
                texts = [...texts, { text: (endsWithDot && index === 0 ? " " : "") + child.text }];
            }
        });
        return texts;
    },
    getFirstImageBlock(editorBlocks) {
        for (let editorBlock of editorBlocks) {
            if (!editorBlock)
                continue;
            if (editorBlock.type === "image" || editorBlock.type === "img") {
                return editorBlock;
            }
            for (let child of editorBlock.children) {
                if (!child)
                    continue;
                if (child.type === "image" || child.type === "img") {
                    return child;
                }
            }
        }
    },
    getImage(editorBlocks) {
        var _a;
        const imageBlock = EditorBlockData.getFirstImageBlock(editorBlocks);
        let image = "";
        if (imageBlock) {
            image =
                (imageBlock === null || imageBlock === void 0 ? void 0 : imageBlock.url) ||
                    ((_a = imageBlock === null || imageBlock === void 0 ? void 0 : imageBlock.options) === null || _a === void 0 ? void 0 : _a.url) ||
                    ImageBlockData.getUrl320(imageBlock) ||
                    "";
        }
        return image;
    },
    getOrdered(editorBlocks, editorOrder) {
        const editorBlockModels = getOrderedModels(editorBlocks, editorOrder);
        return editorBlockModels.map((editorBlock) => toEditorBlock(editorBlock));
    },
    create(text = "", type = "p") {
        return {
            id: uuidv4(),
            type,
            children: [{ text }],
        };
    },
};
