import { Box } from "@mui/material"
import { LoadingSkeleton, getTheme } from "@recall/common"
import { Onboarding } from "components/layouts/components/Onboarding/Onboarding"
import { Modals } from "components/shared/modals/Modals"
import { FC, PropsWithChildren, memo } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { DRAWER_WIDTH } from "../../constants"
import { useItemBreakpoints } from "../../hooks/useItemBreakpoints"
import { Drawer } from "./components/Drawer/Drawer"
import { AppLoader } from "./components/Loader/AppLoader"
import { Survey } from "./components/Survey/Survey"
import { useIsSurveyCompleted } from "./components/Survey/hooks/useIsSurveyCompleted"
import { Tutorial } from "./components/Tutorial/Tutorial"

const PageLayout: FC<PropsWithChildren> = ({ children }) => {
    const isAppLoading = useSelector((state: RootState) => state.app.appLoading)

    if (isAppLoading) return <AppLoader />

    return (
        <Box display="flex" position="relative">
            <Drawer />
            <Modals />
            <PageContent>{children}</PageContent>
        </Box>
    )
}

const PageContent = memo(({ children }: PropsWithChildren) => {
    const drawerOpen = useSelector((state: RootState) => state.drawer.open)
    const loading = useSelector((state: RootState) => state.app.loading)
    const { itemBp } = useItemBreakpoints()
    const { isLoading, shouldShowSurvey } = useIsSurveyCompleted()

    return (
        <Box component="main" sx={{ ...styles.content, ml: drawerOpen && `${DRAWER_WIDTH}px` }}>
            {!loading && !isLoading && (shouldShowSurvey ? <Survey /> : <Tutorial />)}
            {!loading && <Onboarding />}
            <Box sx={styles.container}>
                {loading ? (
                    <Box sx={styles.padding}>
                        <LoadingSkeleton breakpoints={itemBp} />
                    </Box>
                ) : (
                    <>
                        <Box sx={styles.padding}>{children}</Box>
                    </>
                )}
            </Box>
        </Box>
    )
})

export default PageLayout

const theme = getTheme("dark")

const styles = {
    container: {
        position: "relative",
        flex: 1,
        backgroundColor: (theme) => theme.palette.background.default,
        p: 0,
    },
    padding: {
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(1, 2),
        },
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },

    content: {
        flexGrow: 1,
        p: 0,
        transition: (theme) =>
            theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        position: "relative",
    },
    contentShift: {
        [theme.breakpoints.up("sm")]: {
            ml: `${DRAWER_WIDTH}px`,
        },
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: (theme) => theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        minHeight: 64,
    },
}
