import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Article, Google } from "@mui/icons-material";
import { Avatar, Box, Divider, Typography } from "@mui/material";
import { truncate } from "lodash";
import { memo, useEffect, useState } from "react";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { isGoogleSearchUrl } from "../../../utils";
export const SummarizeSearchResult = memo(({ url, getSummaryPreview }) => {
    const [result, setResult] = useState(null);
    const isMobile = useIsMobile();
    useEffect(() => {
        getResult();
    }, []);
    const getResult = async () => {
        try {
            const result = await getSummaryPreview(url);
            setResult(result);
        }
        catch (err) {
            setResult({ title: "", image: "", description: "" });
        }
    };
    if (!(result === null || result === void 0 ? void 0 : result.title))
        return _jsx(SummarizeSearchResultDefault, { url: url });
    return (_jsxs(Box, Object.assign({ width: "100%" }, { children: [_jsxs(Typography, Object.assign({ variant: "body2", display: "flex", alignItems: "center", justifyContent: "center" }, { children: [isMobile ? "Press" : "Click", " to summarize"] })), _jsx(Divider, {}), _jsxs(Box, Object.assign({ display: "flex", alignItems: "center", mt: 1, mb: 0.5, gap: 1, width: "100%" }, { children: [_jsx(Avatar, Object.assign({ src: isGoogleSearchUrl(url) ? undefined : (result === null || result === void 0 ? void 0 : result.image) || undefined, variant: "square", sx: {
                            width: 90,
                            height: 51,
                            borderRadius: 0.5,
                            backgroundColor: "action.hover",
                        } }, { children: isGoogleSearchUrl(url) ? (_jsx(Google, { fontSize: "large", sx: { color: "text.primary" } })) : (_jsx(Article, { fontSize: "large", sx: { color: "text.primary" } })) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "body2", fontWeight: 600, sx: { lineHeight: 1.1 } }, { children: truncate(result === null || result === void 0 ? void 0 : result.title, { length: 60 }) })), (result === null || result === void 0 ? void 0 : result.description) && (_jsx(Typography, Object.assign({ variant: "caption", sx: { lineHeight: 1.1 }, mt: 0.5, component: "p" }, { children: truncate(result === null || result === void 0 ? void 0 : result.description, { length: 60 }) })))] })] }))] })));
});
const SummarizeSearchResultDefault = ({ url }) => {
    const isMobile = useIsMobile();
    return (_jsxs(Box, Object.assign({ width: "100%" }, { children: [_jsxs(Typography, Object.assign({ variant: "body2", display: "flex", alignItems: "center", justifyContent: "center" }, { children: [isMobile ? "Press" : "Click", " to summarize"] })), _jsx(Divider, {}), _jsxs(Box, Object.assign({ display: "flex", alignItems: "center", py: 1, gap: 1, width: "100%" }, { children: [_jsx(Article, {}), _jsx(Typography, Object.assign({ pl: 1 }, { children: truncate(url, { length: 40, omission: "..." }) }))] }))] })));
};
