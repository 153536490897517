import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    List,
    ListItem,
    ListItemButton,
    SxProps,
    Theme,
    Typography,
    alpha,
} from "@mui/material"
import { EditorBlockData, EditorBlockModel, ItemModel, ItemPartial, sentry } from "@recall/common"
import { useOpenItem } from "hooks/items/useOpenItem"
import { FC, Fragment, memo, useEffect, useState } from "react"

interface Props {
    item: ItemModel
    connectionId: string
}

export const Mention: FC<Props> = memo(({ item, connectionId }) => {
    const [itemData, setItemData] = useState<ItemPartial | null>(null)
    const [editorBlocks, setEditorBlocks] = useState<EditorBlockModel[]>([])
    const { openItem } = useOpenItem()

    const getLinkItemAndCount = async () => {
        try {
            const itemPartial = await item.toItemPartial()
            const itemEditorBlocks = await item.editorBlocks.fetch()

            const mentionedEditorBlocks = itemEditorBlocks.filter((editorBlock) =>
                JSON.stringify(editorBlock.children).includes(connectionId)
            )

            setEditorBlocks(mentionedEditorBlocks)
            setItemData(itemPartial)
        } catch (error) {
            sentry.captureException(error)
        }
    }

    useEffect(() => {
        getLinkItemAndCount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    const handleClickItem = (e: any) => {
        e.stopPropagation()
        handleOpenItem()
    }

    const handleOpenItem = () => {
        openItem(item)
    }

    const handleClickMention = (editorBlockId: string) => {
        const elementId = editorBlockId + connectionId
        openItem(item, { state: { elementId } })
    }

    const hasTextMentions = Boolean(editorBlocks.length)

    const getMentionSentence = (mentions) => {
        const texts = []
        let accumulator = ""

        for (const mention of mentions) {
            if (mention?.connectionId === connectionId) {
                if (accumulator) {
                    texts.push({ text: accumulator })
                    accumulator = ""
                }

                texts.push(mention)
            } else {
                accumulator += mention.text
            }

            const sentenceEndIndex = accumulator.lastIndexOf(".")

            if (sentenceEndIndex !== -1) {
                if (texts.length > 0) {
                    texts.push({ text: accumulator.slice(0, sentenceEndIndex + 1) })
                    break
                }
                accumulator = accumulator.slice(sentenceEndIndex + 1)
            }
        }
        return texts
    }

    if (!itemData) return null

    return (
        <Accordion sx={styles.container} disableGutters elevation={0}>
            <AccordionSummary
                sx={{
                    minHeight: "0 !important",
                    m: "0 !important",
                    flexDirection: "row-reverse",
                    px: 1,
                    gap: 0.5,
                }}
                expandIcon={
                    hasTextMentions ? (
                        <ArrowForwardIosSharpIcon sx={styles.arrowIcon} />
                    ) : (
                        <Box width={14.4} />
                    )
                }
                onClick={!hasTextMentions ? handleOpenItem : undefined}
            >
                <Box sx={styles.mentionHeader}>
                    <Box sx={styles.itemContent} onClick={handleClickItem}>
                        {itemData?.images[0] && (
                            <Box pr={1}>
                                <Avatar
                                    sx={{
                                        width: 25,
                                        height: 25,
                                        bgcolor: (theme) => theme.palette.text.secondary,
                                    }}
                                    src={itemData.images[0].url || itemData.images[0].urlThumbnail}
                                />
                            </Box>
                        )}
                        <Typography variant="body2" mr={1} fontWeight={500}>
                            {item.name}
                        </Typography>
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
                <List
                    sx={{
                        p: 0,
                        pb: 1,
                    }}
                >
                    {editorBlocks.map((editorBlock) => {
                        const mentions = EditorBlockData.getTextElements([editorBlock])

                        return (
                            <ListItem sx={{ p: 0 }} key={editorBlock.id}>
                                <ListItemButton
                                    sx={{ py: 0.3, pl: 3, alignItems: "flex-start" }}
                                    onClick={() => handleClickMention(editorBlock.id)}
                                >
                                    <Typography variant="body2" ml={1.5}>
                                        {getMentionSentence(mentions).map(
                                            ({ text, connectionId: mentionId }, index) => (
                                                <Fragment key={text + mentionId + index}>
                                                    {mentionId === connectionId ? (
                                                        <Box
                                                            component="b"
                                                            sx={{
                                                                opacity: 0.9,
                                                                color: (theme) =>
                                                                    theme.palette.mode === "dark"
                                                                        ? "secondary.main"
                                                                        : "primary",
                                                            }}
                                                        >
                                                            {text}
                                                        </Box>
                                                    ) : (
                                                        text
                                                    )}
                                                </Fragment>
                                            )
                                        )}
                                    </Typography>
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                </List>
            </AccordionDetails>
        </Accordion>
    )
})

const styles: Record<string, SxProps<Theme>> = {
    container: {
        m: "0px !important",
        p: 0,
        position: "relative",
        background: (theme) => theme.palette.background.default,
        "& .MuiAccordionSummary-contentGutters": {
            my: 0,
        },
        "& .MuiAccordionSummary-content": {
            m: 0,
        },
        ":not(:nth-child(1))::before": {
            opacity: "1 !important",
            display: "block !important",
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
        },
    },
    mentionHeader: {
        display: "flex",
        alignItems: "center",
        py: 0.8,
    },
    itemContent: {
        display: "flex",
        alignItems: "center",
        px: 0.5,
        py: 0.2,
        "&:hover": {
            cursor: "pointer",
            backgroundColor: (theme) => theme.palette.action.hover,
            borderRadius: 1,
        },
    },
    arrowIcon: {
        fontSize: "0.9rem",
        color: (theme) => alpha(theme.palette.text.primary, 0.5),
        "&:hover": {
            color: (theme) => alpha(theme.palette.text.primary, 0.8),
        },
    },
}
