import { deleteDoc, doc, getDoc, writeBatch } from "firebase/firestore";
import { orderBy } from "lodash";
import { extensionItemsRepository, } from "../repositories/extensionItemsRepository";
import { createAssets } from "../storage/watermelon/helpers/assets";
import { itemRepository } from "../storage/watermelon/repository";
import { itemService } from "../storage/watermelon/services/itemService";
import { isWikipediaUrl, sentry } from "../utils";
import { firebase } from "./firebaseService";
const RERENDER_TAGS_EVENT = "rerender-tags-event";
const deleteExtensionItems = async (extensionItems, uid) => {
    const batch = writeBatch(firebase.firestore);
    const extensionItemRefs = [];
    for (const extensionItem of extensionItems) {
        const extensionItemRef = doc(firebase.firestore, "users", uid, "extension_items", extensionItem.id);
        const extensionItemDoc = await getDoc(extensionItemRef);
        if (!extensionItemDoc.exists())
            throw "Document already processing";
        extensionItemRefs.push(extensionItemRef);
    }
    for (const extensionItemRef of extensionItemRefs) {
        batch.delete(extensionItemRef);
    }
    batch.commit();
};
const saveExtensionItems = async (db, extensionItems, uid) => {
    var _a, _b, _c;
    for (const extensionItem of extensionItems) {
        try {
            if (!extensionItem.expand) {
                const item = await itemService.saveItemApi(db, extensionItem.itemApi, true, {
                    id: extensionItem.itemId,
                });
                await item.setCreatedAt(extensionItem.createdAt);
                await item.setUpdatedAt(extensionItem.createdAt);
                if (extensionItem.url && isWikipediaUrl(extensionItem.url)) {
                    await item.setIsExpanded(true);
                }
                await createAssets(db, item, uid);
            }
            else {
                const sourceIdentifier = (_c = (_b = (_a = extensionItem.itemApi) === null || _a === void 0 ? void 0 : _a.sources) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.identifier;
                if (!sourceIdentifier)
                    return;
                const item = await itemRepository.getBySource({ db, sourceIdentifier });
                const newItem = await itemService.saveItemApi(db, extensionItem.itemApi, true, {
                    id: extensionItem.itemId,
                });
                if (item)
                    await item.merge(newItem);
                // await createAssets(db, item, uid)
                document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT));
            }
        }
        catch (error) {
            sentry.captureException(error, { url: extensionItem.url, uid });
        }
    }
};
const createExtensionItems = async (extensionItems, uid, db) => {
    const orderedExtensionItems = orderBy(extensionItems, "createdAt", "asc");
    try {
        await deleteExtensionItems(orderedExtensionItems, uid);
        await saveExtensionItems(db, extensionItems, uid);
    }
    catch (error) {
        console.error("Transaction failed: ", error);
    }
};
const removeExtensionItem = async (uid, pageUrl, language, summaryLength) => {
    const extensionItems = await extensionItemsRepository.getExtensionItems(uid);
    for (const extensionItem of extensionItems) {
        const { url, itemApi } = extensionItem;
        const isExtensionItem = url === pageUrl &&
            itemApi.language === language &&
            itemApi.summaryLength === summaryLength;
        if (isExtensionItem) {
            const extensionItemRef = doc(firebase.firestore, "users", uid, "extension_items", extensionItem.id);
            await deleteDoc(extensionItemRef);
        }
    }
};
export const extensionItemService = { createExtensionItems, removeExtensionItem };
