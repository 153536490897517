import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { Box, SxProps, Theme, Typography } from "@mui/material"
import { BoldButton } from "components/shared/buttons/BoldButton"
import { FC } from "react"

interface Props {
    handleContinue: () => void
}

export const IntroductionStep: FC<Props> = ({ handleContinue }) => {
    return (
        <>
            <Typography variant="h3" fontWeight={600} textAlign="center">
                Welcome to Recall
            </Typography>
            <Typography variant="h6" textAlign="center">
                Get started by watching this video
            </Typography>
            <Box
                sx={styles.video}
                component="iframe"
                src="https://www.youtube.com/embed/ImS-Ry44Uz8"
                title="Recall Product Overview"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                frameBorder="0"
            />
            <BoldButton onClick={handleContinue} endIcon={<NavigateNextIcon />} fullWidth>
                I've watched the video
            </BoldButton>
        </>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    video: {
        borderRadius: 1,
        width: { xs: "100%", md: "640px" },
        height: { md: "400px" },
    },
}
