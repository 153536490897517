import { Box, Tooltip, Typography } from "@mui/material"
import { withObservables } from "@nozbe/watermelondb/react"
import { TagChip, TagModel } from "@recall/common"
import { orderBy } from "lodash"
import { FC } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"

interface Props {
    tags: TagModel[]
    max?: number
}

const TagsComponent: FC<Props> = ({ tags, max = 3 }) => {
    const selectedTagIds = useSelector((state: RootState) => state.drawer.selectedTagIds)
    const orderedTags = orderBy(tags, ({ id }) => selectedTagIds.includes(id), "desc")
    const activeTags = orderedTags.slice(0, max)

    const tagsLeft = orderedTags.length - activeTags.length

    const getShortenName = (name: string, length: number) => {
        return name.length > length ? name.slice(0, length - 3).trim() + "..." : name
    }

    const getTagName = (name: string) => {
        if (activeTags.length === max - 2) {
            return getShortenName(name, 30)
        }
        if (activeTags.length === max - 1) {
            return getShortenName(name, 15)
        }

        return getShortenName(name, 9)
    }

    return (
        <Box display="flex" flexWrap="wrap" gap={0.4} alignItems="center">
            {activeTags.map((tag) => (
                <Tooltip key={tag.id} title={getTagName(tag.name) === tag.name ? null : tag.name}>
                    <Box>
                        <TagChip
                            label={getTagName(tag.name)}
                            selected={selectedTagIds.includes(tag.id)}
                        />
                    </Box>
                </Tooltip>
            ))}
            {tagsLeft > 0 && (
                <Box onClick={(e) => e.stopPropagation()}>
                    <Tooltip
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    bgcolor: "grey.800",
                                    "& .MuiTooltip-arrow": {
                                        color: "grey.800",
                                    },
                                },
                            },
                        }}
                        title={
                            <Box display="flex" gap={0.4} flexWrap="wrap">
                                {orderedTags.slice(3).map((tag) => (
                                    <TagChip
                                        key={tag.id}
                                        label={tag.name}
                                        selected={selectedTagIds.includes(tag.id)}
                                    />
                                ))}
                            </Box>
                        }
                    >
                        <Typography ml={0.5} variant="caption">
                            +{tagsLeft}
                        </Typography>
                    </Tooltip>
                </Box>
            )}
        </Box>
    )
}

const enhance = withObservables(["tags"], ({ tags }) => ({
    tags,
}))

export const Tags = enhance(TagsComponent)
