export const useItemBreakpoints = () => {
    let itemBp = {
        xs: 12,
        lg: 10,
        xl: 8,
    }

    let itemPartialBp = {
        xs: 12,
        md: 6,
        lg: 6,
        xl: 4,
    }

    return {
        itemPartialBp,
        itemBp,
    }
}
