import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, IconButton } from "@mui/material";
const getBarProperty = (emojiLibrary, focusedCategory) => {
    let width = 0;
    let position = 0;
    if (focusedCategory) {
        width = 100 / emojiLibrary.getGrid().size;
        position = focusedCategory ? emojiLibrary.indexOf(focusedCategory) * 100 : 0;
    }
    return { width, position };
};
export const EmojiPcikerNavigation = ({ i18n, icons, emojiLibrary, focusedCategory, onClick, }) => {
    const { width, position } = getBarProperty(emojiLibrary, focusedCategory);
    return (_jsxs(Box, Object.assign({ display: "flex", position: "relative", justifyContent: "space-around" }, { children: [emojiLibrary
                .getGrid()
                .sections()
                .map(({ id }) => (_jsx(IconButton, Object.assign({ "aria-label": i18n.categories[id], title: i18n.categories[id], onClick: () => onClick(id), size: "small" }, { children: _jsx(Box, Object.assign({ sx: {
                        fill: (theme) => focusedCategory === id
                            ? theme.palette.secondary.main
                            : theme.palette.text.primary,
                        width: 20,
                        height: 20,
                    } }, { children: icons.categories[id].outline })) }), id))), _jsx(Box, { sx: {
                    position: "absolute",
                    bottom: -3,
                    left: 0,
                    height: 3,
                    visibility: `${focusedCategory ? "visible" : "hidden"}`,
                    width: `${width}%`,
                    transform: `translateX(${position}%)`,
                    background: (theme) => theme.palette.secondary.main,
                    transition: "transform 200ms",
                    borderRadius: 1.5,
                } })] })));
};
