import { orderBy, uniqBy } from "lodash";
import { mentionsService } from "../../../services/links/mentionsService";
export const getGroupedLinks = async (connections) => {
    const links = await getLinks(connections);
    const groupedLinks = await groupByTags(links);
    const sortedGroupLinks = sortLinksByMentionsCount(groupedLinks);
    const sortedGroups = sortGroupsAlphabetically(sortedGroupLinks);
    return sortedGroups;
};
const getLinks = async (connections) => {
    return (await Promise.all(connections
        .map(async (connection) => {
        if (!connection.to.id)
            return null;
        const item = await connection.to;
        if (!item)
            return null;
        const link = {
            id: connection.id,
            item,
        };
        return link;
    })
        .filter(Boolean)));
};
const getReferenceItem = async (item, connectionId) => {
    const mentions = await item.mentions;
    const otherMentions = mentions.filter(({ id, isSaved }) => isSaved && id !== connectionId);
    const mentionItems = await mentionsService.getByConnections(otherMentions);
    return {
        isSaved: item.isSaved,
        id: item.id,
        name: item.name,
        description: item.description,
        image: item.image,
        mentionsCount: otherMentions.length,
        mentions: mentionItems,
    };
};
const groupByTags = async (links) => {
    const linksTags = await Promise.all(links.map((link) => link.item.tags.fetch()));
    const groupedLinks = {};
    linksTags.flat().forEach((tag) => {
        groupedLinks[tag.name] = [];
    });
    for (const [index, link] of links.entries()) {
        const tags = uniqBy(linksTags[index], "name");
        const item = await getReferenceItem(link.item, link.id);
        const groupedLink = Object.assign(Object.assign({}, link), { item });
        if (!tags.length) {
            groupedLinks[""] = groupedLinks[""] || [];
            groupedLinks[""].push(groupedLink);
            continue;
        }
        for (const tag of tags) {
            groupedLinks[tag.name].push(groupedLink);
        }
    }
    return groupedLinks;
};
const sortLinksByMentionsCount = (groupedLinks) => {
    return Object.keys(groupedLinks).map((groupKey) => {
        const links = groupedLinks[groupKey];
        const orderedLinks = orderBy(links, "item.mentionsCount", "desc");
        return {
            key: groupKey,
            value: orderedLinks,
        };
    });
};
const sortGroupsAlphabetically = (linkGroups) => {
    return linkGroups.sort((a, b) => {
        if (a.key === "" || b.key === "")
            return a.key === "" ? -1 : 1;
        const lengthCompare = b.value.length - a.value.length;
        if (lengthCompare === 0)
            return a.key.localeCompare(b.key);
        return lengthCompare;
    });
};
