import { emojiTriggeringControllerOptions } from "@udecode/plate-emoji";
export class EmojiTriggeringController {
    constructor(options = emojiTriggeringControllerOptions) {
        this.options = options;
        this._isTriggering = false;
        this._hasTriggeringMark = false;
        this.text = "";
    }
    get isTriggering() {
        return this._isTriggering;
    }
    setIsTriggering(isTriggering) {
        this._isTriggering = isTriggering;
        return this;
    }
    get hasTriggeringMark() {
        return this._hasTriggeringMark;
    }
    hasEnclosingTriggeringMark() {
        return this.endsWithEnclosingMark(this.text);
    }
    setText(text) {
        this._hasTriggeringMark = this.startsWithTriggeringMark(text);
        this.setIsTriggering(this._hasTriggeringMark && text.length > this.options.limitTriggeringChars);
        this.text = this.isTriggering ? text : "";
        return this;
    }
    startsWithTriggeringMark(text) {
        return new RegExp(`^${this.options.trigger}`).test(text);
    }
    endsWithEnclosingMark(text) {
        return new RegExp(`${this.options.trigger}$`).test(text);
    }
    getText() {
        return this.text.replaceAll(/(^:)|(:$)/g, "");
    }
    getTextSize() {
        return this.text.length;
    }
    reset() {
        this.text = "";
        this.setIsTriggering(false);
        this._hasTriggeringMark = false;
        return this;
    }
}
