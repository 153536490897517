import { tutorials } from "@recall/common"
import { memo } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { TutorialAdd } from "./TutorialAdd"
import { TutorialCreateLink } from "./TutorialCreateLink"
import { TutorialExpand } from "./TutorialExpand"
import { TutorialItemIntro } from "./TutorialItemIntro"
import { TutorialItemsIntro } from "./TutorialItemsIntro"
import { useActivateTutorial } from "./hooks/useActivateTutorial"
import { useCompleteTutorial } from "./hooks/useCompleteTutorial"
import { useOpenTutorial } from "./hooks/useOpenTutorial"

const TutorialComponent = () => {
    const activeTutorial = useSelector((state: RootState) => state.user.tutorial.active)

    useCompleteTutorial()
    useActivateTutorial()
    useOpenTutorial()

    if (activeTutorial === tutorials.ITEMS_INTRO) return <TutorialItemsIntro />
    if (activeTutorial === tutorials.ITEM_INTRO) return <TutorialItemIntro />
    if (activeTutorial === tutorials.EXPAND_INTRO) return <TutorialExpand />
    if (activeTutorial === tutorials.CREATE_LINK) return <TutorialCreateLink />
    if (activeTutorial === tutorials.ADD_CARD) return <TutorialAdd />

    return null
}

export const Tutorial = memo(TutorialComponent)
