import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { FIRESTORE_COLLECTIONS } from "../constants";
import { firebase } from "../services";
import { isYoutubeVideo, sentry } from "../utils";
const getLibraryItemApi = async (id) => {
    try {
        const libraryItemRef = doc(firebase.firestore, FIRESTORE_COLLECTIONS.LIB_ITEMS, id);
        const snapshot = await getDoc(libraryItemRef);
        if (!snapshot.exists())
            return null;
        const libraryItem = snapshot.data();
        return (libraryItem === null || libraryItem === void 0 ? void 0 : libraryItem.itemApi) || null;
    }
    catch (e) {
        const err = e;
        sentry.captureException(err);
        return null;
    }
};
const getLibraryItemByVideoId = async (videoId) => {
    try {
        const libraryCollectionRef = collection(firebase.firestore, FIRESTORE_COLLECTIONS.LIB_ITEMS);
        const querySnapshot = await getDocs(query(libraryCollectionRef, where("videoId", "==", videoId)));
        if (querySnapshot.empty)
            return null;
        const libraryItem = querySnapshot.docs[0].data();
        return libraryItem || null;
    }
    catch (e) {
        const err = e;
        sentry.captureException(err);
        return null;
    }
};
const getLibraryItemByUrl = async (url) => {
    try {
        const libraryCollectionRef = collection(firebase.firestore, FIRESTORE_COLLECTIONS.LIB_ITEMS);
        const querySnapshot = await getDocs(query(libraryCollectionRef, where("url", "==", url)));
        if (querySnapshot.empty)
            return null;
        const libraryItem = querySnapshot.docs[0].data();
        return libraryItem || null;
    }
    catch (e) {
        const err = e;
        sentry.captureException(err);
        return null;
    }
};
const getLibraryItemByUrlOrVideoId = async (url) => {
    try {
        if (isYoutubeVideo(url)) {
            const urlParams = new URLSearchParams(new URL(url).search);
            const videoId = urlParams.get("v");
            if (!videoId)
                return null;
            const libraryItem = await getLibraryItemByVideoId(videoId);
            return libraryItem;
        }
        else {
            const libraryItem = await getLibraryItemByUrl(url);
            return libraryItem;
        }
    }
    catch (e) {
        sentry.captureException(e, { url });
        return null;
    }
};
export const libraryRepository = {
    getLibraryItemApi,
    getLibraryItemByUrlOrVideoId,
};
