import { ChevronRight } from "@mui/icons-material"
import { Box, Skeleton, SxProps, Theme } from "@mui/material"

export const TagsTreeSkeleton = () => {
    return (
        <Box mt={0.5} px={0.5}>
            <Box ml={0} display="flex">
                <ChevronRight sx={styles.icon} />
                <Skeleton variant="rounded" width={120} height={22} />
            </Box>
            <Box mt={0.5} ml={3} display="flex">
                <ChevronRight sx={styles.icon} />
                <Skeleton variant="rounded" width={120} height={22} />
            </Box>
            <Box mt={0.5} ml={6} display="flex">
                <ChevronRight sx={styles.icon} />
                <Skeleton variant="rounded" width={120} height={22} />
            </Box>
            <Box mt={0.5} ml={6} display="flex">
                <ChevronRight sx={styles.icon} />
                <Skeleton variant="rounded" width={120} height={22} />
            </Box>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    icon: {
        opacity: 0.3,
        fontSize: 18,
        color: (theme) => theme.palette.grey[500],
    },
}
