import Cookies from "js-cookie"

const get = (name: string) => {
    return Cookies.get(name)
}

const remove = (name: string) => {
    Cookies.remove(name)
}

export const cookiesUtil = { get, remove }
