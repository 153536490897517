import { FilterAlt } from "@mui/icons-material"
import { Badge, IconButton, SxProps, Theme, alpha } from "@mui/material"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import { TagsTree } from "components/shared/tags/TagsTree"
import { FC, useState } from "react"

interface Props {
    handleFilterCard: (id: string) => void
    filtered: boolean
    selectedItemId?: string
}

export const KnowledgeGraphFilter: FC<Props> = ({ handleFilterCard, filtered, selectedItemId }) => {
    const [isOpen, setIsOpen] = useState(true)

    const openFilter = () => {
        setIsOpen(true)
    }

    return (
        <Box>
            <IconButton onClick={openFilter} sx={styles.drawerButton}>
                <Badge variant="dot" color="secondary" invisible={!filtered}>
                    <FilterAlt />
                </Badge>
            </IconButton>
            <Drawer
                variant="persistent"
                anchor="left"
                open={isOpen}
                PaperProps={{
                    sx: { background: (theme) => theme.palette.background.layout, width: 300 },
                }}
            >
                <TagsTree
                    selectedItemId={selectedItemId}
                    handleClickItem={handleFilterCard}
                    isSwipeable={false}
                />
            </Drawer>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    results: {
        overflow: "auto",
        overflowY: "scroll",
        height: "100%",
        "&::-webkit-scrollbar": {
            height: 0,
            width: "6px",
        },
        pr: 0.4,

        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "transparent",
        },

        "&:hover::-webkit-scrollbar-thumb": {
            background: (theme) => alpha(theme.palette.grey[500], 0.2),
            "&:hover": {
                background: (theme) => alpha(theme.palette.grey[500], 0.6),
            },
        },
    },
    drawerButton: {
        position: "absolute",
        left: 16,
        bottom: 16,
        background: (theme) => theme.palette.action.disabled,
    },
}
