import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"

export const usePlatform = () => {
    const platform = useSelector((state: RootState) => state.app.platform)

    if (window.platform) return window.platform

    return platform
}
