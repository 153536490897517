import { DialogContent, Typography } from "@mui/material"
import { tutorials } from "@recall/common"
import { ADD_BUTTON_DIV_ID } from "components/ItemPage/components/item/Item"
import React, { memo, useEffect } from "react"
import { Step, TutorialBase, commonTutorialStyles } from "./TutorialBase"

export interface Props {
    goBack: Function
    goNext: Function
}

const AddButtonTutorial = (props: Props) => {
    useEffect(() => {
        const handleOnClick = () => {
            setTimeout(() => {
                props.goNext()
            })
        }

        const button = document.getElementById(ADD_BUTTON_DIV_ID)

        if (button) {
            button.addEventListener("click", handleOnClick)
            return () => button.removeEventListener("click", handleOnClick)
        }
    }, [props])

    return (
        <DialogContent sx={commonTutorialStyles.popoverContent}>
            <Typography sx={commonTutorialStyles.heading} variant={"h4"}>
                Add Card
            </Typography>
            <Typography sx={commonTutorialStyles.body}>
                Clicking this button will add the card to your knowledge base.
            </Typography>
        </DialogContent>
    )
}

const TutorialAddComponent: React.FC<React.PropsWithChildren<unknown>> = () => {
    const steps: Step[] = [
        {
            anchorId: ADD_BUTTON_DIV_ID,
            placement: "bottom",
            showArrow: true,
            component: (props: Props) => <AddButtonTutorial {...props} />,
        },
    ]

    return <TutorialBase tutorialName={tutorials.ADD_CARD} steps={steps} />
}

export const TutorialAdd = memo(TutorialAddComponent)
