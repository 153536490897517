import { collection, getDocs, query, where } from "@firebase/firestore";
import { FIRESTORE_COLLECTIONS } from "../../constants";
import { firebase } from "./firebaseServices";
const LIFETIME_PRICE_ID = "price_1NiAlNKLKMKbaiD6re1deI2Y";
const checkUserSubscriptions = async (userId) => {
    const subscriptionsQuery = query(collection(firebase.firestore, FIRESTORE_COLLECTIONS.USERS, userId, FIRESTORE_COLLECTIONS.SUBSCRIPTIONS), where("status", "==", "active"));
    const subscriptions = await getDocs(subscriptionsQuery);
    return !!subscriptions.docs.length;
};
const checkIsLifetimeUser = async (userId) => {
    const paymentsQuery = query(collection(firebase.firestore, FIRESTORE_COLLECTIONS.USERS, userId, FIRESTORE_COLLECTIONS.PAYMENTS));
    const payments = await getDocs(paymentsQuery);
    for (const paymentDoc of payments.docs) {
        const payment = paymentDoc.data();
        if (!(payment === null || payment === void 0 ? void 0 : payment.items))
            continue;
        const isLifetimePrice = payment.items.some((item) => { var _a, _b; return ((_a = item === null || item === void 0 ? void 0 : item.price) === null || _a === void 0 ? void 0 : _a.active) && ((_b = item === null || item === void 0 ? void 0 : item.price) === null || _b === void 0 ? void 0 : _b.id) === LIFETIME_PRICE_ID; });
        if (isLifetimePrice)
            return true;
    }
    return false;
};
const checkIsPremiumUser = async (userId) => {
    const isSubscribedPremium = await checkUserSubscriptions(userId);
    if (isSubscribedPremium)
        return true;
    return await checkIsLifetimeUser(userId);
};
const checkIsPremiumNotCanceledUser = async (userId) => {
    const subscriptionsQuery = query(collection(firebase.firestore, FIRESTORE_COLLECTIONS.USERS, userId, FIRESTORE_COLLECTIONS.SUBSCRIPTIONS), where("status", "==", "active"), where("cancel_at_period_end", "==", false));
    const subscriptions = await getDocs(subscriptionsQuery);
    return !!subscriptions.docs.length;
};
export const premiumUserService = {
    checkIsPremiumUser,
    checkIsPremiumNotCanceledUser,
};
