import { ChevronRight } from "@mui/icons-material"
import TreeView from "@mui/lab/TreeView"
import { SxProps, Theme } from "@mui/material"
import { Bookmark, checkIsBookmarkUrl } from "@recall/common"
import { isEqual } from "lodash"
import { FC, createContext, memo } from "react"
import { BookmarksTreeItem } from "./BookmarksTreeItem"

export const TreeContext = createContext<{
    selected: Bookmark[]
    addBookmarks: (bookmarks: Bookmark[]) => void
    removeBookmarks: (id: string[]) => void
    isSelected: (id: string) => boolean
    alreadyAddedBookmarkIds: string[]
}>({
    selected: [],
    addBookmarks: (_: Bookmark[]) => {},
    removeBookmarks: (_: string[]) => {},
    isSelected: () => false,
    alreadyAddedBookmarkIds: [],
})

interface Props {
    bookmarks: Bookmark[]
    bookmarksIds: string[]
}

const BookmarksTreeComponent: FC<Props> = ({ bookmarks, bookmarksIds }) => {
    return (
        <TreeView
            defaultExpanded={bookmarksIds}
            defaultCollapseIcon={<ChevronRight sx={styles.collapseIcon} />}
            defaultExpandIcon={<ChevronRight sx={styles.expandIcon} />}
            defaultEndIcon={<div style={{ width: 24 }} />}
            sx={styles.tree}
        >
            {bookmarks?.map((bookmark) =>
                bookmark.isDirectory || checkIsBookmarkUrl(bookmark.url) ? (
                    <BookmarksTreeItem
                        key={bookmark.id}
                        nodeId={bookmark.id}
                        label="Bookmarks"
                        bookmark={bookmark}
                    />
                ) : null
            )}
        </TreeView>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    tree: {
        overflowY: "auto",
        minHeight: 300,
        maxHeight: 450,
        mx: 1,
        p: 1,
        borderRadius: 1,
        backgroundColor: "background.layout",
    },
    expandIcon: {
        transition: "transform 0.1s linear",
    },
    collapseIcon: {
        transform: "rotate(90deg)",
        transition: "transform 0.1s linear",
    },
}

export const BookmarksTree = memo(BookmarksTreeComponent, (prev, next) => isEqual(prev, next))
