import { ROOT_TYPE_ID } from "@recall/common"
import produce from "immer"
import {
    SET_DRAWER_INCLUDE_REFERENCES,
    SET_DRAWER_OPEN,
    SET_DRAWER_TAG_IDS,
    SET_DRAWER_TYPE_ID,
    SET_TYPE_EXPANDED,
} from "./actionTypes"
import { DrawerState } from "./types"

const initialState: DrawerState = {
    open: true,
    openSection: "types",
    typeSection: {
        typeId: ROOT_TYPE_ID,
        inlcudeReferences: false,
        expanded: {},
    },
    selectedTagIds: [],
}

export const drawerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DRAWER_OPEN: {
            const open = action.payload

            const nextState = produce(state, (draftState) => {
                draftState.open = open
            })

            return nextState
        }
        case SET_DRAWER_TYPE_ID: {
            const typeId = action.payload

            const nextState = produce(state, (draftState) => {
                draftState.typeSection.typeId = typeId
            })

            return nextState
        }
        case SET_DRAWER_INCLUDE_REFERENCES: {
            const inlcudeReferences = action.payload

            const nextState = produce(state, (draftState) => {
                draftState.typeSection.inlcudeReferences = inlcudeReferences
            })

            return nextState
        }
        case SET_TYPE_EXPANDED: {
            const { typeId, expand } = action.payload

            const nextState = produce(state, (draftState) => {
                draftState.typeSection.expanded[typeId] = expand
            })

            return nextState
        }
        case SET_DRAWER_TAG_IDS: {
            const nextState = produce(state, (draftState) => {
                draftState.selectedTagIds = action.payload
            })

            return nextState
        }
        default:
            return state
    }
}
