import { Q } from "@nozbe/watermelondb";
import { v4 as uuidv4 } from "uuid";
import { sentry } from "../../../utils/sentry";
import { CONNECTIONS } from "../schema";
import { connectionPropertyRepository } from "./connectionPropertyRepository";
const getAll = async (db) => {
    return await db.collections.get(CONNECTIONS).query().fetch();
};
const get = async (db, id) => {
    try {
        return await db.collections.get(CONNECTIONS).find(id);
    }
    catch (e) {
        sentry.captureException(e);
        return null;
    }
};
const getConnection = async (db, from, to) => {
    try {
        const connections = await db.collections
            .get(CONNECTIONS)
            .query(Q.where("from_id", from), Q.where("to_id", to))
            .fetch();
        return (connections === null || connections === void 0 ? void 0 : connections[0]) || null;
    }
    catch (e) {
        sentry.captureException(e);
        return null;
    }
};
const getItemConnections = (db, itemId) => {
    return db.collections
        .get(CONNECTIONS)
        .query(Q.where("from_id", itemId))
        .fetch();
};
const create = async (db, connection, isSaved = true) => {
    return await db.write(async () => {
        const collection = db.collections.get(CONNECTIONS);
        const existingConnections = await collection
            .query(Q.where("from_id", connection.fromId), Q.where("to_id", connection.toId))
            .fetch();
        if (existingConnections.length)
            return existingConnections[0];
        let propertyModel = null;
        if (connection.property)
            propertyModel = await connectionPropertyRepository.getOrCreate(db, connection.property);
        return await collection.create((record) => {
            record._raw.id = uuidv4();
            record.from.id = connection.fromId;
            record.to.id = connection.toId;
            if (propertyModel)
                record.property.set(propertyModel);
            record.isSaved = isSaved;
        });
    });
};
const deleteConnections = async (db, ids, dispatchEvent = true) => {
    await db.write(async (writer) => {
        let tasks = [];
        for (const id of ids) {
            const connectionModel = await get(db, id);
            if (!connectionModel)
                continue;
            const moreTasks = await connectionModel.prepareDeleteWithStaleItem(dispatchEvent);
            tasks = [...tasks, ...moreTasks];
        }
        await writer.batch(...tasks);
    });
};
const deleteStale = async (db) => {
    await db.write(async (writer) => {
        let connections = await db.collections.get(CONNECTIONS).query().fetch();
        let tasks = [];
        for (const connection of connections) {
            try {
                await connection.to.fetch();
                await connection.from.fetch();
            }
            catch (e) {
                const moreTasks = await connection.prepareDeleteWithStaleItem();
                tasks = [...tasks, ...moreTasks];
            }
        }
        await writer.batch(...tasks);
    });
};
export const connectionRepository = {
    getAll,
    get,
    getConnection,
    getItemConnections,
    create,
    delete: deleteConnections,
    deleteStale,
};
