import { ELEMENT_IMAGE } from "@udecode/plate-media";
import { duplicateImage, ELEMENT_CUSTOM_IMAGE } from "../../../editor/plugins/image";
import { sentry } from "../../../utils";
import { ASSET_TYPES } from "../models";
import { assetRepository } from "../repository/assetRepository";
const RERENDER_EDITOR_BLOCKS_EVENT = "rerender-editor-blocks-event";
const FIREBASE_STORAGE_BASE = "https://firebasestorage.googleapis.com/v0/b/recall-308915.appspot.com/o/user";
const isFirebaseStorageImage = (block) => {
    var _a;
    if (block.type !== ELEMENT_IMAGE)
        return false;
    const imageUrl = block.url || ((_a = block.options) === null || _a === void 0 ? void 0 : _a.url);
    return imageUrl.startsWith(FIREBASE_STORAGE_BASE);
};
const replaceImage = async (db, block, item, uid) => {
    var _a;
    const imageUrl = block.url || ((_a = block.options) === null || _a === void 0 ? void 0 : _a.url);
    const { url, size } = await duplicateImage(imageUrl, uid);
    if (url === null)
        return null;
    await assetRepository.createAsset(db, {
        item,
        size,
        url,
        type: ASSET_TYPES.IMAGE,
    });
    return url;
};
const replaceEditorBlockImageUrls = async (db, editorBlocks, item, uid) => {
    var _a;
    const blocks = [];
    for (let block of editorBlocks) {
        if (block.children) {
            block.children = await replaceEditorBlockImageUrls(db, block.children, item, uid);
        }
        if (!isFirebaseStorageImage(block)) {
            if (block.type === ELEMENT_IMAGE || block.type === ELEMENT_CUSTOM_IMAGE) {
                const existingAssets = await assetRepository.getByUrlAndItemId(db, item.image, item.id);
                if (!(existingAssets === null || existingAssets === void 0 ? void 0 : existingAssets.length)) {
                    await assetRepository.createAsset(db, {
                        item,
                        size: 0,
                        // @ts-ignore
                        url: (block === null || block === void 0 ? void 0 : block.url) ||
                            ((_a = block === null || block === void 0 ? void 0 : block.options) === null || _a === void 0 ? void 0 : _a.url) ||
                            (block === null || block === void 0 ? void 0 : block.url_320) ||
                            (block === null || block === void 0 ? void 0 : block.urlOriginal),
                        type: ASSET_TYPES.IMAGE_URL,
                    });
                }
            }
            blocks.push(block);
            continue;
        }
        const url = await replaceImage(db, block, item, uid);
        if (!url) {
            continue;
        }
        block.url = url;
        block.options = block.options || {};
        block.options.url = url;
        blocks.push(block);
    }
    return blocks.filter(Boolean);
};
export const createAssets = async (db, item, uid) => {
    const links = await item.getLinkedItems();
    for (const link of [item, ...links]) {
        const editorBlocks = await link.editorBlocks.fetch();
        await replaceImageUrls(db, editorBlocks, link, uid);
    }
    if (typeof document !== "undefined")
        document.dispatchEvent(new CustomEvent(RERENDER_EDITOR_BLOCKS_EVENT));
};
export const replaceImageUrls = async (db, editorBlocks, item, uid) => {
    var _a, _b;
    try {
        for (const editorBlock of editorBlocks) {
            if (isFirebaseStorageImage(editorBlock)) {
                const url = await replaceImage(db, editorBlock, item, uid);
                if (!url) {
                    editorBlock.delete();
                    continue;
                }
                await editorBlock.updateUrl(url);
            }
            else if (editorBlock.type === ELEMENT_IMAGE ||
                editorBlock.type === ELEMENT_CUSTOM_IMAGE) {
                const existingAssets = await assetRepository.getByUrlAndItemId(db, item.image, item.id);
                if (!(existingAssets === null || existingAssets === void 0 ? void 0 : existingAssets.length)) {
                    await assetRepository.createAsset(db, {
                        item,
                        size: 0,
                        url: item.image,
                        type: ASSET_TYPES.IMAGE_URL,
                    });
                }
                // @ts-ignore
                if ((_a = editorBlock === null || editorBlock === void 0 ? void 0 : editorBlock.children) === null || _a === void 0 ? void 0 : _a.length) {
                    const children = await replaceEditorBlockImageUrls(db, editorBlock.children, item, uid);
                    await editorBlock.updateChildren(children);
                }
            }
            // @ts-ignore
            else if ((_b = editorBlock === null || editorBlock === void 0 ? void 0 : editorBlock.children) === null || _b === void 0 ? void 0 : _b.length) {
                const children = await replaceEditorBlockImageUrls(db, editorBlock.children, item, uid);
                await editorBlock.updateChildren(children);
            }
        }
    }
    catch (error) {
        sentry.captureException(error);
    }
};
