var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Chip } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useIsMobile } from "../../hooks/useIsMobile";
import { extractSearchText } from "../../utils";
const TAG_WIDTH_WITH_SCROLL_IN_PX = 28;
export const TagChip = (_a) => {
    var { size = "small", sx = {}, searchText, label } = _a, props = __rest(_a, ["size", "sx", "searchText", "label"]);
    const isMobile = useIsMobile();
    const [shouldWrap, setShouldWrap] = useState(false);
    const tagLabel = useMemo(() => {
        if (!searchText) {
            return _jsx(_Fragment, { children: label });
        }
        const extractedSearch = extractSearchText(label, searchText);
        if (!extractedSearch) {
            return _jsx(_Fragment, { children: label });
        }
        const { beforeSearch, afterSearch, searchedText } = extractedSearch;
        return (_jsxs(_Fragment, { children: [beforeSearch, _jsx(Box, Object.assign({ component: "b", sx: { color: props.selected ? undefined : "secondary.main" } }, { children: searchedText })), afterSearch] }));
    }, [searchText, label, props.selected]);
    const ref = useRef(null);
    useEffect(() => {
        if (isMobile || !(ref === null || ref === void 0 ? void 0 : ref.current) || !(ref === null || ref === void 0 ? void 0 : ref.current.parentElement))
            return;
        if (props.onDelete &&
            ref.current.getBoundingClientRect().right + TAG_WIDTH_WITH_SCROLL_IN_PX >
                ref.current.parentElement.getBoundingClientRect().right) {
            setShouldWrap(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(_Fragment, { children: [shouldWrap && _jsx(Box, { width: TAG_WIDTH_WITH_SCROLL_IN_PX }), _jsx(Chip, Object.assign({ ref: ref, size: size, sx: isMobile
                    ? sx
                    : Object.assign(Object.assign({}, sx), { "& .MuiChip-deleteIcon": {
                            width: 0,
                            transform: "scale(0)",
                            transition: "all 0.2s ease",
                        }, "&:hover": {
                            "& .MuiChip-deleteIcon": {
                                width: 17,
                                transform: "scale(1)",
                            },
                        } }), variant: props.selected ? "filled" : "outlined", color: props.selected ? "primary" : "default" }, props, { label: tagLabel }))] }));
};
