import { useSelector } from "react-redux"
import { useLocation } from "react-router"
import { toast } from "react-toastify"
import { paymentService } from "services/paymentService"
import { RootState } from "storage/redux/rootReducer"

export const useRedirectToCheckout = () => {
    const isPremiumUser = useSelector((state: RootState) => state.user.isPremiumUser)
    const location = useLocation()

    const handleRedirect = async (userId: string) => {
        // @ts-ignore
        const redirectToCheckout = location?.state?.redirectToCheckout
        if (redirectToCheckout) {
            if (isPremiumUser) {
                toast("You are already subscribed.", { type: "success" })
                return
            }
            await paymentService.createCheckout(userId, redirectToCheckout)
        }
    }

    return handleRedirect
}
