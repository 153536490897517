import { RootState } from "../rootReducer"
import { importEnum } from "./types"

export const isImportModalOpen = (state: RootState) => {
    return [
        importEnum.OPENED,
        importEnum.IMPORTING_CLOSED,
        importEnum.IMPORTING_BOOKMARKS_CLOSED_FINISHED,
    ].includes(state.app.importState)
}

export const isUpgradePlanModalOpen = (state: RootState) => {
    return state.app.isUpgradePlanModalOpen
}

export const isScrapingModalOpen = (state: RootState) => {
    return state.app.isScrapingModalOpen
}

export const isImportingBookmarksInBg = (state: RootState) => {
    return [importEnum.IMPORTING_CLOSED, importEnum.IMPORTING_BOOKMARKS_CLOSED_FINISHED].includes(
        state.app.importState
    )
}

export const isBookmarksImportingFinished = (state: RootState) => {
    return state.app.importState === importEnum.IMPORTING_BOOKMARKS_CLOSED_FINISHED
}

export const isLoadingQuestionsSelector = (state: RootState, itemId: string) => {
    return state.app.questionsLoadingItemIds.includes(itemId)
}

export const isAppReadySelector = (state: RootState) => {
    const userLoading = state.app.userLoading
    const isInitialSyncFinished = state.app.isInitialSyncFinished
    const isCreatingExtensionItems = state.app.isCreatingExtensionItems

    return !userLoading && isInitialSyncFinished && !isCreatingExtensionItems
}
