import { SwapVert } from "@mui/icons-material"
import {
    Box,
    ClickAwayListener,
    FormControlLabel,
    Grow,
    IconButton,
    Paper,
    Popper,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material"
import { posthogService } from "@recall/common"
import { ITEMS_ORDER_BY_EVENT, ITEMS_ORDER_DIRECTION_EVENT } from "constants/events"
import { useIsMobile } from "hooks/useIsMobile"
import { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SET_ITEMS_ORDER_BY, SET_ITEMS_ORDER_DIRECTION } from "storage/redux/items/actionTypes"
import { RootState } from "storage/redux/rootReducer"

export const OrderItemsDropdown = () => {
    const ref = useRef()
    const orderBy = useSelector((state: RootState) => state.items.orderBy)
    const direction = useSelector((state: RootState) => state.items.direction)
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()
    const isMobile = useIsMobile()

    const handleChangeOrderBy = (e, orderBy: string) => {
        dispatch({ type: SET_ITEMS_ORDER_BY, payload: orderBy })
        posthogService.captureEvent(ITEMS_ORDER_BY_EVENT, { orderBy })
    }

    const handleChangeDirection = (e, direction: string) => {
        dispatch({ type: SET_ITEMS_ORDER_DIRECTION, payload: direction })
        posthogService.captureEvent(ITEMS_ORDER_DIRECTION_EVENT, { direction })
    }

    const handleToggle = () => {
        setOpen((prev) => !prev)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Box>
            <IconButton ref={ref} onClick={handleToggle}>
                <SwapVert fontSize={isMobile ? "large" : "inherit"} />
            </IconButton>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={ref.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper sx={{ p: 1 }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <Box>
                                    <Typography variant="body2" sx={{ opacity: 0.9, mb: 0.2 }}>
                                        Order by
                                    </Typography>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                        value={orderBy}
                                        onChange={handleChangeOrderBy}
                                    >
                                        <FormControlLabel
                                            value="updatedAt"
                                            control={<Radio size="small" sx={styles.radio} />}
                                            label="Last updated"
                                        />
                                        <FormControlLabel
                                            value="createdAt"
                                            control={<Radio size="small" sx={styles.radio} />}
                                            label="Created at"
                                        />
                                        <FormControlLabel
                                            value="referencesCount"
                                            control={<Radio size="small" sx={styles.radio} />}
                                            label="References count"
                                        />
                                        <FormControlLabel
                                            value="alphabetical"
                                            control={<Radio size="small" sx={styles.radio} />}
                                            label="Alphabetical"
                                        />
                                    </RadioGroup>

                                    <Typography
                                        variant="body2"
                                        sx={{ opacity: 0.9, mb: 0.2, mt: 1 }}
                                    >
                                        Direction
                                    </Typography>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                        value={direction}
                                        onChange={handleChangeDirection}
                                    >
                                        <FormControlLabel
                                            value="asc"
                                            control={<Radio size="small" sx={styles.radio} />}
                                            label="Ascending"
                                        />
                                        <FormControlLabel
                                            value="desc"
                                            control={<Radio size="small" sx={styles.radio} />}
                                            label="Descending"
                                        />
                                    </RadioGroup>
                                </Box>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    )
}

const styles = {
    radio: {
        py: 0.7,
        "&, &.Mui-checked": {
            color: "primary.light",
        },
    },
}
