import { googleAnalyticsService, posthogService } from "@recall/common"

const capture = (name: string, properties?: object) => {
    return posthogService.captureEvent(name, properties)
}

const captureGoogleAndPosthog = (name: string, properties?: object) => {
    posthogService.captureEvent(name, properties)
    googleAnalyticsService.log(name, properties)
}

export const captureEventService = { capture, captureGoogleAndPosthog }
