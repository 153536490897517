import { getAnalytics as firebaseGetAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, OAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { initializeFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { firebaseConfig } from "./firebaseConfig";
let analytics = null;
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const functions = getFunctions(app, "europe-west1");
const storage = getStorage(app);
// connectFunctionsEmulator(functions, "localhost", 5001)
const firestore = initializeFirestore(app, {
    experimentalForceLongPolling: true,
});
const signinWithPopup = () => {
    return signInWithPopup(firebase.auth, new GoogleAuthProvider());
};
const signinWithApple = () => {
    return signInWithPopup(firebase.auth, new OAuthProvider("apple.com"));
};
const getAnalytics = () => {
    if (typeof window === "undefined") {
        console.warn("Cannot get Google analytics without window object.");
        return null;
    }
    if (analytics)
        return analytics;
    analytics = firebaseGetAnalytics(app);
    return analytics;
};
const getAuthToken = async () => {
    var _a;
    return await ((_a = auth.currentUser) === null || _a === void 0 ? void 0 : _a.getIdToken());
};
export const firebase = {
    app,
    auth,
    database,
    functions,
    firestore,
    storage,
    getAnalytics,
    signinWithPopup,
    signinWithApple,
    getAuthToken,
};
