import { UsageChip } from "@recall/common"
import { APP_UPGRADE_CLICK } from "constants/events"
import { memo } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"

export const AppUsageChip = memo(() => {
    const { isPremiumUser, usage } = useSelector((state: RootState) => state.user)

    if (isPremiumUser) return null

    return <UsageChip usage={usage} trackingEventName={APP_UPGRADE_CLICK} />
})
