import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { editableProps } from "./config";
import { Plate } from "@udecode/plate-common";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { isEqual } from "lodash";
import { v4 as uuid } from "uuid";
import { isFirefox } from "../utils";
import { PlateConfig } from "./PlateConfig";
import { PlateSkeleton } from "./components/PlateSkeleton";
import { FixedToolbar } from "./components/Toolbar/FixedToolbar";
import { FloatingToolbar } from "./components/Toolbar/FloatingToolbar";
export const EDITOR_BLOCK_TEXT_DIV_ID = "id-editor-block-text";
export const RERENDER_EDITOR_BLOCKS_EVENT = "rerender-editor-blocks-event";
export const PlateEditor = ({ id, readOnly = false, plugins, sx = {}, isExtension = false, initEditorBlocks, updateEditorBlocks, cleanUpCopiedBlocks, uploadImage, }) => {
    const [editorBlocks, setEditorBlocks] = useState(null);
    // Force rerenders editor, after image deletion for e.g., changing editorBlock don't cause rerender so this is hacky solution
    const [plateEditorKey, setPlateEditorKey] = useState(uuid());
    const getEditorBlocks = async () => {
        const editorBlocks = await initEditorBlocks();
        setEditorBlocks(editorBlocks);
        setPlateEditorKey(uuid());
    };
    useEffect(() => {
        getEditorBlocks();
        document.addEventListener(RERENDER_EDITOR_BLOCKS_EVENT, getEditorBlocks);
        return () => {
            document.removeEventListener(RERENDER_EDITOR_BLOCKS_EVENT, getEditorBlocks);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    const update = async (newValue) => {
        await updateEditorBlocks(newValue);
        setEditorBlocks(newValue);
    };
    const handleChange = async (newValue) => {
        if (isEqual(editorBlocks, newValue))
            return;
        const cleanedUpValue = await cleanUpCopiedBlocks(newValue);
        await update(cleanedUpValue);
    };
    if (!editorBlocks)
        return _jsx(PlateSkeleton, {});
    return (_jsx(Box, Object.assign({ sx: [styles.editor, ...(Array.isArray(sx) ? sx : [sx])], translate: "no" }, { children: _jsxs(Plate, Object.assign({ plugins: plugins, initialValue: editorBlocks, value: editorBlocks, onChange: handleChange, editableProps: Object.assign(Object.assign({}, editableProps), { readOnly: (isExtension && isFirefox()) || readOnly, className: "plate-editor" }) }, { children: [_jsx(PlateConfig, {}), _jsx(FloatingToolbar, { isExtension: isExtension }), !isExtension && !readOnly && _jsx(FixedToolbar, { uploadImage: uploadImage })] }), plateEditorKey) })));
};
const styles = {
    editor: {
        backgroundColor: "background.default",
        borderRadius: 1,
        p: 0,
        pb: 2,
        maxWidth: "100%",
        wordBreak: "break-word",
        position: "relative",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        ".plate-editor": {
            "h1, h2, h3, h4, h5, h6": {
                lineHeight: 1.3,
                mt: 2.5,
                mb: 1.25,
                ":first-child": {
                    mt: 0.5,
                },
            },
        },
    },
};
