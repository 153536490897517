import { DialogContent, Typography } from "@mui/material"
import { tutorials } from "@recall/common"
import React, { memo } from "react"
import { EXPAND_DIV_ID } from "../../../ItemPage/components/item/components/Expand/Expand"
import { Step, TutorialBase, commonTutorialStyles } from "./TutorialBase"

const TutorialExpandComponent: React.FC<React.PropsWithChildren<unknown>> = () => {
    const steps: Step[] = [
        {
            anchorId: EXPAND_DIV_ID,
            placement: "top",
            showArrow: true,
            component: () => (
                <DialogContent sx={commonTutorialStyles.popoverContent}>
                    <Typography sx={commonTutorialStyles.heading} variant={"h4"}>
                        Expand Card Reference
                    </Typography>
                    <Typography sx={commonTutorialStyles.body}>
                        Expanding this reference will populate it with more information from the
                        web.
                    </Typography>
                </DialogContent>
            ),
        },
    ]

    return <TutorialBase tutorialName={tutorials.EXPAND_INTRO} steps={steps} />
}

export const TutorialExpand = memo(TutorialExpandComponent)
