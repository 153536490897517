import { Discount } from "@mui/icons-material"
import { Box, Button, DialogContentText, Fade, SxProps, Theme, Typography } from "@mui/material"
import { InviteReferralButton } from "components/shared/buttons/InviteReferralButton"
import { FC } from "react"
import { ReferralsProgress } from "./ReferralsProgress"

interface Props {
    unclaimedCount: number
    handleOpenConfirm: () => void
    handleOpenCoupons: () => void
}

export const MainSection: FC<Props> = ({
    unclaimedCount,
    handleOpenConfirm,
    handleOpenCoupons,
}) => {
    return (
        <Fade in={true}>
            <Box>
                {unclaimedCount > 0 && <ReferralsProgress referralsCount={unclaimedCount} />}
                <DialogContentText mb={1}>
                    For each user you invite, who signs up and verifies their email, you will
                    receive an additional 10% discount on Recall Plus.
                </DialogContentText>
                {unclaimedCount > 0 && (
                    <Box sx={styles.center} mb={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleOpenConfirm}
                            sx={styles.claim}
                        >
                            Claim {unclaimedCount * 10}% off coupon
                        </Button>
                    </Box>
                )}
                <Typography variant="h6" textAlign="center">
                    For Example
                </Typography>
                <DialogContentText mb={4} textAlign="center">
                    Invite 1 users, get <b>10% off</b> <br />
                    Invite 3 users, get <b>30% off</b> <br />
                    Invite 5 users, get <b>50% off</b> <br />
                </DialogContentText>

                <Box mt={1.5} sx={styles.center}>
                    <InviteReferralButton wide />
                </Box>
                <Box mt={1.5} sx={styles.center}>
                    <Button
                        variant="contained"
                        onClick={handleOpenCoupons}
                        startIcon={<Discount />}
                    >
                        My coupons
                    </Button>
                </Box>
            </Box>
        </Fade>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "space-between",
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}
