// @ts-nocheck
import { ELEMENT_MENTION_INPUT } from "@udecode/plate-mention";
import { Editor, Point, Range, Element as SlateElement, Transforms } from "slate";
import { SEARCH_REFERENCE_TYPE } from "../../services/editorData/constants";
import { EditorBlockData } from "../../services/editorData/EditorBlockData";
const CustomEditor = {
    isInlineActive(editor) {
        const [match] = Editor.nodes(editor, {
            match: (n) => editor.isInline(n) === true,
        });
        return !!match;
    },
    toggleMark(editor, format) {
        const isActive = this.isMarkActive(editor, format);
        if (isActive) {
            Editor.removeMark(editor, format);
        }
        else {
            Editor.addMark(editor, format, true);
        }
    },
    isMarkActive(editor, format) {
        const marks = Editor.marks(editor);
        return marks ? marks[format] === true : false;
    },
    isActive(editor, format) {
        const [match] = Editor.nodes(editor, {
            match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
        });
        return !!match;
    },
    isReferenceTypeActive(editor) {
        return this.isTypeActive(editor, "reference");
    },
    isSearchReferenceTypeActive(editor) {
        return this.isTypeActive(editor, SEARCH_REFERENCE_TYPE);
    },
    isLinkTypeActive(editor) {
        return this.isTypeActive(editor, "link");
    },
    isTypeActive(editor, type) {
        const [match] = Editor.nodes(editor, {
            match: (n) => n.type === type,
        });
        return !!match;
    },
    getTextNode(editor) {
        var _a, _b;
        const path = (_b = (_a = editor.selection) === null || _a === void 0 ? void 0 : _a.anchor) === null || _b === void 0 ? void 0 : _b.path;
        if (!path) {
            return;
        }
        let textNode = editor;
        for (let index of path) {
            textNode = textNode.children[index];
        }
        return textNode;
    },
    matchConnectionId(editor, connectionId) {
        const matches = Editor.nodes(editor, {
            match: (node, path) => node.connectionId === connectionId,
            at: [],
        });
        return matches;
    },
    insertSearchReference(editor, openedWithForwardSlash) {
        const { selection } = editor;
        const isCollapsed = selection && Range.isCollapsed(selection);
        if (!isCollapsed) {
            return;
        }
        const searchReference = {
            trigger: openedWithForwardSlash ? "/" : "",
            type: ELEMENT_MENTION_INPUT,
            children: [{ text: "" }],
        };
        if (!this.isInlineActive(editor)) {
            Transforms.insertNodes(editor, searchReference);
        }
    },
    insertSearchReferenceText(editor, text = "") {
        const { selection } = editor;
        const isCollapsed = selection && Range.isCollapsed(selection);
        if (isCollapsed) {
            return;
        }
        const searchReference = {
            trigger: "",
            type: ELEMENT_MENTION_INPUT,
            children: [{ text }],
        };
        if (!this.isInlineActive(editor)) {
            Transforms.insertNodes(editor, searchReference);
        }
    },
    removeSearchReference(editor) {
        // @ts-ignore
        Transforms.removeNodes(editor, { at: [], match: (n) => n.type === ELEMENT_MENTION_INPUT });
    },
    insertReference(editor, connectionId, value) {
        var _a;
        const { selection } = editor;
        const isCollapsed = selection && Range.isCollapsed(selection);
        this.removeSearchReference(editor);
        if (!isCollapsed) {
            return;
        }
        const reference = {
            type: "reference",
            connectionId: connectionId,
            children: [{ text: value }],
        };
        if (this.isInlineActive(editor)) {
            const focus = (_a = editor.selection) === null || _a === void 0 ? void 0 : _a.focus;
            if (!focus) {
                return;
            }
            const nextPoint = Editor.after(editor, focus);
            Transforms.insertNodes(editor, reference, { at: nextPoint });
            return;
        }
        Transforms.insertNodes(editor, reference);
    },
    enter(editor) {
        if (this.isInlineActive(editor)) {
            return;
        }
        const { selection } = editor;
        const after = Editor.after(editor, selection.focus);
        const before = Editor.before(editor, selection.focus);
        if (after === undefined || selection.focus.path[0] !== after.path[0]) {
            const detailBlock = EditorBlockData.create();
            Transforms.insertNodes(editor, detailBlock, { mode: "highest" });
            return;
        }
        if (before === undefined || selection.focus.path[0] !== before.path[0]) {
            const detailBlock = EditorBlockData.create();
            Transforms.insertNodes(editor, detailBlock, { mode: "highest" });
            return;
        }
        Transforms.splitNodes(editor, { mode: "highest", voids: true });
        return;
    },
    unwrapLink(editor) {
        Transforms.unwrapNodes(editor, {
            match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
        });
    },
    wrapLink(editor, url) {
        if (this.isActive(editor, "link")) {
            this.unwrapLink(editor);
        }
        const { selection } = editor;
        const isCollapsed = selection && Range.isCollapsed(selection);
        const link = {
            type: "link",
            url,
            children: isCollapsed ? [{ text: url }] : [],
        };
        if (isCollapsed) {
            Transforms.insertNodes(editor, link);
        }
        else {
            Transforms.wrapNodes(editor, link, { split: true });
            Transforms.collapse(editor, { edge: "end" });
        }
    },
    moveCursorAfterReference(editor) {
        // Set the selection to the end of the inserted reference
        // WARNING: This is fragile and will break if the schema changes.
        const nextAnchor = Editor.after(editor, editor.selection.anchor.path);
        if (Point.isPoint(nextAnchor)) {
            Transforms.select(editor, {
                anchor: nextAnchor,
                focus: nextAnchor,
            });
        }
    },
    isSelectionCollapsed(editor) {
        const { selection } = editor;
        const isCollapsed = selection && Range.isCollapsed(selection);
        return isCollapsed;
    },
};
export default CustomEditor;
