var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Model } from "@nozbe/watermelondb";
import { children, field, relation } from "@nozbe/watermelondb/decorators";
import { TYPES } from "../schema";
/**
 * @deprecated No longer in use (Use TagModel instead)
 */
export class TypeModel extends Model {
}
TypeModel.table = TYPES;
TypeModel.associations = {
    parent: { type: "belongs_to", key: "parent_id" },
    items: { type: "has_many", foreignKey: "type_id" },
};
__decorate([
    field("name")
], TypeModel.prototype, "name", void 0);
__decorate([
    field("display")
], TypeModel.prototype, "display", void 0);
__decorate([
    field("plural_display")
], TypeModel.prototype, "pluralDisplay", void 0);
__decorate([
    field("wikidata_id")
], TypeModel.prototype, "wikidataId", void 0);
__decorate([
    field("user_created")
], TypeModel.prototype, "userCreated", void 0);
__decorate([
    field("is_saved")
], TypeModel.prototype, "isSaved", void 0);
__decorate([
    children("items")
], TypeModel.prototype, "items", void 0);
__decorate([
    relation("types", "parent_id")
], TypeModel.prototype, "parent", void 0);
