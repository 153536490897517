import { Box, CircularProgress } from "@mui/material"
import { useRefObserver } from "hooks/useRefObserver"
import { FC } from "react"

interface Props {
    increaseVisibleQuestions: () => void
    isLoaded: boolean
}

export const QuestionsLoader: FC<Props> = ({ increaseVisibleQuestions, isLoaded }) => {
    const loader = useRefObserver(increaseVisibleQuestions)

    return (
        <Box ref={loader} display={isLoaded ? "none" : "flex"} justifyContent="center" width="100%">
            <CircularProgress sx={{ color: "text.primary" }} />
        </Box>
    )
}
