import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FormatColorFill, FormatColorText } from "@mui/icons-material";
import { IconButton, Menu, Tooltip } from "@mui/material";
import { MARK_COLOR, useColorDropdownMenuState } from "@udecode/plate-font";
import { useState } from "react";
import { DEFAULT_COLORS, DEFAULT_CUSTOM_COLORS } from "../../constants/colors";
import { ColorPicker } from "./ColorPicker";
export const ColorDropDownMenu = ({ nodeType, tooltip }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const state = useColorDropdownMenuState({
        nodeType,
        colors: DEFAULT_COLORS,
        customColors: DEFAULT_CUSTOM_COLORS,
        closeOnSelect: true,
    });
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ title: tooltip }, { children: _jsx(IconButton, Object.assign({ onClick: (e) => {
                        setAnchorEl(e.currentTarget);
                    }, size: "small", sx: {
                        color: (theme) => Boolean(anchorEl)
                            ? theme.palette.text.primary
                            : theme.palette.action.active,
                        backgroundColor: (theme) => Boolean(anchorEl) ? theme.palette.action.selected : undefined,
                        ":hover": {
                            backgroundColor: (theme) => Boolean(anchorEl) ? theme.palette.action.selected : undefined,
                        },
                    } }, { children: nodeType === MARK_COLOR ? (_jsx(FormatColorText, { sx: { fontSize: "1.4rem" } })) : (_jsx(FormatColorFill, { sx: { fontSize: "1.4rem" } })) })) })), _jsx(Menu, Object.assign({ anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                }, transformOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                }, anchorEl: anchorEl, open: Boolean(anchorEl), onClose: () => setAnchorEl(null) }, { children: _jsx(ColorPicker, { color: state.selectedColor || state.color, colors: state.colors, customColors: state.customColors, updateColor: state.updateColorAndClose, updateCustomColor: state.updateColor, clearColor: state.clearColor }) }))] }));
};
