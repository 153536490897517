import { useDatabase } from "@nozbe/watermelondb/react"
import { getTags, TagsWithPath } from "@recall/common"
import { useEffect, useState } from "react"

export const useTagsWithPaths = () => {
    const db = useDatabase()
    const [allTags, setAllTags] = useState<TagsWithPath[]>([])

    const fetchTags = async () => {
        const tags = await getTags(db)
        setAllTags(tags)
    }

    useEffect(() => {
        fetchTags()
    }, [])

    return { allTags, getAllTags: fetchTags }
}
