var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { alpha, Box } from "@mui/material";
import { PlateElement } from "@udecode/plate-common";
import { formatYoutubeTimestamp, sentry } from "../../utils";
import { OPEN_URL } from "../../services/messaging/messageTypes";
export const YouTubeTimestampElement = (_a) => {
    var _b, _c, _d;
    var { className, children, isExtension } = _a, props = __rest(_a, ["className", "children", "isExtension"]);
    const openInNewTab = (url) => {
        var _a;
        if (window.isNative)
            // @ts-ignore
            (_a = window === null || window === void 0 ? void 0 : window.ReactNativeWebView) === null || _a === void 0 ? void 0 : _a.postMessage(JSON.stringify({ type: OPEN_URL, data: url }));
        else
            window.open(url, "_blank");
    };
    const value = (_d = (_c = (_b = props.element) === null || _b === void 0 ? void 0 : _b.children) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.text;
    if (!value)
        return null;
    const formattedTimestamp = formatYoutubeTimestamp(value);
    const handleClick = () => {
        if (isExtension) {
            handleExtensionClick();
        }
        else {
            const url = props.element.url;
            openInNewTab(url);
        }
    };
    const handleExtensionClick = () => {
        const video = document.querySelector("video");
        const [hours, minutes, seconds] = value.slice(1, -1).split(":").map(Number);
        const totalSeconds = seconds + minutes * 60 + hours * 3600;
        if (video) {
            video.currentTime = totalSeconds;
            if (video.paused)
                video.play();
        }
        else {
            sentry.captureMessage("Video not found");
        }
    };
    return (_jsx(PlateElement, Object.assign({ asChild: true }, props, { children: _jsxs(Box, Object.assign({ component: "span", onClick: handleClick, contentEditable: false, sx: {
                gap: 0.5,
                cursor: "pointer",
                color: (theme) => alpha(theme.palette.text.primary, 0.5),
                fontStyle: "italic",
                textDecoration: "none",
                "&:hover": {
                    textDecoration: "underline",
                    color: "#3366ccee",
                },
                fontSize: 14,
                fontWeight: "normal",
            } }, { children: [children, formattedTimestamp] })) })));
};
