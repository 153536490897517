import { Box } from "@mui/material"
import LogoDark from "assets/svg/logo_dark.svg"
import LogoLight from "assets/svg/logo_light.svg"
import { useIsDarkMode } from "hooks/useThemeMode"
import { FC } from "react"

interface Props {
    size: number
    variant?: "dark" | "light"
}

export const Logo: FC<Props> = ({ size, variant }) => {
    let isDarkMode = useIsDarkMode()

    if (variant) {
        isDarkMode = variant === "dark"
    }

    return (
        <Box display="flex" justifyContent="center">
            <img alt="recall logo" height={size} src={isDarkMode ? LogoDark : LogoLight} />
        </Box>
    )
}
