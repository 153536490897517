import {
    AUTHENTICATE_WITH_APPLE_CREDENTIALS,
    AUTHENTICATE_WITH_CREDENTIALS,
    REDIRECT,
    REDIRECT_TO_HOME,
    SET_APP_TO_BE_NATIVE,
    sentry,
} from "@recall/common"
import { HOME_PATH } from "constants/routes"
import { useCallback, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import { SET_IS_NATIVE } from "storage/redux/app/actionTypes"
import { isValidJSON } from "utils/parsers"
import { useAuth } from "./auth/useAuth"

export const useMessageListener = () => {
    const auth = useAuth()
    const history = useHistory()
    const dispatch = useDispatch()

    const handleMessage = useCallback(
        async (message) => {
            if (!message.data) return

            try {
                if (!isValidJSON(message.data)) return

                const { type, data } = JSON.parse(message.data)

                if (type === AUTHENTICATE_WITH_CREDENTIALS) {
                    await auth.signInUserWithIdToken(data)
                } else if (type === AUTHENTICATE_WITH_APPLE_CREDENTIALS) {
                    await auth.signInUserWithAppleIdToken(data)
                } else if (type === REDIRECT_TO_HOME) {
                    history.push(HOME_PATH)
                } else if (type === REDIRECT) {
                    history.push(data)
                } else if (type === SET_APP_TO_BE_NATIVE) {
                    window.isNative = true
                    window.platform = data
                    dispatch({ type: SET_IS_NATIVE, payload: data })
                }
            } catch (error) {
                sentry.captureException(error)
                toast("Something went wrong.", { type: "error" })
            }
        },
        // eslint-disable-next-line
        [auth]
    )

    useEffect(() => {
        window.addEventListener("message", handleMessage)
        document.addEventListener("message", handleMessage)

        return () => {
            window.removeEventListener("message", handleMessage)
            document.removeEventListener("message", handleMessage)
        }
    }, [handleMessage])
}
