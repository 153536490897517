var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { PlateElement } from "@udecode/plate-common";
export const ListElement = (_a) => {
    var { className, children, variant = "ul" } = _a, props = __rest(_a, ["className", "children", "variant"]);
    const Element = variant;
    return (_jsx(PlateElement, Object.assign({ asChild: true }, props, { children: _jsx(Element, { children: children }) })));
};
