import { posthogService, USAGE_LIMIT_EXCEEDED } from "@recall/common"
import { BOOKMARKS_IMPORT_LIMIT_EXCEEDED, COST_USAGE_LIMIT_EXCEEDED } from "constants/events"
import { useDispatch, useSelector } from "react-redux"
import {
    bookmarksImportUsageService,
    costUsageService,
    summaryUsageService,
} from "repositories/usageRepository"
import { RootState } from "storage/redux/rootReducer"
import { UPDATE_USER } from "storage/redux/user/actionTypes"

export const useUsageUtils = () => {
    const isPremiumUser = useSelector((state: RootState) => state.user.isPremiumUser)
    const dispatch = useDispatch()

    const increaseCostUsage = async (userId: string, cost: number) => {
        const costUsage = await costUsageService.increaseUsage(userId, cost)

        if (costUsageService.isUsageExceededByCount(costUsage)) {
            posthogService.captureEvent(COST_USAGE_LIMIT_EXCEEDED)
        }
    }

    const increaseSummariesUsage = async (userId: string, cost: number) => {
        const generatedSummariesCount = await summaryUsageService.increaseUsage(userId)
        dispatch({ type: UPDATE_USER, payload: { usage: generatedSummariesCount } })

        if (!isPremiumUser && summaryUsageService.isUsageExceededByCount(generatedSummariesCount)) {
            posthogService.captureEvent(USAGE_LIMIT_EXCEEDED, { isExtension: false })
        }

        await increaseCostUsage(userId, cost)
    }

    const increaseBookmarksImportUsage = async (userId: string, cost: number) => {
        const bookmarksImportedCount = await bookmarksImportUsageService.increaseUsage(userId)
        if (
            !isPremiumUser &&
            bookmarksImportUsageService.isUsageExceededByCount(bookmarksImportedCount)
        ) {
            posthogService.captureEvent(BOOKMARKS_IMPORT_LIMIT_EXCEEDED)
        }

        await increaseCostUsage(userId, cost)
    }

    return { increaseSummariesUsage, increaseBookmarksImportUsage }
}
