import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { alpha, Avatar, Box, Grid } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { truncate } from "lodash";
import { useEffect, useRef, useState } from "react";
import { SavedBadge } from "../..";
import { WIKIPEDIA } from "../../../constants";
import { EditorBlockData } from "../../../services/editorData/EditorBlockData";
import { ImageBlockData } from "../../../services/editorData/ImageBlockData";
import { wikiService } from "../../../services/wikiService";
import { getSource } from "../../../types";
import { SummarizeSearchResult } from "./SummarizeSearchResult";
import TypographyTruncated from "./TypographyTruncated";
const SearchResultComp = (props) => {
    var _a, _b;
    const [imageUrl, setImageUrl] = useState(null);
    const classes = useStyles();
    const resultRef = useRef(null);
    const description = EditorBlockData.getText(props.result.editorBlocks);
    const imageBlock = EditorBlockData.getFirstImageBlock(props.result.editorBlocks);
    const getSearchResultImage = async () => {
        var _a;
        if (imageBlock) {
            setImageUrl((imageBlock === null || imageBlock === void 0 ? void 0 : imageBlock.url) || ((_a = imageBlock === null || imageBlock === void 0 ? void 0 : imageBlock.options) === null || _a === void 0 ? void 0 : _a.url) || ImageBlockData.getUrl320(imageBlock));
            return;
        }
        const wiki_source = getSource(WIKIPEDIA, props.result.sources);
        if (!wiki_source)
            return;
        const imageUrl = await wikiService.getImageUrlForSource(wiki_source.identifier);
        if (!imageUrl)
            return;
        setImageUrl(imageUrl);
    };
    useEffect(() => {
        getSearchResultImage();
        // eslint-disable-next-line
    }, [imageBlock, props.result.sources]);
    const matches = match(props.result.name, props.value);
    const parts = parse(props.result.name, matches);
    if (props.selected === true) {
        const currentResultRef = resultRef.current;
        if (currentResultRef !== null) {
            currentResultRef.scrollIntoView({
                block: "nearest",
                behavior: "smooth",
                inline: "nearest",
            });
        }
    }
    if (((_a = props.result) === null || _a === void 0 ? void 0 : _a.isGenerateSummaryOption) === true) {
        return (_jsx(SummarizeSearchResult, { url: props.result.name, getSummaryPreview: props.getSummaryPreview }));
    }
    if (((_b = props.result) === null || _b === void 0 ? void 0 : _b.isCreateOption) === true) {
        return (_jsxs(Box, Object.assign({ sx: {
                py: 0.5,
                px: 2,
                fontSize: "1rem",
            }, ref: resultRef }, { children: ["Create empty card named", " ", _jsxs("b", { children: ["\"", truncate(props.result.name, { length: 35, omission: "..." }), "\""] })] })));
    }
    return (_jsxs(Grid, Object.assign({ ref: resultRef, container: true, alignItems: "center", className: classes.root, wrap: "nowrap" }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Avatar, { alt: props.result.name, children: props.result.name[0], variant: "rounded", className: classes.image, src: imageUrl ? imageUrl : undefined }) })), _jsxs(Grid, Object.assign({ item: true, xs: true }, { children: [parts.map((part, index) => (_jsx("span", Object.assign({ style: { fontWeight: part.highlight ? 700 : 400 } }, { children: part.text }), index))), description && (_jsx(TypographyTruncated, { variant: "body2", color: "textSecondary", className: classes.root, text: description, length: 50 }))] })), _jsx(Grid, Object.assign({ item: true }, { children: props.result.isSaved && _jsx(SavedBadge, { isReference: props.result.isReference }) }))] })));
};
export default SearchResultComp;
const useStyles = makeStyles((theme) => createStyles({
    root: {
        fontFamily: theme.typography.secondaryFontFamily,
    },
    image: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        marginRight: theme.spacing(1),
        backgroundColor: alpha("#FFFFFF", 0.9),
    },
}));
