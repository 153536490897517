import { OPEN_URL } from "@recall/common"
import { messagesService } from "services/messagesService"
import { useIsNative } from "./useIsNative"

export const useOpenInNewTab = () => {
    const isNative = useIsNative()

    const openInNewTab = (url: string) => {
        if (isNative) messagesService.post(OPEN_URL, url, true)
        else window.open(url, "_blank")
    }

    return { openInNewTab }
}
