import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, Typography } from "@mui/material";
import { EmojiSettings } from "@udecode/plate-emoji";
import { memo, useCallback } from "react";
export const EmojiPickerContent = ({ i18n, onSelectEmoji, onMouseOver, emojiLibrary, isSearching = false, searchResult, visibleCategories, refs, settings = EmojiSettings, }) => {
    const getRowWidth = settings.perLine.value * settings.buttonSize.value;
    const isCategoryVisible = useCallback((categoryId) => {
        return visibleCategories.has(categoryId) ? visibleCategories.get(categoryId) : false;
    }, [visibleCategories]);
    const EmojiList = useCallback(() => {
        return emojiLibrary
            .getGrid()
            .sections()
            .map(({ id: categoryId }) => {
            const section = emojiLibrary.getGrid().section(categoryId);
            const { buttonSize } = settings;
            return (_jsxs(Box, Object.assign({ "data-id": categoryId, ref: section.root }, { children: [_jsx(Typography, Object.assign({ sx: {
                            position: "sticky",
                            top: 0,
                            left: 0,
                            backdropFilter: "blur(4px)",
                            fontWeight: 500,
                        } }, { children: i18n.categories[categoryId] })), _jsx(Box, Object.assign({ sx: { height: section.getRows().length * buttonSize.value } }, { children: isCategoryVisible(categoryId) &&
                            section
                                .getRows()
                                .map((row, index) => (_jsx(RowOfEmoji, { emojiLibrary: emojiLibrary, row: row, onSelectEmoji: onSelectEmoji, onMouseOver: onMouseOver }, index))) }))] }), categoryId));
        });
    }, [
        emojiLibrary,
        getRowWidth,
        i18n.categories,
        isCategoryVisible,
        onSelectEmoji,
        onMouseOver,
        settings,
    ]);
    const SearchList = useCallback(() => {
        return (_jsxs(Box, Object.assign({ "data-id": "search" }, { children: [_jsx(Box, Object.assign({ sx: {
                        position: "sticky",
                        top: 0,
                        left: 0,
                        backdropFilter: "blur(4px)",
                        fontWeight: 500,
                    } }, { children: i18n.searchResult })), _jsx(Grid, Object.assign({ container: true }, { children: searchResult.map((emoji, index) => (_jsx(Grid, Object.assign({ xs: 12 / 8 }, { children: _jsx(EmojiButton, { index: index, emoji: emojiLibrary.getEmoji(emoji.id), onSelect: onSelectEmoji, onMouseOver: onMouseOver }, emoji.id) })))) }))] })));
    }, [emojiLibrary, getRowWidth, i18n.searchResult, searchResult, onSelectEmoji, onMouseOver]);
    return (_jsx(Box, Object.assign({ ref: refs.current.contentRoot, sx: { minHeight: "50%", height: "100%", overflowY: "auto" } }, { children: _jsx(Box, Object.assign({ ref: refs.current.content }, { children: isSearching ? SearchList() : EmojiList() })) })));
};
const EmojiButton = memo(({ index, emoji, onSelect, onMouseOver }) => {
    return (_jsx(Box, Object.assign({ "aria-label": emoji.skins[0].native, tabIndex: -1, "data-index": index, onClick: () => onSelect(emoji), onMouseEnter: () => onMouseOver(emoji), onMouseLeave: () => onMouseOver(), sx: {
            fontSize: 24,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
        } }, { children: emoji.skins[0].native })));
});
const RowOfEmoji = memo(({ row, emojiLibrary, onSelectEmoji, onMouseOver }) => (_jsx(Grid, Object.assign({ container: true, "data-index": row.id, display: "flex" }, { children: row.elements.map((emojiId, index) => (_jsx(Grid, Object.assign({ item: true, xs: 12 / 8 }, { children: _jsx(EmojiButton, { index: index, emoji: emojiLibrary.getEmoji(emojiId), onSelect: onSelectEmoji, onMouseOver: onMouseOver }) }), emojiId))) }), row.id)));
