var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Paper, useTheme } from "@mui/material";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { MyPopper } from "./MyPopper";
export const getHeightFromBottom = (element) => {
    const BUFFER = 0;
    const domRect = element.getBoundingClientRect();
    const spaceBelow = window.innerHeight - domRect.bottom - BUFFER;
    return spaceBelow;
};
const DropdownPopper = (props) => {
    const isMobile = useIsMobile();
    const { children } = props, rest = __rest(props, ["children"]);
    const theme = useTheme();
    let heightFromBottom = 280;
    let width = 400;
    const anchorEl = props.anchorEl;
    if (props.open && anchorEl) {
        if (!isMobile) {
            heightFromBottom = getHeightFromBottom(anchorEl) - 20;
            width = anchorEl.offsetWidth;
        }
    }
    return (_jsx(MyPopper, Object.assign({ sx: {
            zIndex: theme.zIndex.modal,
            [theme.breakpoints.up("sm")]: {
                width: `${width}px`,
                minWidth: "400px",
            },
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                padding: "0 15px",
            },
        }, modifiers: [{ name: "flip", enabled: false }] }, rest, { children: _jsx(Paper, Object.assign({ elevation: 3, sx: {
                overflow: "auto",
                maxHeight: `${heightFromBottom}px`,
            } }, { children: children })) })));
};
export default DropdownPopper;
