import { DEFAULT_LANGUAGE, DEFAULT_SEARCH_LANGUAGE } from "../constants";
import { buildPath, buildUrl } from "../utils/httpClient/common";
const YOUTUBE_TRANSCRIPT_SUMMARY_PATH = "/scraper/youtube/";
const YOUTUBE_SECTIONS_SUMMARY_PATH = "/scraper/youtube/sections/";
const PAGE_SUMMARY_PATH = "/scraper/";
const PAGE_HTML_SUMMARY_PATH = "/scraper/encoded-html/";
const PDF_HTML_SUMMARY_PATH = "/scraper/pdf/encoded-html/";
const PDF_FILE_SUMMARY_PATH = "/scraper/pdf/upload/";
const WIKI_SUMMARY_PATH = "/items/get";
const WIKI_SUMMARY_SEARCH_PATH = "/items/search";
const SUMMARY_PREVIEW_PATH = "/search-preview/";
const EXPAND_SUMMARY_URL_PATH = "/expand_url/";
const INVALIDATE_SUMMARY_CACHE_PATH = "/cache/invalidate-html/";
export class SummariesApi {
    constructor(httpClient, isSaveInBackgroundAllowed = false) {
        this.httpClient = httpClient;
        this.isSaveInBackgroundAllowed = isSaveInBackgroundAllowed;
    }
    getCost(response) {
        return Number(response.headers.get("x-response-effort")) || null;
    }
    getIsCardSaved(response) {
        return Boolean(response.headers.get("Is-Card-Saved-In-Background"));
    }
    async getResponse(response) {
        const data = await response.json();
        const cost = this.getCost(response);
        const isCardSavedInBackground = this.getIsCardSaved(response);
        return { data, cost, isCardSavedInBackground };
    }
    getHeaders(options) {
        const headers = { "Content-Type": "application/json" };
        if (options.language)
            headers["Accept-Language"] = (options === null || options === void 0 ? void 0 : options.language) || DEFAULT_LANGUAGE;
        if (options.isSaveInBackgroundAllowed)
            headers["Allow-Save-In-Background"] = "true";
        if (options.contentType)
            headers["Content-Type"] = options.contentType;
        return headers;
    }
    async summarizeYT(payload, options) {
        const response = await this.httpClient.post(buildPath(YOUTUBE_TRANSCRIPT_SUMMARY_PATH, payload.url), JSON.stringify(payload), this.getHeaders(options));
        return await this.getResponse(response);
    }
    async summarizeYTWithSections(payload, options) {
        const response = await this.httpClient.post(buildPath(YOUTUBE_SECTIONS_SUMMARY_PATH, payload.url), JSON.stringify(payload), this.getHeaders(options));
        return await this.getResponse(response);
    }
    async summarizePage(url, summaryLength, options = {}) {
        const response = await this.httpClient.get(buildUrl(PAGE_SUMMARY_PATH, { url, summaryLength }), this.getHeaders(options));
        return await this.getResponse(response);
    }
    async summarizePageHtml(url, summaryLength, encodedHtml, options = {}) {
        const response = await this.httpClient.post(buildPath(PAGE_HTML_SUMMARY_PATH, url), JSON.stringify({ url, encoded_html: encodedHtml, summaryLength }), this.getHeaders(options));
        return await this.getResponse(response);
    }
    async summarizePdfPageHtml(url, summaryLength, encodedHtml, options = {}) {
        const response = await this.httpClient.post(buildPath(PDF_HTML_SUMMARY_PATH, url), JSON.stringify({ url, encoded_html: encodedHtml, summaryLength }), this.getHeaders(options));
        return await this.getResponse(response);
    }
    async summarizePdfFile(url, name, summaryLength, formData, options = {}) {
        const headers = this.getHeaders(options);
        delete headers["Content-Type"];
        const response = await this.httpClient.post(buildUrl(PDF_FILE_SUMMARY_PATH, { url, name, summaryLength }), formData, headers);
        return await this.getResponse(response);
    }
    async summarizeWikipediaPage(slug, language = DEFAULT_SEARCH_LANGUAGE) {
        const response = await this.httpClient.get(buildUrl(WIKI_SUMMARY_PATH, { query: slug, language }));
        return await response.json();
    }
    async findWikipediaSummary(query, language = DEFAULT_SEARCH_LANGUAGE) {
        const response = await this.httpClient.get(buildUrl(WIKI_SUMMARY_SEARCH_PATH, { query, language }));
        return await response.json();
    }
    async getSummaryPreview(url) {
        const response = await this.httpClient.get(buildUrl(SUMMARY_PREVIEW_PATH, { url }));
        return await response.json();
    }
    async expandSummaryUrl(url) {
        const response = await this.httpClient.get(buildUrl(EXPAND_SUMMARY_URL_PATH, { url }));
        const data = await response.json();
        return null || (data === null || data === void 0 ? void 0 : data.url);
    }
    async invalidateSummaryCache(url, summaryLength, encodedHtml, options = {}) {
        await this.httpClient.post(buildPath(INVALIDATE_SUMMARY_CACHE_PATH, url), JSON.stringify({ url, summaryLength, encoded_html: encodedHtml }), this.getHeaders(options));
    }
}
