import { OpenInNew } from "@mui/icons-material"
import {
    Avatar,
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    SxProps,
    Theme,
    Typography,
    alpha,
} from "@mui/material"
import { assets } from "@recall/common"
import { BoldButton } from "components/shared/buttons/BoldButton"
import { FC } from "react"
import { Video } from "./Video"

interface Props {
    handleSkip: () => void
    title?: string
}

const ExampleListItem = ({ imageUrl, title, type, url }) => {
    return (
        <ListItem
            onClick={() => window.open(url, "_blank")}
            secondaryAction={
                <OpenInNew sx={{ cursor: "pointer", opacity: 0.8, "&:hover": { opacity: 1 } }} />
            }
            disablePadding
            sx={{
                border: `1px solid ${alpha("#FFF", 0.2)}`,
                borderRadius: 0.5,
                width: { md: "80%" },
            }}
        >
            <ListItemButton sx={{ p: 0.4 }}>
                <ListItemAvatar>
                    <Avatar
                        src={imageUrl}
                        variant="square"
                        sx={{ width: 90, height: 51, borderRadius: 0.5 }}
                    />
                </ListItemAvatar>
                <ListItemText
                    sx={{ pl: 1, m: 0 }}
                    primary={<Typography>{title}</Typography>}
                    secondary={type}
                />
            </ListItemButton>
        </ListItem>
    )
}

const examples = [
    {
        title: "Huberman Lab Podcast",
        type: "Podcast",
        imageUrl: `https://i.ytimg.com/vi/hFL6qRIJZ_Y/maxresdefault.jpg`,
        url: "https://www.youtube.com/watch?v=hFL6qRIJZ_Y",
    },
    {
        title: "The North Korea Paradox",
        type: "YouTube Video",
        imageUrl: "https://i.ytimg.com/vi/Jt7hE12n11s/maxresdefault.jpg",
        url: "https://www.youtube.com/watch?v=Jt7hE12n11s&ab_channel=JohnnyHarris",
    },
    {
        title: "The particle-physics breakthrough",
        type: "Blog Post",
        imageUrl:
            "https://media.nature.com/lw1024/magazine-assets/d41586-023-02296-z/d41586-023-02296-z_25766904.jpg",
        url: "https://www.nature.com/articles/d41586-023-02296-z",
    },
    {
        title: "Why China Didn’t Invent ChatGPT",
        url: "https://www.nytimes.com/2023/02/17/business/china-chatgpt-microsoft-openai.html",
        imageUrl:
            "https://static01.nyt.com/images/2023/02/18/business/17newworld-gpt-print1/00newworld-gpt-facebookJumbo.jpg",
        type: "News Article",
    },
]

export const CreateExtensionCardStep: FC<Props> = ({ handleSkip, title = "Create a summary" }) => {
    return (
        <Box textAlign="center">
            <Typography variant="h4" fontWeight={500}>
                {title}
            </Typography>
            <Box display="flex" justifyContent="start" alignItems="center" gap={1} mt={2}>
                <Avatar sx={styles.avatar}>1</Avatar>
                <Typography variant="body1" fontWeight={500}>
                    Open one of the examples below:
                </Typography>
            </Box>
            <List sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>
                {examples.map((example) => (
                    <ExampleListItem key={example.title} {...example} />
                ))}
            </List>
            <Typography textAlign="center" variant="body2" sx={{ opacity: 0.8 }}>
                Or open any other web page of your choice.
            </Typography>
            <Box display="flex" justifyContent="start" alignItems="center" gap={1} my={2}>
                <Avatar sx={styles.avatar}>2</Avatar>
                <Typography variant="body1" fontWeight={500}>
                    Then click the extension icon and save your first summary.
                </Typography>
            </Box>
            <Video videoUrl={assets.INSTALL_BROWSER_EXTENSION_2.url} />
            <BoldButton sx={styles.button} fullWidth onClick={handleSkip}>
                I've saved a summary
            </BoldButton>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    button: {
        mt: 2,
    },
    avatar: {
        width: 25,
        height: 25,
        backgroundColor: alpha("#FFF", 0.6),
    },
}
