import { SxProps, TextField, Theme } from "@mui/material"
import { useIsMobile } from "hooks/useIsMobile"
import React, { useEffect, useRef } from "react"

interface Props {
    onChange: Function
    onPressEnter?: Function
    value: string
    placeholder?: string
}

export const SEARCH_INPUT_ID = "id-search-input"

const SearchInputComp: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const inputRef = useRef(null)
    const isMobile = useIsMobile()

    useEffect(() => {
        if (inputRef.current && !isMobile) {
            inputRef.current.focus()
        }
    }, [isMobile])

    const handleOnKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
        if (e.key === "Enter") {
            props.onPressEnter()
        }
    }

    return (
        <TextField
            id={SEARCH_INPUT_ID}
            sx={styles.search}
            value={props.value}
            onKeyDown={handleOnKeyDown}
            variant="outlined"
            onChange={(e) => props.onChange(e.target.value)}
            fullWidth
            InputProps={{
                sx: styles.input,
                inputRef: inputRef,
            }}
            data-testid="cards-search"
            autoComplete="off"
            autoFocus={isMobile ? false : true}
            placeholder={props.placeholder}
        />
    )
}

const styles: Record<string, SxProps<Theme>> = {
    search: {
        pb: 0.5,
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                border: "none",
            },
            "&:hover fieldset": {
                border: "none",
            },
            "&.Mui-focused fieldset": {
                border: "none",
            },
        },
    },
    input: {
        background: (theme) => theme.palette.background.paper,
        "& ::placeholder": {
            fontSize: { xs: 13, sm: 15 },
        },
    },
}

export const focusSearchInput = () => {
    let searchInput = document.getElementById(SEARCH_INPUT_ID)
    if (searchInput) {
        searchInput.focus()
    }
}

export default SearchInputComp
