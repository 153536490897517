import { HelpOutline } from "@mui/icons-material"
import SaveAltIcon from "@mui/icons-material/SaveAlt"
import { LoadingButton } from "@mui/lab"
import { Button, Fade, SxProps, Theme, Tooltip } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useDatabase } from "@nozbe/watermelondb/react"
import {
    Bookmark,
    ItemModel,
    ROOT_TAG_ID,
    Spinner,
    SummaryLengthToggle,
    TagModel,
    WEBSITE,
    createAssets,
    sentry,
    sourceRepository,
    tagRepository,
} from "@recall/common"
import { useUsageStatusUtils } from "components/ItemPage/hooks/useUsageStatusUtils"
import { useOpenOnboarding } from "components/layouts/components/Onboarding/hooks/useOpenOnboarding"
import { UpgradeButton } from "components/shared/buttons/UpgradeButton"
import { BOOKMARKS_CREATE_CARDS } from "constants/events"
import { useItemUtils } from "hooks/useItemUtils"
import { useSummaryLength } from "hooks/useSummaryLength"
import { uniq } from "lodash"
import { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { bookmarksImportUsageService } from "repositories/usageRepository"
import { captureEventService } from "services/captureEventService"
import { extensionService } from "services/extensionService"
import { SET_IMPORT_STATE } from "storage/redux/app/actionTypes"
import { importEnum } from "storage/redux/app/types"
import { RootState } from "storage/redux/rootReducer"
import { BookmarksLoader, FailedBookmark } from "./BookmarksLoader"
import { BookmarksTree, TreeContext } from "./BookmarksTree"
import { useBookmarksImportUsage } from "./hooks/useBookmarksImportUsage"

interface Props {
    onClose: () => void
    isCreatingCards: boolean
    setIsCreatingCards: (_: boolean) => void
}

const BOOKMARKS_IMPORT_BATCH_LIMIT = 200

interface ItemsCreatedState {
    created: number
    existing: number
    count: number
    failedBookmarks: FailedBookmark[]
}

export const BookmarksImport: FC<Props> = ({ onClose, isCreatingCards, setIsCreatingCards }) => {
    const db = useDatabase()
    const { getExistingItem, generateItem } = useItemUtils()
    const { checkIsBookmarksImportEnabled } = useUsageStatusUtils()

    const [isLoading, setIsLoading] = useState(true)
    const [bookmarks, setBookmarks] = useState<Bookmark[]>([])
    const [bookmarksIds, setBookmarkIds] = useState<string[]>([])
    const [alreadyAddedBookmarkIds, setAlreadyAddedBookmarkIds] = useState<string[]>([])

    const [selected, setSelected] = useState<Bookmark[]>([])
    const [isEmpty, setIsEmpty] = useState(false)
    const [itemsCreated, setItemsCreated] = useState<ItemsCreatedState>({
        created: 0,
        existing: 0,
        count: 0,
        failedBookmarks: [],
    })
    const { closeOnboarding } = useOpenOnboarding()
    const { summaryLength, updateSummaryLength } = useSummaryLength()

    const uid = useSelector((state: RootState) => state.user.uid)
    const isPremium = useSelector((state: RootState) => state.user.isPremiumUser)
    const selectedBookmarks = selected.filter((bookmark) => !bookmark.isDirectory)

    const bookmarksImportCardsLeft = useBookmarksImportUsage()

    const freeLimitReached = !isPremium && selectedBookmarks.length > bookmarksImportCardsLeft
    const importLimitReached = isPremium && selectedBookmarks.length >= BOOKMARKS_IMPORT_BATCH_LIMIT

    const dispatch = useDispatch()

    const getBookmarks = async () => {
        try {
            setIsLoading(true)

            const { bookmarks, bookmarksIds } = await extensionService.getPageBookmarks()
            const allBookmarks = extensionService.getChildrenBookmarks(bookmarks)

            const existingCardUrls = new Set(
                (await sourceRepository.getAll(db, WEBSITE)).map((source) => source.identifier)
            )

            const alreadyAddedBookmarkIds = allBookmarks
                .filter((bookmark) => bookmark.url)
                .filter((bookmark) => existingCardUrls.has(bookmark.url))
                .map((bookmark) => bookmark.id)

            setAlreadyAddedBookmarkIds(alreadyAddedBookmarkIds)

            setBookmarkIds(bookmarksIds)
            setBookmarks(bookmarks)
            setIsEmpty(allBookmarks.every((bookmark) => bookmark.isDirectory))
        } catch (error) {
            sentry.captureException(error, { message: "Failed to fetch bookmarks" })
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const bookmarksWithoutDirectories = selected.filter((bookmark) => !bookmark.isDirectory)

        setItemsCreated((prev) => ({ ...prev, count: bookmarksWithoutDirectories.length }))
    }, [selected])

    useEffect(() => {
        getBookmarks()
    }, [])

    const addBookmarks = (bookmarks: Bookmark[]) => {
        setSelected((prev) => uniq([...prev, ...bookmarks]))
    }

    const removeBookmarks = (bookmarkIds: string[]) => {
        setSelected((prev) => prev.filter(({ id }) => !bookmarkIds.includes(id)))
    }

    const isSelected = (id: string) => {
        return selected.some((bookmark) => bookmark.id === id)
    }

    const createCardsForSelectedBookmarks = async () => {
        for (let bookmark of selected) {
            if (!bookmark.url) continue

            const existingItem = await getExistingItem(bookmark.url)

            if (existingItem) {
                setItemsCreated((prev) => ({ ...prev, existing: prev.existing + 1 }))
                continue
            }

            try {
                const isBookmarkImportEnabled = await checkIsBookmarksImportEnabled({
                    openModal: true,
                })
                if (!isBookmarkImportEnabled) return onClose()

                const { item } = await generateItem(bookmark.url, "bookmark-import")

                if (!item) throw new Error("Failed to fetch bookmark card")
                await assignTagsToItem(item, bookmark)
                await createAssets(db, item, uid)
                setItemsCreated((prev) => ({ ...prev, created: prev.created + 1 }))
            } catch {
                sentry.captureMessage("Failed to fetch bookmark card", {
                    url: bookmark?.url,
                })
                setItemsCreated((prev) => ({
                    ...prev,
                    failedBookmarks: [
                        ...prev.failedBookmarks,
                        { name: bookmark.name, url: bookmark.url },
                    ],
                }))
                continue
            }
        }

        dispatch({
            type: SET_IMPORT_STATE,
            payload: importEnum.IMPORTING_BOOKMARKS_CLOSED_FINISHED,
        })
    }

    const assignTagsToItem = async (item: ItemModel, bookmark: Bookmark) => {
        const parents = extensionService.getParentBookmarks(bookmarks, bookmark.id)

        if (!parents.length) return

        const tags: TagModel[] = []
        for (let i = 0; i < parents.length; i++) {
            const parentId = i === 0 ? ROOT_TAG_ID : tags[i - 1].id
            const tagItem = parents.length - 1 === i ? item : null

            const tag = await tagRepository.create({
                db,
                name: parents[i].name,
                item: tagItem,
                isSaved: true,
                parentId,
            })
            tags.push(tag)
        }
    }

    const handleCreateCards = async () => {
        try {
            closeOnboarding()
            setIsCreatingCards(true)
            await createCardsForSelectedBookmarks()
            captureEventService.capture(BOOKMARKS_CREATE_CARDS)
        } catch (error) {
            sentry.captureException(error, { message: "Failed to generate card from bookmark" })
        } finally {
            setIsCreatingCards(false)
        }
    }

    const getTreeComponent = () => {
        if (isLoading)
            return (
                <Box sx={styles.loader}>
                    <Spinner />
                </Box>
            )
        if (isEmpty)
            return (
                <Typography variant="body1" p={2}>
                    We have not found any bookmarks.
                </Typography>
            )

        return <BookmarksTree bookmarks={bookmarks} bookmarksIds={bookmarksIds} />
    }

    const isImportExecuted =
        itemsCreated.created || itemsCreated.existing || itemsCreated.failedBookmarks.length

    if (isCreatingCards || isImportExecuted)
        return <BookmarksLoader {...itemsCreated} isLoading={isCreatingCards} />

    return (
        <TreeContext.Provider
            value={{ selected, removeBookmarks, addBookmarks, isSelected, alreadyAddedBookmarkIds }}
        >
            <Box sx={styles.tree}>
                <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                    <Typography sx={styles.title} variant="h5" fontWeight={500}>
                        Choose Bookmarks to Import
                    </Typography>
                    <Tooltip
                        title="By importing your bookmarks, you will automatically create cards from each
                    bookmarked item."
                    >
                        <HelpOutline />
                    </Tooltip>
                </Box>
                <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
                    <SummaryLengthToggle value={summaryLength} onChange={updateSummaryLength} />
                </Box>

                {getTreeComponent()}
            </Box>
            <Box p={1}>
                {!isEmpty && (
                    <Typography variant="body2" ml={1} mb={2}>
                        Bookmarks selected: {selectedBookmarks.length}
                    </Typography>
                )}

                <Fade in={freeLimitReached || importLimitReached}>
                    <Box>
                        {freeLimitReached && (
                            <Box sx={styles.limitReached}>
                                <Box>
                                    <Typography variant="h6">Free Limit reached</Typography>
                                    <Typography variant="body2">
                                        On the free plan you can import up to{" "}
                                        {bookmarksImportUsageService.limit} bookmarks.
                                    </Typography>
                                </Box>
                                <UpgradeButton />
                            </Box>
                        )}
                        {importLimitReached && (
                            <Box sx={styles.limitReached}>
                                <Typography>
                                    You can only import <b>{BOOKMARKS_IMPORT_BATCH_LIMIT}</b>{" "}
                                    bookmarks at the time.
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Fade>
            </Box>
            <Box sx={styles.createButtonContainer}>
                <Button
                    variant="contained"
                    sx={{
                        color: "text.primary",
                        borderColor: "text.primary",
                        "&:hover": { borderColor: "text.primary" },
                    }}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                {!isEmpty && (
                    <LoadingButton
                        loading={isCreatingCards}
                        variant="contained"
                        color="secondary"
                        sx={styles.createButton}
                        onClick={handleCreateCards}
                        disabled={
                            isCreatingCards ||
                            !selectedBookmarks.length ||
                            freeLimitReached ||
                            importLimitReached
                        }
                        endIcon={<SaveAltIcon />}
                    >
                        Import
                    </LoadingButton>
                )}
            </Box>
        </TreeContext.Provider>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    title: {
        textAlign: "left",
    },
    tree: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    createButtonContainer: {
        p: 2,
        display: "flex",
        justifyContent: "space-between",
    },
    createButton: {
        ml: 2,
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
    },
    limitReached: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "background.default",
        borderRadius: 1,
        p: 2,
    },
}
