import { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    bookmarksImportUsageService,
    costUsageService,
    summaryUsageService,
} from "repositories/usageRepository"
import {
    SET_IS_COST_USAGE_MODAL_OPEN,
    SET_IS_SCRAPING_MODAL_OPEN,
} from "storage/redux/app/actionTypes"
import { RootState } from "storage/redux/rootReducer"

export const useUsageStatusUtils = () => {
    const userId = useSelector((state: RootState) => state.user.uid)
    const isPremium = useSelector((state: RootState) => state.user.isPremiumUser)

    const dispatch = useDispatch()

    const checkIsBookmarksImportEnabled = useCallback(
        async ({ openModal } = { openModal: false }) => {
            if (isPremium) return true

            const isUsageExceeded = await bookmarksImportUsageService.isUsageExceeded(userId)
            const isEnabled = !isUsageExceeded

            if (openModal && !isEnabled)
                dispatch({ type: SET_IS_SCRAPING_MODAL_OPEN, payload: true })

            return isEnabled
        },
        // eslint-disable-next-line
        [isPremium]
    )

    const checkAreSummariesEnabled = useCallback(
        async ({ openModal } = { openModal: false }) => {
            if (isPremium) return true

            const isUsageExceeded = await summaryUsageService.isUsageExceeded(userId)
            const isEnabled = !isUsageExceeded

            if (openModal && !isEnabled)
                dispatch({ type: SET_IS_SCRAPING_MODAL_OPEN, payload: true })

            return isEnabled
        },
        // eslint-disable-next-line
        [isPremium]
    )

    const checkIsCostUsageExceeded = useCallback(
        async ({ openModal } = { openModal: false }) => {
            const isExceeded = await costUsageService.isUsageExceeded(userId)

            if (openModal && isExceeded)
                dispatch({ type: SET_IS_COST_USAGE_MODAL_OPEN, payload: true })

            return isExceeded
        },
        [dispatch, userId]
    )

    return useMemo(
        () => ({
            checkIsCostUsageExceeded,
            checkAreSummariesEnabled,
            checkIsBookmarksImportEnabled,
        }),
        [checkIsCostUsageExceeded, checkAreSummariesEnabled, checkIsBookmarksImportEnabled]
    )
}
