var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { PlateElement } from "@udecode/plate-common";
import React from "react";
import { EDITOR_BLOCK_TEXT_DIV_ID } from "../PlateEditor";
export const ParagraphElement = React.forwardRef((_a, ref) => {
    var { className, children } = _a, props = __rest(_a, ["className", "children"]);
    return (_jsx(PlateElement, Object.assign({ id: EDITOR_BLOCK_TEXT_DIV_ID, ref: ref }, props, { children: _jsx(Box, Object.assign({ mb: 0.5 }, { children: children })) })));
});
ParagraphElement.displayName = "ParagraphElement";
