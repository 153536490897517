import { PaletteMode, useMediaQuery } from "@mui/material"
import { useSelector } from "react-redux"
import { themeModeEnum } from "storage/redux/app/types"
import { RootState } from "storage/redux/rootReducer"

export default function useThemeMode(): PaletteMode {
    let themeMode = useSelector((state: RootState) => state.app.themeMode)
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")

    if (themeMode === themeModeEnum.SYSTEM) {
        themeMode = prefersDarkMode ? themeModeEnum.DARK : themeModeEnum.LIGHT
    }

    return themeMode as PaletteMode
}

export const useIsDarkMode = () => {
    const themeMode = useThemeMode()
    return themeMode === themeModeEnum.DARK
}
