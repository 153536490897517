import { useDatabase } from "@nozbe/watermelondb/react"
import { handleCreate, handleDetach, ItemModel, TagInput, tagRepository } from "@recall/common"
import { useItemTags } from "components/shared/tags/hooks/useItemTags"
import { useTagsWithPaths } from "components/shared/tags/hooks/useTagsWithPaths"
import { FC, useEffect } from "react"

interface Props {
    item: ItemModel
    readOnly?: boolean
}

export const Tags: FC<Props> = ({ item, readOnly = false }) => {
    const db = useDatabase()
    const { allTags, getAllTags } = useTagsWithPaths()
    const { itemTags, getItemTags } = useItemTags(item)

    useEffect(() => {
        const subscription = tagRepository.observeCount(db).subscribe(() => {
            getAllTags()
            getItemTags()
        })

        return () => {
            subscription.unsubscribe()
        }
        // eslint-disable-next-line
    }, [])

    return (
        <TagInput
            readOnly={readOnly}
            itemId={item.id}
            allTags={allTags}
            getAllTags={getAllTags}
            itemTags={itemTags}
            getItemTags={getItemTags}
            detach={(tagId: string, itemId: string) => handleDetach(db, tagId, itemId)}
            create={(itemId: string, tag: string) => handleCreate(db, itemId, tag)}
        />
    )
}
