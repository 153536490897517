import { AuthLayout } from "components/layouts/AuthLayout"
import { ReviewUnsubscribe } from "components/UnsubscirbePages/ReviewUnsubscribe"
import { LOGIN_PATH, RESET_PATH, REVIEW_UNSUBSCRIBE, SIGNUP_PATH } from "constants/routes"
import { FC, lazy } from "react"
import { Redirect, Route, RouteProps } from "react-router-dom"
import { useRedirectPath } from "./hooks/useRedirectPath"

const LoginPage = lazy(() => import("../UserPages/LoginPage/LoginPage"))
const ResetPage = lazy(() => import("../UserPages/ResetPage/ResetPage"))
const SignUpPage = lazy(() => import("../UserPages/SignUpPage/SignUpPage"))

export const PublicRoutes: FC<RouteProps> = (props) => {
    const redirectPath = useRedirectPath(props.location.pathname)

    if (redirectPath) return <Redirect to={redirectPath} />

    return (
        <Route
            {...props}
            render={() => (
                <AuthLayout>
                    <Route path={SIGNUP_PATH} exact>
                        <SignUpPage />
                    </Route>
                    <Route path={LOGIN_PATH} exact>
                        <LoginPage />
                    </Route>
                    <Route path={RESET_PATH} exact>
                        <ResetPage />
                    </Route>
                    <Route path={REVIEW_UNSUBSCRIBE} exact>
                        <ReviewUnsubscribe />
                    </Route>
                </AuthLayout>
            )}
        />
    )
}
