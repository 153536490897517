import posthog from "posthog-js";
import { v4 as uuid } from "uuid";
import { HttpClient, isDevelopment } from "../utils";
const POSTHOG_API_HOST = "https://d2squso3hsjcqf.cloudfront.net";
const API_KEY = "phc_XER41Cya8lZH6DXgQpNpHZb8NRHljrXBBJ8VSOeqBqG";
const posthogHttpClient = new HttpClient(POSTHOG_API_HOST);
class PosthogService {
    constructor() {
        this.init = (config = {}) => {
            if (isDevelopment()) {
                return;
            }
            posthog.init(API_KEY, Object.assign({ api_host: POSTHOG_API_HOST }, config));
        };
        this.setUser = (email) => {
            if (isDevelopment()) {
                return;
            }
            posthog.identify(email, { email });
        };
        this.setUserId = (email) => {
            if (isDevelopment()) {
                return;
            }
            this.userId = email;
        };
        this.captureEvent = (name, properties) => {
            if (isDevelopment()) {
                return;
            }
            posthog.capture(name, properties);
        };
        this.capturePublicApiEvent = async (event, properties = {}) => {
            if (isDevelopment()) {
                return;
            }
            await posthogHttpClient.post("/capture/", JSON.stringify({
                api_key: API_KEY,
                event,
                properties: Object.assign(Object.assign({}, properties), { distinct_id: this.userId }),
            }));
        };
        this.isFeatureEnabled = (name) => {
            if (isDevelopment()) {
                return true;
            }
            return posthog.isFeatureEnabled(name);
        };
        this.userId = uuid();
    }
}
const posthogService = new PosthogService();
export { posthogService };
