import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Box, IconButton, SxProps, Theme } from "@mui/material"
import { HOME_PATH } from "constants/routes"
import React, { MouseEvent, MouseEventHandler } from "react"
import { useHistory } from "react-router"

interface Props {
    onClose?: MouseEventHandler
}

const SimpleLayoutComponent: React.FC<React.PropsWithChildren<Props>> = ({ children, onClose }) => {
    const history = useHistory()

    const handleClose = (e: MouseEvent) => {
        e.preventDefault()
        history.push(HOME_PATH)
    }

    return (
        <Box sx={styles.container}>
            <IconButton
                size="small"
                sx={styles.close}
                onClick={onClose || handleClose}
                color="inherit"
            >
                <CloseOutlinedIcon sx={{ fontSize: 30 }} />
            </IconButton>
            {children}
        </Box>
    )
}

export const HEADER_HEIGHT = "80px"

const styles: Record<string, SxProps<Theme>> = {
    container: {
        position: "relative",
    },
    close: {
        position: { xs: "absolute", md: "fixed" },
        top: { xs: 8, md: 48 },
        right: 12,
        zIndex: 1000,
    },
}

export const SimpleLayout = React.memo(SimpleLayoutComponent)
