var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlateElement } from "@udecode/plate-common";
import React from "react";
export const HrElement = React.forwardRef((_a, ref) => {
    var { className, nodeProps } = _a, props = __rest(_a, ["className", "nodeProps"]);
    const { children } = props;
    return (_jsxs(PlateElement, Object.assign({ ref: ref }, props, { children: [_jsx("div", Object.assign({ contentEditable: false }, { children: _jsx("hr", Object.assign({}, nodeProps)) })), children] })));
});
HrElement.displayName = "HrElement";
