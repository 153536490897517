var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImageOutlined } from "@mui/icons-material";
import { Box, Button, alpha } from "@mui/material";
import { PlateElement, focusEditor } from "@udecode/plate-common";
import { Image, useMediaState } from "@udecode/plate-media";
import { Resizable, useResizableState } from "@udecode/plate-resizable";
import { useEffect, useState } from "react";
import { assets } from "../../../assets";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { useMyEditorState } from "../../types";
import { ResizeHandle } from "./Resizable";
export const ImageElement = (_a) => {
    var _b;
    var { children, nodeProps, readOnly = false, isExtension = false, isMainImage, updateImage } = _a, props = __rest(_a, ["children", "nodeProps", "readOnly", "isExtension", "isMainImage", "updateImage"]);
    const element = props.element;
    const url = ((_b = element === null || element === void 0 ? void 0 : element.options) === null || _b === void 0 ? void 0 : _b.url) || (element === null || element === void 0 ? void 0 : element.urlOriginal) || (element === null || element === void 0 ? void 0 : element.url);
    const isMobile = useIsMobile();
    const [failedToLoad, setFailedToLoad] = useState(false);
    const { width, setWidth } = useResizableState();
    const isMain = [url, element === null || element === void 0 ? void 0 : element.urlThumbnail, element === null || element === void 0 ? void 0 : element.url_320].some(isMainImage);
    const { focused, selected, align = "center" } = useMediaState();
    const editor = useMyEditorState();
    useEffect(() => {
        var _a;
        if (!isMobile)
            setWidth(((_a = element === null || element === void 0 ? void 0 : element.options) === null || _a === void 0 ? void 0 : _a.width) || (element === null || element === void 0 ? void 0 : element.width) || 350);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleMakePrimary = async () => {
        await updateImage(url);
        focusEditor(editor);
    };
    const minWidth = isMobile ? 200 : 250;
    return (_jsxs(PlateElement, Object.assign({}, props, { children: [_jsx(Box, Object.assign({ sx: styles.resizableWrapper, component: "figure", contentEditable: false }, { children: _jsx(Resizable, Object.assign({ style: { margin: "auto" }, options: {
                        minWidth,
                        align,
                        readOnly,
                    }, className: "resizable" }, { children: _jsxs(Box, Object.assign({ sx: styles.imageWrapper }, { children: [!readOnly && (_jsx(ResizeHandle, { options: { direction: "left" }, style: { zIndex: 10000 } })), _jsxs(Box, Object.assign({ sx: Object.assign(Object.assign({}, styles.image), { border: (theme) => `2px solid ${selected && focused
                                        ? theme.palette.text.primary
                                        : "transparent"}` }) }, { children: [!readOnly && !isExtension && (_jsx(Box, Object.assign({ className: "image-button", sx: styles.imageButton }, { children: _jsx(Button, Object.assign({ color: "inherit", sx: {
                                                backgroundColor: (theme) => alpha(theme.palette.background.default, isMain ? 0.8 : 0.4),
                                                ":hover": {
                                                    backgroundColor: (theme) => alpha(theme.palette.background.default, 0.6),
                                                },
                                            }, disabled: isMain, startIcon: _jsx(ImageOutlined, {}), onClick: handleMakePrimary }, { children: isMain ? "Main image" : "Use as main image" })) }))), _jsx(Image, Object.assign({ style: {
                                            maxWidth: "100%",
                                            borderRadius: 10,
                                            objectFit: "contain",
                                            cursor: "pointer",
                                            backgroundColor: "rgba(255,255,255,0.9)",
                                            width,
                                        }, alt: "", onError: () => {
                                            setFailedToLoad(true);
                                        } }, nodeProps, { src: !url || failedToLoad ? assets.PLACEHOLDER_IMAGE_ASSET : url }))] })), !readOnly && (_jsx(ResizeHandle, { options: { direction: "right" }, style: { zIndex: 10000 } }))] })) })) })), children] })));
};
const styles = {
    resizableWrapper: {
        display: "flex",
        mx: 0.5,
        justifyContent: "center",
        userSelect: "none",
        "& .resizable": {
            mx: "auto",
        },
    },
    imageWrapper: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        gap: 0.4,
        mx: "auto",
        maxWidth: "100%",
        "& .resize-handler": {
            visibility: "hidden",
        },
        "&:hover .resize-handler": {
            visibility: "visible",
        },
    },
    image: {
        p: 0.4,
        borderRadius: "10px",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        ".image-button": { display: "none" },
        ":hover": {
            ".image-button": {
                display: "flex",
            },
        },
    },
    imageButton: {
        position: "absolute",
        top: 5,
        left: 5,
        display: "flex",
    },
};
