import { sentry } from "../sentry";
export class HttpClientError extends Error {
    constructor(status, statusText, url, details) {
        super(`Error ${status} ${statusText} in ${url}`);
        this.status = status;
        this.statusText = statusText;
        this.url = url;
        this.message = (details === null || details === void 0 ? void 0 : details.message) || "";
        this.details = details || {};
    }
}
export class HttpClient {
    constructor(baseURL, defaultHeaders) {
        this.baseURL = baseURL;
        this.defaultHeaders = defaultHeaders || {};
    }
    async initHeaders(headers = {}) {
        return Object.assign(Object.assign({}, this.defaultHeaders), headers);
    }
    async handleResponse(response) {
        if (!response.ok) {
            const error = await response.json();
            throw new HttpClientError(response.status, response.statusText, response.url, error);
        }
        return response;
    }
    async get(path, headers = { "Content-Type": "application/json" }) {
        try {
            const url = `${this.baseURL}${path}`;
            const initHeaders = await this.initHeaders(headers);
            const response = await fetch(url, { method: "GET", headers: initHeaders });
            return this.handleResponse(response);
        }
        catch (err) {
            sentry.captureException(err);
            throw err;
        }
    }
    async post(path, body, headers = { "Content-Type": "application/json" }) {
        try {
            const url = `${this.baseURL}${path}`;
            const initHeaders = await this.initHeaders(headers);
            const response = await fetch(url, {
                method: "POST",
                headers: initHeaders,
                body,
            });
            return this.handleResponse(response);
        }
        catch (err) {
            sentry.captureException(err);
            throw err;
        }
    }
    async head(url) {
        const response = await fetch(url, { method: "HEAD" });
        return Object.fromEntries(response.headers.entries());
    }
}
