import { CircularProgress, Grid, SxProps, Theme } from "@mui/material"
import { useRefObserver } from "hooks/useRefObserver"
import { memo, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { INCREASE_VISIBLE_ITEMS } from "storage/redux/app/actionTypes"
import { RootState } from "storage/redux/rootReducer"

interface LoaderProps {
    isVisible: boolean
    itemsCount: number
}

export const ItemsLoader = memo(({ isVisible, itemsCount }: LoaderProps) => {
    const visibleItems = useSelector((state: RootState) => state.app.visibleItems)

    const dispatch = useDispatch()

    const handleObserve = useCallback(() => {
        dispatch({ type: INCREASE_VISIBLE_ITEMS })
        // eslint-disable-next-line
    }, [])

    const loader = useRefObserver(handleObserve)

    const isLoaded = itemsCount && visibleItems >= itemsCount

    return (
        <Grid
            item
            sx={{ ...styles.loader, my: !isVisible ? 0 : 10 }}
            visibility={isVisible ? "visible" : "hidden"}
            display={isLoaded ? "none" : "flex"}
            ref={loader}
            xs={12}
        >
            <CircularProgress sx={{ color: "text.primary" }} />
        </Grid>
    )
})

const styles: Record<string, SxProps<Theme>> = {
    loader: {
        alignItems: "center",
        my: 10,
        justifyContent: "center",
    },
}
