var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Model } from "@nozbe/watermelondb";
import { date, field, readonly, relation, writer } from "@nozbe/watermelondb/decorators";
import { ITEMS, ITEM_TAG, TAGS } from "../schema";
export class ItemTagModel extends Model {
    constructor() {
        super(...arguments);
        this.prepareSave = () => {
            if (this.isSaved)
                return null;
            return this.prepareUpdate((record) => {
                record.isSaved = true;
            });
        };
        this.prepareDelete = () => {
            if (this._preparedState === null) {
                if (this.isSaved) {
                    return this.prepareMarkAsDeleted();
                }
                else {
                    return this.prepareDestroyPermanently();
                }
            }
        };
    }
    async delete() {
        const task = this.prepareDelete();
        await this.batch(task);
    }
}
ItemTagModel.table = ITEM_TAG;
__decorate([
    field("is_saved")
], ItemTagModel.prototype, "isSaved", void 0);
__decorate([
    readonly,
    date("created_at")
], ItemTagModel.prototype, "createdAt", void 0);
__decorate([
    relation(ITEMS, "item_id")
], ItemTagModel.prototype, "item", void 0);
__decorate([
    relation(TAGS, "tag_id")
], ItemTagModel.prototype, "tag", void 0);
__decorate([
    writer
], ItemTagModel.prototype, "delete", null);
