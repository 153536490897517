export const firebaseConfig = {
    apiKey: "AIzaSyAldguaLASAFGTIqFsTAgpAovZNxb02L6Y",
    authDomain: "app.getrecall.ai",
    projectId: "recall-308915",
    storageBucket: "recall-308915.appspot.com",
    messagingSenderId: "76037267237",
    appId: "1:76037267237:web:a859bcedcfbde2777544cc",
    databaseURL: "https://recall-308915-default-rtdb.europe-west1.firebasedatabase.app/",
    measurementId: "G-NR9JJSQFTF",
};
