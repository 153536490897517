import { Check, Close, ContentCopy, Share } from "@mui/icons-material"
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Fade,
    IconButton,
    Switch,
    SxProps,
    Theme,
    Typography,
} from "@mui/material"
import { COPY_TO_CLIPBOARD, ItemModel, sharingRepository } from "@recall/common"
import { LoadingButton } from "components/shared/buttons/LoadingButton"
import copyToClipboard from "copy-to-clipboard"
import { useIsNative } from "hooks/useIsNative"
import { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { messagesService } from "services/messagesService"
import { RootState } from "storage/redux/rootReducer"
import { updateUser } from "storage/redux/user/actions"

interface Props {
    item: ItemModel
    closeModal: () => void
}

export const ShareModal: FC<Props> = ({ item, closeModal }) => {
    const sharedCards = useSelector((state: RootState) => state.user.sharedCards)
    const uid = useSelector((state: RootState) => state.user.uid)
    const isShared = sharedCards.includes(item.id)
    const isNative = useIsNative()
    const dispatch = useDispatch()
    const [link, setLink] = useState<null | string>(null)
    const [isCopied, setIsCopied] = useState(false)

    const handleChange = async (_, checked: boolean) => {
        const newSharedCards = checked
            ? [...sharedCards, item.id]
            : sharedCards.filter((card) => card !== item.id)
        dispatch(updateUser({ sharedCards: newSharedCards }, uid))

        if (checked) sharingRepository.shareCard(uid, item.id)
        else sharingRepository.unshareCard(item.id)
    }

    const handleCopyLink = () => {
        if (!isNative) copyToClipboard(link)
        else messagesService.post(COPY_TO_CLIPBOARD, link, true)
        setIsCopied(true)

        setTimeout(() => {
            setIsCopied(false)
        }, 4000)
    }

    useEffect(() => {
        if (isShared) {
            const sharingUrl = sharingRepository.getSharingUrl(item.id)
            setLink(sharingUrl)
        }
    }, [isShared])

    return (
        <Dialog
            PaperProps={{
                sx: styles.wrapper,
            }}
            onClose={closeModal}
            open
        >
            <DialogTitle
                variant="h6"
                pb={0.5}
                display="flex"
                justifyContent="space-between"
                columnGap={3}
            >
                <Box display="flex" alignItems="center" gap={1}>
                    <Share /> Sharing
                </Box>
                <IconButton sx={{ mr: -2, mt: -1 }} onClick={closeModal}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography>Share your content with others</Typography>
                <Box sx={styles.shareSwitch}>
                    <Typography>Anyone with the link can access</Typography>
                    <Switch color="secondary" checked={isShared} onChange={handleChange} />
                </Box>

                <Fade in={isShared} unmountOnExit>
                    <Box>
                        <Typography mt={1} mb={0.5}>
                            Link
                        </Typography>
                        <Box sx={styles.link} onClick={handleCopyLink}>
                            <Typography variant="caption">{link}</Typography>
                        </Box>
                        <Box sx={styles.copyButton}>
                            <LoadingButton
                                color="secondary"
                                loading={!link}
                                startIcon={isCopied ? <Check /> : <ContentCopy />}
                                onClick={handleCopyLink}
                            >
                                {isCopied ? "Copied" : "Copy link"}
                            </LoadingButton>
                        </Box>
                    </Box>
                </Fade>
            </DialogContent>
        </Dialog>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    wrapper: {
        background: (theme) => theme.palette.background.default,
        border: (theme) => `1px solid ${theme.palette.background.paper}`,
    },
    shareSwitch: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 2,
        p: 0.5,
        pl: 2,
        mt: 0.5,
        border: (theme) => `1px solid ${theme.palette.background.paper}`,
        background: (theme) => theme.palette.background.paper,
        borderRadius: 0.5,
    },
    copyButton: {
        mt: 1,
        display: "flex",
        justifyContent: "center",
    },
    link: {
        border: (theme) => `1px solid ${theme.palette.background.paper}`,
        background: (theme) => theme.palette.background.paper,
        borderRadius: 0.5,
        px: 2,
        py: 0.5,
        cursor: "pointer",
        maxWidth: "100%",
        overflow: "auto",
        textWrap: "nowrap",
        "&::-webkit-scrollbar": {
            height: 0,
        },
    },
}
