import { addDoc, collection, deleteDoc, doc, getDocs, onSnapshot, query, where, } from "firebase/firestore";
import { uniqBy } from "lodash";
import { firebase } from "../services";
export class ExtensionItemsRepository {
    constructor() {
        this.getExtensionItems = async (uid) => {
            const querySnapshot = await getDocs(collection(firebase.firestore, `users/${uid}/extension_items`));
            const documents = [];
            querySnapshot.forEach((doc) => {
                documents.push(Object.assign({ id: doc.id }, doc.data()));
            });
            return documents;
        };
        this.getExtensionItemByUrl = async (uid, url) => {
            var _a;
            const querySnapshot = await getDocs(query(collection(firebase.firestore, `users/${uid}/extension_items`), where("url", "==", url)));
            const doc = (_a = querySnapshot.docs) === null || _a === void 0 ? void 0 : _a[0];
            if (doc) {
                return Object.assign({ id: doc.id }, doc.data());
            }
            else {
                return null;
            }
        };
        this.createExtensionItem = async (uid, payload) => {
            if (!uid)
                throw new Error("User id must be provided");
            const extensionItem = Object.assign(Object.assign({}, payload), { createdAt: Date.now() });
            await addDoc(collection(firebase.firestore, `users/${uid}/extension_items`), extensionItem);
            return extensionItem;
        };
        this.deleteExtensionItem = async (uid, id) => {
            await deleteDoc(doc(firebase.firestore, `users/${uid}/extension_items`, id));
        };
        this.observeExtensionItems = (uid, callback) => {
            const collectionRef = collection(firebase.firestore, `users/${uid}/extension_items`);
            const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
                const documents = [];
                const addedSnapshots = snapshot.docChanges().filter((change) => {
                    return change.type === "added";
                });
                addedSnapshots.forEach((change) => {
                    documents.push(Object.assign({ id: change.doc.id }, change.doc.data()));
                });
                const uniqueExtensionItems = uniqBy(documents, "id");
                if (!!uniqueExtensionItems.length)
                    callback(uniqueExtensionItems);
            });
            return unsubscribe;
        };
    }
}
export const extensionItemsRepository = new ExtensionItemsRepository();
