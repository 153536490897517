import { useDatabase } from "@nozbe/watermelondb/react"
import { createPlatePlugins, ItemModel, makeUploadImage, usePluginHelpers } from "@recall/common"
import { useOpenItem } from "hooks/items/useOpenItem"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { summariesApi } from "services/api"
import { itemAPI } from "storage/api/items/Item"
import { RootState } from "storage/redux/rootReducer"

export const usePlatePlugins = (item: ItemModel, readOnly = false) => {
    const db = useDatabase()
    const uid = useSelector((state: RootState) => state.user.uid)
    const { openItemById } = useOpenItem()
    const {
        getItemByConnectionId,
        deleteConnectionById,
        createConnection,
        updateImage,
        isMainImage,
    } = usePluginHelpers(item)

    const language = useSelector((state: RootState) => state.user.searchLanguage)

    const searchWikipedia = async (query: string) => {
        return await itemAPI.searchWikipedia(query, language)
    }

    const plugins = useMemo(() => {
        return createPlatePlugins({
            readOnly,
            isMainImage,
            updateImage,
            uploadImage: makeUploadImage(db, item, uid),
            openItemById,
            getItemByConnectionId,
            deleteConnectionById,
            createConnection,
            searchWikipedia,
            getSummaryPreview: summariesApi.getSummaryPreview,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    return plugins
}
