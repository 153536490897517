var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getPluginOptions, usePlateEditorRef, usePlateReadOnly } from "@udecode/plate-common";
import { flip, offset, shift, useVirtualFloating, } from "@udecode/plate-floating";
import { ELEMENT_LINK, floatingLinkActions, useFloatingLinkSelectors, } from "@udecode/plate-link";
import { useFocused } from "slate-react";
import { useIsMobile } from "../../hooks/useIsMobile";
import { getSelectionBoundingClientRect } from "./useFloatingToolbarState";
export const useFloatingLinkInsertState = ({ floatingOptions, isExtension = false, } = {}) => {
    const editor = usePlateEditorRef();
    const { triggerFloatingLinkHotkeys } = getPluginOptions(editor, ELEMENT_LINK);
    const readOnly = usePlateReadOnly();
    const focused = useFocused();
    const mode = useFloatingLinkSelectors().mode();
    const isOpen = useFloatingLinkSelectors().isOpen(editor.id);
    const isMobile = useIsMobile();
    const floating = useVirtualFloatingLink(Object.assign({ editorId: editor.id, open: isOpen && mode === "insert", whileElementsMounted: () => { }, placement: "bottom", middleware: [
            offset(isMobile ? 55 : 10),
            flip({
                padding: 12,
            }),
            shift({ padding: 5 }),
        ], isExtension }, floatingOptions));
    return {
        editor,
        triggerFloatingLinkHotkeys,
        floating,
        focused,
        isOpen,
        readOnly,
    };
};
const useVirtualFloatingLink = (_a) => {
    var { editorId, isExtension } = _a, floatingOptions = __rest(_a, ["editorId", "isExtension"]);
    return useVirtualFloating(Object.assign({ onOpenChange: (open) => floatingLinkActions.openEditorId(open ? editorId : null), getBoundingClientRect: () => getSelectionBoundingClientRect(isExtension) }, floatingOptions));
};
