import { ROOT_TAG_ID } from "@recall/common"
import { isEmpty } from "lodash"
import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { updateUser } from "storage/redux/user/actions"
import { GroupedTags } from "./useGroupedTags"

export const useExpandedTags = (tags: GroupedTags) => {
    const dispatch = useDispatch()

    const uid = useSelector((state: RootState) => state.user.uid)
    const expandedTags = useSelector((state: RootState) => state.user.menu?.expandedTags)

    useEffect(() => {
        if (isEmpty(tags) || expandedTags?.length) return

        const isRootOnly = Object.keys(tags).length === 1 && tags[ROOT_TAG_ID]
        if (isRootOnly) return

        getExpandedTags()
        // eslint-disable-next-line
    }, [tags])

    const getExpandedTags = async () => {
        if (!uid) return

        if (!expandedTags) await initializeExpandedTags()
    }

    const initializeExpandedTags = async () => {
        const expandedTags = Object.keys(tags)
        dispatch(updateUser({ menu: { expandedTags } }, uid))
    }

    const handleChangeExpanded = async (expandedTags: string[]) => {
        dispatch(updateUser({ menu: { expandedTags } }, uid))
    }
    const expandTag = async (tagId: string) => {
        handleChangeExpanded([...expandedTags, tagId])
    }

    return useMemo(
        () => ({ expandedTags: expandedTags || [], handleChangeExpanded, expandTag }),
        // eslint-disable-next-line
        [expandedTags]
    )
}
