var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Tooltip, Typography } from "@mui/material";
import { truncateText } from "../../../utils/Strings";
const TypographyTruncated = (props) => {
    const { text, length } = props, rest = __rest(props, ["text", "length"]);
    const [truncatedText, truncated] = truncateText(text, length);
    const truncatedTypography = _jsx(Typography, Object.assign({}, rest, { children: truncatedText }));
    const tooltipTypography = (_jsx(Typography, Object.assign({}, rest, { color: "inherit" }, { children: text })));
    return (_jsx(_Fragment, { children: truncated ? (_jsx(Tooltip, Object.assign({ title: tooltipTypography, disableTouchListener: true }, { children: truncatedTypography }))) : (truncatedTypography) }));
};
export default TypographyTruncated;
