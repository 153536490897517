import { UsageService } from "./usageService";
export const EXTENSION_USAGE_LIMIT = 10;
export class SummaryUsageService extends UsageService {
    constructor() {
        super(...arguments);
        this.limit = EXTENSION_USAGE_LIMIT;
        this.increaseUsage = async (userId) => {
            const summariesGeneratedCount = await this.getUsage(userId);
            const user = await this.userRepository.getUser(userId);
            const newSummariesGeneratedCount = summariesGeneratedCount + 1;
            await this.userRepository.upsertUser(userId, {
                usage: this.getUserUsage(user, { extension: newSummariesGeneratedCount }),
            });
            return newSummariesGeneratedCount;
        };
        this.isUsageExceeded = async (userId) => {
            const summariesGeneratedCount = await this.getUsage(userId);
            return summariesGeneratedCount >= this.limit;
        };
        this.getUsage = async (userId) => {
            var _a;
            const user = await this.userRepository.getUser(userId);
            const summariesGeneratedCount = ((_a = user === null || user === void 0 ? void 0 : user.usage) === null || _a === void 0 ? void 0 : _a.extension) || 0;
            return summariesGeneratedCount;
        };
    }
}
