import { CircularProgress, Grid, SxProps, Theme } from "@mui/material"
import { useRefObserver } from "hooks/useRefObserver"
import { memo, useCallback } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"

interface LoaderProps {
    isVisible: boolean
    tagsCount: number
    increaseVisibleTags: () => void
}

export const TagsLoader = memo(({ isVisible, tagsCount, increaseVisibleTags }: LoaderProps) => {
    const visibleItems = useSelector((state: RootState) => state.app.visibleItems)

    const handleObserve = useCallback(() => {
        increaseVisibleTags()
    }, [])

    const loader = useRefObserver(handleObserve)

    const isLoaded = tagsCount && visibleItems >= tagsCount

    return (
        <Grid
            item
            sx={{ ...styles.loader, my: !isVisible ? 0 : 2 }}
            visibility={isVisible ? "visible" : "hidden"}
            display={isLoaded ? "none" : "flex"}
            ref={loader}
            xs={12}
        >
            <CircularProgress size={20} sx={{ color: "text.primary" }} />
        </Grid>
    )
})

const styles: Record<string, SxProps<Theme>> = {
    loader: {
        alignItems: "center",
        justifyContent: "center",
    },
}
