import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { alpha, Box, Tooltip, Typography } from "@mui/material";
import { PremiumIcon } from "../icons/PremiumIcon";
import { EXTENSION_USAGE_LIMIT } from "../../services/usage/summaryUsageService";
import { RECALL_APP_URL } from "../../settings";
import { posthogService } from "../../services/postHog";
export const UsageChip = ({ usage, trackingEventName }) => {
    const handleUpgrade = () => {
        window.open(`${RECALL_APP_URL}/pricing`, "_blank");
        posthogService.captureEvent(trackingEventName);
    };
    if (usage === undefined)
        return null;
    return (_jsx(Tooltip, Object.assign({ title: "This is how many cards you can create before you need to upgrade." }, { children: _jsxs(Box, Object.assign({ sx: styles.count, onClick: handleUpgrade }, { children: [_jsx(PremiumIcon, { sx: { fontSize: "1.3rem", marginTop: -0.3 } }), _jsxs(Typography, Object.assign({ variant: "caption", gap: 0.3, display: "flex" }, { children: [_jsx("span", { children: Math.min(usage, EXTENSION_USAGE_LIMIT) }), "/", _jsx("span", { children: EXTENSION_USAGE_LIMIT })] }))] })) })));
};
const styles = {
    count: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 0.6,
        color: (theme) => theme.palette.text.secondary,
        border: (theme) => `1px solid ${alpha(theme.palette.text.primary, 0.2)}`,
        borderRadius: 1,
        px: 1,
        py: 0.5,
        height: "100%",
        cursor: "pointer",
        "&:hover": {
            background: (theme) => theme.palette.action.hover,
        },
    },
};
