import DeleteIcon from "@mui/icons-material/Delete"
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    TextField,
    Typography,
} from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { dbUtils, firebase, posthogService, premiumUserService, sentry } from "@recall/common"
import { USER_DELETED_ACCOUNT } from "constants/events"
import { AuthError } from "firebase/auth"
import { clone } from "lodash"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { paymentService } from "services/paymentService"
import { RootState } from "storage/redux/rootReducer"
import { ReauthenticateModal } from "./ReauthenticateModal"

export const DeleteAccountButton = () => {
    const email = useSelector((state: RootState) => state.user.email)
    const [isOpen, setIsOpen] = useState(false)
    const { control, handleSubmit } = useForm({ defaultValues: { email: "" } })
    const [shouldReauthenticate, setShouldReauthenticate] = useState(false)
    const [showPremiumUserMessage, setShowPremiumUserMessage] = useState(false)
    const db = useDatabase()

    const closeModal = () => {
        setIsOpen(false)
    }

    const openModal = () => {
        setIsOpen(true)
    }

    const deleteUser = async () => {
        try {
            const email = clone(firebase.auth.currentUser.email)
            await firebase.auth.currentUser.delete()
            toast.success("Account deleted successfully")
            posthogService.captureEvent(USER_DELETED_ACCOUNT, { email })
        } catch (err) {
            const error = err as AuthError

            if (error.code === "auth/requires-recent-login") {
                setShouldReauthenticate(true)
                return
            }

            throw err
        }
    }

    const onSubmit = async () => {
        try {
            if (!firebase.auth.currentUser) return

            const isPremium = await premiumUserService.checkIsPremiumNotCanceledUser(
                firebase.auth?.currentUser?.uid
            )
            if (isPremium) {
                setShowPremiumUserMessage(true)
                return
            }
            await deleteUser()
            await dbUtils.deleteDatabase(db)
        } catch (err) {
            sentry.captureException(err, {
                user: firebase.auth?.currentUser?.email,
                message: "Failed to delete user",
            })
        }
    }

    if (!email) return null

    if (showPremiumUserMessage)
        return (
            <Dialog
                open
                onClose={() => {
                    setShowPremiumUserMessage(false)
                }}
            >
                <DialogTitle color="error">Unsubscribe</DialogTitle>
                <DialogContent>
                    <Typography>
                        Before deleting your account, please ensure you have canceled any active
                        subscriptions with Recall Plus. Failure to unsubscribe may result in
                        continued billing.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setShowPremiumUserMessage(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button color="secondary" onClick={paymentService.openBillingPortal}>
                        Manage subscription
                    </Button>
                </DialogActions>
            </Dialog>
        )

    if (shouldReauthenticate)
        return (
            <ReauthenticateModal
                callback={onSubmit}
                handleClose={() => setShouldReauthenticate(false)}
            />
        )

    return (
        <Box mt={1}>
            <Button
                color="inherit"
                variant="contained"
                onClick={openModal}
                startIcon={<DeleteIcon color="error" />}
            >
                Delete Account
            </Button>
            <Dialog open={isOpen} onClose={closeModal}>
                <DialogTitle color="error">Confirm Account Deletion</DialogTitle>
                <DialogContent>
                    <Typography>
                        By confirming, your account and all related data will be{" "}
                        <strong>permanently deleted</strong>. This action is irreversible, and you
                        will lose access to our services with this account.
                    </Typography>
                    <Typography mt={2}>
                        Confirm you want to delete this collection by typing your email: {email}
                    </Typography>
                    <Box mt={1}>
                        <Controller
                            control={control}
                            name="email"
                            rules={{
                                required: "Your email is required",
                                validate: (inputEmail: string) => {
                                    return inputEmail === email || "Email does not match"
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <>
                                    <TextField fullWidth {...field} />
                                    {error && (
                                        <FormHelperText error>{error.message}</FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal} variant="contained">
                        Cancel
                    </Button>
                    <Button variant="contained" color="error" onClick={handleSubmit(onSubmit)}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
