import { SxProps, Theme, Tooltip, Typography } from "@mui/material"
import React from "react"
import { dates } from "utils/dates"

interface Props {
    dateTime: Date
}

export const DateTimeComp: React.FC<Props> = ({ dateTime }) => {
    return (
        <Tooltip
            sx={styles.tooltip}
            title={<Typography>{dates.formatPrettyDateTime(dateTime)}</Typography>}
        >
            <Typography variant="subtitle2" component="span">
                {dates.formatTimeAgo(dateTime)}
            </Typography>
        </Tooltip>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    tooltip: {
        cursor: "pointer",
    },
}
