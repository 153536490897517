import { pick } from "lodash";
import { EditorBlockData } from "../editorData";
const getByConnections = async (connections) => {
    const items = [];
    for (const connection of connections) {
        const item = await connection.from;
        const editorBlocks = await item.editorBlocks.fetch();
        const blocks = getMentionedEditorBlocks(editorBlocks, connection.id);
        items.push(Object.assign(Object.assign({}, pick(item, ["id", "image", "name", "description"])), { blocks }));
    }
    return items;
};
const getMentionedEditorBlocks = (editorBlocks, connectionId) => {
    const mentionedEditorBlocks = editorBlocks.filter((editorBlock) => JSON.stringify(editorBlock.children).includes(connectionId));
    let blocks = [];
    for (const editorBlock of mentionedEditorBlocks) {
        const textElements = EditorBlockData.getTextElements([editorBlock]);
        const elementBlocks = getMentionSentence(textElements, connectionId);
        blocks = [
            ...blocks,
            {
                textBlocks: elementBlocks,
                elementId: editorBlock.id + connectionId,
            },
        ];
    }
    return blocks;
};
const getMentionSentence = (mentions, connectionId) => {
    const texts = [];
    let accumulator = "";
    let isAdded = false;
    for (const mention of mentions) {
        if ((mention === null || mention === void 0 ? void 0 : mention.connectionId) === connectionId) {
            isAdded = true;
            if (accumulator) {
                texts.push({ text: accumulator });
                accumulator = "";
            }
            texts.push(Object.assign(Object.assign({}, mention), { connectionId, isHighlighted: true }));
        }
        else {
            accumulator += mention.text;
            if (accumulator.length > 40) {
                if (!isAdded) {
                    accumulator = "..." + accumulator.slice(-40);
                }
                else {
                    accumulator = accumulator.slice(0, 40).trimEnd() + "...";
                    texts.push({ text: accumulator });
                    break;
                }
            }
        }
    }
    return texts;
};
export const mentionsService = { getByConnections };
