import { map } from "lodash";
import { toEditorBlock } from "../../storage/watermelon/helpers/editorBlocks";
import { connectionRepository } from "../../storage/watermelon/repository/connectionRepository";
import { editorBlockRepository } from "../../storage/watermelon/repository/editorBlockRepository";
import { editorOrderRepository } from "../../storage/watermelon/repository/editorOrderRepository";
import { EditorBlockData } from "./EditorBlockData";
export const initEditorData = async (db, itemId) => {
    const editorBlockModels = await editorBlockRepository.getEditorBlocksByItemId(db, itemId);
    const editorOrderModel = await editorOrderRepository.get(db, itemId);
    let value = EditorBlockData.getOrdered(editorBlockModels, editorOrderModel);
    const connections = await connectionRepository.getItemConnections(db, itemId);
    const connectionIds = map(connections, "id");
    EditorBlockData.removeStaleReferences(value, connectionIds);
    if (value.length === 0) {
        value = [EditorBlockData.create()];
    }
    return value;
};
const initItemNameBlockInEditorBlocks = async (db, editorBlocks, editorOrder, item) => {
    for (const editorBlock of editorBlocks) {
        const blockContent = EditorBlockData.getText([editorBlock]);
        if (blockContent.trim() === item.name.trim())
            return editorBlocks;
        if (blockContent !== "")
            break;
    }
    const titleBlock = EditorBlockData.create(item.name, "h1");
    const editorBlock = await editorBlockRepository.upsert(db, item.id, titleBlock);
    if (!editorBlock)
        return [...editorBlocks];
    await editorOrderRepository.upsert(db, item.id, [editorBlock.id, ...editorOrder]);
    return [toEditorBlock(editorBlock), ...editorBlocks];
};
export const initItemEditorData = async (db, item) => {
    const [editorBlockModels, editorOrderModels, connectionIds] = await Promise.all([
        item.editorBlocks.fetch(),
        item.editorOrders.fetch(),
        item.links.fetchIds(),
    ]);
    const editorOrderModel = (editorOrderModels === null || editorOrderModels === void 0 ? void 0 : editorOrderModels[0]) || null;
    let orderedEditorBlocks = EditorBlockData.getOrdered(editorBlockModels, editorOrderModel);
    orderedEditorBlocks = await initItemNameBlockInEditorBlocks(db, orderedEditorBlocks, (editorOrderModel === null || editorOrderModel === void 0 ? void 0 : editorOrderModel.order) || [], item);
    EditorBlockData.removeStaleReferences(orderedEditorBlocks, connectionIds);
    if (orderedEditorBlocks.length === 0) {
        orderedEditorBlocks = [EditorBlockData.create()];
    }
    return orderedEditorBlocks;
};
