import { Box } from "@mui/material"

export const SharedBadge = () => {
    return (
        <Box
            sx={{
                bgcolor: (theme) => theme.palette.secondary.main,
                color: (theme) => theme.palette.secondary.contrastText,
                fontSize: 12,
                borderRadius: 0.2,
                px: 0.6,
                fontWeight: 600,
            }}
        >
            Shared
        </Box>
    )
}
