import { Button, ButtonProps, CircularProgress } from "@mui/material"
import { FC, PropsWithChildren } from "react"

interface LoadingButtonProps extends ButtonProps {
    loading?: boolean
}

export const LoadingButton: FC<PropsWithChildren<LoadingButtonProps>> = ({
    loading,
    startIcon,
    children,
    ...props
}) => {
    return (
        <Button
            translate="no"
            variant="contained"
            {...props}
            disabled={loading || props.disabled}
            startIcon={!loading && startIcon}
        >
            {loading ? <CircularProgress size={20} color="inherit" /> : children}
        </Button>
    )
}
