import { Box, SxProps, Theme, Typography, alpha } from "@mui/material"
import { getTheme } from "@recall/common"
import { FC } from "react"

export const PricingFAQ: FC = () => {
    return (
        <Box sx={styles.container}>
            <Typography mt={3} variant="h3" sx={styles.title}>
                FAQ
            </Typography>
            <Box mb={4}>
                <Box mb={4}>
                    <Typography sx={styles.question} variant="h5">
                        What is a knowledge card?
                    </Typography>
                    <Typography color="text.secondary">
                        A knowledge card is a concise summary of information about a specific topic
                        with connection to related topics. In Recall, there are two ways to create
                        knowledge cards:
                    </Typography>
                    <Box component="ol" color="text.secondary">
                        <li>
                            <strong>With the in-app search</strong> that uses predefined data
                            sources such as Wikipedia, Wikidata, Google Knowledge Graph, IMDB,
                            GoodReads, and more. These knowledge cards are free to create and save.
                        </li>
                        <li>
                            <strong>Using the Recall browser extension</strong> to create a summary
                            from any web page including YouTube videos any PDFs. Once you create a
                            summary, it can be saved in the app as a knowledge card. The browser
                            extension relies on AI which is why we apply a greater limit in the free
                            tier.
                        </li>
                    </Box>
                </Box>
                <Typography sx={styles.question} variant="h5">
                    How do I cancel, change plans, or change credit cards?
                </Typography>
                <Typography color="text.secondary">
                    You can manage your subscription from the "Manage Subscription" section found in
                    the main app menu.
                </Typography>
            </Box>
            <Box mb={4}>
                <Typography sx={styles.question} variant="h5">
                    Do you offer refunds?
                </Typography>
                <Typography color="text.secondary">
                    Yes, if you are not satisfied with our product within the first 30 days, we will
                    refund you.
                </Typography>
            </Box>
        </Box>
    )
}

const theme = getTheme("light")

const styles: Record<string, SxProps<Theme>> = {
    container: {
        my: 4,
        maxWidth: 600,
        color: "text.primary",
        "& ol": {
            pl: 4,
        },
    },
    title: {
        color: alpha(theme.palette.primary.contrastText, 0.9),
        fontWeight: 600,
        textAlign: "center",
        mb: 2,
    },
    question: {
        fontWeight: 600,
    },
}
