import { Close } from "@mui/icons-material"
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from "@mui/material"
import { FC } from "react"
import { UpgradeButton } from "../buttons/UpgradeButton"

interface Props {
    closeModal(): void
}

export const UpgradePlanModal: FC<Props> = ({ closeModal }) => {
    return (
        <Dialog onClose={closeModal} open>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <DialogTitle>
                    You have reached your limit{" "}
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
            </Box>
            <DialogContent>
                <DialogContentText>
                    Upgrade to <strong>Recall Plus</strong> to continue adding to your knowledge
                    base.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <UpgradeButton onClick={closeModal} fullWidth />
            </DialogActions>
        </Dialog>
    )
}
