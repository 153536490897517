import { useState } from "react"

export const useRerender = () => {
    const [_, setState] = useState(0)

    const rerender = () => {
        setState((prev) => prev + 1)
    }

    return rerender
}
