var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Model } from "@nozbe/watermelondb";
import { date, field, readonly, text, writer } from "@nozbe/watermelondb/decorators";
import { DATA_MIGRATIONS } from "../schema";
export class DataMigrationModel extends Model {
    async delete() {
        const task = this.prepareMarkAsDeleted();
        await this.batch(task);
    }
}
DataMigrationModel.table = DATA_MIGRATIONS;
__decorate([
    text("name")
], DataMigrationModel.prototype, "name", void 0);
__decorate([
    field("is_saved")
], DataMigrationModel.prototype, "isSaved", void 0);
__decorate([
    readonly,
    date("created_at")
], DataMigrationModel.prototype, "createdAt", void 0);
__decorate([
    readonly,
    date("updated_at")
], DataMigrationModel.prototype, "updatedAt", void 0);
__decorate([
    writer
], DataMigrationModel.prototype, "delete", null);
