import { Check, ContentCopy } from "@mui/icons-material"
import { Button, IconButton, Tooltip } from "@mui/material"
import { RECALL_WEBSITE_URL, posthogService } from "@recall/common"
import { INVITE_REFERRAL_BUTTON_CLICKED } from "constants/events"
import { REFERRAL_TOKEN_QUERY } from "constants/query"
import copyToClipboard from "copy-to-clipboard"
import React, { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { referralService } from "services/referralService"
import { RootState } from "storage/redux/rootReducer"

interface Props {
    wide?: boolean
}

export const InviteReferralButton: FC<Props> = ({ wide = false }) => {
    const uid = useSelector((state: RootState) => state.user.uid)
    const [showCheckmark, setShowCheckmark] = useState(false)

    const handleInvite = async (e: React.MouseEvent) => {
        e.stopPropagation()
        const referralToken = await referralService.getOrCreateReferralOwned(uid)

        copyToClipboard(
            `Hi, I've been using Recall and absolutely love it! Give it a try for free. ${RECALL_WEBSITE_URL}?${REFERRAL_TOKEN_QUERY}=${referralToken}`
        )

        posthogService.captureEvent(INVITE_REFERRAL_BUTTON_CLICKED)
        setShowCheckmark(true)
    }

    useEffect(() => {
        if (showCheckmark) {
            setTimeout(() => {
                setShowCheckmark(false)
            }, 3000)
        }
    }, [showCheckmark])

    if (!uid) return null

    return (
        <Tooltip title="Copy your invite link">
            {wide ? (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleInvite}
                    startIcon={showCheckmark ? <Check /> : <ContentCopy />}
                >
                    {showCheckmark ? "Copied" : "Copy your invite link"}
                </Button>
            ) : (
                <IconButton onClick={handleInvite}>
                    {showCheckmark ? <Check /> : <ContentCopy />}
                </IconButton>
            )}
        </Tooltip>
    )
}
