import { ItemModel, extractSearchedText } from "@recall/common"

export type ExtractedText = {
    beforeSearch: string
    afterSearch: string
    searchedText: string
}

export type SearchItem = {
    item: ItemModel
    image: string
    extractedText?: null | ExtractedText
}

export const filterItems = async (items: SearchItem[], searchText: string) => {
    const filteredItems = []

    for (const item of items) {
        const editorBlocks = await item.item.searchEditorBlocks(searchText)
        const extractedText = extractSearchedText(editorBlocks, searchText)
        const isTitleSearched = item.item.name.toLowerCase().includes(searchText.toLowerCase())

        if (extractedText || isTitleSearched) {
            filteredItems.push({ ...item, extractedText })
        }
    }
    return filteredItems
}
