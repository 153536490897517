import { Box, Typography } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import {
    IS_EXTENSION_TUTORIAL_CARD,
    ItemModel,
    OnboardingStepsEnum,
    SummaryLoading,
    WIKIPEDIA,
    createAssets,
    sentry,
} from "@recall/common"
import { useUsageStatus } from "components/ItemPage/hooks/useUsageStatus"
import { HOME_PATH } from "constants/routes"
import { useOpenItem } from "hooks/items/useOpenItem"
import { useIsNative } from "hooks/useIsNative"
import { uniq } from "lodash"
import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router"
import { ToastContent, toast } from "react-toastify"
import { SET_IS_SCRAPING_MODAL_OPEN } from "storage/redux/app/actionTypes"
import { isAppReadySelector } from "storage/redux/app/selectors"
import { RootState } from "storage/redux/rootReducer"
import { setOnboarding } from "storage/redux/user/actions"
import { useItemUtils } from "../../hooks/useItemUtils"

const ShareTargetPage = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const location = useLocation<{ pdfFile: File }>()
    const isNative = useIsNative()
    const onboarding = useSelector((state: RootState) => state.user.onboarding)
    const summaryLength = useSelector((state: RootState) => state.user.defaultLength)

    const isAppReady = useSelector((state: RootState) => isAppReadySelector(state))
    const uid = useSelector((state: RootState) => state.user.uid)
    const pdfFile = location?.state?.pdfFile

    const { openItem } = useOpenItem()

    const db = useDatabase()

    const { isGeneratingSummariesEnabled, isLoading } = useUsageStatus()

    const { generateItem, getExistingItem, generateItemFromPdf } = useItemUtils()

    const showErrorAndRedirect = useCallback(
        (msg: ToastContent = "Something went wrong and could not parse the page.") => {
            toast(msg, {
                type: "error",
                autoClose: false,
            })
            history.push(HOME_PATH)
        },
        [history]
    )

    const checkIsShareTargetEnabled = () => {
        if (!isGeneratingSummariesEnabled) {
            dispatch({ type: SET_IS_SCRAPING_MODAL_OPEN, payload: true })
            history.push(HOME_PATH)
            return false
        }

        return true
    }

    const clearUrl = (url: string) => {
        return url.replace(`?${IS_EXTENSION_TUTORIAL_CARD}=true`, "")
    }

    const handleShareTarget = async (url: string) => {
        try {
            if (!checkIsShareTargetEnabled()) return

            let existingItem = await getExistingItem(url)

            if (existingItem) {
                toast("Already added.", { type: "info" })
                openItem(existingItem)
                return
            }

            let item: ItemModel | null = null
            let error: { detail: any } | null = null

            const response = await generateItem(url)
            item = response.item
            error = response.error

            if (error?.detail?.message) return showErrorAndRedirect(error.detail.message)
            else if (!item) return showErrorAndRedirect()

            const source = await item.getSource(WIKIPEDIA)

            if (source) {
                await item.setIsExpanded(true)
            }

            if (
                isNative &&
                onboarding?.finished &&
                !onboarding?.finished?.includes(OnboardingStepsEnum.CREATE_CARD_WITH_SHARE_TARGET)
            )
                dispatch(
                    setOnboarding({
                        ...onboarding,
                        finished: uniq([
                            ...onboarding.finished,
                            OnboardingStepsEnum.CREATE_CARD_WITH_SHARE_TARGET,
                        ]),
                    })
                )
            await createAssets(db, item, uid)
            openItem(item)
        } catch (error) {
            sentry.captureException(error)
            showErrorAndRedirect()
        }
    }

    const handleSharePdf = async () => {
        try {
            const formData = new FormData()
            formData.append("file", pdfFile)

            const { item, error } = await generateItemFromPdf(formData, pdfFile.name, summaryLength)

            if (error?.detail?.message) return showErrorAndRedirect(error.detail.message)
            else if (!item) return showErrorAndRedirect()

            await createAssets(db, item, uid)
            openItem(item)
        } catch (error) {
            sentry.captureException(error)
            showErrorAndRedirect()
        }
    }

    useEffect(() => {
        if (isLoading || !isAppReady) return

        if (pdfFile) {
            handleSharePdf()
            return
        }

        const url = location.search?.split("url=")?.[1] + (location.hash || "")

        if (!url) return showErrorAndRedirect()

        handleShareTarget(clearUrl(url))
        // eslint-disable-next-line
    }, [dispatch, isLoading, isAppReady])

    return (
        <Box
            height="70vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <SummaryLoading />
            {isNative && (
                <Typography sx={{ mt: 2, fontWeight: 600 }}>
                    Creating <b>{summaryLength}</b> summary.
                </Typography>
            )}
        </Box>
    )
}

export default ShareTargetPage
