import { Q } from "@nozbe/watermelondb";
import { SOURCES } from "../schema";
const getAll = async (db, sourceName) => {
    const query = db.collections
        .get(SOURCES)
        .query(Q.where("is_saved", true), Q.where("name", sourceName));
    return query.fetch();
};
const get = async (db, sourceIdentifier, sourceName = null) => {
    const query = db.collections
        .get(SOURCES)
        .query(Q.where("identifier", sourceIdentifier), Q.where("is_saved", true));
    if (sourceName)
        query.extend(Q.where("name", sourceName));
    const results = await query.fetch();
    return (results === null || results === void 0 ? void 0 : results[0]) || null;
};
const getAny = async (db, sourceIdentifier, sourceName = null) => {
    const query = db.collections
        .get(SOURCES)
        .query(Q.where("identifier", sourceIdentifier));
    if (sourceName)
        query.extend(Q.where("name", sourceName));
    const results = await query.fetch();
    return (results === null || results === void 0 ? void 0 : results[0]) || null;
};
const getByIds = async (db, identifiers) => {
    const query = db.collections
        .get(SOURCES)
        .query(Q.where("identifier", Q.oneOf(identifiers)), Q.where("is_saved", true));
    const results = await query.fetch();
    return results;
};
const getAllById = async (db, identifier) => {
    const query = db.collections.get(SOURCES).query(Q.where("identifier", identifier));
    const results = await query.fetch();
    return results;
};
const deleteStale = async (db) => {
    await db.write(async (writer) => {
        const tasks = [];
        const sources = await db.collections.get(SOURCES).query().fetch();
        for (const source of sources) {
            try {
                await source.item.fetch();
            }
            catch (e) {
                const anotherTask = source.prepareDelete();
                tasks.push(anotherTask);
            }
        }
        await writer.batch(...tasks);
    });
};
export const sourceRepository = { get, getAny, getByIds, deleteStale, getAll, getAllById };
