import { format, formatDistance, isValid, parse } from "date-fns";
const PRETTY_DATETIME_TEMPLATE = "yyyy-MM-dd HH:mm:ss";
const DATETIME_TEMPLATE = "yyyy-MM-dd'T'HH-mm-ss";
const formatDateTime = (date) => {
    return format(date, DATETIME_TEMPLATE);
};
const formatPrettyDateTime = (date) => {
    return format(date, PRETTY_DATETIME_TEMPLATE);
};
const formatNow = () => {
    const date = new Date();
    return formatDateTime(date);
};
const formatTimeAgo = (date) => {
    return formatDistance(date, new Date(), { addSuffix: true });
};
const isTimeFormat = (str) => {
    const parsedDate = parse(removeBrackets(str), "HH:mm:ss", new Date());
    return isValid(parsedDate);
};
function removeBrackets(str) {
    if (str.startsWith("(") && str.endsWith(")")) {
        return str.slice(1, -1);
    }
    return str;
}
export const dates = {
    formatNow,
    formatDateTime,
    formatTimeAgo,
    formatPrettyDateTime,
    isTimeFormat,
};
