import { alpha, AppBar, Box, SxProps, Theme, Toolbar, Typography, useTheme } from "@mui/material"
import { AppUsageChip } from "components/shared/chips/AppUsageChip"
import { HOME_PATH, KNOWLEDGE_GRAPH_PATH, SPACED_REPETITION } from "constants/routes"
import { useIsMobile } from "hooks/useIsMobile"
import { useIsNative } from "hooks/useIsNative"
import { memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router"
import { SET_DRAWER_OPEN } from "storage/redux/drawer/actionTypes"
import { RootState } from "storage/redux/rootReducer"
import AppMenu from "../AppMenu/AppMenu"

const navigation = [
    { label: "Home", path: HOME_PATH },
    { label: "Graph", path: KNOWLEDGE_GRAPH_PATH, isDesktopOnly: true },
    { label: "Review", path: SPACED_REPETITION },
]

export const Navigation = memo(() => {
    const history = useHistory()
    const location = useLocation()
    const isAppLoading = useSelector((state: RootState) => state.app.appLoading)
    const isMobile = useIsMobile()
    const isNative = useIsNative()
    const theme = useTheme()
    const dispatch = useDispatch()

    const handleOnClick = (path: string) => {
        if (isMobile) dispatch({ type: SET_DRAWER_OPEN, payload: false })

        history.push(path)
    }
    if (isAppLoading) return null

    return (
        <>
            <AppBar sx={styles.appBar} elevation={0}>
                <Toolbar sx={styles.toolbar}>
                    <Box sx={{ display: "flex", gap: 2 }}>
                        {navigation.map(({ label, path, isDesktopOnly }) => {
                            if (isDesktopOnly && (isMobile || isNative)) return null

                            const isSelected = location.pathname.includes(path)

                            return (
                                <Typography
                                    key={path}
                                    onClick={() => handleOnClick(path)}
                                    noWrap
                                    component="div"
                                    variant={isMobile ? "body1" : "body2"}
                                    sx={{
                                        ...styles.navItem,
                                        background: isSelected
                                            ? theme.palette.mode === "dark"
                                                ? theme.palette.action.disabledBackground
                                                : alpha(
                                                      theme.palette.action.disabledBackground,
                                                      0.08
                                                  )
                                            : "",
                                        fontWeight: isSelected ? 500 : 400,
                                    }}
                                >
                                    {label}
                                </Typography>
                            )
                        })}
                    </Box>
                    <Box display="flex" gap={1} alignItems="center">
                        <AppUsageChip />
                        <AppMenu />
                    </Box>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </>
    )
})

const styles: Record<string, SxProps<Theme>> = {
    navItem: {
        py: { xs: 0.6, md: 0.4 },
        px: { xs: 1.2, md: 0.8 },
        cursor: "pointer",
        borderRadius: 0.5,
        color: (theme) => theme.palette.text.primary,
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        pr: "8px !important",
        pl: "12px !important",
    },
    appBar: {
        position: "fixed",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: (theme) => theme.palette.background.default,
        borderBottom: (theme) => `1px solid ${theme.palette.action.disabledBackground}`,
    },
}
