import { Box } from "@mui/material"
import { useRefObserver } from "hooks/useRefObserver"
import React, { FC, useCallback } from "react"

interface Props {
    setLoading: (_: boolean) => void
}

const LinksLoaderComponent: FC<Props> = ({ setLoading }) => {
    const handleObserve = useCallback(() => {
        setLoading(false)
    }, [])

    const loader = useRefObserver(handleObserve)

    return <Box sx={{ height: 10, width: "100%" }} ref={loader} />
}

export const LinksLoader = React.memo(LinksLoaderComponent, () => true)
