import { useSelector, useDispatch } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { UPDATE_USER } from "storage/redux/user/actionTypes"
import { summaryOptionsService } from "services/summaryOptionsService"
import { SummaryLengthEnum } from "@recall/common"

export const useSummaryLength = () => {
    const dispatch = useDispatch()
    const summaryLength = useSelector((state: RootState) => state.user.defaultLength)
    const uid = useSelector((state: RootState) => state.user.uid)

    const updateSummaryLength = async (value: SummaryLengthEnum) => {
        if (!summaryLength) return

        const summaryOptions = await summaryOptionsService.getSummaryOptions(uid)

        dispatch({
            type: UPDATE_USER,
            payload: { defaultLength: value },
        })

        await summaryOptionsService.updateSummaryOptions(uid, {
            defaultLength: value,
            language: summaryOptions.language,
            searchLanguage: summaryOptions.searchLanguage,
        })
    }

    return { summaryLength, updateSummaryLength }
}
