import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { referralService } from "services/referralService"
import { RootState } from "storage/redux/rootReducer"

export const useReferrals = () => {
    const uid = useSelector((state: RootState) => state.user.uid)
    const [referrals, setReferrals] = useState({
        isLoading: true,
        unclaimedCount: 0,
        unclaimedReferrals: [],
        claimedReferrals: [],
        allReferrals: [],
    })

    useEffect(() => {
        if (!uid) return

        getReferrals()
        // eslint-disable-next-line
    }, [uid])

    const getReferrals = async () => {
        const referrals = await referralService.getReferrals(uid)

        const unclaimedReferrals = referrals.filter(
            (referral) => !referral.isClaimed && referral.isVerified
        )
        const claimedReferrals = referrals.filter((referral) => referral.isClaimed)
        const unclaimedCount = unclaimedReferrals.length >= 5 ? 5 : unclaimedReferrals.length % 5

        setReferrals({
            isLoading: false,
            unclaimedReferrals,
            allReferrals: referrals,
            claimedReferrals,
            unclaimedCount,
        })
    }

    return { ...referrals, getReferrals }
}
