import { useDatabase } from "@nozbe/watermelondb/react"
import { ItemModel, itemRepository } from "@recall/common"
import { Dictionary, groupBy, orderBy } from "lodash"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"

export const RERENDER_ITEMS_LIST = "rerender-items-list"

export const useGroupedItems = () => {
    const visibleItems = useSelector((state: RootState) => state.app.visibleItems)
    const selectedTagIds = useSelector((state: RootState) => state.drawer.selectedTagIds)

    const includeReferences = useSelector(
        (state: RootState) => state.drawer.typeSection.inlcudeReferences
    )
    const itemsOrderBy = useSelector((state: RootState) => state.items.orderBy)
    const itemsOrderDirection = useSelector((state: RootState) => state.items.direction)

    const [groupedItemsState, setGroupedItemsState] = useState<{
        groupedItems: Dictionary<ItemModel[]>
        order: string[]
        itemsCount: number
    }>({
        groupedItems: {},
        order: [],
        itemsCount: 0,
    })
    const db = useDatabase()

    const searchItems = async () => {
        if (!selectedTagIds.length) return await itemRepository.getOrdered(db, includeReferences)

        return await itemRepository.getByTags({
            db,
            includeRefs: includeReferences,
            tags: selectedTagIds,
        })
    }

    const getGroupedItemsByReferencesCount = async () => {
        const items = await searchItems()
        const itemsWithCount = await Promise.all(
            items.map(async (item) => {
                const mentionsCount = await item.mentions.count
                return { mentionsCount, item }
            })
        )

        const orderItems = orderBy(
            itemsWithCount,
            [
                ({ mentionsCount }) => {
                    return mentionsCount
                },
            ],
            [itemsOrderDirection]
        )

        const groupedItems = groupBy(
            orderItems.slice(0, visibleItems),
            ({ mentionsCount }) => mentionsCount
        )

        const order = Object.keys(groupedItems).sort((a, b) =>
            itemsOrderDirection === "desc" ? Number(b) - Number(a) : Number(a) - Number(b)
        )

        const groupedOrderedItems = order.reduce((acc, order) => {
            if (Number(order) === 1) {
                return { ...acc, "1 reference": groupedItems[order].map(({ item }) => item) }
            }
            return { ...acc, [`${order} references`]: groupedItems[order].map(({ item }) => item) }
        }, {})

        const orderedLabels = order.map((order) =>
            Number(order) === 1 ? "1 reference" : `${order} references`
        )

        setGroupedItemsState({
            groupedItems: groupedOrderedItems,
            order: orderedLabels,
            itemsCount: items.length,
        })
    }

    const getGroupedItemsByDate = async (
        sort_by: "updatedAt" | "createdAt",
        visibleItems: number
    ) => {
        const items = await searchItems()
        const orderedItems = orderBy(
            items,
            [(item) => new Date(item[sort_by]).valueOf()],
            [itemsOrderDirection]
        )

        const renderItems = orderedItems.slice(0, visibleItems)

        const groupedItems = groupBy(renderItems, (item: ItemModel) =>
            new Date(item[sort_by]).toDateString()
        )

        Object.keys(groupedItems).forEach((date) => {
            groupedItems[date] = orderBy(
                groupedItems[date],
                [(item) => !item.isReference, (item) => new Date(item[sort_by]).valueOf()],
                [itemsOrderDirection, itemsOrderDirection]
            )
        })

        const order = orderBy(
            Object.keys(groupedItems),
            [(date) => new Date(date).valueOf()],
            [itemsOrderDirection]
        )

        setGroupedItemsState({ groupedItems, order, itemsCount: items.length })
    }

    const getGroupedItemsByAlphabet = async () => {
        const items = await searchItems()

        const orderedItems = orderBy(
            items,
            ({ name }) => name.toUpperCase(),
            itemsOrderDirection
        ).slice(0, visibleItems)

        const groupedItems = groupBy(orderedItems, ({ name }) => {
            return name[0].toUpperCase()
        })

        let order = Object.keys(groupedItems).sort()

        if (itemsOrderDirection === "desc") order = order.reverse()

        setGroupedItemsState({ groupedItems, order, itemsCount: items.length })
    }

    const getGroupedItems = () => {
        if (itemsOrderBy === "referencesCount") getGroupedItemsByReferencesCount()
        else if (itemsOrderBy === "alphabetical") getGroupedItemsByAlphabet()
        else getGroupedItemsByDate(itemsOrderBy, visibleItems)
    }

    useEffect(() => {
        const subscription = itemRepository.observeCount(db, true).subscribe(getGroupedItems)
        document.addEventListener(RERENDER_ITEMS_LIST, getGroupedItems)

        return () => {
            subscription.unsubscribe()
            document.removeEventListener(RERENDER_ITEMS_LIST, getGroupedItems)
        }
        // eslint-disable-next-line
    }, [visibleItems, itemsOrderBy, itemsOrderDirection, includeReferences, selectedTagIds])

    return groupedItemsState
}
