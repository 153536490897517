import { useDatabase } from "@nozbe/watermelondb/react"
import { User, dbUtils } from "@recall/common"
import { debounce, map } from "lodash"
import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { userRepository } from "repositories/userRepository"
import { SET_APP_LOADING, SET_IS_INITIAL_SYNC_FINISHED } from "storage/redux/app/actionTypes"
import { RootState } from "storage/redux/rootReducer"
import { getMigrations } from "storage/watermelon/dataMigrations"
import allDataMigrations from "storage/watermelon/dataMigrations/allDataMigrations"
import { SYNC_TIME_MS, syncDatabase } from "storage/watermelon/helper/sync"
import { useIsOnline } from "../useIsOnline"
import { useCreateExtensionItems } from "./useCreateExtensionItems"
import { useDbVersion } from "./useDbVersion"
import { useUpdateUser } from "./useUpdateUser"

export const shouldSyncDb = async (uid: string, dbVersion: string) => {
    const user = await userRepository.getUser(uid, { forceFetch: true })
    return !user?.dbVersion || user.dbVersion !== dbVersion
}

export const useDbSync = () => {
    const db = useDatabase()
    const dispatch = useDispatch()
    const uid = useSelector((state: RootState) => state.user.uid)
    const userAuthenticating = useSelector((state: RootState) => state.app.userLoading)
    const updateUser = useUpdateUser()
    const dbVersion = useSelector((state: RootState) => state.user.dbVersion)
    const shouldSync = useRef(false)
    const isSyncing = useRef(false)
    const isOnline = useIsOnline()
    const { isDbVersionSame, updateDbVersion, updateLocalDbVersion } = useDbVersion()
    useCreateExtensionItems()

    const syncDbVersions = async (remoteDbVersion: string) => {
        if (!uid) return

        if (isDbVersionSame(remoteDbVersion)) {
            dispatch({ type: SET_APP_LOADING, payload: false })
            return
        }

        syncDb(true)
    }

    const handleUserChange = (user: User) => {
        syncDbVersions(user?.dbVersion)
        updateUser(user)
    }

    useEffect(() => {
        if (userAuthenticating || !uid) return

        initialSync()
        // eslint-disable-next-line
    }, [uid, userAuthenticating])

    const initialSync = async () => {
        const shouldSync = await shouldSyncDb(uid, dbVersion)

        if (shouldSync) syncDb(true)
        else {
            const executedMigrations = await getMigrations(db)
            const executedMigrationsNames = map(executedMigrations, "name")
            const shouldMigrate = allDataMigrations.some(
                ({ name }) => !executedMigrationsNames.includes(name)
            )
            syncDb(true, shouldMigrate)
        }
    }

    useEffect(() => {
        if (userAuthenticating || !uid) return

        const unsubscribe = userRepository.observeUser(uid, handleUserChange)

        return unsubscribe

        // eslint-disable-next-line
    }, [uid, db, dbVersion, updateUser, userAuthenticating])

    useEffect(() => {
        if (userAuthenticating || isOnline) return

        dispatch({ type: SET_APP_LOADING, payload: false })
    }, [isOnline, dispatch, userAuthenticating])

    useEffect(() => {
        const debouncedSync = debounce(syncDb, SYNC_TIME_MS)

        if (userAuthenticating || !uid) return

        const subscription = dbUtils.subscribeOnChanges(db, debouncedSync)

        return () => subscription.unsubscribe()

        // eslint-disable-next-line
    }, [dispatch, db, uid, userAuthenticating])

    useEffect(() => {
        const confirmExit = () => isSyncing.current || null

        window.onbeforeunload = confirmExit
    }, [])

    const callback = () => {
        dispatch({ type: SET_APP_LOADING, payload: false })
        isSyncing.current = false
        dispatch({ type: SET_IS_INITIAL_SYNC_FINISHED, payload: true })

        if (shouldSync.current) {
            shouldSync.current = false
            syncDb()
        }
    }

    const syncDb = (isInitial = false, isInitialLoadingVisible = true) => {
        if (!uid) return

        if (isSyncing.current) {
            shouldSync.current = true
            return
        }

        isSyncing.current = true

        if (isInitial && isInitialLoadingVisible) {
            dispatch({ type: SET_APP_LOADING, payload: true })
        }

        syncDatabase({
            db,
            uid,
            handleFinish: () => callback(),
            isInitial,
            handlePullCallback: updateLocalDbVersion,
            handlePushCallback: updateDbVersion,
        })
    }
}
