var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Fade, Popper, useTheme } from "@mui/material";
export const MyPopper = (props) => {
    const { children } = props, rest = __rest(props, ["children"]);
    const theme = useTheme();
    return (_jsx(Popper, Object.assign({ transition: true, style: { zIndex: theme.zIndex.modal + 2 } }, rest, { children: ({ TransitionProps }) => (_jsx(Fade, Object.assign({}, TransitionProps, { timeout: 200 }, { children: _jsx(Box, { children: children }) }))) })));
};
