import {
    FIREFOX_RECALL_EXTENSION_STORE_URL,
    OnboardingStepsEnum,
    RECALL_EXTENSION_STORE_URL,
    isFirefox,
    tutorials,
} from "@recall/common"
import { SKIPPED_ONBOARDING_STEP, STARTED_ONBOARDING_STEP } from "constants/events"
import { HOME_PATH, INSTALL_EXTENSION_PATH, SIGNUP_PATH } from "constants/routes"
import { useAuth } from "hooks/auth/useAuth"
import { useIsMobile } from "hooks/useIsMobile"
import { useIsNative } from "hooks/useIsNative"
import { uniq } from "lodash"
import { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { captureEventService } from "services/captureEventService"
import { SET_IMPORT_STATE } from "storage/redux/app/actionTypes"
import { importEnum } from "storage/redux/app/types"
import { RootState } from "storage/redux/rootReducer"
import { SET_ACTIVE_TUTORIAL, TUTORIAL_RESTART_SINGLE } from "storage/redux/user/actionTypes"
import { setOnboarding } from "storage/redux/user/actions"
import { OPEN_SEARCH_CUSTOM_EVENT } from "../../Header/AddButton/AddButton"
import { useOpenOnboarding } from "./useOpenOnboarding"

export interface OnboardingSection {
    title: string
    description?: string
    steps: OnboardingStep[]
}

interface OnboardingStep {
    title: string
    completeLabel?: string
    isCompleted: boolean
    isEnabled: boolean
    isSkipped?: boolean
    skippedLabel?: string
    description?: string
    handleComplete?: () => void
    handleSkip?: () => void
}

export const useSteps = () => {
    const onboarding = useSelector((state: RootState) => state.user.onboarding)
    const [isVerificationResent, setIsVerificationResent] = useState(false)
    const { closeOnboarding } = useOpenOnboarding()
    const isMobile = useIsMobile()
    const auth = useAuth()
    const history = useHistory()
    const isNative = useIsNative()

    const dispatch = useDispatch()

    const isCompleted = useCallback(
        (step: OnboardingStepsEnum): boolean => {
            return onboarding.finished.includes(step)
        },
        [onboarding]
    )

    const isSkipped = useCallback(
        (step: OnboardingStepsEnum): boolean => {
            return onboarding.skipped.includes(step)
        },
        [onboarding]
    )

    const makeHandleStart = (step: OnboardingStepsEnum) => () => {
        captureEventService.capture(STARTED_ONBOARDING_STEP, { step })
        switch (step) {
            case OnboardingStepsEnum.CREATE_CARD: {
                dispatch({ type: SET_ACTIVE_TUTORIAL, payload: tutorials.ITEMS_INTRO })
                dispatch({ type: TUTORIAL_RESTART_SINGLE, payload: tutorials.ITEMS_INTRO })
                closeOnboarding()
                break
            }
            case OnboardingStepsEnum.CREATE_LINK: {
                dispatch({ type: SET_ACTIVE_TUTORIAL, payload: tutorials.CREATE_LINK })
                dispatch({ type: TUTORIAL_RESTART_SINGLE, payload: tutorials.CREATE_LINK })
                closeOnboarding()
                break
            }
            case OnboardingStepsEnum.INSTALL_EXTENSION: {
                window.open(
                    !isFirefox() ? RECALL_EXTENSION_STORE_URL : FIREFOX_RECALL_EXTENSION_STORE_URL,
                    "_blank"
                )
                break
            }
            case OnboardingStepsEnum.CREATE_CARD_WITH_EXTENSION: {
                history.push(INSTALL_EXTENSION_PATH)
                break
            }
            case OnboardingStepsEnum.IMPORT_BOOKMARKS: {
                dispatch({ type: SET_IMPORT_STATE, payload: importEnum.OPENED })
                break
            }
            case OnboardingStepsEnum.CREATE_ACCOUNT: {
                history.push(SIGNUP_PATH)
                break
            }
            case OnboardingStepsEnum.CREATE_CARD_IN_APP_SEARCH: {
                closeOnboarding()
                history.push(HOME_PATH)
                setTimeout(
                    () => document.dispatchEvent(new CustomEvent(OPEN_SEARCH_CUSTOM_EVENT)),
                    200
                )
                break
            }
            case OnboardingStepsEnum.CREATE_CARD_WITH_SHARE_TARGET: {
                history.push(INSTALL_EXTENSION_PATH)
                break
            }
        }
    }

    const makeHandleSkip = (step: OnboardingStepsEnum) => () => {
        if (onboarding.skipped.includes(step)) return

        const skipped = uniq([...onboarding.skipped, step])

        captureEventService.capture(SKIPPED_ONBOARDING_STEP, {
            step,
            isSkipped: skipped.includes(step),
        })

        dispatch(setOnboarding({ finished: onboarding.finished, skipped }))
    }

    const onboardingSections: OnboardingSection[] = [
        !isMobile && {
            title: "Extension",
            steps: [
                {
                    title: "Install browser extension",
                    isCompleted: isCompleted(OnboardingStepsEnum.INSTALL_EXTENSION),
                    description:
                        "The Recall Browser Extension lets you save and summarize content from webpages like articles, blogs, YouTube videos, and PDFs.",
                    isEnabled: true,
                    handleComplete: makeHandleStart(OnboardingStepsEnum.INSTALL_EXTENSION),
                },
                {
                    title: `Create a summary using the browser extension`,
                    description:
                        "After installing the extension, you can begin to create summaries of online content. The extension is designed to help you distill the main ideas and facts from extensive content into a condensed form for easier review and organization.",
                    isCompleted: isCompleted(OnboardingStepsEnum.CREATE_CARD_WITH_EXTENSION),
                    isEnabled: isCompleted(OnboardingStepsEnum.INSTALL_EXTENSION),
                    handleComplete: makeHandleStart(OnboardingStepsEnum.CREATE_CARD_WITH_EXTENSION),
                },
                {
                    title: `Import your bookmarks`,
                    description:
                        "Simply select which bookmarks to import, and the app will summarize their content and create knowledge cards for quick reference. This turns your bookmarks into an organized, accessible knowledge base within the app.",
                    isCompleted: isCompleted(OnboardingStepsEnum.IMPORT_BOOKMARKS),
                    isEnabled: isCompleted(OnboardingStepsEnum.INSTALL_EXTENSION) && !isMobile,
                    isSkipped: isSkipped(OnboardingStepsEnum.IMPORT_BOOKMARKS),
                    handleComplete: makeHandleStart(OnboardingStepsEnum.IMPORT_BOOKMARKS),
                    handleSkip: makeHandleSkip(OnboardingStepsEnum.IMPORT_BOOKMARKS),
                },
            ],
        },
        {
            title: "Knowledge cards",
            steps: [
                isNative && {
                    title: "Create summary with share intent",
                    isCompleted: isCompleted(OnboardingStepsEnum.CREATE_CARD_WITH_SHARE_TARGET),
                    description: "Use share intent to create knowledge cards.",
                    isEnabled: true,
                    handleComplete: makeHandleStart(
                        OnboardingStepsEnum.CREATE_CARD_WITH_SHARE_TARGET
                    ),
                },
                {
                    title: "Create knowledge card in search",
                    description:
                        "Directly create a knowledge card by searching within the Recall app or paste a URL into the Recall search to save and summarize that page as a knowledge card.",
                    isCompleted: isCompleted(OnboardingStepsEnum.CREATE_CARD_IN_APP_SEARCH),
                    isEnabled: true,
                    handleComplete: makeHandleStart(OnboardingStepsEnum.CREATE_CARD_IN_APP_SEARCH),
                },
                {
                    title: "Create link between knowledge cards",
                    description:
                        "By linking your knowledge cards, you create a knowledge graph that intelligently surfaces relevant information when needed, enhancing the utility of your knowledge base.",
                    isCompleted: isCompleted(OnboardingStepsEnum.CREATE_LINK),
                    isEnabled: true,
                    handleComplete: makeHandleStart(OnboardingStepsEnum.CREATE_LINK),
                },
            ],
        },
    ]

    return onboardingSections
        .filter(Boolean)
        .map((section) => ({ ...section, steps: section.steps.filter(Boolean) }))
}
