import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    SxProps,
    TextField,
    Theme,
    Typography,
} from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { getTheme, ROOT_TAG_ID, TagChip, tagRepository } from "@recall/common"
import { FC, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { RERENDER_TAGS_EVENT } from "./hooks/useGroupedTags"
import { useTagsWithPaths } from "./hooks/useTagsWithPaths"

interface Props {
    onClose: () => void
    tagId: string
    parentId: string | null
    name: string
}

interface FormValues {
    parentId: string | null
    name: string
}

export const TagUpdateModal: FC<Props> = ({ parentId = null, tagId, name, onClose }) => {
    const { allTags } = useTagsWithPaths()
    const [isMergeTagsOpen, setIsMergeTagsOpen] = useState(false)

    const {
        handleSubmit,
        formState: { isValid },
        setError,
        watch,
        control,
    } = useForm<FormValues>({
        defaultValues: { name, parentId },
        mode: "onChange",
    })

    const db = useDatabase()

    const handleUpdate = async (values: FormValues) => {
        const newParentId = values.parentId || ROOT_TAG_ID

        const existingTag = await tagRepository.getTagByNameAndParentId(
            db,
            values.name,
            newParentId
        )

        if (existingTag && tagId !== existingTag.id) {
            setIsMergeTagsOpen(true)
            return
        }

        if (parentId !== newParentId) {
            const nestedTags = await tagRepository.getNestedTags(db, [tagId])
            const nestedTag = nestedTags.find((tag) => tag?.id === newParentId)
            if (nestedTag) {
                setError("parentId", {
                    message: `${name} can't be child of ${nestedTag.name}`,
                })
                return
            }

            await tagRepository.updateParent(db, newParentId, tagId)
        }

        if (name !== values.name) {
            await tagRepository.rename(db, tagId, values.name)
        }

        document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
        onClose()
    }

    const newTagName = watch("name")
    const formParentId = watch("parentId")
    const parent = allTags.find(({ tag }) => tag.id === formParentId)

    const handleMerge = async () => {
        const tag = await tagRepository.get(db, tagId)
        const existingTag = await tagRepository.getTagByNameAndParentId(
            db,
            newTagName,
            formParentId
        )
        if (!tag || !existingTag) return

        await tagRepository.mergeTags(db, existingTag, tag)
        document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
        onClose()
    }

    return (
        <Dialog open onClose={onClose}>
            <DialogTitle>Update tag</DialogTitle>
            {!isMergeTagsOpen ? (
                <>
                    <DialogContent>
                        <Box mt={1} sx={styles.input}>
                            <Controller
                                control={control}
                                name="parentId"
                                render={({ field: { onChange }, fieldState: { error } }) => (
                                    <Autocomplete
                                        value={
                                            parent
                                                ? { value: parent.tag.id, label: parent.path }
                                                : null
                                        }
                                        onChange={(_, tag) => {
                                            onChange(tag?.value || "")
                                        }}
                                        ListboxProps={{
                                            // @ts-ignore
                                            sx: styles.listbox,
                                        }}
                                        options={allTags
                                            .filter(({ tag }) => tag.id !== tagId)
                                            .map(({ tag, path }) => ({
                                                label: path,
                                                value: tag.id,
                                            }))}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Parent tag"
                                                error={!!error?.message}
                                                helperText={error?.message}
                                                data-testid="tag-update-parent-input"
                                            />
                                        )}
                                        renderOption={(props, option, state) => (
                                            <Box
                                                component="li"
                                                {...props}
                                                key={option.value}
                                                sx={{ py: "2px !important", px: "6px !important" }}
                                            >
                                                <TagChip
                                                    sx={{ direction: "rtl" }}
                                                    label={option.label}
                                                    searchText={state.inputValue}
                                                />
                                            </Box>
                                        )}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                    />
                                )}
                            />
                        </Box>
                        <Box mt={2} sx={styles.input}>
                            <Controller
                                control={control}
                                name="name"
                                rules={{
                                    required: "Tag name is required",
                                    minLength: {
                                        value: 2,
                                        message: "Tag should contain at least 2 characters",
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        label="Tag name"
                                        error={!!error?.message}
                                        helperText={error?.message}
                                        data-testid="tag-update-name-input"
                                    />
                                )}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={onClose} size="small">
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            disabled={!isValid}
                            onClick={handleSubmit(handleUpdate)}
                            data-testid="tag-update-save"
                        >
                            Save
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <>
                    <DialogContent>
                        <Typography>
                            A tag named {newTagName} already exists in the {parent.tag.name}.
                        </Typography>
                        <Typography>
                            Do you want to merge the {newTagName} tag with the one in the{" "}
                            {parent.tag.name}?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setIsMergeTagsOpen(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={!isValid}
                            onClick={handleMerge}
                            data-testid="tag-update-save"
                        >
                            Merge
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    )
}

const theme = getTheme("dark")

const styles: Record<string, SxProps<Theme>> = {
    input: {
        [theme.breakpoints.up("sm")]: {
            width: 400,
        },
    },
    listbox: {
        border: (theme: Theme) => theme.borders.paper,
        borderRadius: 1,
        mt: 0.5,
    },
}
