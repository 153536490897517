import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Check, Colorize } from "@mui/icons-material";
import { Box, Button, Divider, Grid, Tooltip, Typography, alpha, } from "@mui/material";
import { useColorInput, useColorsCustom, useColorsCustomState } from "@udecode/plate-font";
export const ColorPicker = ({ color, colors, customColors, updateColor, updateCustomColor, clearColor, }) => {
    const state = useColorsCustomState({
        color,
        colors,
        customColors,
        updateCustomColor,
    });
    const { inputProps } = useColorsCustom(state);
    const { inputRef, childProps } = useColorInput();
    return (_jsxs(Grid, Object.assign({ container: true, spacing: 1, sx: { maxWidth: 300, p: 1, py: 0 } }, { children: [customColors.map((paletteColor) => (_jsx(Grid, Object.assign({ item: true, xs: 12 / 10 }, { children: _jsx(ColorBox, { color: paletteColor, currentColor: color, updateColor: updateCustomColor }) }), paletteColor.value))), _jsxs(Grid, Object.assign({ item: true, xs: 6, justifyContent: "flex-end", display: "flex", alignItems: "center" }, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: "Custom:" })), _jsxs(Box, Object.assign({}, childProps, { sx: Object.assign(Object.assign({}, styles.color), { background: color, border: (theme) => `1px solid ${alpha(theme.palette.text.primary, 0.1)}`, ml: 0.6 }) }, { children: [_jsx(Colorize, { color: "inherit", fontSize: "inherit" }), _jsx(Box, { overflow: "hidden", component: "input", ref: inputRef, type: "color", value: inputProps.value, onChange: inputProps.onChange, height: 0, width: 0, border: "none", p: 0 })] }))] })), _jsx(Grid, Object.assign({ xs: 12, item: true }, { children: _jsx(Divider, {}) })), colors.map((paletteColor) => (_jsx(Grid, Object.assign({ item: true, xs: 12 / 10 }, { children: _jsx(ColorBox, { color: paletteColor, currentColor: color, updateColor: updateColor }) }), paletteColor.value))), color && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Button, Object.assign({ color: "inherit", onClick: clearColor }, { children: "Clear" })) })))] })));
};
const ColorBox = ({ color: { name, value, isBrightColor }, currentColor, updateColor, }) => {
    return (_jsx(Tooltip, Object.assign({ title: name }, { children: _jsx(Box, Object.assign({ onClick: () => updateColor(value), sx: Object.assign(Object.assign({}, styles.color), { color: isBrightColor ? "black" : "white", border: (theme) => `1px solid ${currentColor === value
                    ? alpha(theme.palette.text.primary, 0.3)
                    : alpha(theme.palette.text.primary, 0.1)}`, backgroundColor: value }) }, { children: currentColor === value ? _jsx(Check, { color: "inherit", fontSize: "inherit" }) : null })) })));
};
const styles = {
    color: {
        width: 24,
        height: 24,
        borderRadius: "50%",
        cursor: "pointer",
        fontSize: 16,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
};
