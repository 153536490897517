var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { flip, offset, shift, useFloatingToolbar } from "@udecode/plate-floating";
import { MarkButtons } from "./MarkButtons";
import { TextTypeMenu } from "./TextTypeMenu";
import { Bolt } from "@mui/icons-material";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { getSelection, useFloatingToolbarState } from "../../hooks/useFloatingToolbarState";
import { useMyEditorRef } from "../../types";
import CustomEditor from "../../utils/CustomEditor";
import { LinkToolbarButton } from "./LinkToolbarButton";
export const CREATE_LINK_BUTTON_CLICKED_EVENT = "create-link-button-clicked-event";
export const CREATE_LINK_BUTTON_ID = "create-link-button-id";
export const FloatingToolbar = (_a) => {
    var _b;
    var { isExtension = false } = _a, props = __rest(_a, ["isExtension"]);
    const isMobile = useIsMobile();
    const selection = getSelection(isExtension);
    const isTextSelected = ((_b = selection === null || selection === void 0 ? void 0 : selection.anchorNode) === null || _b === void 0 ? void 0 : _b.nodeName) === "#text" && (selection === null || selection === void 0 ? void 0 : selection.type) === "Range";
    const editor = useMyEditorRef();
    const floatingToolbarState = useFloatingToolbarState({
        floatingOptions: {
            placement: "top",
            middleware: [
                offset(isMobile ? 55 : 10),
                flip({
                    padding: 12,
                }),
                shift({ padding: 5 }),
            ],
        },
        isExtension,
    });
    const { ref: floatingRef, props: rootProps, hidden } = useFloatingToolbar(floatingToolbarState);
    const shouldHideToolbar = hidden || (isExtension ? false : !isTextSelected);
    const insertReference = () => {
        var _a;
        CustomEditor.insertSearchReferenceText(editor, (_a = window === null || window === void 0 ? void 0 : window.getSelection()) === null || _a === void 0 ? void 0 : _a.toString());
        document.dispatchEvent(new CustomEvent(CREATE_LINK_BUTTON_CLICKED_EVENT));
    };
    if (isMobile && shouldHideToolbar && !floatingToolbarState.isMenuOpen)
        return null;
    if (!isMobile && shouldHideToolbar)
        return null;
    return (_jsxs(Stack, Object.assign({ ref: floatingRef, spacing: 0.4, direction: "row", justifyContent: "center", alignItems: "center", sx: styles.stack }, rootProps, props, { onMouseDown: (e) => {
            e.preventDefault();
        } }, { children: [_jsx(TextTypeMenu, { isOpen: floatingToolbarState.isMenuOpen, setIsOpen: floatingToolbarState.setIsMenuOpen }), _jsx(MarkButtons, { isCodeVisible: !isMobile }), !isExtension && _jsx(LinkToolbarButton, {}), !isExtension && (_jsx(Tooltip, Object.assign({ title: "Create link" }, { children: _jsx(IconButton, Object.assign({ onClick: insertReference, color: "secondary", id: CREATE_LINK_BUTTON_ID }, { children: _jsx(Bolt, {}) })) })))] })));
};
const styles = {
    stack: {
        zIndex: 1201,
        borderRadius: 1,
        px: 0.6,
        border: (theme) => `1px solid ${theme.palette.divider}`,
        py: 0.3,
        backgroundColor: (theme) => theme.palette.background.layout,
        boxShadow: (theme) => theme.shadows[4],
    },
};
