import { Add } from "@mui/icons-material"
import { Box, Button, Modal, SxProps, Theme } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { ItemModel } from "@recall/common"
import { FC, memo, useState } from "react"
import { useSelector } from "react-redux"
import { questionReviewService } from "services/questionReviewService"
import { RootState } from "storage/redux/rootReducer"
import { FormQuestion, QuestionForm } from "./QuestionForm"

interface Props {
    refetchQuestions: () => Promise<void>
    item: ItemModel
    disabled?: boolean
}

const CreateQuestionComponent: FC<Props> = ({ refetchQuestions, item, disabled = false }) => {
    const [open, setOpen] = useState(false)
    const db = useDatabase()
    const uid = useSelector((state: RootState) => state.user.uid)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleSubmit = async (_, data: FormQuestion) => {
        await questionReviewService.createQuestionAndScheduleReview(uid, {
            db,
            item,
            question: data.question,
            correctAnswer: data.correctAnswer,
            options: data.options,
        })

        await refetchQuestions()
    }

    return (
        <>
            <Button
                sx={styles.button}
                color="primary"
                variant="outlined"
                onClick={handleOpen}
                disabled={disabled}
                startIcon={<Add />}
            >
                Create Question Manually
            </Button>
            {open && (
                <Modal open onClose={handleClose}>
                    <Box sx={styles.modal}>
                        <QuestionForm
                            deleteQuestion={async () => {}}
                            handleClose={handleClose}
                            handleFormSubmit={handleSubmit}
                        />
                    </Box>
                </Modal>
            )}
        </>
    )
}

export const CreateQuestion = memo(
    CreateQuestionComponent,
    (prev, next) => prev.item.id === next.item.id && prev.disabled === next.disabled
)

const styles: Record<string, SxProps<Theme>> = {
    modal: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: 400,
        minWidth: 320,
        bgcolor: "background.layout",
        border: (theme) => `1px solid ${theme.palette.background.paper}`,
        boxShadow: 24,
        px: 3,
        py: 2,
        borderRadius: 1,
    },
    button: {
        color: (theme) => theme.palette.text.primary,
    },
}
