import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from "@mui/material"
import {
    FIREFOX_RECALL_EXTENSION_STORE_URL,
    RECALL_EXTENSION_STORE_URL,
    assets,
    isFirefox,
} from "@recall/common"
import { BoldButton } from "components/shared/buttons/BoldButton"
import { InstallExtensionButton } from "components/shared/buttons/InstallExtensionButton"
import { FC, useState } from "react"
import { Video } from "./Video"

interface Props {
    handleSkip: () => void
}

export const InstallExtensionStep: FC<Props> = ({ handleSkip }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const handleOpenDialog = () => {
        setIsDialogOpen(true)
    }

    const handleCloseDialog = () => {
        setIsDialogOpen(false)
    }

    const handleOpenExtensionPage = () => {
        handleCloseDialog()
        window.open(
            !isFirefox() ? RECALL_EXTENSION_STORE_URL : FIREFOX_RECALL_EXTENSION_STORE_URL,
            "_blank"
        )
    }

    return (
        <>
            <Typography variant="h3" fontWeight={600} textAlign="center">
                Install The Browser Extension
            </Typography>
            <Typography variant="h6" textAlign="center">
                To summarize and save any webpage with Recall, install our browser extension
            </Typography>
            <Video videoUrl={assets.INSTALL_BROWSER_EXTENSION_1.url} />
            <InstallExtensionButton onClick={handleOpenExtensionPage} />
            <BoldButton size="small" variant="text" onClick={handleOpenDialog}>
                Skip
            </BoldButton>
            <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Are you sure you want to skip this step?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        To get the best experience with Recall, we recommend installing the browser
                        extension.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleSkip}>
                        Skip anyway
                    </Button>
                    <InstallExtensionButton onClick={handleOpenExtensionPage} />
                </DialogActions>
            </Dialog>
        </>
    )
}
