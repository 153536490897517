import { jsx as _jsx } from "react/jsx-runtime";
import { alpha, Avatar, Chip, Grid } from "@mui/material";
import { useCallback } from "react";
import { assets } from "../assets";
const examples = [
    {
        url: "https://www.youtube.com/watch?v=hFL6qRIJZ_Y",
        title: "Podcast",
        icon: assets.PODCAST_ICON.url,
    },
    {
        url: "https://www.youtube.com/watch?v=Jt7hE12n11s",
        title: "Youtube Video",
        icon: assets.VIDEO_ICON.url,
    },
    {
        url: "https://www.nature.com/articles/d41586-023-02296-z",
        title: "Blog Post",
        icon: assets.BLOG_ICON.url,
    },
    {
        url: "https://www.nytimes.com/2023/02/17/business/china-chatgpt-microsoft-openai.html",
        title: "News Article",
        icon: assets.NEWS_ICON.url,
    },
    {
        url: "https://en.wikipedia.org/wiki/Hedonic_treadmill",
        title: "Wikipedia page",
        icon: assets.WIKIPEDIA_ICON.url,
    },
];
export const ExampleLinks = ({ small = true }) => {
    const makeHandleClick = useCallback((url) => async () => {
        if (window) {
            // @ts-ignore
            window.open(url, "_blank").focus();
            return;
        }
        document.location.href = url;
    }, []);
    return (_jsx(Grid, Object.assign({ container: true, justifyContent: "center", alignItems: "center", spacing: 1.5 }, { children: examples.map((exampleLink) => (_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Chip, { avatar: _jsx(Avatar, { alt: exampleLink.title, src: exampleLink.icon }), onClick: makeHandleClick(exampleLink.url), label: exampleLink.title, sx: styles.chip, size: small ? "small" : undefined }) }), exampleLink.title))) })));
};
const styles = {
    chip: {
        color: alpha("#000000", 0.8),
        fontWeight: 500,
        backgroundColor: alpha("#FFFFFF", 0.3),
        "&:hover": {
            backgroundColor: alpha("#FFFFFF", 0.5),
        },
    },
};
