import { ELEMENT_IMAGE } from "@udecode/plate-media";
import { MAX_DESCRIPTION_LENGTH } from "../../../constants/editor";
import { ELEMENT_CUSTOM_EDITOR_BLOCK } from "../../../editor/plugins/editor-block";
import { ELEMENT_CUSTOM_PARAGRAPH } from "../../../editor/plugins/paragraph";
import { YOUTUBE_TIMESTAMP } from "../../../editor/plugins/youtube-timestamp";
export const getFormattedText = (editorBlocks) => {
    const elements = getTextElements(editorBlocks);
    return elements.map(({ text }) => text).join("");
};
const getTextElements = (editorBlocks, endsWithDot = false) => {
    let texts = [];
    editorBlocks.forEach((child, index) => {
        var _a, _b, _c;
        if (child.connectionId) {
            let text = ((_a = child === null || child === void 0 ? void 0 : child.children) === null || _a === void 0 ? void 0 : _a.length) ? getText(child.children, null) : "";
            text = !Boolean(text) && Boolean(child.text) ? child.text : text;
            texts = [
                ...texts,
                {
                    text: endsWithDot && index === 0 ? " " + text : text,
                    connectionId: child.connectionId,
                },
            ];
        }
        else if (child.children) {
            const isLastEndOfSentence = texts.length > 0 ? (_c = (_b = texts[texts.length - 1]) === null || _b === void 0 ? void 0 : _b.text) === null || _c === void 0 ? void 0 : _c.endsWith(".") : false;
            texts = [...texts, ...getTextElements(child.children, isLastEndOfSentence)];
        }
        else {
            texts = [...texts, { text: (endsWithDot && index === 0 ? " " : "") + child.text }];
        }
    });
    return texts;
};
export const getText = (editorBlocks, maxLength = MAX_DESCRIPTION_LENGTH) => {
    let text = "";
    for (let child of editorBlocks) {
        if (!child || (child === null || child === void 0 ? void 0 : child.type) === YOUTUBE_TIMESTAMP)
            continue;
        if (child.children) {
            text += " " + getText(child.children, maxLength);
        }
        else {
            text += " " + child.text;
        }
        if (maxLength && text.length > maxLength) {
            return text;
        }
    }
    return text.trim();
};
export const getFirstImageBlock = (editorBlocks) => {
    for (let editorBlock of editorBlocks) {
        if (!editorBlock)
            continue;
        if (editorBlock.type === "image" || editorBlock.type === "img") {
            return editorBlock;
        }
        for (let child of editorBlock.children) {
            if (!child)
                continue;
            if (child.type === "image" || child.type === "img") {
                return child;
            }
        }
    }
};
export const isOnlyChildParagraph = (editorBlock) => {
    var _a, _b, _c;
    return (
    // @ts-ignore
    ((_a = editorBlock.children) === null || _a === void 0 ? void 0 : _a.length) === 1 &&
        ((_c = (_b = editorBlock.children) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.type) === ELEMENT_CUSTOM_PARAGRAPH);
};
export const toEditorBlock = (editorBlock) => {
    let children = editorBlock === null || editorBlock === void 0 ? void 0 : editorBlock.children;
    const isEditorBlockElement = !editorBlock.type || editorBlock.type === ELEMENT_CUSTOM_EDITOR_BLOCK;
    if (isEditorBlockElement && isOnlyChildParagraph(editorBlock))
        children = children[0].children;
    return Object.assign({ id: editorBlock.id, children, type: editorBlock.type || ELEMENT_CUSTOM_EDITOR_BLOCK, options: editorBlock.options }, (editorBlock.options || {}));
};
export const removeEditorBlockWithUrl = (editorBlocks, targetUrl) => {
    return editorBlocks
        .map((block) => {
        if (block.children) {
            block.children = removeEditorBlockWithUrl(block.children, targetUrl);
        }
        return block;
    })
        .filter((block) => {
        var _a;
        const hasUrl = block.type === ELEMENT_IMAGE &&
            (block.url === targetUrl || ((_a = block.options) === null || _a === void 0 ? void 0 : _a.url) === targetUrl);
        return !hasUrl;
    });
};
