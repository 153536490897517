import ExtensionIcon from "@mui/icons-material/Extension"
import { Button, ButtonProps } from "@mui/material"
import { SxProps, Theme, alpha } from "@mui/material/styles"
import { FC } from "react"

interface Props extends ButtonProps {
    target?: string
}

export const InstallExtensionButton: FC<Props> = (props) => {
    return (
        <Button
            variant="contained"
            color="secondary"
            startIcon={<ExtensionIcon sx={styles.buttonIcon} />}
            {...props}
        >
            Install Browser Extension
        </Button>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    buttonIcon: {
        color: alpha("#000", 0.6),
        "&:hover:": {
            color: alpha("#000", 0.8),
        },
    },
}
