import { FC, memo } from "react"

import { PushPinOutlined } from "@mui/icons-material"
import { Box, Tooltip, Typography } from "@mui/material"
import { SxProps, Theme } from "@mui/material/styles"
import { useDatabase } from "@nozbe/watermelondb/react"
import { ItemModel, tagRepository } from "@recall/common"
import { useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { isCardShared } from "storage/redux/user/selector"
import { MentionCountBadge } from "../../badges/MentionCountBadge"
import { SharedBadge } from "../../badges/SharedBadge"
import { RERENDER_TAGS_EVENT } from "../../tags/hooks/useGroupedTags"
import { DescriptionComp } from "./DescriptionComp"
import { ListImage } from "./ListImage"
import { Tags } from "./Tags"

interface Props {
    item: ItemModel
    isSelected: boolean
}

const ListLayoutComponent: FC<Props> = ({ isSelected, item }) => {
    const db = useDatabase()

    const [isPinned, setIsPinned] = useState(false)
    const isShared = useSelector((state: RootState) => isCardShared(state, item.id))

    const handlePin = async (e, item: ItemModel) => {
        e.preventDefault()
        e.stopPropagation()
        setIsPinned(true)
        await item.setIsReference(!item.isReference)
        const tags = await item.tags.fetch()
        await tagRepository.updateTagsReferenceState(db, tags, item)
        document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
    }

    return (
        <Box sx={{ opacity: isSelected ? 0.6 : 1, height: 145 }}>
            <Box display="flex" justifyContent="space-between" height="100%">
                <Box
                    p={1}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    flex={3}
                    flexGrow={3}
                >
                    <Box>
                        <Typography sx={styles.name} variant="h6">
                            {item.name}
                        </Typography>

                        <DescriptionComp description={item.description} />
                    </Box>

                    <Box pt={0.5} display="flex">
                        <Tags tags={item.tags} />
                    </Box>
                </Box>
                {item.image && <ListImage image={item.image} />}
            </Box>

            <Box sx={{ ...styles.mentions, bottom: 2 }}>
                {!isPinned && item.isReference && (
                    <Tooltip
                        title={
                            <Typography variant="body2">
                                <b>Pin</b> this card
                            </Typography>
                        }
                    >
                        <Box sx={styles.pin} onClick={(e) => handlePin(e, item)}>
                            <PushPinOutlined sx={styles.pinIcon} />
                        </Box>
                    </Tooltip>
                )}
                <MentionCountBadge item={item} />
            </Box>
            {isShared && (
                <Box sx={{ position: "absolute", top: 8, left: 8 }}>
                    <SharedBadge />
                </Box>
            )}
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    content: {
        py: 1,
        px: 1,
    },
    flexGrow: {
        flexGrow: 1,
    },
    name: {
        fontFamily: (theme) => theme.typography.secondaryFontFamily,
        lineHeight: 1.2,
        fontSize: "1rem",
        fontWeight: 500,
        mb: 0.5,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
    },
    marginRight: {
        mr: (theme) => theme.spacing(3),
    },
    mentions: {
        display: "flex",
        position: "absolute",
        right: 4,
        columnGap: 0.4,
        height: 30,
    },
    pin: {
        color: (theme) => theme.palette.grey[200],
    },
    pinIcon: {
        p: 0.3,
        transform: "rotate(45deg)",
        border: (theme) => `2px solid ${theme.palette.action.disabledBackground}`,
        borderRadius: "50%",
        background: (theme) => theme.palette.action.disabledBackground,
        color: (theme) => theme.palette.text.secondary,
        transition: "transform 0.2s",
        "&:hover": {
            transform: "scale(1.05)",
            background: (theme) => theme.palette.action.selected,
        },
    },
}

export const ListLayout = memo(ListLayoutComponent)
