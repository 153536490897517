import { HOME_PATH } from "constants/routes"
import { RootState } from "../rootReducer"
import { SET_BREADCRUMBS } from "./actionTypes"
import { BreadcrumbItem } from "./types"

export const addBreadcrumbAction =
    (breadcrumb: BreadcrumbItem, lastPosition = 0) =>
    (dispatch: Function, getState: Function) => {
        const state: RootState = getState()
        const breadcrumbs = state.app.breadcrumbs || [{ label: "Home", path: HOME_PATH }]
        const index = breadcrumbs.findIndex((b) => b.path === breadcrumb.path)

        if (index !== -1) {
            return dispatch({
                type: SET_BREADCRUMBS,
                payload: [...breadcrumbs.slice(0, index), breadcrumb],
            })
        }

        if (breadcrumbs.length <= 1)
            return dispatch({
                type: SET_BREADCRUMBS,
                payload: [...breadcrumbs, breadcrumb],
            })

        const oldBreadcrumbs = breadcrumbs.slice(0, breadcrumbs.length - 1)
        const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1]

        dispatch({
            type: SET_BREADCRUMBS,
            payload: [...oldBreadcrumbs, { ...lastBreadcrumb, position: lastPosition }, breadcrumb],
        })
    }

export const removeBreadcrumbsAfterAction =
    (path: string) => (dispatch: Function, getState: Function) => {
        const state: RootState = getState()
        const breadcrumbs = state.app.breadcrumbs || [{ label: "Home", path: HOME_PATH }]
        const index = breadcrumbs.findIndex((b) => b.path === path)

        dispatch({
            type: SET_BREADCRUMBS,
            payload: index !== -1 ? breadcrumbs.slice(0, index + 1) : breadcrumbs,
        })
    }

export const popBreadcrumbAction =
    (redirect: (path: string) => void) => (dispatch: Function, getState: Function) => {
        const state: RootState = getState()
        const breadcrumbs = state.app.breadcrumbs || []
        if (!breadcrumbs.length)
            return dispatch({
                type: SET_BREADCRUMBS,
                payload: [{ label: "Home", path: HOME_PATH }],
            })

        const newBreadcrumbs = breadcrumbs.slice(0, breadcrumbs.length - 1)
        dispatch({
            type: SET_BREADCRUMBS,
            payload: newBreadcrumbs,
        })

        const lastBreadcrumb = newBreadcrumbs[newBreadcrumbs.length - 1]
        if (!lastBreadcrumb) {
            dispatch({
                type: SET_BREADCRUMBS,
                payload: [{ label: "Home", path: HOME_PATH }],
            })
            redirect(HOME_PATH)
        } else {
            redirect(lastBreadcrumb.path)
        }
    }

export const updateLastLabelAction =
    (label: string) => (dispatch: Function, getState: Function) => {
        const state: RootState = getState()
        const breadcrumbs = state.app.breadcrumbs || []

        if (!breadcrumbs.length) return

        const oldBreadcrumbs = breadcrumbs.slice(0, breadcrumbs.length - 1)
        const lastBreadcrumb = oldBreadcrumbs[oldBreadcrumbs.length - 1]

        dispatch({
            type: SET_BREADCRUMBS,
            payload: [...oldBreadcrumbs, { ...lastBreadcrumb, label }],
        })
    }
