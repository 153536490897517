import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { SummaryLengthToggle } from "./SummaryLengthToggle";
export const SummaryLengthForm = () => {
    const { control } = useFormContext();
    return (_jsx(Controller, { control: control, name: "summaryLength", render: ({ field: { onChange, value } }) => (_jsxs(Box, Object.assign({ display: "flex", flexDirection: "column" }, { children: [_jsx(Typography, Object.assign({ fontWeight: 500, variant: "h5" }, { children: "Summary Length" })), _jsx(Typography, Object.assign({ variant: "caption", color: "text.secondary" }, { children: "Choose the default length for your summaries." })), _jsx(Box, Object.assign({ mt: 1 }, { children: _jsx(SummaryLengthToggle, { value: value, onChange: onChange }) })), _jsx(Typography, Object.assign({ variant: "body2", sx: { mt: 1 } }, { children: value === "concise"
                        ? "Concise summaries provide a brief overview of the main points of the content."
                        : "Detailed summaries offer a comprehensive breakdown of the content and aim to capture all important points." }))] }))) }));
};
