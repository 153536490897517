import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { alpha, Box, Chip, keyframes, SxProps, Theme, Typography } from "@mui/material"
import { Asset, getTheme } from "@recall/common"
import { FC } from "react"
import { BillingPeriod } from "services/paymentService"
import { ActionButton } from "./ActionButton"

const lightTheme = getTheme("light")

export interface Props {
    features: string[]
    image: Asset
    billingPeriod: BillingPeriod
    showAction: boolean
    priceComponent: JSX.Element
    isBold?: boolean
    isBusiness?: boolean
    isFree?: boolean
    highlighted?: boolean
}

const glow = keyframes`
 0% { box-shadow: 0px 0px 12px ${lightTheme.palette.secondary.light} } 
 100% { box-shadow: 0px 0px 2px ${lightTheme.palette.secondary.light} }                 
`

export const PricingCard: FC<Props> = ({
    features,
    image,
    billingPeriod,
    showAction,
    priceComponent,
    isBold = false,
    isBusiness = false,
    isFree = false,
    highlighted = false,
}) => {
    const styles = makeStyles(isBold)

    return (
        <Box
            sx={{
                ...styles.card,
            }}
        >
            <Box component="img" alt={image.name} src={image.url} mb={2} />
            {priceComponent}
            <Box flexGrow={1} my={2}>
                {features.map((feature) => (
                    <Box key={feature} sx={styles.featureRow}>
                        <CheckCircleIcon sx={styles.featureIcon} />
                        <Typography ml={1}>{feature}</Typography>
                    </Box>
                ))}
            </Box>
            {highlighted && (
                <Box sx={styles.mostPopular}>
                    <Chip
                        label="Most Popular"
                        color="secondary"
                        sx={{
                            fontWeight: 600,
                            animation: `2s ${glow} linear infinite alternate`,
                        }}
                    />
                </Box>
            )}
            {showAction && (
                <ActionButton
                    isBusiness={isBusiness}
                    isFree={isFree}
                    billingPeriod={billingPeriod}
                />
            )}
        </Box>
    )
}

const makeStyles = (isBold: boolean): Record<string, SxProps<Theme>> => ({
    card: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        p: 2,
        m: 1,
        borderRadius: 2,
        maxWidth: 300,
        backgroundColor: isBold
            ? lightTheme.palette.secondary.main
            : alpha(lightTheme.palette.background.paper, 0.8),
    },
    featureRow: {
        display: "flex",
        alignItems: "flex-start",
        py: 0.5,
    },
    featureIcon: {
        color: (theme) => theme.palette.primary.dark,
    },
    mostPopular: {
        position: "absolute",
        top: 0,
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
})
