import { jsx as _jsx } from "react/jsx-runtime";
import CircleIcon from "@mui/icons-material/Circle";
import { Tooltip, Typography } from "@mui/material";
export const SavedBadge = (props) => {
    let description = "Saved card";
    let color = "success";
    if (props.isReference) {
        description = "Unpinned card";
        color = "secondary";
    }
    return (_jsx(Tooltip, Object.assign({ title: _jsx(Typography, Object.assign({ variant: "body2" }, { children: description })) }, { children: _jsx(CircleIcon, { sx: {
                minWidth: "24px",
                fontSize: "0.9rem",
                verticalAlign: "text-top",
                color: (theme) => theme.palette[color].main,
            } }) })));
};
export default SavedBadge;
