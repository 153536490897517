import { useEffect } from "react";
export const useTabFocus = (callback) => {
    useEffect(() => {
        const handleVisibilityChange = (e) => {
            if (!document.hidden)
                callback(e);
        };
        window.addEventListener("visibilitychange", handleVisibilityChange);
        return () => window.removeEventListener("visibilitychange", handleVisibilityChange);
    }, [callback]);
};
