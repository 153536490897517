export const SET_BRIGHTNESS_MODE = "SET_BRIGHTNESS_MODE"
export const SET_LOADING = "SET_LOADING"
export const SET_IMPORT_STATE = "SET_IMPORT_STATE"
export const SET_APP_LOADING = "SET_APP_LOADING"
export const SET_USER_LOADING = "SET_USER_LOADING"
export const SET_EXTENSION_STATE = "SET_EXTENSION_STATE"
export const SET_IS_UPGRADE_MODAL_OPEN = "SET_IS_UPGRADE_MODAL_OPEN"
export const SET_IS_COST_USAGE_MODAL_OPEN = "SET_IS_COST_USAGE_MODAL_OPEN"
export const SET_IS_SCRAPING_MODAL_OPEN = "SET_IS_SCRAPING_MODAL_OPEN"
export const INCREASE_VISIBLE_ITEMS = "INCREASE_VISIBLE_ITEMS"
export const SET_SCROLL_POSITION = "SET_SCROLL_POSITION"
export const RESET_SCROLL_STATE = "RESET_SCROLL_STATE"
export const SET_IS_NATIVE = "SET_IS_NATIVE"
export const ADD_LOADING_QUESTION_ITEM_ID = "ADD_LOADING_QUESTION_ITEM_ID"
export const REMOVE_LOADING_QUESTION_ITEM_ID = "REMOVE_LOADING_QUESTION_ITEM_ID"
export const SET_BREADCRUMBS = "SET_BREADCRUMBS"
export const SET_IS_INITIAL_SYNC_FINISHED = "SET_IS_INITIAL_SYNC_FINISHED"
export const SET_IS_CREATING_EXTENSION_ITEMS = "SET_IS_CREATING_EXTENSION_ITEMS"
export const SET_SEARCH_TERM = "SET_SEARCH_TERM"
