import { languages } from "../../constants";
import { SummaryLengthEnum } from "../../repositories";
export class SummaryOptionsService {
    constructor(userRepository) {
        this.getLanguageByCode = (languageCode) => { var _a; return (_a = languages === null || languages === void 0 ? void 0 : languages[languageCode]) === null || _a === void 0 ? void 0 : _a.label; };
        this.getDefaultSummaryOptions = () => {
            return {
                language: "auto",
                searchLanguage: "en",
                defaultLength: SummaryLengthEnum.detailed,
            };
        };
        this.getSummaryOptions = async (uid) => {
            const user = await this.userRepository.getUser(uid);
            const defaultOptions = this.getDefaultSummaryOptions();
            const mergedOptions = Object.assign(Object.assign({}, defaultOptions), user === null || user === void 0 ? void 0 : user.summaryOptions);
            return mergedOptions;
        };
        this.updateSummaryOptions = async (uid, options) => {
            await this.userRepository.upsertUser(uid, { summaryOptions: options });
        };
        this.userRepository = userRepository;
    }
}
