import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { useUsageStatusUtils } from "./useUsageStatusUtils"

export const useUsageStatus = () => {
    const userId = useSelector((state: RootState) => state.user.uid)
    const isPremium = useSelector((state: RootState) => state.user.isPremiumUser)

    const { checkIsCostUsageExceeded, checkAreSummariesEnabled, checkIsBookmarksImportEnabled } =
        useUsageStatusUtils()

    const [usageStatus, setUsageStatus] = useState({
        isBookmarksImportEnabled: false,
        isGeneratingSummariesEnabled: false,
        isCostUsageExceeded: false,
        isLoading: true,
    })

    const checkUsageStatus = useCallback(async () => {
        const [isCostUsageExceeded, isGeneratingSummariesEnabled, isBookmarksImportEnabled] =
            await Promise.all([
                checkIsCostUsageExceeded(),
                checkAreSummariesEnabled(),
                checkIsBookmarksImportEnabled(),
            ])

        setUsageStatus({
            isCostUsageExceeded,
            isGeneratingSummariesEnabled,
            isBookmarksImportEnabled,
            isLoading: false,
        })
        // eslint-disable-next-line
    }, [isPremium, userId])

    useEffect(() => {
        checkUsageStatus()
    }, [checkUsageStatus])

    return usageStatus
}
