import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Skeleton } from "@mui/material";
import { styled } from "@mui/system";
const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
    marginBottom: 4,
    marginLeft: 8,
    borderRadius: 4,
}));
const LoadingSearchResult = () => {
    return (_jsxs(Box, Object.assign({ sx: {
            display: "flex",
            p: 1,
        } }, { children: [_jsx(Skeleton, { variant: "circular", width: 60, height: 60 }), _jsxs(Box, Object.assign({ sx: {
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                } }, { children: [_jsx(StyledSkeleton, { height: 30, width: "60%" }), _jsx(StyledSkeleton, { height: 10, width: "80%" }), _jsx(StyledSkeleton, { height: 10, width: "85%" })] }))] })));
};
export default LoadingSearchResult;
