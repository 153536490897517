import { Discount } from "@mui/icons-material"
import { Box, Button, Fade, SxProps, Theme, Typography } from "@mui/material"
import { posthogService, sentry } from "@recall/common"
import { LoadingButton } from "components/shared/buttons/LoadingButton"
import { CLAIM_COUPON_CONFIRM_BUTTON_CLICKED } from "constants/events"
import { FC, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { functions } from "services/firebase/firebaseFunction"
import { MONTHLY_PRICING, paymentService } from "services/paymentService"
import { RootState } from "storage/redux/rootReducer"

interface Props {
    referralsCount: number
    handleClose: () => void
    handleShowCoupons: () => void
}

export const ConfirmSection: FC<Props> = ({ referralsCount, handleClose, handleShowCoupons }) => {
    const uid = useSelector((state: RootState) => state.user.uid)
    const isPremium = useSelector((state: RootState) => state.user.isPremiumUser)
    const [claimStatus, setClaimStatus] = useState<"idle" | "loading" | "claimed">("idle")

    const handleClaimCoupon = async () => {
        try {
            setClaimStatus("loading")
            posthogService.captureEvent(CLAIM_COUPON_CONFIRM_BUTTON_CLICKED)

            const response = await functions.claimPromoCode()
            const promoCodeId = response.data

            if (!promoCodeId) throw new Error("Something went wrong")

            if (!isPremium) paymentService.createCheckout(uid, MONTHLY_PRICING, { promoCodeId })

            setClaimStatus("claimed")
        } catch (error) {
            toast.error("Something went wrong")
            sentry.captureException(error, { uid })
            setClaimStatus("idle")
        }
    }

    const isLoading = claimStatus === "loading"

    return claimStatus === "claimed" ? (
        <Fade in={true}>
            <Box sx={styles.container}>
                <Typography>
                    You have claimed your coupon, you can see all your coupons by clicking the
                    button below.
                </Typography>
                <Box sx={styles.center} mt={2} mb={1}>
                    <Button onClick={handleShowCoupons} startIcon={<Discount />}>
                        My coupons
                    </Button>
                </Box>
            </Box>
        </Fade>
    ) : (
        <Fade in={true}>
            <Box sx={styles.container}>
                <Typography>
                    Are you sure you want to claim {referralsCount * 10}% off coupon?
                </Typography>
                <Box sx={styles.center} mt={2} gap={2}>
                    <Button
                        sx={styles.button}
                        disabled={isLoading}
                        variant="outlined"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        disabled={isLoading}
                        variant="contained"
                        color="secondary"
                        onClick={handleClaimCoupon}
                        sx={styles.button}
                    >
                        Claim
                    </LoadingButton>
                </Box>
            </Box>
        </Fade>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "space-between",
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    button: {
        minWidth: 100,
    },
}
