import { getMark, usePlateEditorState } from "@udecode/plate-common";
import { usePlateEditorRef } from "@udecode/plate-core";
import { focusEditor } from "@udecode/slate-react";
import { removeMark } from "@udecode/slate-utils";
import { isDefined } from "@udecode/utils";
import { withoutNormalizing } from "@udecode/slate";
import castArray from "lodash/castArray";
export const isMarkActive = (editor, type) => {
    const mark = getMark(editor, type);
    return Boolean(isDefined(mark) && mark);
};
export const useMarkToolbarButtonState = ({ nodeType, clear, }) => {
    const editor = usePlateEditorState();
    const pressed = !!(editor === null || editor === void 0 ? void 0 : editor.selection) && isMarkActive(editor, nodeType);
    return {
        pressed,
        nodeType,
        clear,
    };
};
export const useMarkToolbarButton = (state) => {
    const editor = usePlateEditorRef();
    return {
        props: {
            pressed: state.pressed,
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleMark(editor, { key: state.nodeType, clear: state.clear });
                focusEditor(editor);
            },
        },
    };
};
export const toggleMark = (editor, { key, clear }) => {
    if (!editor.selection)
        return;
    withoutNormalizing(editor, () => {
        const isActive = isMarkActive(editor, key);
        if (isActive) {
            removeMark(editor, { key });
            return;
        }
        if (clear) {
            const clears = castArray(clear);
            removeMark(editor, { key: clears });
        }
        editor.addMark(key, true);
    });
};
