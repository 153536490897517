import { Q } from "@nozbe/watermelondb";
import { subDays } from "date-fns";
import { map } from "lodash";
import { assetRepository, sourceRepository, tagRepository } from ".";
import { SummaryLengthEnum } from "../../../repositories";
import { isWikipediaUrl } from "../../../utils";
import { ITEMS } from "../schema";
const observe = (db) => {
    return db.collections.get(ITEMS).query().observe();
};
const observeCount = (db, isSaved = true, isReference) => {
    if (isReference !== undefined) {
        return db.collections
            .get(ITEMS)
            .query(Q.and(Q.where("is_saved", isSaved), Q.where("is_reference", isReference)))
            .observeCount(false);
    }
    return db.collections
        .get(ITEMS)
        .query(Q.where("is_saved", isSaved))
        .observeCount(false);
};
const getByTags = async ({ db, includeRefs, tags }) => {
    let query = db.collections
        .get(ITEMS)
        .query(Q.where("is_saved", true), Q.sortBy("updated_at", "desc"));
    if (!includeRefs) {
        query = query.extend(Q.where("is_reference", false));
    }
    if (tags.length) {
        const itemTags = await tagRepository.getItemTagsByTagIds(db, tags);
        const itemIds = map(itemTags, (itemTag) => itemTag.item.id || null);
        query = query.extend(Q.where("id", Q.oneOf(itemIds)));
    }
    return query.fetch();
};
const getByName = async (db, name) => {
    const items = await db.collections
        .get(ITEMS)
        .query(Q.where("name", name), Q.where("is_saved", true))
        .fetch();
    return items.length > 0 ? items[0] : null;
};
const getBySearchParams = async ({ db, search, includeRefs, tags }) => {
    let query = db.collections
        .get(ITEMS)
        .query(Q.where("name", Q.like(`%${Q.sanitizeLikeString(search)}%`)), Q.where("is_saved", true), Q.sortBy("updated_at", "desc"));
    if (!includeRefs) {
        query = query.extend(Q.where("is_reference", false));
    }
    if (tags.length) {
        const itemTags = await tagRepository.getItemTagsByTagIds(db, tags);
        const itemIds = map(itemTags, (itemTag) => itemTag.item.id || null);
        query = query.extend(Q.where("id", Q.oneOf(itemIds)));
    }
    return query.fetch();
};
const postFilterSearchItems = (items, searchTerm, limit) => {
    if (!searchTerm) {
        return items.sort((a, b) => b.createdAt - a.createdAt).slice(0, limit);
    }
    searchTerm = searchTerm.toLowerCase();
    const filteredItems = items.filter((item) => {
        const lowerCaseName = item.name.toLowerCase();
        return lowerCaseName.startsWith(searchTerm) || lowerCaseName.includes(` ${searchTerm}`);
    });
    return filteredItems.sort((a, b) => b.createdAt - a.createdAt).slice(0, limit);
};
const search = async (db, search, includeRefs, limit) => {
    let items = await getBySearchParams({
        db,
        search,
        includeRefs,
        tags: [],
    });
    items = postFilterSearchItems(items, search, limit);
    return items;
};
const getByItemIds = (db, itemIds) => {
    return db.collections
        .get(ITEMS)
        .query(Q.where("id", Q.oneOf(itemIds)))
        .fetch();
};
const getBySources = async (db, sources, onlySaved = false) => {
    for (const source of sources) {
        const item = await getAnyBySource({
            db,
            sourceIdentifier: source.identifier,
            sourceName: source.name,
        });
        if (!item || (onlySaved && !(item === null || item === void 0 ? void 0 : item.isSaved)))
            continue;
        return item;
    }
    return null;
};
const getItemsBySourceIdentifiers = async (db, identifiers) => {
    const sources = await sourceRepository.getByIds(db, identifiers);
    return await Promise.all(sources.map((source) => source.item.fetch()));
};
const getBySource = async ({ db, sourceIdentifier, sourceName }) => {
    let source = await sourceRepository.get(db, sourceIdentifier, sourceName);
    if (!source)
        return null;
    return source.item.fetch();
};
const getAnyBySource = async ({ db, sourceIdentifier, sourceName }) => {
    let source = await sourceRepository.getAny(db, sourceIdentifier, sourceName);
    if (!source)
        return null;
    return source.item.fetch();
};
const getOrCreate = (db, item) => {
    return db.write(async (writer) => {
        for (const source of item.sources) {
            const itemModel = await getBySource({
                db,
                sourceIdentifier: source.identifier,
                sourceName: source.name,
            });
            if (!itemModel)
                continue;
            return itemModel;
        }
        const existingItem = await get(db, item.id);
        if (existingItem)
            return existingItem;
        return await writer.callWriter(() => createFull(db, item));
    });
};
const createFull = (db, item) => {
    return db.write(async (writer) => {
        const itemModel = await writer.callWriter(() => create(db, item, item.isSaved));
        await writer.callWriter(() => itemModel.addEditorBlocks(item.editorBlocks, item.isSaved));
        await writer.callWriter(() => itemModel.addSources(item.sources, item.isSaved));
        return itemModel;
    });
};
const create = async (db, item, isSaved = false) => {
    return await db.write(async () => {
        const itemModel = await db.collections.get(ITEMS).create((record) => {
            record._raw.id = item.id;
            record.name = item.name;
            record.isReference = item.isReference;
            record.isSaved = isSaved;
            record.isExpanded = item.isExpanded || false;
            if (item.language)
                record.language = item.language;
            if (item.image)
                record.image = item.image;
            record.description = item.description;
            record.length = item.length;
        });
        return itemModel;
    });
};
const observeById = (db, itemId) => {
    return db.collections.get(ITEMS).findAndObserve(itemId);
};
const get = async (db, itemId) => {
    try {
        return await db.collections.get(ITEMS).find(itemId);
    }
    catch (e) {
        return null;
    }
};
const getByIds = async (db, ids) => {
    return await db.collections
        .get(ITEMS)
        .query(Q.where("id", Q.oneOf(ids)))
        .fetch();
};
const deleteUnsaved = async (db) => {
    await db.write(async (writer) => {
        const unsavedItems = await db.collections
            .get(ITEMS)
            .query(Q.where("is_saved", false))
            .fetch();
        let tasks = (await Promise.all(unsavedItems.map(async (i) => await i.prepareDelete()))).flat();
        tasks = tasks.filter((a) => !!a);
        const uniqueTasks = Array.from(new Set(tasks.map((a) => a.id))).map((id) => {
            return tasks.find((a) => a.id === id);
        });
        await writer.batch(...uniqueTasks);
    });
};
const deleteStale = async (db) => {
    let items = await db.collections
        .get(ITEMS)
        .query(Q.where("is_reference", true))
        .fetch();
    for (const item of items) {
        const isStale = await item.isStale();
        if (!isStale)
            continue;
        await item.delete();
    }
};
const deleteAllStale = async (db) => {
    const fiveDaysAgo = subDays(new Date(), 5);
    const items = await db.collections
        .get(ITEMS)
        .query(Q.and(Q.where("is_saved", false), Q.where("created_at", Q.lt(fiveDaysAgo.getTime()))))
        .fetch();
    for (const item of items) {
        const isStale = await item.isStale();
        if (!isStale)
            continue;
        await assetRepository.deleteByItemId(db, item.id);
        await item.delete();
    }
};
const getCountByTypeIds = async (db, typeIds, inlcudeReferences) => {
    let query = db.collections
        .get(ITEMS)
        .query(Q.where("type_id", Q.oneOf(typeIds)), Q.where("is_saved", true));
    if (inlcudeReferences === false) {
        query = query.extend(Q.where("is_reference", false));
    }
    return await query.fetchCount();
};
const getQueryAll = (db, includeReferences) => {
    let query = db.collections.get(ITEMS).query();
    if (includeReferences) {
        query = query.extend(Q.where("is_reference", true));
    }
    else {
        query = query.extend(Q.where("is_reference", false));
    }
    return query;
};
const getQuery = (db, references) => {
    let query = db.collections.get(ITEMS).query(Q.where("is_saved", true));
    if (references === true) {
        query = query.extend(Q.where("is_reference", true));
    }
    else {
        query = query.extend(Q.where("is_reference", false));
    }
    return query;
};
const getOrdered = (db, references) => {
    if (!references) {
        return db.collections
            .get(ITEMS)
            .query(Q.and(Q.where("is_saved", true), Q.where("is_reference", false)))
            .fetch();
    }
    else {
        return db.collections
            .get(ITEMS)
            .query(Q.and(Q.where("is_saved", true)))
            .fetch();
    }
};
const getCount = (db, references) => {
    let query = getQuery(db, references);
    return query.fetchCount();
};
const getAll = async (db) => {
    return await db.collections.get(ITEMS).query().fetch();
};
const getUntaggedItems = async (db) => {
    let query = db.collections.get(ITEMS).query(Q.where("is_saved", true));
    const items = await query.fetch();
    const untaggedItems = await Promise.all(items.map(async (item) => {
        const tagsCount = await item.tags.count;
        return tagsCount === 0 ? item : false;
    }));
    return untaggedItems.filter(Boolean);
};
const getAllBySourceIdAndLanguage = async (db, id, summaryLength, language = "en") => {
    const sources = await sourceRepository.getAllById(db, id);
    const items = (await Promise.all(sources.map((source) => source.item))).flat();
    const item = items.find((existingItem) => {
        if (isWikipediaUrl(id)) {
            return existingItem.language === language;
        }
        const existingItemLength = existingItem.length || SummaryLengthEnum.detailed;
        const existingItemLanguage = existingItem.language || "en";
        return existingItemLanguage === language && existingItemLength === summaryLength;
    });
    return item;
};
export const itemRepository = {
    observe,
    observeById,
    observeCount,
    get,
    getQuery,
    getQueryAll,
    getByItemIds,
    getBySearchParams,
    getBySources,
    getBySource,
    getByIds,
    getCount,
    getCountByTypeIds,
    search,
    getOrCreate,
    createFull,
    create,
    deleteUnsaved,
    deleteStale,
    getAll,
    getUntaggedItems,
    getItemsBySourceIdentifiers,
    getOrdered,
    getByTags,
    getByName,
    getAllBySourceIdAndLanguage,
    getAnyBySource,
    deleteAllStale,
};
