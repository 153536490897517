import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, alpha } from "@mui/material";
import { Stack } from "@mui/material";
import { MarkButtons } from "./MarkButtons";
import { TextTypeMenu } from "./TextTypeMenu";
import { IconList, IconListNumbers } from "@tabler/icons-react";
import { MARK_BG_COLOR, MARK_COLOR } from "@udecode/plate-font";
import { ListStyleType } from "@udecode/plate-indent-list";
import React, { useState } from "react";
import { ColorDropDownMenu } from "../ColorPicker/ColorDropDownMenu";
import { EmojiDropDownMenu } from "../EmojiPicker/EmojiDropDownMenu";
import { AlignToolbarButton } from "./AlignToolbarButton";
import { ImageToolbarButton } from "./ImageToolbarButton";
import { IndentListToolbarButton } from "./IndentListToolbarButton";
import { LinkToolbarButton } from "./LinkToolbarButton";
const FixedToolbarComponent = ({ uploadImage }) => {
    const [isTextMenuOpen, setIsTextMenuOpen] = useState(false);
    return (_jsx(Box, Object.assign({ sx: styles.toolbar }, { children: _jsxs(Stack, Object.assign({ spacing: 1, rowGap: 0.6, direction: "row", flexWrap: "wrap", justifyContent: "center", alignItems: "center", onMouseDown: (e) => {
                e.preventDefault();
            } }, { children: [_jsx(TextTypeMenu, { isOpen: isTextMenuOpen, setIsOpen: setIsTextMenuOpen }), _jsx(MarkButtons, {}), _jsx(LinkToolbarButton, {}), _jsx(ColorDropDownMenu, { tooltip: "Text Color", nodeType: MARK_COLOR }), _jsx(ColorDropDownMenu, { tooltip: "Highlight Color", nodeType: MARK_BG_COLOR }), _jsx(Box, Object.assign({ px: 0.5, height: 20 }, { children: _jsx(Divider, { orientation: "vertical" }) })), _jsx(AlignToolbarButton, {}), _jsx(IndentListToolbarButton, Object.assign({ nodeType: ListStyleType.Disc }, { children: _jsx(IconList, {}) })), _jsx(IndentListToolbarButton, Object.assign({ nodeType: ListStyleType.Decimal }, { children: _jsx(IconListNumbers, {}) })), _jsx(Box, Object.assign({ px: 0.5, height: 20 }, { children: _jsx(Divider, { orientation: "vertical" }) })), _jsx(ImageToolbarButton, { uploadImage: uploadImage }), _jsx(EmojiDropDownMenu, {})] })) })));
};
export const FixedToolbar = React.memo(FixedToolbarComponent, () => true);
const styles = {
    toolbar: {
        position: "sticky",
        bottom: 0,
        left: 0,
        width: "100%",
        zIndex: 1001,
        p: 1,
        borderRadius: 1,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        background: (theme) => alpha(theme.palette.background.layout, 0.8),
        backdropFilter: "blur(2px)",
    },
};
