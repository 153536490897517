import { Q } from "@nozbe/watermelondb";
import { v4 as uuid } from "uuid";
import { QUESTIONS } from "../schema";
const getQuestionsByItemId = async (db, id) => {
    const questions = await db.get(QUESTIONS).query(Q.where("item_id", id)).fetch();
    return questions;
};
const getQuestionsForReview = async (db) => {
    return await db
        .get(QUESTIONS)
        .query(Q.where("next_review_time", Q.lte(Date.now())))
        .fetch();
};
const getAll = async (db) => {
    return await db.get(QUESTIONS).query().fetch();
};
const observeCount = (db) => {
    return db.collections.get(QUESTIONS).query().observeCount(false);
};
const create = async ({ db, item, question, correctAnswer, options }) => {
    return await db.write(async () => {
        const reviewQuestion = await db.collections
            .get(QUESTIONS)
            .create((record) => {
            record._raw.id = uuid();
            record.item.set(item);
            record.isSaved = true;
            record.question = question;
            record.options = options;
            record.correctAnswer = correctAnswer;
            record.answer = null;
            record.nextReviewTime = new Date();
        });
        return reviewQuestion;
    });
};
const update = async ({ db, question, data }) => {
    return await db.write(async () => {
        const updatedQuestion = await question.update((record) => {
            record.answer = data.answer;
            record.question = data.question || record.question;
            record.correctAnswer = data.correctAnswer || record.correctAnswer;
            record.options = data.options || record.options;
            record.nextReviewTime = data.nextReviewTime || record.nextReviewTime;
        });
        return updatedQuestion;
    });
};
const getPendingQuestionsForReview = async (db) => {
    return await db
        .get(QUESTIONS)
        .query(Q.where("next_review_time", Q.gt(Date.now())))
        .fetch();
};
const getUreviewedCount = async (db) => {
    return await db
        .get(QUESTIONS)
        .query(Q.where("next_review_time", Q.lte(Date.now())))
        .fetchCount();
};
export const questionRepository = {
    getAll,
    getQuestionsByItemId,
    getQuestionsForReview,
    getPendingQuestionsForReview,
    create,
    update,
    getUreviewedCount,
    observeCount,
};
