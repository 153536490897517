import { remarkDefaultTextRules, remarkTransformNode } from "@udecode/plate-serializer-md";
// Use dynamic import when buildinf cloud functions
import markdown from "remark-parse";
import { unified } from "unified";
import { createMyPlateEditor } from "../types";
import { remarkDefaultElementRules } from "./remarkElementRules";
export const deserializeMd = async (data, plugins) => {
    const editor = createMyPlateEditor({ plugins });
    // Import is dynamic because of cloud functions, remark-parse doesn't support cjs https://github.com/remarkjs/remark/issues/969#issuecomment-1070543329
    // const { unified } = await import("unified")
    // const markdown = await import("remark-parse")
    const tree = unified()
        .use(markdown)
        // .use(markdown.default)
        .use(remarkPlugin, {
        editor,
        elementRules: remarkDefaultElementRules,
        textRules: remarkDefaultTextRules,
    })
        .processSync(data);
    return tree.result;
};
export function remarkPlugin(options) {
    const compiler = (node) => {
        return node.children.flatMap((child) => remarkTransformNode(child, options));
    };
    // @ts-ignore
    this.Compiler = compiler;
}
