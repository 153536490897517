const QUESTIONS_PATH = "/questions/";
export class QuestionsApi {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    async generateQuestions(text, existingQuestions, headers) {
        try {
            const response = await this.httpClient.post(QUESTIONS_PATH, JSON.stringify({
                text,
                model: "gpt-4",
                existing_questions: existingQuestions || null,
            }), headers);
            return await response.json();
        }
        catch (_a) {
            return null;
        }
    }
}
