import { SxProps, Theme, Typography } from "@mui/material"
import { FC } from "react"

interface Props {
    description: string
}

export const DescriptionComp: FC<Props> = ({ description }) => {
    if (!description) return null

    return (
        <Typography variant="body2" sx={styles.root}>
            {description}
        </Typography>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    root: {
        mb: 0.5,
        fontFamily: (theme) => theme.typography.secondaryFontFamily,
        fontWeight: 400,
        fontSize: "0.75rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
    },
}
