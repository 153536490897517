import { Box, SxProps, Theme, Typography } from "@mui/material"
import { Spinner } from "@recall/common"
import { AuthLayout } from "components/layouts/AuthLayout"

export const AppLoader = () => {
    return (
        <AuthLayout isContained={false}>
            <Loader />
        </AuthLayout>
    )
}

export const Loader = ({ loadingText = "Loading..." }) => {
    return (
        <Box sx={styles.loader}>
            <Spinner />
            <Typography variant="h6">{loadingText}</Typography>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 4,
        pt: 6,
        pb: 4,
        px: 8,
    },
}
