import { addDoc, collection, onSnapshot } from "@firebase/firestore"
import { FIRESTORE_COLLECTIONS, RECALL_APP_URL, firebase } from "@recall/common"
import { Stripe, loadStripe } from "@stripe/stripe-js"
import { toast } from "react-toastify"

let stripePromise: Stripe | null

const CUSTOMER_BILLING_PORTAL = "https://billing.stripe.com/p/login/dR63emaamcVN2SQdQQ"
const PUBLISHABLE_KEY =
    "pk_live_51M02k8KLKMKbaiD6RSkU3YcW1MZPUeVC5AAUgOceWXUu33zmaufFfvSj2s84iQnJjY8CD9BSZs12WEFQw8BkhSwn003lgJVtPr"

const initializeStripe = async () => {
    if (!stripePromise) {
        stripePromise = await loadStripe(PUBLISHABLE_KEY)
    }
    return stripePromise
}

export const MONTHLY_PRICING = "monthly"
export const YEARLY_PRICING = "yearly"
export const LIFETIME_PRICING = "lifetime"

const PRICE_MAP = {
    [MONTHLY_PRICING]: "price_1N00FGKLKMKbaiD6C1oGH21V",
    [YEARLY_PRICING]: "price_1N00FGKLKMKbaiD6BBa4vjBn",
    [LIFETIME_PRICING]: "price_1NiAlNKLKMKbaiD6re1deI2Y",
}

export type BillingPeriod = typeof MONTHLY_PRICING | typeof YEARLY_PRICING | typeof LIFETIME_PRICING

const createCheckout = async (
    userId: string,
    billingPeriod: BillingPeriod,
    cfg?: { promoCodeId?: string }
) => {
    if (!userId) return
    toast("Redirecting you to the checkout page.", { type: "success" })

    const isLifetime = billingPeriod === LIFETIME_PRICING

    const price_id = PRICE_MAP[billingPeriod]

    const sessionsRef = collection(
        firebase.firestore,
        FIRESTORE_COLLECTIONS.USERS,
        userId,
        FIRESTORE_COLLECTIONS.CHECKOUT_SESSIONS
    )

    const checkoutSession = {
        price: price_id,
        success_url: `${RECALL_APP_URL}/items?show-thank-you-modal=true`,
        cancel_url: window.location.origin,
        after_expiration: {
            recovery: {
                enabled: true,
                allow_promotion_codes: true,
            },
        },
    }

    // @ts-ignore
    if (isLifetime) checkoutSession.mode = "payment"

    if (cfg?.promoCodeId) {
        // @ts-ignore
        checkoutSession.promotion_code = cfg?.promoCodeId
    } else {
        // @ts-ignore
        checkoutSession.allow_promotion_codes = true
    }

    const docRef = await addDoc(sessionsRef, checkoutSession)

    onSnapshot(docRef, async (snapshot) => {
        const session = snapshot.data()

        if (!session?.sessionId) return

        const stripe = await initializeStripe()

        if (!stripe) return

        stripe.redirectToCheckout({ sessionId: session.sessionId })
    })
}

const openBillingPortal = async () => {
    window.open(CUSTOMER_BILLING_PORTAL, "_blank")
}

export const paymentService = {
    createCheckout,
    openBillingPortal,
}
