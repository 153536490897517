import { focusEditor } from "@udecode/plate-common";
import { useEffect } from "react";
import { Transforms } from "slate";
import { useMyEditorState } from "../types";
import CustomEditor from "../utils/CustomEditor";
export const INSERT_SEARCH_REFERENCE_EVENT = "event-insert-search-reference";
export const useConnectionDeleted = () => {
    const editor = useMyEditorState();
    useEffect(() => {
        const handleRemoveConnection = (e) => {
            const connectionId = e.detail.connectionId;
            setTimeout(() => Transforms.unwrapNodes(editor, {
                at: [],
                match: (node) => node.connectionId === connectionId,
            }));
        };
        const insertSearchReference = () => {
            focusEditor(editor);
            CustomEditor.insertSearchReference(editor, true);
        };
        document.addEventListener(INSERT_SEARCH_REFERENCE_EVENT, insertSearchReference);
        document.addEventListener("connectionDeleted", handleRemoveConnection);
        return () => {
            document.removeEventListener("connectionDeleted", handleRemoveConnection);
            document.removeEventListener(INSERT_SEARCH_REFERENCE_EVENT, insertSearchReference);
        };
    }, [editor]);
};
