import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Collapse, List, ListItem, ListItemButton, ListItemText, ListSubheader, } from "@mui/material";
import { isKeyHotkey } from "is-hotkey";
import { isEqual } from "lodash";
import { memo, useEffect, useState } from "react";
import { TransitionGroup } from "react-transition-group";
import DropdownPopper from "./components/DropdownPopper";
import LoadingSearchResult from "./components/LoadingSearchResult";
import SearchResultComp from "./components/SearchResult";
const DEFAULT_INDEX = -1;
const SearchResultsComp = ({ results, loading, onSelectResult, closeOnEmptyBackspace, value, open, anchorEl, subheaderText, onClose, getSummaryPreview, }) => {
    const [selectedIndex, setSelectedIndex] = useState(DEFAULT_INDEX);
    useEffect(() => {
        setSelectedIndex(DEFAULT_INDEX);
    }, [open]);
    useEffect(() => {
        if (open) {
            document.addEventListener("keydown", handleSearchKeyDown);
        }
        return () => document.removeEventListener("keydown", handleSearchKeyDown);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, selectedIndex, results]);
    const handleSearchKeyDown = (e) => {
        if (isKeyHotkey("enter", e)) {
            e.preventDefault();
            const result = results[selectedIndex];
            if (result) {
                onSelectResult(result);
            }
        }
        else if (isKeyHotkey("backspace", e)) {
            if (value.length === 0 && closeOnEmptyBackspace === true) {
                e.preventDefault();
                onClose(true);
            }
        }
        else if (isKeyHotkey("escape", e)) {
            e.preventDefault();
            onClose(true);
        }
        else if (isKeyHotkey("down", e)) {
            e.preventDefault();
            let nextSelectedIndex = selectedIndex + 1;
            if (nextSelectedIndex < results.length) {
                setSelectedIndex(nextSelectedIndex);
            }
        }
        else if (isKeyHotkey("up", e)) {
            e.preventDefault();
            let nextSelectedIndex = selectedIndex - 1;
            if (nextSelectedIndex >= 0) {
                setSelectedIndex(nextSelectedIndex);
            }
        }
    };
    const preventFocus = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    let content = _jsx(_Fragment, {});
    if (loading) {
        content = (_jsxs(Collapse, { children: [_jsx(LoadingSearchResult, {}), _jsx(LoadingSearchResult, {}), _jsx(LoadingSearchResult, {}), _jsx(LoadingSearchResult, {})] }));
    }
    else if (results.length) {
        content = results.map((result, index) => {
            var _a;
            let key = "id-create-option";
            let isCreateOption = !!(result === null || result === void 0 ? void 0 : result.isCreateOption);
            if (!isCreateOption) {
                key = result.id;
            }
            return (_jsxs(Collapse, { children: [_jsx(ListItemButton, Object.assign({ sx: isCreateOption
                            ? Object.assign(Object.assign({}, styles.listItemRoot), { justifyContent: "center", backgroundColor: "action.selected", pb: 2 }) : styles.listItemRoot, selected: selectedIndex === index, onClick: () => onSelectResult(result), onMouseDown: preventFocus, "data-testid": "cards-search-result" }, { children: _jsx(SearchResultComp, { result: result, value: value, selected: selectedIndex === index, getSummaryPreview: getSummaryPreview }) })), results.length === 1 && !((_a = results[0]) === null || _a === void 0 ? void 0 : _a.isGenerateSummaryOption) && (_jsx(ListItem, { children: _jsx(ListItemText, { primary: "No matching results." }) }))] }, key));
        });
    }
    else {
        content = subheaderText ? (_jsx(Collapse, { children: _jsx(ListSubheader, Object.assign({ sx: {
                    fontSize: "17px",
                    lineHeight: "1.5em",
                    py: 1.5,
                }, component: "div" }, { children: subheaderText })) })) : null;
    }
    return (_jsx(DropdownPopper, Object.assign({ open: open, anchorEl: anchorEl }, { children: _jsx(List, Object.assign({ component: "nav", sx: styles.popover }, { children: _jsx(TransitionGroup, { children: content }) })) })));
};
export const SearchResults = memo(SearchResultsComp, (prev, next) => isEqual(prev.results, next.results) &&
    prev.loading === next.loading &&
    prev.open === next.open);
const styles = {
    listItemRoot: {
        p: (theme) => `${theme.spacing(0.5)} ${theme.spacing(1)} !important`,
    },
    popover: {
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: (theme) => theme.palette.background.paper,
    },
};
