import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote";
import { ELEMENT_CODE_BLOCK, ELEMENT_CODE_LINE } from "@udecode/plate-code-block";
import { getPluginType } from "@udecode/plate-common";
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3, ELEMENT_H4, ELEMENT_H5, ELEMENT_H6, } from "@udecode/plate-heading";
import { ELEMENT_HR } from "@udecode/plate-horizontal-rule";
import { ELEMENT_OL, ELEMENT_UL } from "@udecode/plate-list";
import { ELEMENT_LINK } from "@udecode/plate-link";
import { ELEMENT_IMAGE } from "@udecode/plate-media";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import { remarkTransformNode } from "@udecode/plate-serializer-md";
import { isYoutubeVideo } from "../../utils";
import { dates } from "../../utils/dates";
import { ELEMENT_REFERENCE } from "../plugins/reference";
import { YOUTUBE_TIMESTAMP } from "../plugins/youtube-timestamp";
export const remarkTransformElementChildren = (node, options) => {
    const { children } = node;
    if (!(children === null || children === void 0 ? void 0 : children.length))
        return [{ text: "" }];
    return children.flatMap((child) => remarkTransformNode(child, options));
};
export const remarkDefaultElementRules = {
    heading: {
        transform: (node, options) => {
            var _a;
            const headingType = {
                1: ELEMENT_H1,
                2: ELEMENT_H2,
                3: ELEMENT_H3,
                4: ELEMENT_H4,
                5: ELEMENT_H5,
                6: ELEMENT_H6,
            }[(_a = node.depth) !== null && _a !== void 0 ? _a : 1];
            return {
                type: getPluginType(options.editor, headingType),
                children: [...remarkTransformElementChildren(node, options), { text: "" }],
            };
        },
    },
    list: {
        transform: (node, options) => {
            return {
                type: getPluginType(options.editor, node.ordered ? ELEMENT_OL : ELEMENT_UL),
                children: remarkTransformElementChildren(node, options),
            };
        },
    },
    listItem: {
        transform: (node, options) => {
            var _a, _b, _c;
            const indent = ((_b = (_a = node === null || node === void 0 ? void 0 : node.position) === null || _a === void 0 ? void 0 : _a.start) === null || _b === void 0 ? void 0 : _b.column) || 1;
            let children = remarkTransformElementChildren(node, options);
            if ((children === null || children === void 0 ? void 0 : children.length) === 1 && ((_c = children[0]) === null || _c === void 0 ? void 0 : _c.children)) {
                children = children[0].children;
            }
            return {
                type: getPluginType(options.editor, ELEMENT_PARAGRAPH),
                children,
                indent,
                listStyleType: "disc",
            };
        },
    },
    paragraph: {
        transform: (node, options) => {
            const children = [...remarkTransformElementChildren(node, options), { text: "" }];
            const paragraphType = getPluginType(options.editor, ELEMENT_PARAGRAPH);
            const splitBlockTypes = new Set([getPluginType(options.editor, ELEMENT_IMAGE)]);
            const elements = [];
            let inlineNodes = [];
            const flushInlineNodes = () => {
                if (inlineNodes.length > 0) {
                    elements.push({
                        type: paragraphType,
                        children: inlineNodes,
                    });
                    inlineNodes = [];
                }
            };
            children.forEach((child) => {
                const { type } = child;
                if (type && splitBlockTypes.has(type)) {
                    flushInlineNodes();
                    elements.push(child);
                }
                else {
                    inlineNodes.push(child);
                }
            });
            flushInlineNodes();
            return elements;
        },
    },
    link: {
        transform: (node, options) => {
            var _a, _b;
            const text = (_b = (_a = node === null || node === void 0 ? void 0 : node.children) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.value;
            if (text && dates.isTimeFormat(text) && node.url && isYoutubeVideo(node.url)) {
                return {
                    type: YOUTUBE_TIMESTAMP,
                    url: node.url,
                    children: remarkTransformElementChildren(node, options),
                };
            }
            if (["https", "mailto"].some((startFormat) => { var _a; return (_a = node === null || node === void 0 ? void 0 : node.url) === null || _a === void 0 ? void 0 : _a.startsWith(startFormat); }))
                return {
                    type: getPluginType(options.editor, ELEMENT_LINK),
                    url: node.url,
                    children: remarkTransformElementChildren(node, options),
                };
            return {
                type: ELEMENT_REFERENCE,
                connectionId: node.url,
                children: remarkTransformElementChildren(node, options),
            };
        },
    },
    image: {
        transform: (node, options) => ({
            type: getPluginType(options.editor, ELEMENT_IMAGE),
            children: [{ text: "" }],
            url: node.url,
            width: 400,
            caption: [{ text: node.alt }],
        }),
    },
    blockquote: {
        transform: (node, options) => {
            return {
                type: getPluginType(options.editor, ELEMENT_BLOCKQUOTE),
                children: node.children.flatMap((paragraph) => remarkTransformElementChildren(paragraph, options)),
            };
        },
    },
    code: {
        transform: (node, options) => {
            var _a;
            return ({
                type: getPluginType(options.editor, ELEMENT_CODE_BLOCK),
                lang: (_a = node.lang) !== null && _a !== void 0 ? _a : undefined,
                children: (node.value || "").split("\n").map((line) => ({
                    type: getPluginType(options.editor, ELEMENT_CODE_LINE),
                    children: [{ text: line }],
                })),
            });
        },
    },
    thematicBreak: {
        transform: (node, options) => ({
            type: getPluginType(options.editor, ELEMENT_HR),
            children: [{ text: "" }],
        }),
    },
};
