var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Model, Q } from "@nozbe/watermelondb";
import { children, date, field, relation, writer } from "@nozbe/watermelondb/decorators";
import { compact, map, orderBy } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { sentry } from "../../../utils";
import { getFirstImageBlock, getFormattedText, getText, toEditorBlock, } from "../helpers/editorBlocks";
import { EDITOR_BLOCKS, EDITOR_ORDERS, ITEM_TAG, SOURCES, TAGS } from "../schema";
import { getOrderedModels } from "../services/editorBlocks";
import { dbUtils } from "../utils";
export class ItemModel extends Model {
    constructor() {
        super(...arguments);
        this.toItemPartial = async () => {
            const [sources, editorBlockModels] = await Promise.all([
                (await this.sources.fetch()).map((source) => source.toSource()),
                this.getOrderedEditorBlocks(),
            ]);
            const editorBlocks = editorBlockModels.map((editorBlock) => toEditorBlock(editorBlock));
            const description = getText(editorBlocks);
            const image = getFirstImageBlock(editorBlocks);
            const images = [];
            if (image)
                images.push(image);
            return {
                id: this.id,
                name: this.name,
                description: description,
                images: images,
                editorBlocks: editorBlocks,
                createdAt: this.createdAt,
                updatedAt: this.updatedAt,
                isReference: this.isReference,
                isSaved: this.isSaved,
                sources: sources,
                language: this.language,
                length: this.length,
            };
        };
        this.getTags = async () => {
            const itemTags = await this.itemTags.fetch();
            const orderedItemTags = orderBy(itemTags, "createdAt");
            const tags = (await Promise.all(orderedItemTags.map(async (itemTag) => {
                try {
                    const tag = await itemTag.tag.fetch();
                    return tag;
                }
                catch (e) {
                    sentry.captureException(e);
                    return null;
                }
            }))).filter(Boolean);
            return compact(tags);
        };
        this.getLinkedItems = async () => {
            const links = await this.links.fetch();
            const linkedItems = await Promise.all(links.map(async (link) => await link.to.fetch()));
            return linkedItems;
        };
        this.getMentionedItems = async () => {
            const mentions = await this.mentions.fetch();
            const mentionedItems = await Promise.all(mentions.map(async (mention) => await mention.from.fetch()));
            return mentionedItems;
        };
        this.getOrderedEditorBlocks = async () => {
            const editorOrders = await this.editorOrders.fetch();
            const editorBlocks = await this.editorBlocks.fetch();
            if (editorOrders.length === 0)
                return [];
            return getOrderedModels(editorBlocks, editorOrders[0]);
        };
        this.getEditorOrder = async () => {
            return (await this.editorOrders.fetch())[0];
        };
        this.getSource = async (sourceName) => {
            const sources = await this.sources.fetch();
            for (let source of sources) {
                if (source.name === sourceName) {
                    return source;
                }
            }
            return null;
        };
        this.prepareSaveDeep = async () => {
            let tasks = await this.prepareSave();
            const itemsToSave = await this.getLinkedItems();
            for (let itemModel of itemsToSave) {
                // TODO - does this make sense
                // const moreTasks = await itemModel.prepareSaveDeep()
                const moreTasks = await itemModel.prepareSave();
                tasks = [...tasks, ...moreTasks];
            }
            return tasks;
        };
        this.prepareSave = async () => {
            let tasks = [];
            if (this.isSaved === false) {
                tasks.push(this.prepareUpdate((record) => {
                    record.isSaved = true;
                }));
                const sourceTasks = (await this.sources.fetch()).map((source) => source.prepareSave());
                const editorBlockTasks = (await this.editorBlocks.fetch()).map((editorBlock) => editorBlock.prepareSave());
                const editorOrderTasks = (await this.editorOrders.fetch()).map((editorOrder) => editorOrder.prepareSave());
                const connectionTasks = (await this.links.fetch()).map((link) => link.prepareSave());
                const tagsTasks = (await Promise.all((await this.tags.fetch()).map((tag) => tag.prepareSave()))).flat();
                const itemTagTasks = (await this.itemTags.fetch()).map((itemTag) => itemTag.prepareSave());
                tasks = [
                    ...tasks,
                    ...sourceTasks,
                    ...editorBlockTasks,
                    ...editorOrderTasks,
                    ...connectionTasks,
                    ...tagsTasks,
                    ...itemTagTasks,
                ];
            }
            return tasks;
        };
        this.prepareAddSource = (source, isSaved = true) => {
            return this.collections.get(SOURCES).prepareCreate((record) => {
                record._raw.id = source.id;
                record.item.set(this);
                record.name = source.name;
                record.identifier = source.identifier;
                record.isSaved = isSaved;
            });
        };
        this.prepareDelete = async () => {
            let tasks = [];
            // @ts-ignore
            if (this._preparedState === null) {
                if (this.isSaved) {
                    tasks.push(this.prepareMarkAsDeleted());
                }
                else {
                    tasks.push(this.prepareDestroyPermanently());
                }
            }
            const sources = await this.sources.fetch();
            const editorBlocks = await this.editorBlocks.fetch();
            const editorOrders = await this.editorOrders.fetch();
            const mentions = await this.mentions.fetch();
            const links = await this.links.fetch();
            const itemTags = await this.itemTags.fetch();
            const questions = await this.questions.fetch();
            tasks = [
                ...tasks,
                ...(await Promise.all(links.map(async (l) => await l.prepareDeleteWithStaleItem(false)))).flat(),
                ...mentions.map((m) => m.prepareDelete()),
                ...sources.map((s) => s.prepareDelete()),
                ...editorBlocks.map((eb) => eb.prepareDelete()),
                ...editorOrders.map((eo) => eo.prepareDelete()),
                ...itemTags.map((t) => t.prepareDelete()),
                ...questions.map((q) => q.prepareDelete()),
            ];
            return tasks;
        };
        this.isStale = async () => {
            if (this.isSaved === false) {
                return true;
            }
            return false;
        };
        this.isEmpty = async () => {
            const orderedEditorBlocks = await this.getOrderedEditorBlocks();
            const text = getText(orderedEditorBlocks);
            if (this.name === "" && text.length === 0) {
                return true;
            }
            return false;
        };
    }
    get links() {
        return this.collections
            .get("connections")
            .query(Q.where("from_id", Q.eq(this.id)));
    }
    get mentions() {
        return this.collections
            .get("connections")
            .query(Q.where("to_id", Q.eq(this.id)));
    }
    get itemTags() {
        return this.collections.get(ITEM_TAG).query(Q.where("item_id", this.id));
    }
    get tags() {
        return this.collections
            .get(TAGS)
            .query(Q.on(ITEM_TAG, Q.where("item_id", this.id)));
    }
    async setIsReference(isReference) {
        await this.update((record) => {
            record.isReference = isReference;
        });
    }
    async setCreatedAt(createdAt) {
        await this.update((record) => {
            record.createdAt = createdAt;
        });
    }
    async setUpdatedAt(createdAt) {
        await this.update((record) => {
            record.updatedAt = createdAt;
        });
    }
    async setIsExpanded(isExpanded) {
        await this.update((record) => {
            record.isExpanded = isExpanded;
        });
    }
    async merge(item) {
        let tasks = [];
        const editorBlocks = await this.getOrderedEditorBlocks();
        let newEditorBlocks = await item.getOrderedEditorBlocks();
        let nextEditorOrder = [];
        newEditorBlocks = newEditorBlocks.filter((editorBlock) => !JSON.stringify(editorBlock.children).includes('"type":"image"') &&
            editorBlock.type !== "h1" &&
            editorBlock.type !== "img");
        nextEditorOrder = [...map(editorBlocks, "id"), ...map(newEditorBlocks, "id")];
        const editorOrderModel = await this.getEditorOrder();
        if (editorOrderModel)
            tasks.push(editorOrderModel.prepareUpdate((record) => {
                record.order = nextEditorOrder;
            }));
        else
            tasks.push(this.collections
                .get(EDITOR_ORDERS)
                .prepareCreate((record) => {
                record._raw.id = uuidv4();
                record.item.id = this.id;
                record.order = nextEditorOrder;
                record.isSaved = this.isSaved;
            }));
        const editorBlocksToSaveTasks = newEditorBlocks.map((editorBlock) => editorBlock.prepareUpdate((record) => {
            record.item.id = this.id;
            record.isSaved = true;
        }));
        tasks = [...tasks, ...editorBlocksToSaveTasks];
        tasks.push(this.prepareUpdate((record) => {
            record.isExpanded = true;
            record.isReference = false;
        }));
        const existingLinks = await this.links.fetch();
        const links = await item.links.fetch();
        const linkedItems = await Promise.all(links.map(async (link) => await link.to.fetch()));
        for (const linkedItem of linkedItems) {
            const linkedItemTasks = await linkedItem.prepareSave();
            tasks = [...tasks, ...linkedItemTasks];
        }
        for (const link of links) {
            if (existingLinks.some((existingLink) => existingLink.to.id === link.to.id))
                continue;
            tasks.push(link.prepareUpdate((record) => {
                record.from.id = this.id;
                record.isSaved = true;
            }));
        }
        const existingTags = await this.tags.fetch();
        const itemTagsToSet = (await item.itemTags.fetch()).filter((itemTag) => existingTags.every((tag) => tag.id !== itemTag.tag.id));
        const tagIdsToCreate = map(itemTagsToSet, "tag.id");
        const tagsToCreate = (await item.tags.fetch()).filter((tag) => tagIdsToCreate.includes(tag.id));
        for (const tag of tagsToCreate) {
            const tagToCreate = await tag.prepareSave();
            if (!tagToCreate)
                continue;
            tasks = [...tasks, ...tagToCreate];
        }
        const itemTagTasks = itemTagsToSet.map((itemTag) => itemTag.prepareUpdate((record) => {
            record.item.set(this);
            record.isSaved = true;
        }));
        const sourceIdentifiers = (await this.sources.fetch()).map((source) => source.identifier);
        const newSources = await item.sources.fetch();
        const sourceTasks = newSources
            .filter((source) => !sourceIdentifiers.includes(source.identifier))
            .map((source) => source.prepareUpdate((record) => {
            record.item.set(this);
        }));
        const sourceDeleteTasks = newSources
            .filter((source) => sourceIdentifiers.includes(source.identifier))
            .map((source) => source.prepareDelete());
        tasks = [...tasks, ...sourceTasks, ...sourceDeleteTasks, ...itemTagTasks];
        tasks.push(item.prepareDestroyPermanently());
        await this.batch(...tasks);
        await this.callWriter(() => this.updateItemTagReferenceStatus());
    }
    async updateItemTagReferenceStatus() {
        const tags = await this.tags.fetch();
        // await this.callWriter(() =>
        //     tagRepository.updateTagsReferenceState(this.database, tags, this)
        // )
    }
    async saveDeep() {
        let tasks = await this.prepareSaveDeep();
        await this.batch(...tasks);
    }
    async searchEditorBlocks(searchTerm) {
        return await this.editorBlocks
            .extend(Q.where("text", Q.like(`%${dbUtils.sanitizeSearchTerm(searchTerm)}%`)))
            .fetch();
    }
    async save() {
        const tasks = await this.prepareSave();
        await this.batch(...tasks);
    }
    async updateName(name) {
        await this.update((record) => {
            record.name = name;
        });
    }
    async updateImage(image) {
        await this.update((record) => {
            record.image = image;
        });
    }
    async updateDescription(description) {
        await this.update((record) => {
            record.description = description;
        });
    }
    async updateLanguage(language) {
        await this.update((record) => {
            record.language = language;
        });
    }
    async updateUpdatedAt() {
        await this.update((record) => { });
    }
    async addSource(source, isSaved = true) {
        const task = this.prepareAddSource(source, isSaved);
        await this.batch(task);
    }
    async addSources(sources, isSaved = true) {
        const collection = this.collections.get(SOURCES);
        const tasks = sources.map((source) => collection.prepareCreate((record) => {
            record._raw.id = source.id;
            record.item.set(this);
            record.name = source.name;
            record.identifier = source.identifier;
            record.isSaved = isSaved;
        }));
        await this.batch(...tasks);
    }
    async addEditorBlocks(editorBlocks, isSaved = true) {
        const collection = this.collections.get(EDITOR_BLOCKS);
        const tasks = editorBlocks.map((editorBlock) => {
            const { id, children, type } = editorBlock, options = __rest(editorBlock, ["id", "children", "type"]);
            return collection.prepareCreate((record) => {
                record._raw.id = id;
                record.item.set(this);
                record.children = children;
                record.isSaved = isSaved;
                record.type = type;
                record.options = options || {};
                record.text = getFormattedText([editorBlock]);
            });
        });
        const editorOrder = editorBlocks.map((editorBlock) => editorBlock.id);
        tasks.push(this.collections
            .get(EDITOR_ORDERS)
            .prepareCreate((record) => {
            record._raw.id = uuidv4();
            record.item.id = this.id;
            record.order = editorOrder;
            record.isSaved = isSaved;
        }));
        await this.batch(...tasks);
    }
    // TODO delete, prepareDelete, etc can be shared in base class.
    async delete() {
        const tasks = await this.prepareDelete();
        await this.batch(...tasks);
    }
}
ItemModel.table = "items";
ItemModel.associations = {
    sources: { type: "has_many", foreignKey: "item_id" },
    editor_orders: { type: "has_many", foreignKey: "item_id" },
    editor_blocks: { type: "has_many", foreignKey: "item_id" },
    item_tag: { type: "has_many", foreignKey: "item_id" },
    review_questions: { type: "has_many", foreignKey: "item_id" },
    question_reviews: { type: "has_many", foreignKey: "item_id" },
    assets: { type: "has_many", foreignKey: "item_id" },
};
__decorate([
    field("name")
], ItemModel.prototype, "name", void 0);
__decorate([
    field("is_saved")
], ItemModel.prototype, "isSaved", void 0);
__decorate([
    field("is_reference")
], ItemModel.prototype, "isReference", void 0);
__decorate([
    field("is_expanded")
], ItemModel.prototype, "isExpanded", void 0);
__decorate([
    field("language")
], ItemModel.prototype, "language", void 0);
__decorate([
    field("description")
], ItemModel.prototype, "description", void 0);
__decorate([
    field("image")
], ItemModel.prototype, "image", void 0);
__decorate([
    field("length")
], ItemModel.prototype, "length", void 0);
__decorate([
    children("sources")
], ItemModel.prototype, "sources", void 0);
__decorate([
    children("editor_orders")
], ItemModel.prototype, "editorOrders", void 0);
__decorate([
    children("editor_blocks")
], ItemModel.prototype, "editorBlocks", void 0);
__decorate([
    children("review_questions")
], ItemModel.prototype, "questions", void 0);
__decorate([
    children("assets")
], ItemModel.prototype, "assets", void 0);
__decorate([
    relation("types", "type_id")
], ItemModel.prototype, "type", void 0);
__decorate([
    date("created_at")
], ItemModel.prototype, "createdAt", void 0);
__decorate([
    date("updated_at")
], ItemModel.prototype, "updatedAt", void 0);
__decorate([
    writer
], ItemModel.prototype, "setIsReference", null);
__decorate([
    writer
], ItemModel.prototype, "setCreatedAt", null);
__decorate([
    writer
], ItemModel.prototype, "setUpdatedAt", null);
__decorate([
    writer
], ItemModel.prototype, "setIsExpanded", null);
__decorate([
    writer
], ItemModel.prototype, "merge", null);
__decorate([
    writer
], ItemModel.prototype, "updateItemTagReferenceStatus", null);
__decorate([
    writer
], ItemModel.prototype, "saveDeep", null);
__decorate([
    writer
], ItemModel.prototype, "save", null);
__decorate([
    writer
], ItemModel.prototype, "updateName", null);
__decorate([
    writer
], ItemModel.prototype, "updateImage", null);
__decorate([
    writer
], ItemModel.prototype, "updateDescription", null);
__decorate([
    writer
], ItemModel.prototype, "updateLanguage", null);
__decorate([
    writer
], ItemModel.prototype, "updateUpdatedAt", null);
__decorate([
    writer
], ItemModel.prototype, "addSource", null);
__decorate([
    writer
], ItemModel.prototype, "addSources", null);
__decorate([
    writer
], ItemModel.prototype, "addEditorBlocks", null);
__decorate([
    writer
], ItemModel.prototype, "delete", null);
