var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Model } from "@nozbe/watermelondb";
import { date, field, json, readonly, relation, writer } from "@nozbe/watermelondb/decorators";
import { TAGS } from "../schema";
const sanitizeTagChildren = (data) => data;
export class TagModel extends Model {
    constructor() {
        super(...arguments);
        this.prepareSave = async () => {
            if (this.isSaved)
                return null;
            let tasks = [];
            const parent = await this.parent.fetch();
            if ((parent === null || parent === void 0 ? void 0 : parent.isSaved) === false) {
                const parentTasks = await parent.prepareSave();
                tasks = [...tasks, ...parentTasks];
            }
            const task = this.prepareUpdate((record) => {
                record.isSaved = true;
            });
            tasks.push(task);
            return tasks;
        };
        this.prepareDelete = () => {
            if (this._preparedState === null) {
                if (this.isSaved) {
                    return this.prepareMarkAsDeleted();
                }
                else {
                    return this.prepareDestroyPermanently();
                }
            }
        };
    }
    async setIsReference(isReference) {
        await this.update((record) => {
            record.isReference = isReference;
        });
    }
    async delete() {
        const task = this.prepareDelete();
        await this.batch(task);
    }
}
TagModel.table = TAGS;
TagModel.associations = {
    item_tag: { type: "has_many", foreignKey: "tag_id" },
    tags: { type: "belongs_to", key: "parent_id" },
};
__decorate([
    field("name")
], TagModel.prototype, "name", void 0);
__decorate([
    field("is_saved")
], TagModel.prototype, "isSaved", void 0);
__decorate([
    field("is_reference")
], TagModel.prototype, "isReference", void 0);
__decorate([
    json("children", sanitizeTagChildren)
], TagModel.prototype, "children", void 0);
__decorate([
    relation("tags", "parent_id")
], TagModel.prototype, "parent", void 0);
__decorate([
    readonly,
    date("created_at")
], TagModel.prototype, "createdAt", void 0);
__decorate([
    readonly,
    date("updated_at")
], TagModel.prototype, "updatedAt", void 0);
__decorate([
    writer
], TagModel.prototype, "setIsReference", null);
__decorate([
    writer
], TagModel.prototype, "delete", null);
