import { Box, SxProps, Theme } from "@mui/material"
import { FC } from "react"

interface Props {
    videoUrl: string
}

export const Video: FC<Props> = ({ videoUrl }) => {
    return (
        <Box sx={styles.video} component="video" autoPlay loop muted>
            <source src={videoUrl} type="video/mp4" />
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    video: {
        border: (theme) => `2px solid ${theme.palette.divider}`,
        borderRadius: 0.5,
        boxShadow: 6,
        width: { xs: "100%", md: "640px" },
    },
}
