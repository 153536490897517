import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { OutgoingLinkIcon } from "../icons/LinkIcons/OutgoingLinkIcon";
import { Link } from "./Link";
export const LINKS_DIV_ID = "id-links";
export const Links = ({ itemId, groupedLinks, connectionsCount, readOnly, handleDelete, openItemById, }) => {
    return (_jsxs(Grid, Object.assign({ item: true, xs: 12, alignItems: "center" }, { children: [_jsxs(Box, Object.assign({ id: LINKS_DIV_ID, mb: 1, display: "flex", alignItems: "center", gap: 1 }, { children: [_jsx(Tooltip, Object.assign({ title: `This card links to ${connectionsCount} other card${connectionsCount > 1 ? "s" : ""}` }, { children: _jsx(Box, { children: _jsx(OutgoingLinkIcon, { count: connectionsCount }) }) })), _jsx(Tooltip, Object.assign({ sx: { cursor: "default" }, title: "These are the links from this card to other cards" }, { children: _jsx(Typography, Object.assign({ variant: "h5" }, { children: "Links" })) }))] })), _jsx(Grid, Object.assign({ container: true, spacing: 0, sx: { borderRadius: 1, overflow: "hidden" } }, { children: groupedLinks.map((sortedGroup) => (_jsxs(Grid, Object.assign({ container: true, item: true, xs: 12, sx: styles.linkGroup }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Typography, Object.assign({ sx: styles.linkGroupTitle, variant: "subtitle1" }, { children: sortedGroup.key })) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: sortedGroup.value.map((link) => (_jsx(Link, { readOnly: readOnly, item: link.item, connectionId: link.id, handleDelete: handleDelete, openItemById: openItemById }, itemId + link.id))) }))] }), sortedGroup.key))) }))] })));
};
const styles = {
    select: {
        minWidth: "180px",
    },
    menu: {
        "&.MuiMenu-paper": {
            backgroundColor: "blue",
        },
        "& .MuiList-root": {
            pt: 0,
            pb: 0,
        },
    },
    linkGroupTitle: {
        mb: 0.5,
    },
    linkGroup: {
        mb: 0.2,
        backgroundColor: "background.default",
        p: (theme) => theme.spacing(0.5, 1),
    },
};
