import { isEqual, uniq } from "lodash"
import { useEffect, useMemo } from "react"
import { useParams } from "react-router"
import { GroupedTags, Tag } from "./useGroupedTags"

interface FocusItemProps {
    tags: GroupedTags
    expandedTags: string[]
    makeTagVisibleInTree: (id: string) => void
    handleChangeExpanded: (ids: string[]) => void
}

export const useFocusItem = ({
    tags,
    expandedTags,
    makeTagVisibleInTree,
    handleChangeExpanded,
}: FocusItemProps) => {
    const { id: itemId } = useParams<{ id: string }>()

    const tag = useMemo(() => {
        return Object.values(tags).find((tag) => {
            if (!itemId) return null

            return tag.items.some(({ item }) => item.id === itemId)
        })
    }, [tags, itemId])

    useEffect(() => {
        if (!tag || !expandedTags) return
        expandTagsTreeToItem()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tag, itemId])

    const expandTagsTreeToItem = () => {
        const parentTagsToExpand = getAllParentIds(tag)
        const newExpandedTags = uniq([...expandedTags, ...parentTagsToExpand, tag.id])

        for (const parent of [tag.id, ...parentTagsToExpand].reverse()) {
            makeTagVisibleInTree(parent)
        }

        if (isEqual(newExpandedTags, expandedTags)) {
            scrollToItem()
            return
        }
        handleChangeExpanded(newExpandedTags)
        scrollToItem()
    }

    const scrollToItem = () => {
        setTimeout(() => {
            const item = document.getElementById(itemId)
            if (item) item.scrollIntoView({ block: "center" })
        }, 50)
    }

    const getAllParentIds = (tag: Tag) => {
        let maxNested = 20
        let parent = tags[tag.parentId]
        const ids = []
        while (Boolean(parent) && maxNested-- > 0) {
            ids.push(parent.id)
            if (!parent.parentId) break
            parent = tags[parent.parentId]
        }

        return ids
    }
}
