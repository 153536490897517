import { getInjectedPlugins, pipeInsertDataQuery, pipeInsertFragment, pipeTransformData, pipeTransformFragment, } from "@udecode/plate-common";
export const withPaste = (editor) => {
    const { insertData } = editor;
    editor.insertData = (dataTransfer) => {
        const inserted = [...editor.plugins].reverse().some((plugin) => {
            const insertDataOptions = plugin.editor.insertData;
            if (!insertDataOptions)
                return false;
            const injectedPlugins = getInjectedPlugins(editor, plugin);
            const { format, getFragment } = insertDataOptions;
            if (!format)
                return false;
            let data = dataTransfer.getData(format);
            if (!data)
                return false;
            if (!pipeInsertDataQuery(injectedPlugins, {
                data,
                dataTransfer,
            })) {
                return false;
            }
            data = pipeTransformData(injectedPlugins, {
                data,
                dataTransfer,
            });
            let fragment = getFragment === null || getFragment === void 0 ? void 0 : getFragment({
                data,
                dataTransfer,
            });
            if (!(fragment === null || fragment === void 0 ? void 0 : fragment.length))
                return false;
            fragment = pipeTransformFragment(injectedPlugins, {
                fragment,
                data,
                dataTransfer,
            });
            if (fragment.length === 0)
                return false;
            for (const frag of fragment) {
                frag.copied = true;
            }
            pipeInsertFragment(editor, injectedPlugins, {
                fragment,
                data,
                dataTransfer,
            });
            return true;
        });
        if (inserted)
            return;
        insertData(dataTransfer);
    };
    return editor;
};
