import { Box, Typography } from "@mui/material"
import EmptyStateDarkImage from "assets/svg/empty_list_dark.svg"
import EmptyStateLightImage from "assets/svg/empty_list_light.svg"
import { useIsDarkMode } from "hooks/useThemeMode"

export const TagsTreeEmptyState = () => {
    const isDarkTheme = useIsDarkMode()

    return (
        <Box
            height="100%"
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            color="text.primary"
            sx={{ opacity: 0.6 }}
        >
            <Box
                component="img"
                width={40}
                mb={1}
                src={isDarkTheme ? EmptyStateDarkImage : EmptyStateLightImage}
                sx={{ opacity: 0.6 }}
            />
            <Typography variant="body2">Your cards and tags will appear here</Typography>
        </Box>
    )
}
