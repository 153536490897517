import { Box, Dialog, DialogContent, SxProps, Theme, Typography } from "@mui/material"
import { posthogService } from "@recall/common"
import { Loader } from "components/layouts/components/Loader/AppLoader"
import { CLAIM_COUPON_BUTTON_CLICKED, COUPONS_SECTION_OPENED } from "constants/events"
import { FC, useEffect, useState } from "react"
import { ConfirmSection } from "./ConfirmSection"
import { CouponsSection } from "./CouponsSection"
import { MainSection } from "./MainSection"
import { useReferrals } from "./hooks/useReferrals"

interface Props {
    closeModal: () => void
}

export const ReferralsModal: FC<Props> = ({ closeModal }) => {
    const [activeSection, setActiveSection] = useState<"main" | "confirm" | "coupons">("main")
    const { isLoading, unclaimedCount, getReferrals } = useReferrals()

    useEffect(() => {
        if (activeSection === "main" && !isLoading) getReferrals()
        // eslint-disable-next-line
    }, [activeSection])

    const handleOpenConfirm = () => {
        setActiveSection("confirm")
        posthogService.captureEvent(CLAIM_COUPON_BUTTON_CLICKED)
    }

    const handleOpenMain = () => {
        setActiveSection("main")
    }

    const handleOpenCoupons = () => {
        setActiveSection("coupons")
        posthogService.captureEvent(COUPONS_SECTION_OPENED)
    }

    return (
        <Dialog onClose={closeModal} open>
            <DialogContent sx={styles.content}>
                <Typography variant="h4" sx={styles.title}>
                    Get 50% off
                </Typography>
                {isLoading ? (
                    <Loader />
                ) : (
                    <Box display="flex" flexDirection="column" flex={1}>
                        {activeSection === "main" && (
                            <MainSection
                                unclaimedCount={unclaimedCount}
                                handleOpenConfirm={handleOpenConfirm}
                                handleOpenCoupons={handleOpenCoupons}
                            />
                        )}
                        {activeSection === "confirm" && (
                            <ConfirmSection
                                referralsCount={unclaimedCount}
                                handleClose={handleOpenMain}
                                handleShowCoupons={handleOpenCoupons}
                            />
                        )}
                        {activeSection === "coupons" && (
                            <CouponsSection handleClose={handleOpenMain} />
                        )}
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    title: {
        textAlign: "center",
        mb: 3,
    },
    progress: {
        mb: 2,
    },
    progressLabel: {
        mt: 1,
        textAlign: "center",
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    content: {
        width: { xs: "100%", sm: 500 },
        minHeight: 290,
        display: "flex",
        flexDirection: "column",
        background: (theme) => theme.palette.background.default,
        border: (theme) => `1px solid ${theme.palette.background.paper}`,
        borderRadius: "16px",
    },
}
