import { Database } from "@nozbe/watermelondb"
import { createAssets, itemRepository, itemService } from "@recall/common"
import { DEFAULT_CARD } from "./data"

const initializeDefaultCard = async (db: Database, uid: string) => {
    const allItems = await itemRepository.getAll(db)
    if (allItems.length > 0) return

    const defaultCard = await itemRepository.getByName(db, DEFAULT_CARD.name)
    if (defaultCard) return

    const itemModel = await itemService.saveItemApi(db, DEFAULT_CARD, true, { isExpanded: true })
    if (!itemModel) return
    await itemModel.saveDeep()
    await createAssets(db, itemModel, uid)
}

export const itemHelper = { initializeDefaultCard }
