import { jsx as _jsx } from "react/jsx-runtime";
import { Link } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useLinkToolbarButton, useLinkToolbarButtonState } from "@udecode/plate-link";
import { useIsMobile } from "../../../hooks/useIsMobile";
export const LinkToolbarButton = () => {
    const state = useLinkToolbarButtonState();
    const { props } = useLinkToolbarButton(state);
    const isMobile = useIsMobile();
    return (_jsx(Tooltip, Object.assign({ title: "External Link", disableTouchListener: true }, { children: _jsx(IconButton, Object.assign({ onClick: props.onClick, size: "small", sx: {
                color: (theme) => props.pressed ? theme.palette.text.primary : theme.palette.action.active,
                backgroundColor: (theme) => props.pressed ? theme.palette.action.selected : undefined,
                ":hover": {
                    backgroundColor: (theme) => isMobile && props.pressed ? theme.palette.action.selected : undefined,
                },
            } }, { children: _jsx(Link, {}) })) })));
};
