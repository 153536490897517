var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Model } from "@nozbe/watermelondb";
import { field } from "@nozbe/watermelondb/decorators";
import { CONNECTION_PROPERTIES } from "../schema";
export class ConnectionPropertyModel extends Model {
    constructor() {
        super(...arguments);
        this.prepareSave = () => {
            if (this.isSaved === false)
                return this.prepareUpdate((record) => {
                    record.isSaved = true;
                });
            return null;
        };
    }
}
ConnectionPropertyModel.table = CONNECTION_PROPERTIES;
__decorate([
    field("name")
], ConnectionPropertyModel.prototype, "name", void 0);
__decorate([
    field("display")
], ConnectionPropertyModel.prototype, "display", void 0);
__decorate([
    field("description")
], ConnectionPropertyModel.prototype, "description", void 0);
__decorate([
    field("plural_display")
], ConnectionPropertyModel.prototype, "pluralDisplay", void 0);
__decorate([
    field("wikidata_pid")
], ConnectionPropertyModel.prototype, "wikidataPid", void 0);
__decorate([
    field("is_saved")
], ConnectionPropertyModel.prototype, "isSaved", void 0);
