import { Database, Q } from "@nozbe/watermelondb"
import {
    EDITOR_BLOCKS,
    EditorBlockData,
    EditorBlockModel,
    editorBlockRepository,
    ImageBlockData,
    ItemModel,
    ITEMS,
    QuestionModel,
    questionRepository,
    QUESTIONS,
    ROOT_TAG_ID,
    TagModel,
    tagRepository,
    TAGS,
} from "@recall/common"
import { keyBy } from "lodash"
import { DataMigrationImplementation } from "./types"

const setDefaultIsExpandedValue: DataMigrationImplementation = {
    name: "Set default is_expanded value",
    run: async (database: Database) => {
        await database.write(async (writer) => {
            const items = await database.collections.get<ItemModel>(ITEMS).query().fetch()

            for (const item of items) {
                await item.update((record) => {
                    record.isExpanded = !record.isReference
                })
            }
        })
    },
}

const setEditorBlocksText: DataMigrationImplementation = {
    name: "Set editor blocks text 1",
    run: async (database: Database) => {
        await database.write(async (writer) => {
            const editorBlocks = await database.collections
                .get<EditorBlockModel>(EDITOR_BLOCKS)
                .query()
                .fetch()

            for (const editorBlock of editorBlocks) {
                await editorBlock.update((record) => {
                    record.text = EditorBlockData.getFormattedText([editorBlock])
                })
            }
        })
    },
}

const removeStaleQuestions = {
    name: "Remove questions without items",
    run: async (database: Database) => {
        const questions = await questionRepository.getAll(database)

        for (const question of questions) {
            try {
                const item = await question.item.fetch()
                if (item._raw._status === "deleted") {
                    await question.delete()
                }
            } catch {
                await question.delete()
            }
        }
    },
}

const setItemsDescriptionAndImage: DataMigrationImplementation = {
    name: "Set items description and image",
    run: async (database: Database) => {
        await database.write(async (writer) => {
            const items = await database.collections
                .get<ItemModel>(ITEMS)
                .query(Q.where("is_saved", true))
                .fetch()

            for (const item of items) {
                const editorOrders = await item.editorOrders.fetch()
                if (!editorOrders.length) continue

                const editorBlocks = await Promise.all(
                    editorOrders[0].order.map((id) => editorBlockRepository.get(database, id))
                )
                let text = ""
                if (editorBlocks.length > 1) text = EditorBlockData.getText(editorBlocks.slice(1))
                else text = EditorBlockData.getText(editorBlocks)

                const description = text.length > 60 ? text.slice(0, 60).trim() + "..." : text
                const imageBlock = EditorBlockData.getFirstImageBlock(editorBlocks)

                let image = ""
                if (imageBlock) {
                    image =
                        imageBlock?.url ||
                        imageBlock?.options?.url ||
                        ImageBlockData.getUrl320(imageBlock) ||
                        ""
                }

                const updatedAt = item.updatedAt

                await item.update((record) => {
                    record.description = description
                    record.image = image
                    record.updatedAt = updatedAt
                })
            }
        })
    },
}

const removeQuestionsWithoutAnswers = {
    name: "Remove questions without answer 2",
    run: async (database: Database) => {
        const questions = await database.collections
            .get<QuestionModel>(QUESTIONS)
            .query(Q.where("is_saved", true))
            .fetch()

        for (const question of questions) {
            if (question.options.includes(question.correctAnswer)) continue
            await question.delete()
        }
    },
}

const assignParentTags = {
    name: "Assign parent tags 2",
    run: async (database: Database) => {
        await database.write(async (writer) => {
            const rootTag = await tagRepository.get(database, ROOT_TAG_ID)
            if (!rootTag) return

            let allTags = await database.collections.get<TagModel>(TAGS).query().fetch()

            const tagsById = keyBy(allTags, "id")
            const tagsWithParentIds = new Set()
            for (const tag of allTags) {
                if (tag?.parent?.id) tagsWithParentIds.add(tag.id)
            }

            for (const tag of allTags) {
                if (!tag.children || tagsWithParentIds.has(tag.id)) continue

                for (const child of tag.children) {
                    if (child in tagsById) {
                        const tagToUpdate = tagsById[child]
                        await tagToUpdate.update((record) => {
                            record.parent.set(tag)
                        })
                    }
                }
            }

            allTags = await database.collections.get<TagModel>(TAGS).query().fetch()
            for (const tag of allTags) {
                if (!tag.parent.id && tag.id !== ROOT_TAG_ID) {
                    await tag.update((record) => {
                        record.parent.set(rootTag)
                    })
                }
            }
        })
    },
}

const allDataMigrations = [
    setDefaultIsExpandedValue,
    setEditorBlocksText,
    removeStaleQuestions,
    setItemsDescriptionAndImage,
    removeQuestionsWithoutAnswers,
    assignParentTags,
]

export default allDataMigrations
