import { tutorials } from "@recall/common"
import { isEqual } from "lodash"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { userRepository } from "repositories/userRepository"
import { RootState } from "storage/redux/rootReducer"

export const useCompleteTutorial = () => {
    const completedTutorials = useSelector((state: RootState) => state.user.tutorial.completed)
    const userId = useSelector((state: RootState) => state.user.uid)
    const userLoading = useSelector((state: RootState) => state.app.userLoading)

    const completeTutorial = async (completed: tutorials[]) => {
        const user = await userRepository.getUser(userId)

        if (isEqual(user?.tutorial?.completed, completed)) return
        await userRepository.upsertUser(userId, { tutorial: { completed } })
    }

    useEffect(() => {
        if (userLoading) return

        const tutorials = Object.keys(completedTutorials)
        const completed = tutorials.filter((tutorial) => completedTutorials[tutorial])

        completeTutorial(completed as tutorials[])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completedTutorials, userLoading])
}
