import { useDatabase, withObservables } from "@nozbe/watermelondb/react"
import {
    ConnectionModel,
    deleteConnectionById,
    getGroupedLinks,
    LinkItem,
    Links as LinksComponent,
} from "@recall/common"
import { useOpenItem } from "hooks/items/useOpenItem"
import { FC, memo, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { LinksLoader } from "./LinksLoader"

export const LINKS_DIV_ID = "id-links"

interface Props {
    id: string
    connections: ConnectionModel[]
    readOnly?: boolean
}

const LinksComp: FC<Props> = ({ id, connections, readOnly = false }) => {
    const db = useDatabase()
    const [groupedLinks, setGroupedLinks] = useState<{ key: string; value: LinkItem[] }[]>([])
    const activeTutorial = useSelector((state: RootState) => state.user.tutorial.active)
    const [isLoading, setIsLoading] = useState(true)
    const { openItemById } = useOpenItem()

    useEffect(() => {
        setLinks()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connections])

    useEffect(() => {
        if (activeTutorial !== "none") {
            setIsLoading(false)
        }
    }, [activeTutorial])

    const setLinks = async () => {
        const links = await getGroupedLinks(connections)
        setGroupedLinks(links)
    }

    const handleDelete = async (id: string) => {
        await deleteConnectionById(db, id)
    }

    if (!groupedLinks.length) return null

    if (isLoading) return <LinksLoader setLoading={setIsLoading} />

    return (
        <LinksComponent
            itemId={id}
            readOnly={readOnly}
            connectionsCount={connections.length}
            groupedLinks={groupedLinks}
            handleDelete={handleDelete}
            openItemById={openItemById}
        />
    )
}

const enhance = withObservables(["connections"], ({ connections }) => ({
    connections,
}))

export const Links = memo(enhance(LinksComp))
