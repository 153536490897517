export interface BreadcrumbItem {
    path: string
    label: string
    position?: number
}

export interface AppState {
    platform: "web" | "ios" | "android"
    isNative: boolean
    themeMode: themeModeEnum
    loading: boolean
    appLoading: boolean
    userLoading: boolean
    extensionState: extensionStateEnum
    importState: importEnum
    isUpgradePlanModalOpen: boolean
    isScrapingModalOpen: boolean
    visibleItems: number
    scrollPosition: number
    questionsLoadingItemIds: string[]
    breadcrumbs: BreadcrumbItem[]
    isInitialSyncFinished: boolean
    isCreatingExtensionItems: boolean
    searchTerm: string
}

export enum themeModeEnum {
    LIGHT = "light",
    SYSTEM = "system",
    DARK = "dark",
}

export enum importEnum {
    CLOSED = "closed",
    OPENED = "opened",
    IMPORTING_CLOSED = "importing-closed",
    IMPORTING_BOOKMARKS_CLOSED_FINISHED = "importing-bookmarks-closed-finished",
}

export enum extensionStateEnum {
    NOT_LOGGED_IN = "not-logged-in",
    LOGGED_IN = "logged-in",
    LOADING = "loading",
    NOT_INSTALLED = "not-installed",
}
