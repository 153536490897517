import { Box, Paper, Typography } from "@mui/material"
import { posthogService, Spinner } from "@recall/common"
import { REVIEW_UNSUBSCRIBE_EVENT } from "constants/events"
import { HOME_PATH, SETTINGS_PATH } from "constants/routes"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router"
import { reviewUnsubscribe } from "services/firebase/firebaseFunction"
import { RootState } from "storage/redux/rootReducer"

export const ReviewUnsubscribe = () => {
    const uid = useSelector((state: RootState) => state.user.uid)
    const [{ isLoading, email }, setEmail] = useState<{ email: string; isLoading: boolean }>({
        email: null,
        isLoading: true,
    })
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        const token = getTokenFromLocation()
        if (token) unsubscribe(token)
        else history.push(HOME_PATH)
    }, [])

    const getTokenFromLocation = () => {
        const params = location.search.substring(1).split("&")
        for (let param of params) {
            const [key, value] = param.split("=")
            if (key === "token") {
                return decodeURIComponent(value)
            }
        }
        return null
    }

    const unsubscribe = async (token: string) => {
        const result = await reviewUnsubscribe({ token })
        const email = result.data

        if (!email && uid) {
            history.push(SETTINGS_PATH)
        } else {
            posthogService.captureEvent(REVIEW_UNSUBSCRIBE_EVENT)
            setEmail({ email, isLoading: false })
        }
    }

    return (
        <Box>
            {isLoading ? (
                <Spinner />
            ) : (
                <Paper sx={{ p: 1.5 }}>
                    {!email ? (
                        <>
                            <Typography variant="h5">Unsubscribe from Recall Review</Typography>
                            <Typography mt={0.5}>
                                Log in to your account and navigate to your settings to unsubscribe.
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography variant="h5">
                                You have unsubscribed from Recall Review emails
                            </Typography>
                            <Typography mt={0.5}>
                                To resubscribe, visit your account settings.
                            </Typography>
                            <Typography>{email}</Typography>
                        </>
                    )}
                </Paper>
            )}
        </Box>
    )
}
