import React from "react"
import { DarkLayout } from "./DarkLayout"

interface Props {
    isContained?: boolean
}

const AuthLayoutComponent: React.FC<React.PropsWithChildren<Props>> = ({
    children,
    isContained = true,
}) => {
    return <DarkLayout isContained={isContained}>{children}</DarkLayout>
}

export const AuthLayout = React.memo(AuthLayoutComponent)
